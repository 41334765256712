import React, { useCallback, useEffect, useState } from "react";
import wait from "./wait";
import { noop } from "lodash";

// simple hasNext function that will repeat $times
export const repeatTimes = (times: number): (() => boolean) => {
  let round = 0;
  return () => round++ !== times;
};

export const GenericRepeatedGame = ({
  // the component to repeat - must accept an onComplete callback
  GameComponent,

  // func that returns the next game props
  getNextGameProps,

  // what to do when an individual round is complete
  onRoundComplete = noop,

  onRoundCompleteDelay = 800,

  // what to do when all rounds complete
  onComplete,

  // a function that returns a boolean whether to continue
  hasNext,
}: {
  GameComponent: any;
  getNextGameProps: (trialNum: number) => any;
  onRoundComplete?: (...completionProps: any) => void;
  onRoundCompleteDelay?: number;
  onComplete: () => void;
  hasNext: () => boolean;
}) => {
  const [gameState, setGameState] = useState({ trialNum: -1 });

  const initNewGame = useCallback(
    (trialNum) => {
      // for the first round only, we pass a `withIntro` so we know
      // to play introductory audio, etc.
      setGameState({
        trialNum,
        withIntro: trialNum === 1,
        ...getNextGameProps(trialNum),
      });
    },
    [getNextGameProps]
  );

  const onGameComplete = useCallback(
    async (...completeProps) => {
      onRoundComplete(...completeProps);
      await wait(onRoundCompleteDelay);
      if (hasNext()) {
        initNewGame(gameState.trialNum + 1);
      } else {
        onComplete();
      }
    },
    [
      onRoundCompleteDelay,
      gameState,
      hasNext,
      initNewGame,
      onComplete,
      onRoundComplete,
    ]
  );

  useEffect(() => {
    if (hasNext()) {
      initNewGame(1);
    } else {
      onComplete();
    }
  }, [hasNext, initNewGame, onComplete]);

  if (!gameState || gameState.trialNum === -1) {
    return null;
  }

  console.log("NEW GAME STATE!", gameState);

  return React.createElement(GameComponent, {
    key: gameState.trialNum,
    onComplete: onGameComplete,
    ...gameState,
  });
};
