import generators from "./generators";

/**
 * @class defines a manager for use with KSEP assessments. KSEPAssessmentManager generates and serves
 * questions while also keeping track of the score.
 */
export default class KSEPAssessmentManager {
  /**
   * @constructs KSEPAssessmentManager by generating questions and assigning initial class member values.
   * @param {Object} allQuestions      contains information about categories and possible question choices.
   * @param {Object} distribution      contains the number of questions to be asked per category.
   */
  constructor(allQuestions, distribution, resources) {
    /**
     * @private {Array}  questions contains the generated questions that will be asked during this assessment.
     * @private {number} score is the users current score on the assessment.
     * @private {number} questionIndex is the current index of the question to ask.
     */
    this.questions = this.getQuestionSet(allQuestions, distribution, resources);
    this.score = 0;
    this.questionIndex = 0;
  }

  /**
   * Generates a random question set with the number of questions for each category as provided in distribution.
   * @param {Object} allQuestions  contains information about categories and possible question choices.
   * @param {Object} distribution  describes the number of questions to generate per category.
   * @return {Array}               includes all questions for this particular assessment instance.
   */
  getQuestionSet(allQuestions, distribution, resources) {
    const categories = Object.keys(allQuestions);
    const assessmentQuestions = [];

    // We generate questions for each category independently as each may have a different format in allQuestions
    categories.forEach((category) => {
      const categoryData = allQuestions[category];
      const numQuestions = distribution[category];
      const generator = generators[category];

      const categoryQuestions = generator(
        categoryData,
        numQuestions,
        resources
      ).map(
        // Add category and activity type to question props, so that event data includes the category
        (question) => ({
          ...question,
          props: { ...question.props, category, activity: "ksepAssessment" },
        })
      );

      assessmentQuestions.push(...categoryQuestions);
    });

    return assessmentQuestions;
  }

  /**
   * Gets the next question to be asked in the assessment.
   * @return {Object} containing the question type as well as the props necessary to instantiate a game for the question.
   */
  getNextGroup() {
    const question = this.questions[this.questionIndex];

    // Increment our question index
    this.questionIndex++;

    return question;
  }

  /** Increments our score by 1. */
  markAnswerCorrect() {
    this.score++;
  }

  /**
   * Gets the score for the user.
   * @return {string} representing the user's score as a percent.
   */
  getTotalScore() {
    // Convert the score to a percent and round to two decimal places
    return ((this.score / this.questions.length) * 100).toFixed(2);
  }

  /**
   * Checks if this is the last question.
   * @return {boolean} describing if the user is on the last question.
   */
  isLastQuestion() {
    return this.questionIndex === this.questions.length;
  }

  /**
   * Retrieves the current progress of the user within the assessment.
   * @return {Number} the percentage progress of the user in the assessment
   */
  getCurrentProgress() {
    return this.questionIndex / this.questions.length;
  }
}
