import React, { useContext, useEffect } from "react";
import AudioContext from "../../../../util/Audio/context";
import wait from "../../../../util/wait";
import Guide from "../../../Guide/Guide";
import RoundedText from "../../../RoundedText/RoundedText";
import styles from "./AssessComplete.module.scss";

export default ({ score, outOf, onFinished }) => {
  const audioService = useContext(AudioContext);
  useEffect(() => {
    const audioPromise = async () => {
      await wait(800);

      let narratorFeedback = [];
      if (score <= 10) {
        narratorFeedback = ["BetterLuckNextTime"];
      } else if (score <= 15) {
        Math.random() < 0.5
          ? (narratorFeedback = ["LetsReview"])
          : (narratorFeedback = ["WorkOnSkills"]);
      } else {
        narratorFeedback = ["Awesome"];
      }
      await audioService.composeAndPlayAudio([
        {
          directory: "score",
          files: [`${score}out20`],
        },
        {
          directory: "narrator",
          file: narratorFeedback,
        },
      ]);

      await wait(1000);
      onFinished();
    };
    audioPromise();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Guide />
      <div className={styles.textContainer}>
        <RoundedText>
          {score} / {outOf}
        </RoundedText>
      </div>
    </div>
  );
};
