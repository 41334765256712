import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BottomButton,
  GameContainer,
  GameWrapper,
  PublicSpiralTopBar,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import Shelf from "../../components/Shelf/Shelf";
import { ClipLoader } from "react-spinners";
import ShelfImageItem from "../../components/Shelf/ShelfImageItem";
import { WithAnonymousLogin } from "../../util/anonymousLogin";

//hardcoded for now - todo move to JSON
const rhymes = [
  "At1",
  "At2",
  "At3",
  "Ed1",
  "Ed2",
  "Ack1",
  "Og1",
  "Op1",
  "Ee1",
  "En1",
  "Ing1",
  "All1",
  "Ap1",
  "Ell1",
  "Et1",
  "Ig1",
  "In1",
  "It1",
  "Oh1",
  "Ump1",
  "Ump2",
];

const getBooks = async (items) => {
  const promises = items.map((item) => {
    return axios
      .get(`${process.env.PUBLIC_URL}/content/ebooks/books/${item}.json`)
      .then((resp) => resp.data);
  });
  return await Promise.all(promises);
};

export const GlenRhymesCategoryWithAuth = () => <Category withAuth={true} />;
export const GlenRhymesCategoryWithoutAuth = () => (
  <WithAnonymousLogin
    deploymentId={process.env.REACT_APP_GLEN_RHYMES_ANONYMOUS_DEPLOYMENT_ID}
  >
    <Category withAuth={false} />
  </WithAnonymousLogin>
);

const Category = ({ withAuth }) => {
  const { deploymentId } = useParams();
  const router = useHistory();
  const booksPerShelf = 4;
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const books = await getBooks(rhymes);
      setBooks(books);
    };
    fetch();
  }, []);

  const gotoRhyme = useCallback(
    (bookId) =>
      router.push(
        withAuth
          ? `/deployment/${deploymentId}/glenLearn/glenRhymes/rhyme/${bookId}`
          : `/glenRhymes/rhyme/${bookId}`
      ),
    [deploymentId, router, withAuth]
  );

  const mapped = useCallback(
    (shelfNum) =>
      books
        .slice(
          (booksPerShelf + 1) * (shelfNum - 1),
          (booksPerShelf + 1) * shelfNum
        )
        .map((book) => (
          <ShelfImageItem
            image={`${process.env.PUBLIC_URL}${book.cover}`}
            onClick={() => gotoRhyme(book.id)}
            booksPerShelf={booksPerShelf}
          />
        )),
    [books, gotoRhyme]
  );

  const shelves = useMemo(() => {
    return rhymes.length / (booksPerShelf + 1);
  }, []);

  const shelfDivs = useMemo(() => {
    let shelvesDivs = [];
    for (let i = 0; i < rhymes.length; i++) {
      if (shelves > i) {
        shelvesDivs.push(
          <div>
            {books && (
              <Shelf
                items={mapped(i + 1)}
                centerMode={true}
                booksPerShelf={booksPerShelf}
              />
            )}
          </div>
        );
      }
    }
    return <div> {shelvesDivs} </div>;
  }, [books, mapped, shelves]);
  const onClickSpiral = () => history.push("/glenRhymes");
  const onClickInfo = () => history.push("/about/glenRhymes");
  const goBack = () => history.push(`/deployment/${deploymentId}/glenLearn`);

  const history = useHistory();

  return (
    <GameWrapper>
      {withAuth ? (
        <AuthedAndProfiledTopBar />
      ) : (
        <PublicSpiralTopBar
          onClickSpiral={onClickSpiral}
          onClickInfo={onClickInfo}
        />
      )}
      <GameContainer>
        {books.length === 0 && <ClipLoader size={75} />}
        {shelfDivs}
      </GameContainer>
      {withAuth && (
        <BottomBar
          left={
            <BottomButton
              src={process.env.PUBLIC_URL + "/images/back.png"}
              onClick={goBack}
            />
          }
        />
      )}{" "}
    </GameWrapper>
  );
};
