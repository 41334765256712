import React from "react";
import MultipleChoiceQuestionWithoutAnalytics from "./MultipleChoiceQuestion";
import MultipleChoiceQuestionWithAnalytics from "./MultipleChoiceQuestionWithAnalytics";
import PropTypes from "prop-types";

/**
 * MultipleChoiceQuestion is a generic multiple choice game component. It allows
 * full configuration of the layout, prompt, and answer types (described in detail)
 * in subcomponents.js.
 *
 * `onComplete` is called when the user finishes the question, with one argument, `wasCorrect`.
 *
 * If `showCorrectAnswer` is on, the multiple choice question will circle the correct answer
 * upon question completion if the user was incorrect.
 *
 * If `analytics` is on, events for when the user starts and completes the question
 * will be sent to the backend. Event data includes all props passed to this component.
 * The completion event will also include `wasCorrect`.
 */
const MultipleChoiceQuestion = ({ analytics, ...props }) => {
  if (analytics) {
    return <MultipleChoiceQuestionWithAnalytics {...props} />;
  } else {
    return <MultipleChoiceQuestionWithoutAnalytics {...props} />;
  }
};

MultipleChoiceQuestion.propTypes = {
  onComplete: PropTypes.func,
  layout: PropTypes.shape({
    type: PropTypes.string.isRequired,
    props: PropTypes.object,
  }),
  prompt: PropTypes.shape({
    type: PropTypes.string.isRequired,
    props: PropTypes.object,
  }),
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      props: PropTypes.object,
      correct: PropTypes.bool.isRequired,
    })
  ),
  analytics: PropTypes.bool,
  showCorrectAnswer: PropTypes.bool,
  showReviewScreen: PropTypes.bool,
};

export default MultipleChoiceQuestion;
