import React, { useContext, useEffect, useMemo, useState } from "react";
import noop from "lodash/noop";
import AudioRepeat from "../../components/AudioRepeat/AudioRepeat";
import styles from "./SoundClick1.module.scss";
import RoundedTextWithCheck from "../../components/RoundedTextWithCheck/RoundedTextWithCheck";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";
import shuffle from "lodash/shuffle";
import DisableOverlay from "../../components/DisableOverlay/DisableOverlay";
import classnames from "classnames";

const Option = ({
  word,
  onClick,
  size = "xl",
  isCorrect = false,
  isIncorrect = false,
}) => {
  return (
    <div className={`${styles.option} animated fadeIn`} onClick={onClick}>
      <RoundedTextWithCheck
        size={size}
        isCorrect={isCorrect}
        isIncorrect={isIncorrect}
      >
        <div>
          {word.length > 1 ? (
            <>
              <strong>{word[0]}</strong>
              {word.substr(1)}
            </>
          ) : (
            word
          )}
        </div>
      </RoundedTextWithCheck>
    </div>
  );
};

export default ({
  targetSound,
  correctWord,
  confusionWords,
  onStart = noop,
  onComplete = noop,
}) => {
  const audioService = useContext(AudioContext);
  const [showAudioHolder, setShowAudioHolder] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [clickedWord, setClickedWord] = useState(null);
  const allWords = useMemo(() => shuffle([...confusionWords, correctWord]), []);

  useEffect(() => {
    onStart();
    const animation = async () => {
      await playTargetSound();
      setShowAudioHolder(true);
      await wait(1000);
      setShowOptions(true);
    };
    animation();
  }, []);

  const onClickOption = async (word) => {
    setClickedWord(word);
    const wasCorrect = word === correctWord;
    const file = wasCorrect ? "correct" : "incorrect";
    await audioService.composeAndPlaySingleAudio("soundfx", file);
    //if (wasCorrect) {
    //await audioService.playFX(getRandomEncouragementFX());
    //}
    await wait(400);
    onComplete(wasCorrect);
  };

  const playTargetSound = async () => {
    return await audioService.composeAndPlayAudio(
      Array.isArray(targetSound) // if targetSound is an array, it will contain prompts at index 0 and consonants at index 1
        ? [
            {
              directory: "prompts",
              files: [targetSound[0]],
            },
            {
              directory: "consonants",
              files: [targetSound[1]],
            },
          ]
        : [
            {
              directory: "consonants",
              files: [targetSound],
            },
          ]
    );
  };

  const renderOptions = (words, size) => {
    return words.map((w) => (
      <Option
        key={w}
        word={w}
        size={size}
        isIncorrect={w !== correctWord && clickedWord === w}
        isCorrect={w === correctWord && clickedWord === w}
        onClick={() => onClickOption(w)}
      />
    ));
  };

  const isFourWords = allWords.length === 4;
  const boxSize = isFourWords ? "md" : "xxxl";

  return (
    <DisableOverlay enable={clickedWord !== null} style={{ height: "100%" }}>
      <div className={`row ${styles.gameContainer} h-100`}>
        <div className="col-4">
          {showOptions && (
            <div className={styles.optionsContainer}>
              {renderOptions(
                isFourWords ? allWords.slice(2, 4) : allWords.slice(1, 2),
                boxSize
              )}
            </div>
          )}
        </div>
        <div className="col-4">
          {showAudioHolder && (
            <div
              className={classnames(styles.audioRepeatContainer, {
                [styles.fullHeight]: isFourWords,
                [styles.halfHeight]: allWords.length === 2,
              })}
            >
              <AudioRepeat onClick={playTargetSound} />
            </div>
          )}
        </div>
        <div className="col-4">
          {showOptions && (
            <div className={styles.optionsContainer}>
              {renderOptions(
                isFourWords ? allWords.slice(0, 2) : allWords.slice(0, 1),
                boxSize
              )}
            </div>
          )}
        </div>
      </div>
    </DisableOverlay>
  );
};
