import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenVocabProfile } from "../../../util/Profile/ProfileUtil";
import Strengthen from "../../../components/Lessons/Strengthen/Strengthen";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const profile = useGlenVocabProfile();
  const lessonResource = useResourceFromRegistry("vocabLessons");
  const lessonGroupResource = useResourceFromRegistry("vocabLessonGroups");
  return (
    <Strengthen
      profile={profile}
      lessonGroupResource={lessonGroupResource}
      lessonResource={lessonResource}
      type={types.GLEN_VOCAB}
    />
  );
};
