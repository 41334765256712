import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

export default ({ registry, children }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const populate = async () => {
      await registry.populate();
      setLoaded(true);
    };
    populate();
  }, []);

  if (!loaded) {
    return <ClipLoader size={75} />;
  }
  return children;
};
