import noop from "lodash/noop";
import { activity } from "../../util/Analytics/events";
import React from "react";
import SoundClick from "./SoundClick";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.SOUND_CLICK,
    targetWord: props.targetWord,
    confusionWords: props.confusionWords,
  };
  const onCompleteOverride = (wasCorrect) => {
    completeEvent({ ...eventData, wasCorrect });
    onComplete(wasCorrect);
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  return (
    <SoundClick
      {...props}
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
    />
  );
};
