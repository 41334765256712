import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BottomButton,
  GameContainer,
  GameWrapper,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CongratsStar from "../../CongratsAnimation/CongratsAnimation";
import LessonGroupCard from "../../../components/GroupCard/GroupCard";
import { useCongratsStarAnimation } from "../util/hooks";
import Confetti, {
  ConfettiAnimation,
} from "../../../components/Confetti/Confetti";
import Shelf from "../../Shelf/Shelf";
import styles from "./LessonGroups.module.scss";
import DisableOverlay from "../../DisableOverlay/DisableOverlay";

export default ({ profile, lessonGroupsResource, type, bottomBar = null }) => {
  const showCongratsStar = useCongratsStarAnimation();

  const history = useHistory();
  const { deploymentId } = useParams();
  const location = useLocation();

  const gotoGroup = (groupId) =>
    history.push(`/deployment/${deploymentId}/${type}/group/${groupId}`);

  // if this is a brand new user, we set last mastered to 1 to make the first group available.
  const lastMasteredLesson = profile.getLastMasteredLesson();
  const profileBoxRef = useRef(null);

  const goBack = () => history.push(`/deployment/${deploymentId}/glenLearn`);
  const lessonGroups = useMemo(() => lessonGroupsResource.getContent(), [
    lessonGroupsResource,
  ]);

  useEffect(() => {
    let forceLastMasteredLesson = new URLSearchParams(location.search).get(
      "forceLastMasteredLesson"
    );
    if (forceLastMasteredLesson) {
      profile.setLastMasteredLesson(parseInt(forceLastMasteredLesson, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeCardIndex = lessonGroups.findIndex(
    (group) =>
      lastMasteredLesson + 1 >= group.start &&
      lastMasteredLesson + 1 <= group.end
  );
  const [clicksDisabled, setClicksDisabled] = useState(false);

  const onGuideStop = useCallback(() => setClicksDisabled(false), []);

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar
        ref={profileBoxRef}
        withAdminDashboardIcon={true}
      />
      <GameContainer>
        {showCongratsStar && <CongratsStar elem={profileBoxRef.current} />}
        {showCongratsStar && (
          <Confetti animation={ConfettiAnimation.FIREWORKS} />
        )}

        <Shelf
          autoscroll={false}
          infinite={false}
          noBackground={true}
          gotoSlideNum={activeCardIndex}
          items={lessonGroupsResource.getContent().map((group, i) => {
            const isCompleted = lastMasteredLesson >= group.end;
            const isActive = lastMasteredLesson >= group.start - 1;
            return (
              <DisableOverlay enable={clicksDisabled}>
                <div className={styles.centered}>
                  <LessonGroupCard
                    group={group}
                    isActive={isActive}
                    isPlayable={i === activeCardIndex}
                    isCompleted={isCompleted}
                    isLarge={true}
                    onClick={() => gotoGroup(i)}
                    shouldSkipIntro={profile
                      .getLessonGroupsWithPlayedIntro()
                      .includes(i)}
                    onGuideStart={() => {
                      setClicksDisabled(true);
                      profile.markPlayedIntroForLessonGroupId(i);
                    }}
                    onGuideStop={onGuideStop}
                    withRangeText={true}
                  />
                </div>
              </DisableOverlay>
            );
          })}
        />
      </GameContainer>
      {/*unless a bottom bar is provided, use a back button*/}
      {bottomBar !== null ? (
        bottomBar
      ) : (
        <BottomBar
          left={
            <BottomButton
              src={process.env.PUBLIC_URL + "/images/back.png"}
              onClick={goBack}
            />
          }
        />
      )}
    </GameWrapper>
  );
};
