import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenVocabProfile } from "../../../util/Profile/ProfileUtil";
import Lesson from "../../../components/Lessons/Lesson/Lesson";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const glenVocabProfile = useGlenVocabProfile();
  const vocabLessonResource = useResourceFromRegistry("vocabLessons");
  const vocabLessonGroupResource = useResourceFromRegistry("vocabLessonGroups");

  return (
    <Lesson
      profile={glenVocabProfile}
      lessonResource={vocabLessonResource}
      lessonGroupResource={vocabLessonGroupResource}
      type={types.GLEN_VOCAB}
      allowAssessStrengthen={false}
    />
  );
};
