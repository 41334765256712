/**
 * Each key in the KSEP_assessment.json is mapped to a generator function of
 * the form `(categoryData, numQuestions) => array of questions`.
 *
 * A generator function handles creating unique questions from a particular
 * category. The manager uses these exported generating functions by providing
 * them with the corresponding category data, as well as the number of questions
 * in the distribution for that category.
 */
import { activity } from "../../../../util/Analytics/events";
import numberToWord from "../../../../constants/numberToWord";
import { getMCProps } from "./adapters";
import { sampleFromAnswers, sampleFromCategories } from "./samplers";

const numbersTextPromptGenerator = (data, numQuestions, flashcardResources) => {
  const NUMBERS_1A_AUDIO_PATH = `/content/audio/prompts/which_picture_has.mp3`;
  const NUMBERS_1B_AUDIO_PATH_PLURAL = `/content/audio/prompts/shapes.mp3`;
  const NUMBERS_1B_AUDIO_PATH_SINGULAR = `/content/audio/prompts/shape.mp3`;

  const numberAnswers = sampleFromAnswers(data, numQuestions, 4);

  return numberAnswers.map((choices) => {
    const audio = `${process.env.PUBLIC_URL}/content/audio/numbers/${
      numberToWord[choices[0]]
    }.mp3`;

    const followedByAudio =
      choices[0] === 1
        ? NUMBERS_1B_AUDIO_PATH_SINGULAR
        : NUMBERS_1B_AUDIO_PATH_PLURAL;

    return getMCProps("grid", "text", choices[0], "number", choices, [
      NUMBERS_1A_AUDIO_PATH,
      audio,
      followedByAudio,
    ]);
  });
};

const numbersShapesPromptGenerator = (data, numQuestions) => {
  const NUMBERS_2_AUDIO_PATH = `/content/audio/prompts/how_many_shapes_do_you_see.mp3`;

  const textAnswers = sampleFromAnswers(data, numQuestions, 4);

  return textAnswers.map((choices) =>
    getMCProps(
      "column",
      "number",
      choices[0],
      "text",
      choices,
      NUMBERS_2_AUDIO_PATH
    )
  );
};

const numbersGenerator = (data, numQuestions, resources) => {
  // For small numbers (<= 10), prompt with text and show answers as numbers
  // For large numbers (> 10), prompt with numbers and show answers as text
  const { number: numberData, text: textData } = data;

  const numberQuestions = numbersTextPromptGenerator(
    numberData,
    Math.floor(numQuestions / 2),
    resources.flashcards
  );
  const textQuestions = numbersShapesPromptGenerator(
    textData,
    Math.ceil(numQuestions / 2)
  );

  return [...numberQuestions, ...textQuestions];
};

const flashcardImageGenerator = (data, numQuestions, resources) => {
  const answers = sampleFromAnswers(data, numQuestions, 4);

  return answers.map((choices) => {
    const { meaning, audio } = resources.flashcards.getFlashcard(choices[0]);
    const images = choices.map(
      (choice) => resources.flashcards.getFlashcard(choice).image
    );
    return getMCProps("grid", "text", meaning, "image", images, audio);
  });
};

const shapeImageGenerator = (data, numQuestions, resources) => {
  const answers = sampleFromAnswers(data, numQuestions, 4);

  return answers.map((choices) => {
    const { audio } = resources.flashcards.getFlashcard(choices[0]);
    const images = choices.map(
      (choice) => resources.shapes.getShape(choice).image
    );
    return getMCProps("grid", "text", choices[0], "image", images, audio);
  });
};

const soundToConsonantGenerator = (data, numQuestions, phonicsResources) => {
  const answers = sampleFromAnswers(data, numQuestions, 2);

  return answers.map((choices) => {
    const { audio } = phonicsResources.getPhonic(choices[0]);
    return getMCProps("row", "audio", audio, "letter", choices);
  });
};

const consonantToWordGenerator = (data, numQuestions) => {
  const WHICH_FITS_AUDIO_PATH = "which_word_fits_with_the_sound";
  const questions = sampleFromCategories(data, numQuestions, 3, 1);

  return questions.map(([consonant, correctAnswers, incorrectAnswers]) => ({
    questionType: activity.CONSONANT_SOUNDS,
    props: {
      consonant: consonant,
      answer: correctAnswers[0],
      correctWords: correctAnswers.slice(1, 3),
      confusionWord: incorrectAnswers[0],
      targetOverride: {
        audio: [WHICH_FITS_AUDIO_PATH, consonant],
      },
      showMeaning: false,
    },
  }));
};

const consonantsGenerator = (data, numQuestions, resources) => {
  // Like numbers, split consonants category 50/50 into sound and word questions
  const soundQuestions = soundToConsonantGenerator(
    Object.keys(data),
    Math.floor(numQuestions / 2),
    resources.phonics
  );
  const consonantQuestions = consonantToWordGenerator(
    data,
    Math.ceil(numQuestions / 2)
  );

  return [...soundQuestions, ...consonantQuestions];
};

const lettersGenerator = (data, numQuestions, resources) => {
  const LETTER_1_AUDIO_PATH = `/content/audio/prompts/which_letter_does.mp3`;
  const LETTER_2_AUDIO_PATH = `/content/audio/prompts/start_with.mp3`;
  const questions = sampleFromCategories(data, numQuestions, 1, 3);

  return questions.map(([category, correctAnswers, incorrectAnswers]) => {
    const { image, audio } = resources.flashcards.getFlashcard(
      correctAnswers[0]
    );
    const answers = [correctAnswers[0], ...incorrectAnswers];
    return getMCProps("column", "image", image, "letter", answers, [
      LETTER_1_AUDIO_PATH,
      audio,
      LETTER_2_AUDIO_PATH,
    ]);
  });
};

const rhymesGenerator = (data, numQuestions, resources) => {
  const RHYME_AUDIO_PATH = `/content/audio/prompts/which_picture_is_for_a_word_that_rhymes_with.mp3`;
  const questions = sampleFromCategories(data, numQuestions, 2, 3);

  return questions.map(([category, correctAnswers, incorrectAnswers]) => {
    const { meaning, audio } = resources.flashcards.getFlashcard(
      correctAnswers[0]
    );
    const answerImages = [correctAnswers[1], ...incorrectAnswers].map(
      (key) => resources.flashcards.getFlashcard(key).image
    );
    return getMCProps("grid", "text", meaning, "image", answerImages, [
      RHYME_AUDIO_PATH,
      audio,
    ]);
  });
};

const generators = {
  numbers: numbersGenerator,
  colors: flashcardImageGenerator,
  shapes: shapeImageGenerator,
  consonants: consonantsGenerator,
  letters: lettersGenerator,
  rhymes: rhymesGenerator,
};

export default generators;
