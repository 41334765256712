import React from "react";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BottomButton,
  GameContainer,
  GameWrapper,
  PublicSpiralTopBar,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import CategoryIcon from "../../components/CategoryIcon/CategoryIcon";
import { useHistory, useParams } from "react-router-dom";
import { WithAnonymousLogin } from "../../util/anonymousLogin";

export const GlenBooksCategoriesWithAuth = () => <Categories withAuth={true} />;
export const GlenBooksCategoriesWithoutAuth = () => (
  <WithAnonymousLogin
    deploymentId={process.env.REACT_APP_GLEN_BOOKS_ANONYMOUS_DEPLOYMENT_ID}
  >
    <Categories withAuth={false} />
  </WithAnonymousLogin>
);

const Categories = ({ withAuth }) => {
  const history = useHistory();
  const { deploymentId } = useParams();
  const goBack = () => history.push(`/deployment/${deploymentId}/glenLearn`);
  const onClick = (category) =>
    history.push(
      withAuth
        ? `/deployment/${deploymentId}/glenLearn/glenBooks/category/${category}`
        : `/glenBooks/category/${category}`
    );

  const onClickSpiral = () => history.push("/glenBooks/categories");
  const onClickInfo = () => history.push("/about/glenBooks");

  return (
    <GameWrapper>
      {withAuth ? (
        <AuthedAndProfiledTopBar />
      ) : (
        <PublicSpiralTopBar
          onClickSpiral={onClickSpiral}
          onClickInfo={onClickInfo}
        />
      )}
      <GameContainer>
        <div className="row p-5 text-center">
          <div className="col-6">
            <CategoryIcon
              imageSrc={
                process.env.PUBLIC_URL + "/images/app_icons/GLENbooks.png"
              }
              imageText={"Classics"}
              onClick={() => onClick("classics")}
            />
          </div>
          <div className="col-6">
            <CategoryIcon
              imageSrc={
                process.env.PUBLIC_URL + "/images/app_icons/GLENbooks.png"
              }
              imageText={"Originals"}
              onClick={() => onClick("originals")}
            />
          </div>
        </div>
        <div className="row p-5 text-center">
          <div className="col-6">
            <CategoryIcon
              imageSrc={
                process.env.PUBLIC_URL + "/images/app_icons/GLENbooks.png"
              }
              imageText={"Cuentos Clásicos"}
              onClick={() => onClick("clasicos")}
            />
          </div>
          <div className="col-6">
            <CategoryIcon
              imageSrc={
                process.env.PUBLIC_URL + "/images/app_icons/GLENbooks.png"
              }
              imageText={"Cuentos Nuevos"}
              onClick={() => onClick("nuevos")}
            />
          </div>
        </div>
      </GameContainer>
      {withAuth && (
        <BottomBar
          left={
            <BottomButton
              src={process.env.PUBLIC_URL + "/images/back.png"}
              onClick={goBack}
            />
          }
        />
      )}
    </GameWrapper>
  );
};
