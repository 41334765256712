import React, { useCallback, useEffect, useMemo, useState } from "react";
import ImageClickWithAnalytics from "../../../../games/ImageClick/ImageClickWithAnalytics";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { GenericRepeatedGame } from "../../../../util/repeatedGame";
import ShapesStrengthenManager from "./manager";

const RepeatedImageClickGame = ({ manager, updateProgress, onComplete }) => {
  const onRoundComplete = useCallback(
    (wasCorrect) => {
      manager.onRoundComplete(manager.currentWord, wasCorrect);
      updateProgress();
    },
    [manager, updateProgress]
  );

  const getNextGameProps = useCallback(() => {
    return { ...manager.getNext(), shouldShowReview: true };
  }, [manager]);

  const hasNext = useCallback(() => manager.hasNext(), [manager]);

  return (
    <GenericRepeatedGame
      GameComponent={ImageClickWithAnalytics}
      getNextGameProps={getNextGameProps}
      onRoundComplete={onRoundComplete}
      onComplete={onComplete}
      hasNext={hasNext}
    />
  );
};

export default ({ concepts, onStart, onComplete }) => {
  useEffect(onStart, []);
  const manager = useMemo(
    () => new ShapesStrengthenManager(concepts),
    // eslint-disable-next-line
    [concepts]
  );
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => setCurrentProgress(manager.getCurrentProgress());

  let GameComponent = (
    <RepeatedImageClickGame
      manager={manager}
      updateProgress={updateProgress}
      onComplete={onComplete}
    />
  );

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
