import { events } from "./timeline";
import {
  useGlenABCProfile,
  useGlenLearnProfile,
  useGlenPhonicsProfile,
  useGlenShapesProfile,
  useGlenVocabProfile,
} from "../Profile/ProfileUtil";

// pass through a specific profile from which to read timeline events,
// or by default we use the currently logged in user.
export const useTimelineEvents = (profileOverride = null) => {
  // currently displaying:
  // glen learn - all events (certificates and lesson group completion)
  // glen phonics - certificates only
  const glenLearnProfile = useGlenLearnProfile(profileOverride);
  const glenPhonicsProfile = useGlenPhonicsProfile(profileOverride);
  const glenShapesProfile = useGlenShapesProfile(profileOverride);
  const glenABCProfile = useGlenABCProfile(profileOverride);
  const glenVocabProfile = useGlenVocabProfile(profileOverride);
  return [
    ...glenLearnProfile.getTimelineEvents(profileOverride),
    ...glenPhonicsProfile.getTimelineEvents(profileOverride),
    ...glenVocabProfile.getTimelineEvents(profileOverride),

    // shapes and ABC should not show group completion events.
    ...glenShapesProfile
      .getTimelineEvents()
      .filter((event) => event.type === events.CERTIFICATE),
    ...glenABCProfile
      .getTimelineEvents()
      .filter((event) => event.type === events.CERTIFICATE),
  ].sort((a, b) => a.time - b.time);
};

export const useWallOfFameEvents = () => {
  const glenLearnProfile = useGlenLearnProfile();
  const glenPhonicsProfile = useGlenPhonicsProfile();
  const glenShapesProfile = useGlenShapesProfile();
  const glenABCProfile = useGlenABCProfile();

  const profiles = [
    glenLearnProfile,
    glenPhonicsProfile,
    glenShapesProfile,
    glenABCProfile,
  ];

  let mergedEvents = [];
  profiles.forEach((profile) => {
    mergedEvents = [
      ...mergedEvents,
      ...profile
        .getTimelineEvents()
        .filter((event) => event.type === events.CERTIFICATE),
    ];
  });

  return mergedEvents;
};
