import React, { useCallback, useEffect, useMemo, useState } from "react";
import PhonicStrengthen0Manager from "./manager";
import LessonC3 from "../../../LessonTypes/LessonC3/LessonC3";

export default ({
  consonantWordMap,
  recentlyFailedSounds,
  onStart,
  onComplete,
}) => {
  const [gameState, setGameState] = useState(null);
  const manager = useMemo(() => {
    return new PhonicStrengthen0Manager(consonantWordMap, recentlyFailedSounds);
  }, [consonantWordMap, recentlyFailedSounds]);

  const initNewGame = useCallback(
    (trialNum) => {
      const parameters = manager.nextLessonC3;
      setGameState({ trialNum, parameters });
    },
    [manager]
  );

  const onRoundComplete = () => {
    console.log("ROUND IS COMPLETE");
    manager.onRoundComplete();
    if (manager.isComplete) {
      onComplete();
    } else {
      initNewGame(gameState.trialNum + 1);
    }
  };

  useEffect(onStart, []);
  useEffect(initNewGame.bind(this, 1), []);

  if (gameState === null) {
    return null;
  }

  return (
    <LessonC3
      key={gameState.trialNum}
      parameters={gameState.parameters}
      onComplete={onRoundComplete}
    />
  );
};
