import React, { useCallback, useEffect, useState, useMemo } from "react";
import ComprehensiveManager from "./Comprehensive/manager";
import KSEPManager from "./KSEP/manager";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import ImageClickWithAnalytics from "../../../games/ImageClick/ImageClickWithAnalytics";
import WordClickWithAnalytics from "../../../games/WordClick/WordClickWithAnalytics";
import SoundClick2WithAnalytics from "../../../games/SoundClick2/SoundClick2WithAnalytics";
import ConsonantSounds from "../../../games/ConsonantSounds/ConsonantSoundsWithAnalytics";
import MultipleChoiceQuestion from "../../../games/MultipleChoice";
import { useHistory, useParams } from "react-router-dom";

import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BackButton,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import wait from "../../../util/wait";
import Guide from "../../../components/Guide/Guide";
import noop from "lodash/noop";
import { activity } from "../../../util/Analytics/events";

const QUESTION_TYPE_TO_COMPONENT = {
  [activity.IMAGE_CLICK]: ImageClickWithAnalytics,
  [activity.WORD_CLICK]: WordClickWithAnalytics,
  [activity.SOUND_CLICK_2]: SoundClick2WithAnalytics,
  [activity.CONSONANT_SOUNDS]: ConsonantSounds,
  [activity.MULTIPLE_CHOICE]: MultipleChoiceQuestion,
};

const RepeatedGame = ({
  manager,
  onLevelStart,
  onLevelComplete,
  onComplete,
  activityType,
  updateProgress,
}) => {
  const [gameState, setGameState] = useState({ trialNum: 0 });

  const initNewGame = useCallback(() => {
    updateProgress(manager.getCurrentProgress());
    const { questionType, props } = manager.getNextGroup();
    setGameState((gameState) => ({
      trialNum: gameState.trialNum + 1,
      questionType,
      questionProps: { ...props, shouldShowReview: false },
    }));
  }, [manager]);

  const onRoundComplete = useCallback(
    (wasCorrect) => {
      console.log("WAS CORRECT", wasCorrect);
      if (wasCorrect) {
        manager.markAnswerCorrect();
      } else if (activityType === activity.COMPREHENSIVE_ASSESSMENT) {
        manager.markAnswerIncorrect();
        if (manager.shouldExitEarly()) {
          console.log("EARLY EXIT");
          onComplete({ score: manager.getTotalScore() });
          return;
        }
      }

      if (
        activityType === activity.KSEP_ASSESSMENT &&
        manager.isLastQuestion()
      ) {
        onLevelComplete({
          score: manager.getTotalScore(),
        });
        onComplete({ score: manager.getTotalScore() });
        return;
      }

      if (
        activityType === activity.COMPREHENSIVE_ASSESSMENT &&
        manager.isLevelComplete()
      ) {
        onLevelComplete({
          level: manager.getCurrentLevel(),
          score: manager.getCurrentLevelScore(),
        });

        if (manager.isLastLevel()) {
          onComplete({ score: manager.getTotalScore() });
          return;
        }
        manager.incrementLevel();
        onLevelStart({ level: manager.getCurrentLevel() });
      }

      initNewGame();
    },
    [updateProgress, manager, gameState, initNewGame, onComplete]
  );

  useEffect(() => {
    if (activityType === activity.COMPREHENSIVE_ASSESSMENT) {
      onLevelStart({ level: manager.getCurrentLevel() });
    } else if (activityType === activity.KSEP_ASSESSMENT) {
      onLevelStart();
    }

    initNewGame();
  }, [initNewGame]);

  if (gameState.trialNum === 0) {
    return null;
  }

  const { trialNum, questionType, questionProps } = gameState;
  const QuestionComponent = QUESTION_TYPE_TO_COMPONENT[questionType];

  return (
    <QuestionComponent
      key={trialNum}
      onComplete={onRoundComplete}
      {...questionProps}
    />
  );
};

export default ({
  onComplete = noop,
  onLevelStart,
  onLevelComplete,
  stopAfterIncorrectNum = 8,
  ...props
}) => {
  const { deploymentId } = useParams();
  const history = useHistory();
  const goBack = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/selectAssessment`);
  const [assessAudio, setAssessAudio] = useState(null);
  const [isAssessComplete, setIsAssessComplete] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);

  let manager;
  let questionnaire;
  if (props.activity === activity.KSEP_ASSESSMENT) {
    questionnaire = useResourceFromRegistry("ksepQuestions");
    const resources = {
      flashcards: useResourceFromRegistry("flashcards"),
      shapes: useResourceFromRegistry("shapes"),
      phonics: useResourceFromRegistry("phonics"),
    };
    manager = useMemo(
      () =>
        new KSEPManager(questionnaire.content, props.distribution, resources),
      []
    );
  } else if (props.activity === activity.COMPREHENSIVE_ASSESSMENT) {
    questionnaire = useResourceFromRegistry("vocabLevels");
    manager = useMemo(
      () =>
        new ComprehensiveManager(questionnaire.content, stopAfterIncorrectNum),
      []
    );
  }
  const onAssessComplete = ({ score }) => {
    // When the assessment is complete, this line 'completes the progress bar'
    setCurrentProgress(1);
    setAssessAudio(
      score >= 80 ? ["awesome", "that_was_fun"] : ["that_was_fun"]
    );
    setIsAssessComplete(true);
  };
  const onGuideComplete = () => wait(1500).then(onComplete);

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>
        {isAssessComplete && (
          <Guide audio={assessAudio} onComplete={onGuideComplete} />
        )}
        <RepeatedGame
          manager={manager}
          onLevelStart={onLevelStart}
          onLevelComplete={onLevelComplete}
          onComplete={onAssessComplete}
          activityType={props.activity}
          updateProgress={setCurrentProgress}
        />
      </GameContainer>
      <BottomBar
        center={<ProgressBar value={currentProgress} />}
        left={<BackButton onClick={goBack} />}
      />
    </GameWrapper>
  );
};
