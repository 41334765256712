import React from "react";
import {
  AuthedTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useParams, useHistory } from "react-router-dom";
import styles from "./About.module.scss";

const getTitle = (app) => {
  return {
    glenBooks: "About GLEN Books",
    glenRhymes: "About GLEN Rhymes",
    glenLearn: "About GLEN Learn",
  }[app];
};
const getContent = (app) => {
  switch (app) {
    case "glenLearn":
      return (
        <div className={styles.segment}>
          <p>
            GLEN Learn helps children learn to read English, with guided exercises that build pre-reading skills, and
            engaging stories and rhymes. It is personalized, letting children learn at their own pace, with built-in
            assessments and skill-strengthening exercises that adapt to the child’s progress. We have drawn on best
            practices in Instructed Second Language Acquisition research to design content that systematically builds
            the foundations of literacy: vocabulary (what words mean), phonics (how words are built from sounds), and
            orthography (how words are written). Children can enjoy GLEN Learn at home, preschool or school. Teachers
            and caregivers can use GLEN Learn as a resource to help children get ready for school, or to catch up on
            language skills that they might be missing.
          </p>
          <p>
            GLEN Learn is developed by GLEN World, a nonprofit organization on a mission to scale early English literacy
            so that every child has access to the educational and economic opportunities it unlocks. Visit
            www.glenworld.org for more information. Join us in our mission by making a donation today!
          </p>
        </div>
      );
    case "glenBooks":
      return (
        <div className={styles.segment}>
          <p>
            GLEN Books features a collection of children's short stories with
            beautiful illustrations and in-app narration. The stories include
            popular children's classics, including adaptations of Aesop's fables
            and Indian folk tales, as well as original stories from GLEN World
            authors. In addition to our collection of English stories, we also
            feature a number of Spanish stories.
          </p>
          <p>
            Reading aloud to children is well-accepted as a crucial stepping
            stone for language learning. GLEN Books is designed to nurture a
            love of books, and to facilitate playful interaction between kids
            and adults. With the (optional) auto-narration feature, even parents
            with limited reading skills can read bedtime stories with their
            children. Skilled teachers can count on GLEN Books for in-class
            reading and comprehension exercises.
          </p>
          <p>
            GLEN Books is also included in GLEN Learn, a research-based
            personalized learning app for early English literacy. GLEN Books and
            GLEN Learn are developed by GLEN World, a nonprofit organization on
            a mission to scale early English literacy so that every child has
            access to the educational and economic opportunities it unlocks.
            Visit www.glenworld.org for more information. Join us in our mission
            by making a donation today!
          </p>
        </div>
      );
    case "glenRhymes":
      return (
        <>
          <p>
            GLEN Rhymes features a collection of children's rhymes with
            beautiful illustrations and in-app narration. The app features a
            number of original children's rhymes written by GLEN World's authors
            with a goal to engage children's imagination and inculcate a love of
            literature while helping develop language skills. GLEN Rhymes is
            designed to facilitate quality learning and playful interaction
            among children and adults (parents or teachers).
          </p>
          <p>
            GLEN Rhymes is also included as part of GLEN Learn, a research-based
            personalized learning app for early English literacy, designed to
            help children develop reading skills via a series of guided
            exercises, engaging stories and rhymes. GLEN Learn's content is
            based on Instructed Second Language Acquisition (ISLA) research,
            targeting the fundamental skills required for literacy: vocabulary
            knowledge, recognition of sounds, and recognition of spelling. Our
            learning modules reinforce each other as they target these
            fundamental skills.
          </p>
          <p>
            GLEN Rhymes and GLEN Learn are developed by GLEN World, a nonprofit
            organization on a mission to scale early English literacy so that
            every child has access to the educational and economic opportunities
            it unlocks. Visit www.glenworld.org for more information. Join us in
            our mission by making a donation today!
          </p>
        </>
      );
    default:
      return "";
  }
};

export default () => {
  const history = useHistory();
  const { app } = useParams();
  const goBack = () => history.goBack();
  const gotoFaq = () => history.push(`/faq/${app}`);

  return (
    <GameWrapper>
      <AuthedTopBar />
      <GameContainer>
        <div className="text-white" style={{ fontSize: "2.3vh" }}>
          <div>
            <h1 className="text-center" style={{ fontSize: "3vw" }}>
              {getTitle(app)}
            </h1>
            {getContent(app)}
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={gotoFaq}
              style={{ fontSize: "2.3vh" }}
            >
              FAQ
            </button>
          </div>
        </div>
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} />
    </GameWrapper>
  );
};
