import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GenericFlipCard from "./Card/Card";
import { useFlashcards } from "../../util/Flashcard/hooks";
import range from "lodash/range";
import wait from "../../util/wait";
import classnames from "classnames";
import shuffle from "lodash/shuffle";
import noop from "lodash/noop";
import { useAudio } from "../../util/Audio/context";
import styles from "./GlenMatch.module.scss";
import GCard from "../../components/Card/Card";

//switches each array item from true => false with a 50 ms delay
const useBounceAnimation = (isComplete: boolean) => {
  const [bounceAnimation, setBounceAnimation] = useState(
    range(0, 8).map((_) => false)
  );
  useEffect(() => {
    const runAnimation = async (i: number) => {
      setBounceAnimation([
        ...range(0, i).map((_) => true),
        ...range(i, 8).map((_) => false),
      ]);
      await wait(50);
      if (i < 8) {
        runAnimation(i + 1);
      }
    };
    runAnimation(0);
  }, [isComplete]);
  return bounceAnimation;
};

interface Component {
  matchKey: number;
  component: ReactNode;
  audio: string;
}

export const GlenMatchWithFlashcards = ({
  words,
  isImageTextMatching,
  onStart = noop,
  onMatch = noop,
  onComplete = noop,
}: {
  words: string[];
  isImageTextMatching: boolean;
  onStart?: Function;
  onMatch?: Function;
  onComplete?: Function;
}) => {
  const flashcards = useFlashcards(words);
  const components = useMemo(() => {
    const flashcards1 = flashcards.map((flashcard: any) => {
      if (isImageTextMatching) {
        return {
          matchKey: flashcard.key,
          audio: flashcard.audio,
          component: <GCard bg="white" image={flashcard.image} text={null} />,
        };
      } else {
        return {
          matchKey: flashcard.key,
          audio: flashcard.audio,
          component: (
            <GCard
              bg="white"
              image={flashcard.image}
              text={flashcard.meaning}
            />
          ),
        };
      }
    });
    const flashcards2 = flashcards.map((flashcard: any) => {
      if (isImageTextMatching) {
        return {
          matchKey: flashcard.key,
          audio: flashcard.audio,
          component: <GCard bg="white" image={null} text={flashcard.meaning} />,
        };
      } else {
        return {
          matchKey: flashcard.key,
          audio: flashcard.audio,
          component: (
            <GCard
              bg="white"
              image={flashcard.image}
              text={flashcard.meaning}
            />
          ),
        };
      }
    });
    return shuffle([...flashcards1, ...flashcards2]);
  }, [flashcards, isImageTextMatching]);

  return (
    <GlenMatch
      components={components}
      onStart={onStart}
      onMatch={onMatch}
      onComplete={onComplete}
    />
  );
};

export type GlenMatchParameters = {
  components: Component[];
  onStart?: Function;
  onMatch?: Function;
  onComplete?: Function;
};
export const GlenMatch = (inputs: GlenMatchParameters) => {
  const {
    components,
    onStart = noop,
    onMatch = noop,
    onComplete = noop,
  } = inputs;

  const audioService = useAudio();
  const [flips, setFlips] = useState(range(0, 8).map((_) => false));
  const matchOne: any = useRef(null);
  const [isDisabled, setDisabled] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const bounceAnimation = useBounceAnimation(isComplete);
  const shuffledComponents = useMemo(() => shuffle(components), [components]);

  useEffect(() => {
    onStart();
    audioService.composeAndPlaySingleAudio("soundfx", "newgame");
  }, [audioService, onStart]);

  const doFlip = useCallback(
    (i, key) => {
      const fx = async () => {
        setDisabled(true);
        const newFlips = [...flips];
        newFlips[i] = true;
        setFlips(newFlips);

        if (matchOne.current === null) {
          matchOne.current = { key, index: i };
        } else {
          if (matchOne.current.key === key) {
            audioService.composeAndPlaySingleAudio("soundfx", "correct_low");
            onMatch(key);
            if (newFlips.every((f) => f === true)) {
              setIsComplete(true);
              await wait(2500);
              // allow time for bounce animation
              onComplete();
            }
          } else {
            await wait(800);
            const updatedFlips = [...flips];
            updatedFlips[matchOne.current.index] = false;
            updatedFlips[i] = false;
            setFlips(updatedFlips);
          }
          matchOne.current = null;
        }
        setDisabled(false);
      };
      fx();
    },
    [audioService, flips, onComplete, onMatch]
  );

  const doClick = useCallback(
    async (i, data: Component) => {
      // not currently in disabled state or card already flipped (completed);
      if (!isDisabled && flips[i] !== true) {
        audioService.composeAndPlaySingleAudio("soundfx", "flip");
        audioService.playAudio(data.audio);
        doFlip(i, data.matchKey);
      }
    },
    [audioService, doFlip, flips, isDisabled]
  );

  return (
    <div className={`row ${styles.gameContainer}`}>
      {shuffledComponents.map((data, i) => {
        return (
          <div className={`col-3 ${styles.cardContainer}`} key={i}>
            <div
              className={classnames({ "animated bounce": bounceAnimation[i] })}
            >
              <GenericFlipCard
                isFlipped={flips[i]}
                onClick={() => doClick(i, data)}
              >
                {data.component}
              </GenericFlipCard>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GlenMatchWithFlashcards;
