import React from "react";
import styles from "./RowLayout.module.scss";

const RowLayout = ({ prompt, answers }) => {
  const answerCards = answers.map((answer, idx) => (
    <div key={"card-" + idx} className={styles.answerCard}>
      {React.cloneElement(answer, { size: "xxxl" })}
    </div>
  ));
  return (
    <div className={styles.cardContainer}>
      <div className={styles.promptRow}>
        {React.cloneElement(prompt, { size: "sm" })}
      </div>
      <div className={styles.answerRow}>{answerCards}</div>
    </div>
  );
};

export default RowLayout;
