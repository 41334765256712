import { useMemo } from "react";
import { useResourceFromRegistry } from "../Resource/useResource";

//we have to do this once (and not in the render method) because it can change between renders as selection
//is randomized
export const useFlashcards = (words) => {
  const flashcardResource = useResourceFromRegistry("flashcards");
  return useMemo(() => {
    return words.map((word) => flashcardResource.getFlashcard(word));
  }, [flashcardResource, words]);
};
export const useFlashcard = (word) => useFlashcards([word]);
