import { useHistory, useParams } from "react-router-dom";
import useFirebase from "../../../util/Firebase/useFirebase";
import {
  AuthedTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import React, { useState } from "react";
import classnames from "classnames";
import ProfileForm from "../ProfileForm/ProfileForm";
import { profileAlreadyExists } from "../util";
import { useProfilesForDeployment } from "../../../util/Profile/useProfile";

export default () => {
  const { deploymentId } = useParams();
  const firebase = useFirebase();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [username, setUsername] = useState("");
  const [age, setAge] = useState(null);
  const [avatarId, setAvatarId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const onBack = () => history.push(`/deployment/${deploymentId}/profiles`);
  const [existingProfiles, loading, error] = useProfilesForDeployment(
    deploymentId
  );
  const isFilledOut = username && age && avatarId;

  const showError = (message) => {
    setErrorMessage(message);
    setIsDisabled(false);
  };

  const addUsername = async () => {
    if (isDisabled) {
      return false;
    }
    setIsDisabled(true);
    if (loading || error) {
      showError("Waiting for existing profiles");
    } else if (!isFilledOut) {
      showError("Please fill out all fields");
    } else if (profileAlreadyExists(username, existingProfiles.docs)) {
      showError("Username taken, please try a different one");
    } else {
      await firebase.createDeploymentAccount(
        deploymentId,
        username,
        avatarId,
        age
      );
      history.push(`/deployment/${deploymentId}/profiles`);
    }
  };

  const RightIcon = () => (
    <div className="text-right">
      <button
        disabled={isDisabled || !isFilledOut}
        className={classnames("btn btn-lg btn-primary", {
          "animated pulse infinite": isFilledOut,
        })}
        type="button"
        onClick={addUsername}
      >
        Done
      </button>
    </div>
  );

  return (
    <GameWrapper>
      <AuthedTopBar />
      <GameContainer>
        <ProfileForm
          state={{ username, age, avatarId }}
          onSetAge={setAge}
          onSetUsername={setUsername}
          onSetAvatarId={setAvatarId}
          error={errorMessage}
        />
      </GameContainer>
      <BottomBar left={<BackButton onClick={onBack} />} right={<RightIcon />} />
    </GameWrapper>
  );
};
