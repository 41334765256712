import noop from "lodash/noop";
import React, { useCallback } from "react";
import { activity } from "../../util/Analytics/events";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";
import { SelectAnswerWithSingleImage } from "./SelectAnswer";

const eventData = {
  activity: activity.SELECT_ANSWER,
};

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const onCompleteOverride = useCallback(
    (wasCorrect) => {
      completeEvent({ ...eventData, wasCorrect });
      onComplete(wasCorrect);
    },
    [completeEvent, onComplete]
  );

  const onStartOverride = useCallback(() => {
    startEvent(eventData);
    onStart();
  }, [onStart, startEvent]);
  return (
    <SelectAnswerWithSingleImage
      {...props}
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
    />
  );
};
