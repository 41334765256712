export default class LessonA2Manager {
  constructor(parameters) {
    this.words = parameters.content;
    this.exposureMap = {};
  }

  exposeWord(word) {
    let count = this.exposureMap[word] || 0;
    this.exposureMap[word] = count + 1;
  }

  getNextByExposureCount() {
    this.words.sort((a, b) => {
      let aExposure = this.exposureMap[a] || 0;
      let bExposure = this.exposureMap[b] || 0;
      return aExposure - bExposure;
    });
    return this.words[0];
  }

  getExposedConceptsCount() {
    //number of concepts with exposure_count > 0
    return Object.keys(this.exposureMap).length;
  }

  getExposureCriterion() {
    //exposure_criterion = {exposed_concepts}/{number of concepts}
    return this.getExposedConceptsCount() / this.words.length;
  }

  getCurrentProgress() {
    return this.getExposureCriterion();
  }

  getWords() {
    return this.words;
  }
}
