import React from "react";
import BasePrompt from "./BasePrompt";
import TextAnswer from "../Answers/TextAnswer/TextAnswer";

const TextPrompt = ({
  text,
  audio = null,
  delays = null,
  onPromptFinished,
}) => {
  return (
    <BasePrompt
      audio={audio}
      delays={delays}
      onPromptFinished={onPromptFinished}
    >
      <TextAnswer text={text} />
    </BasePrompt>
  );
};

export default TextPrompt;
