import React from "react";
import {
  AuthedAndProfiledTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useHistory, useParams } from "react-router-dom";
import Timeline from "../../AdminDashboard/DashboardForUser/Timeline/Timeline";
import styles from "./Timeline.module.scss";
import Avatar from "../../../../components/Avatar/Avatar";
import useProfile from "../../../../util/Profile/useProfile";
import { useTimelineEvents } from "../../../../util/Timeline/useTimelineEvents";

export default () => {
  const { deploymentId } = useParams();
  const history = useHistory();
  const goBack = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/userDashboard`);
  const profile = useProfile();
  const timelineEvents = useTimelineEvents();

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>
        <div className={styles.progressHeader}>
          Learning progress for {profile.username}{" "}
          <Avatar avatarId={profile.avatarId} size={"xs"} />{" "}
        </div>
        <div className={styles.timelineContainer}>
          <Timeline
            timelineEvents={timelineEvents}
            profile={profile}
            isAdmin={false}
          />
        </div>
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} right={null} />
    </GameWrapper>
  );
};
