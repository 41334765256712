import React, { useCallback } from "react";
import { activity } from "../../../../../util/Analytics/events";
import noop from "lodash/noop";
import PhonicStrengthen0 from "./PhonicStrengthen0";
import { useTimedAnalytics } from "../../../util/hooks";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.PHONIC_STRENGTHEN,
    wasManual: props.wasManual,
    lessonGroupId: props.lessonGroupId,
  };
  const onStartOverride = useCallback(() => {
    startEvent(eventData);
    onStart();
  }, [eventData, onStart, startEvent]);
  const onCompleteOverride = useCallback(() => {
    completeEvent({ ...eventData });
    onComplete();
  }, [completeEvent, eventData, onComplete]);

  return (
    <PhonicStrengthen0
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
      {...props}
    />
  );
};
