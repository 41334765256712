import styles from "./RoundedTextWithCheck.module.scss";
import RoundedText from "../RoundedText/RoundedText";
import React from "react";
import { ReactComponent as CheckIcon } from "../Svg/Check.svg";
import { ReactComponent as CrossIcon } from "../Svg/Cross.svg";
import classnames from "classnames";

export default ({
  children,
  onClick,
  size = "md",
  isCorrect = false,
  isIncorrect = false,
  withCircle = false,
  ...props
}) => {
  const animationClass = classnames({ bounce: isCorrect, shake: isIncorrect });
  return (
    <div
      className={classnames(styles.container, {
        [`animated ${animationClass}`]: animationClass !== null,
      })}
      onClick={onClick}
    >
      <div className={styles.checkContainer}>
        <div className={styles.checkContainerInner}>
          {isCorrect && <CheckIcon className={styles.isCorrect} />}
          {isIncorrect && <CrossIcon className={styles.isIncorrect} />}
        </div>
      </div>
      <RoundedText
        border={classnames({ green: isCorrect, red: isIncorrect })}
        withCircle={withCircle}
        size={size}
        color="black"
        bg="white"
        {...props}
      >
        {children}
      </RoundedText>
    </div>
  );
};
