import { useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import AudioContext from "./context";
// this HOC is responsible for pausing the audio between routes.

const AudioController = ({ children }) => {
  const router = useHistory();
  const audioService = useContext(AudioContext);
  useEffect(() => {
    const unlisten = router.listen((location, action) => {
      audioService.pauseCurrentlyPlaying();
    });
    return () => unlisten();
  }, []);
  return children;
};
export default AudioController;
