import noop from "lodash/noop";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";
import { activity } from "../../util/Analytics/events";
import React from "react";
import TraceAndSound from "./TraceAndSound";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.TRACE_AND_SOUND,
    word: props.word,
    consonant: props.consonant,
  };
  const onCompleteOverride = () => {
    completeEvent(eventData);
    onComplete();
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  return (
    <TraceAndSound
      {...props}
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
    />
  );
};
