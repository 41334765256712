import React from "react";
import styles from "./ProfileForm.module.scss";
import AgeSelector from "./AgeSelector/AgeSelector";
import AvatarSelector from "./AvatarSelector/AvatarSelector";

export default ({
  state = { username: "", age: null, avatarId: null },
  onSetUsername,
  onSetAge,
  onSetAvatarId,
  error = null,
}) => {
  return (
    <div className={styles.addProfileContainer}>
      <div className="form-group row mb-4">
        <label className="col-3 col-form-label">Learner's Name</label>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="Ex: Glen"
            value={state.username}
            onChange={(e) => onSetUsername(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group row mb-4">
        <label className="col-3 col-form-label">Age</label>
        <div className="col-9">
          <AgeSelector selectedAge={state.age} onSelectAge={onSetAge} />
        </div>
      </div>
      <div className="form-group row mb-4">
        <label className="col-3 col-form-label">Avatar</label>
        <div className="col-9">
          <AvatarSelector
            selectedAvatar={state.avatarId}
            onSelect={onSetAvatarId}
          />
        </div>
      </div>
      {error !== null ? error : null}
    </div>
  );
};
