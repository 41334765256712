import wait from "../wait";

export const getAudioPath = (path) => {
  if (window.Cordova && window.device.platform === "Android") {
    return `file:///android_asset/www${path}`;
  }
  return `${process.env.PUBLIC_URL}${path}`;
};

export class AudioService {
  constructor() {
    this.currentlyPlaying = null;
  }

  pauseCurrentlyPlaying() {
    if (this.currentlyPlaying) {
      this.currentlyPlaying.pause();
    }
  }

  /* Example input for composeAndPlayAudio function
  [
    {
        directory: "prompts",
        files: ["which_letter_does"]
        },
      {
        directory: "consonants",
        files: ["guh", "buh"]
      }
  ] 
   */

  /**
   * Takes in an array of objects (example given above)), converts them
   * to the paths of the given audio files, puts them into an array, and
   * calls playAudio on each path
   * @param {Array} audio an array of objects (example given above)
   * @param {Array}[delays] (optional) array (of same length as audio) of delays in milliseconds
   * @returns {Promise} the playAudio function with the array of audioPath's passed through
   */
  async composeAndPlayAudio(audio, delays = null) {
    const audioPaths = [];
    audio.forEach((content) => {
      const basePath = `/content/audio/${content.directory}`;
      if (!content.files) {
        return;
      }
      content.files.forEach((file) => {
        // concatenating the basePath to the actual file name and adding it to audioPaths array
        audioPaths.push(`${basePath}/${file}.mp3`);
      });
    });

    return this.playAudio(audioPaths, delays);
  }

  /**
   * Takes in a directory and file to play the relevant audio
   * @param {string} directory is the directory where the file is stored
   * @param {string} file      is the name of the file
   * @returns {Promise} the playAudio instance
   */
  async composeAndPlaySingleAudio(directory, file) {
    return this.playAudio(`/content/audio/${directory}/${file}.mp3`);
  }

  /**
   * Plays one audio file.
   * @param {String} audio the path to the audio file
   */
  async playSingleAudio(audio) {
    const src = getAudioPath(audio.trim());
    console.log("Play audio");
    console.log(src);
    return new Promise((resolve, reject) => {
      if (typeof window.cordova !== "undefined") {
        var obj = new window.Media(
          src,
          function () {
            console.log("Audio completed playout");
            // release the media resource once finished playing
            obj.release();
            resolve();
          },
          function onError(e) {
            console.log("audio error");
            console.log(e);
            console.log(src);
            obj.release();
            reject();
          }
        );
        obj.play();
        this.currentlyPlaying = obj;
      } else {
        const audioObj = new Audio(audio);
        audioObj.autoplay = true; // autoplay when loaded
        audioObj.onerror = (e) => {
          console.error(e);
          //still resolve, even if there was an error
          resolve();
        }; // on error, reject
        audioObj.onended = resolve; // when done, resolve
        console.log(audioObj);
        audioObj.play();
        this.currentlyPlaying = audioObj;
      }
    });
  }

  /**
   * Plays one audio file or an array of audio files sequentially. If playing an array
   * of audio files, optionally supports an array of numbers representing number of
   * milliseconds to delay after each file.
   * @param {String} audio the path to the audio file
   * @param {Array} [delays] (optional) array (of same length as audio) of delays in milliseconds
   */
  async playAudio(audio, delays = null) {
    if (!audio) {
      return;
    }
    if (Array.isArray(audio)) {
      // If audio is an array, play audio files in sequence

      if (delays == null) {
        // Default: no delay between audio files
        delays = audio.map(() => 0);
      } else if (delays.length !== audio.length) {
        throw new TypeError(
          "array of delays must be same length as array of audio files"
        );
      }

      for (let i = 0; i < audio.length; ++i) {
        await this.playSingleAudio(audio[i]);
        if (delays[i] > 0) {
          await wait(delays[i]);
        }
      }

      return;
    }

    if (delays != null) {
      throw new TypeError(
        "array of delays requires passing an array of audio files"
      );
    }
    return this.playSingleAudio(audio);
  }
}
