import { useSelector } from "react-redux";
import useFirebase from "../Firebase/useFirebase";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import isEqual from "lodash/isEqual";

// fetches the profile for a given user ID
export const useProfileForUserId = (userId) => {
  const firebase = useFirebase();
  // [value, loading, error]
  return useDocumentData(firebase.deploymentAccount(userId));
};

export const useProfilesForDeployment = (deploymentId) => {
  const firebase = useFirebase();
  return useCollection(firebase.deploymentAccounts(deploymentId));
};

export const useAdminAccount = () => {
  return useSelector((state) => state.sessionState.authUser, isEqual);
}

// uses profile from the currently selected deployment user
export default () => {
  //when we the user state is updated, let's make sure it's changed before forcing a re-render.
  //see ProfileSync: onSnapshot immediately creates a request to firebase, but it may already match what we have in
  // the user's local state.
  return useSelector((state) => state.profileState.profileUser, isEqual);
};
