import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenLearnProfile } from "../../../util/Profile/ProfileUtil";
import Strengthen from "../../../components/Lessons/Strengthen/Strengthen";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const lessonGroupResource = useResourceFromRegistry("lessonGroups");
  const lessonResource = useResourceFromRegistry("lessons");
  const glenLearnProfile = useGlenLearnProfile();

  return (
    <Strengthen
      profile={glenLearnProfile}
      lessonGroupResource={lessonGroupResource}
      lessonResource={lessonResource}
      type={types.GLEN_LEARN}
    />
  );
};
