import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BottomButton,
  GameContainer,
  GameWrapper,
  PublicSpiralTopBar,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import GlenBooksWithAnalytics from "../../games/GlenBooks/GlenBooksWithAnalytics";
import { activity } from "../../util/Analytics/events";
import GlenBooks from "../../games/GlenBooks/GlenBooks";
import noop from "lodash/noop";
import { WithAnonymousLogin } from "../../util/anonymousLogin";

export const GlenRhymesBookWithAuth = () => <Book withAuth={true} />;
export const GlenRhymesBookWithoutAuth = () => (
  <WithAnonymousLogin
    deploymentId={process.env.REACT_APP_GLEN_RHYMES_ANONYMOUS_DEPLOYMENT_ID}
  >
    <Book withAuth={false} />
  </WithAnonymousLogin>
);

const Book = ({ withAuth }) => {
  const { book } = useParams();
  const history = useHistory();
  const onComplete = () => history.goBack();
  const onClickSpiral = () => history.push("/glenRhymes");
  const onClickInfo = () => history.push("/about/glenRhymes");

  return (
    <GameWrapper>
      {withAuth ? (
        <AuthedAndProfiledTopBar />
      ) : (
        <PublicSpiralTopBar
          onClickSpiral={onClickSpiral}
          onClickInfo={onClickInfo}
        />
      )}
      <GameContainer>
        {withAuth && (
          <GlenBooksWithAnalytics
            activity={activity.RHYME}
            bookId={book}
            onComplete={onComplete}
            autoTurnCover={true}
            autoTurnEnabled={false}
            isSingle={false}
            rhymes={true}
          />
        )}
        {!withAuth && (
          <GlenBooks
            bookId={book}
            onStart={noop}
            onComplete={onComplete}
            autoTurnCover={true}
            autoTurnEnabled={false}
            isSingle={false}
            rhymes={true}
          />
        )}
      </GameContainer>
      <BottomBar
        left={
          <BottomButton
            src={process.env.PUBLIC_URL + "/images/back.png"}
            onClick={onComplete}
          />
        }
      />
    </GameWrapper>
  );
};
