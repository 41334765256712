import React, { useMemo, useCallback } from "react";
import AlphabetSyncWithAnalytics from "../../../../games/AlphabetSync/AlphabetSyncWithAnalytics";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import {
  GenericRepeatedGame,
  repeatTimes,
} from "../../../../util/repeatedGame";
import GlenBooksWithAnalytics from "../../../../games/GlenBooks/GlenBooksWithAnalytics";
import NumbersBookWithAnalytics from "../../../../games/NumbersBook/NumbersBookWithAnalytics";

// each component here must have an `onComplete` prop to call for this lesson to complete.
const nameToExercise = {
  AlphabetSync: AlphabetSyncWithAnalytics,
  GlenBooks: GlenBooksWithAnalytics,
  NumbersBook: NumbersBookWithAnalytics,
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  // repeatCount = 1, because by default the game only plays once.
  const { type, args, repeatCount = 1 } = parameters;
  const timesFx = useMemo(() => repeatTimes(repeatCount), [repeatCount]);
  const getNextPropsFx = useCallback(() => args, [args]);
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>
        <GenericRepeatedGame
          GameComponent={nameToExercise[type]}
          getNextGameProps={getNextPropsFx}
          onComplete={onComplete}
          hasNext={timesFx}
        />
      </GameContainer>
      <BottomBar />
    </GameWrapper>
  );
};
