import noop from "lodash/noop";
import ImageClick from "./ImageClick";
import React from "react";
import { activity } from "../../util/Analytics/events";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.IMAGE_CLICK,
    ...props,
  };
  const onCompleteOverride = (wasCorrect) => {
    completeEvent({ ...eventData, wasCorrect });
    onComplete(wasCorrect);
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  return (
    <ImageClick
      {...props}
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
    />
  );
};
