import React from "react";
import styles from "./LargeCard.module.scss";

export default ({ image, text }) => {
  return (
    <div className={styles.card}>
      <img className={styles.img} src={image} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};
