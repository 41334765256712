import React, { useContext } from "react";
import { AudioService } from "./Audio";

const AudioContext = React.createContext(null);

// @ts-ignore
export const useAudio: () => AudioService = () => {
  return useContext(AudioContext);
};

export default AudioContext;
