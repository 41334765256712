import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BottomButton,
  GameContainer,
  GameWrapper,
  PublicSpiralTopBar,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import Shelf from "../../components/Shelf/Shelf";
import { ClipLoader } from "react-spinners";
import ShelfImageItem from "../../components/Shelf/ShelfImageItem";
import styles from "./Shelf.module.scss";
import { WithAnonymousLogin } from "../../util/anonymousLogin";
//hardcoded for now - todo move to JSON
const categories = {
  classics: [
    "LionMouse",
    "HareTortoise",
    "FoxCrow",
    "FoxGrapes",
    "AntGrasshopper",
    "CamelPig",
    "FoxStork",
    "BoyWolf",
    "MaxBell",
  ],
  originals: [
    "WhereKitty",
    "OneTwoThree",
    "BigRedApple",
    "RedBalloon",
    "StreetLight",
  ],
  clasicos: [
    "LionMouseSpanish",
    "LiebreTortuga",
    "CamelloCerdo",
    "MaxCampana",
    "NinoLobo",
    "ZorroCuervo",
  ],
  nuevos: ["Gatita", "GranManzana", "ElFarol"],
};

if (!(window.Cordova && window.device.platform === "Android")) {
  categories.originals.push("MiaPabloCave");
  categories.originals.push("JamesBikeRide");
  categories.nuevos.push("MiaPablo");
  categories.nuevos.push("JamesBicicleta");
}

const getBooks = async (items) => {
  return await Promise.all(
    items.map((item) => {
      return axios
        .get(`${process.env.PUBLIC_URL}/content/ebooks/books/${item}.json`)
        .then((resp) => resp.data);
    })
  );
};

export const GlenBooksCategoryWithAuth = () => <Category withAuth={true} />;
export const GlenBooksCategoryWithoutAuth = () => (
  <WithAnonymousLogin
    deploymentId={process.env.REACT_APP_GLEN_BOOKS_ANONYMOUS_DEPLOYMENT_ID}
  >
    <Category withAuth={false} />
  </WithAnonymousLogin>
);

const Category = ({ withAuth }) => {
  const { deploymentId } = useParams();
  const { category } = useParams();
  const history = useHistory();
  const booksPerShelf = 4;
  const [books, setBooks] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const books = await getBooks(categories[category]);
      setBooks(books);
    };
    fetch();
  }, [category]);
  const goBack = () =>
    history.push(
      withAuth
        ? `/deployment/${deploymentId}/glenLearn/glenBooks/categories`
        : `/glenBooks/categories`
    );

  const gotoBook = useCallback(
    (bookId) =>
      history.push(
        withAuth
          ? `/deployment/${deploymentId}/glenLearn/glenBooks/book/${bookId}`
          : `/glenBooks/book/${bookId}`
      ),
    [deploymentId, history, withAuth]
  );

  const mapped = useCallback(
    (shelfNum) =>
      books
        .slice(
          (booksPerShelf + 1) * (shelfNum - 1),
          (booksPerShelf + 1) * shelfNum
        )
        .map((book) => (
          <ShelfImageItem
            image={`${process.env.PUBLIC_URL}${book.cover}`}
            onClick={() => gotoBook(book.id)}
            booksPerShelf={booksPerShelf}
          />
        )),
    [books, gotoBook]
  );

  const shelves = useMemo(() => {
    return categories[category].length / (booksPerShelf + 1);
  }, [category]);

  const shelfDivs = useMemo(() => {
    let shelvesDivs = [];
    for (let i = 0; i < categories[category].length; i++) {
      if (shelves > i) {
        shelvesDivs.push(
          <Shelf
            key={i}
            items={mapped(i + 1)}
            centerMode={true}
            booksPerShelf={booksPerShelf}
          />
        );
      }
    }
    return <div> {shelvesDivs} </div>;
  }, [books, category, mapped, shelves]);

  const onClickSpiral = () => history.push("/glenBooks/categories");
  const onClickInfo = () => history.push("/about/glenBooks");

  return (
    <GameWrapper>
      {withAuth ? (
        <AuthedAndProfiledTopBar />
      ) : (
        <PublicSpiralTopBar
          onClickSpiral={onClickSpiral}
          onClickInfo={onClickInfo}
        />
      )}
      <GameContainer>
        {books.length === 0 && <ClipLoader size={75} />}
        <div className={styles.shelf}>{shelfDivs}</div>
      </GameContainer>
      <BottomBar
        left={
          <BottomButton
            src={process.env.PUBLIC_URL + "/images/back.png"}
            onClick={goBack}
          />
        }
      />
    </GameWrapper>
  );
};
