import {
  getConceptsBetweenLessons,
  getGroupsBetween,
} from "../../../../util/Lesson/util";

export const getVocabStrengthenInfo = (
  lessonResource,
  lessonGroup,
  getVocabAssessmentRecord
) => {
  const textIndicator = lessonGroup.assessment.text_indicator;
  const concepts = getConceptsBetweenLessons(
    lessonResource,
    lessonGroup.start,
    lessonGroup.end
  );
  const groups = getGroupsBetween(
    lessonResource,
    lessonGroup.start,
    lessonGroup.end
  );
  const recentlyFailedConcepts = concepts.filter((concept) => {
    const records = getVocabAssessmentRecord(concept, textIndicator);
    return records.length > 0 && records[records.length - 1] === false;
  });

  return {
    concepts,
    recentlyFailedConcepts,
    groups,
    textIndicator,
  };
};
