import React, { useContext, useEffect, useState } from "react";
import wait from "../../util/wait";
import AudioContext from "../../util/Audio/context";
import styles from "./CongratsAnimation.module.scss";
import classnames from "classnames";
import { ReactComponent as StarIcon } from "./../Svg/Star.svg";

export const CONTENT = {
  STAR: <StarIcon />,
  CERTIFICATE: (
    <img
      className={styles.certificateImage}
      src={process.env.PUBLIC_URL + "/images/certificate.png"}
    />
  ),
};

// elem is where it should end up
// content is the object iself
export default ({
  elem,
  content = CONTENT.STAR,
  audioSequence1 = "you_did_it",
  audioSequence2 = "congratulations",
  onComplete = () => {},
}) => {
  const [disappear, setDisappear] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [increaseSize, setIncreaseSize] = useState(false);
  const [move, setMove] = useState(false);
  const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });

  const audioService = useContext(AudioContext);

  useEffect(() => {
    const animation = async () => {
      await wait(500);
      setIncreaseSize(true);
      await wait(500);
      const rect = elem.getBoundingClientRect();
      setCoordinates({ left: rect.left, top: rect.top });
      await audioService.composeAndPlaySingleAudio("narrator", audioSequence1);

      setMove(true);
      await wait(500);
      audioService.composeAndPlaySingleAudio("narrator", audioSequence2);
      setDisappear(true);
      await wait(2000);
      setHidden(true);
      onComplete();
    };
    if (elem) {
      animation();
    }
  }, [audioService, elem]);

  return (
    <div
      className={classnames(styles.glenStar, "animated fadeIn", styles.move, {
        [styles.increaseSize]: increaseSize,
        [styles.disappear]: disappear,
        [styles.hidden]: hidden,
      })}
      style={move ? coordinates : {}}
    >
      {content}
    </div>
  );
};
