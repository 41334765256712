import sample from "lodash/sample";
import axios from "axios";

class Resource {
  constructor() {
    this.location = null;
    this.content = undefined;
  }
  getContent() {
    return this.content;
  }
  async populate() {
    //we use axios instead of the native fetch because when we build for Cordova,
    //fetch doesn't support the local file:// protocol
    let content = await axios.get(
      `${process.env.PUBLIC_URL}/content/json/${this.location}`
    );
    this.content = content.data;
    return this.content;
  }
  isPopulated() {
    return typeof this.content !== "undefined";
  }
}
class CollectedWordsResource extends Resource {
  constructor() {
    super();
    this.location = "collected_words_groups.json";
  }
  getGroup(groupNum) {
    return this.content[groupNum];
  }
}
class VocabLessonGroupResource extends Resource {
  constructor() {
    super();
    this.location = "vocab_lesson_groups.json";
  }
  getContent() {
    return this.content.map((group) => {
      let groupCopy = { ...group };
      if (groupCopy.images) {
        groupCopy.images = groupCopy.images.map(
          (i) => process.env.PUBLIC_URL + i
        );
      }
      return groupCopy;
    });
  }
  getGroup(groupNum) {
    let group = { ...this.content[groupNum] };
    if (group.images) {
      group.images = group.images.map((i) => process.env.PUBLIC_URL + i);
    }
    return group;
  }
}
class VocabLessonResource extends Resource {
  constructor() {
    super();
    this.location = "vocab_lessons.json";
  }
  getLesson(lessonNum) {
    return this.content[lessonNum];
  }
}
class PhonicsLessonGroupResource extends Resource {
  constructor() {
    super();
    this.location = "phonics_lesson_groups.json";
  }
  getContent() {
    return this.content.map((group) => {
      let groupCopy = { ...group };
      if (groupCopy.images) {
        groupCopy.images = groupCopy.images.map(
          (i) => process.env.PUBLIC_URL + i
        );
      }
      return groupCopy;
    });
  }
  getGroup(groupNum) {
    let group = { ...this.content[groupNum] };
    if (group.images) {
      group.images = group.images.map((i) => process.env.PUBLIC_URL + i);
    }
    return group;
  }
}
class PhonicsLessonResource extends Resource {
  constructor() {
    super();
    this.location = "phonics_lessons.json";
  }
  getLesson(lessonNum) {
    return this.content[lessonNum];
  }
}
class Phonics extends Resource {
  constructor() {
    super();
    this.location = "phonics.json";
  }
  getPhonic(sound) {
    if (!this.content[sound]) {
      console.log("Missing sound", sound);
    }
    const audio = this.content[sound];
    return {
      key: sound,
      audio: `${process.env.PUBLIC_URL}/content/audio/consonants/${audio}`,
    };
  }
}
class LessonGroupResource extends Resource {
  constructor() {
    super();
    this.location = "lesson_groups.json";
  }
  getContent() {
    return this.content.map((group) => {
      let groupCopy = { ...group };
      if (groupCopy.images) {
        groupCopy.images = groupCopy.images.map(
          (i) => process.env.PUBLIC_URL + i
        );
      }
      return groupCopy;
    });
  }
  getGroup(groupNum) {
    let group = { ...this.content[groupNum] };
    if (group.images) {
      group.images = group.images.map((i) => process.env.PUBLIC_URL + i);
    }
    return group;
  }
}
class LessonResource extends Resource {
  constructor() {
    super();
    this.location = "lessons.json";
  }
  getLesson(lessonNum) {
    return this.content[lessonNum];
  }
  getTotalLessonCount() {
    return Object.keys(this.content).length;
  }
}
class FlashcardResource extends Resource {
  constructor() {
    super();
    this.location = "flashcards.json";
  }
  getFlashcard(key, randomize = true) {
    if (!this.content[key]) {
      console.log("Missing flashcard", key);
      return null;
    }

    const card = this.content[key];
    const image = randomize ? sample(card.images) : card.images[0];
    return {
      key,
      image: `${process.env.PUBLIC_URL}/content/images/flashcards/${image}`,
      imageOptions: card.images.map(
        (i) => `${process.env.PUBLIC_URL}/content/images/flashcards/${i}`
      ),
      audio: `${process.env.PUBLIC_URL}/content/audio/flashcards/${card.audio}`,
      meaning: card.meaning,
    };
  }
}
class DescribeItResource extends Resource {
  constructor() {
    super();
    this.location = "describe_it.json";
  }
  getSentence(key) {
    let sentence = this.content[key];
    return {
      audio: `${process.env.PUBLIC_URL}/content/audio/${sentence.audio}`,
      structure: sentence.structure.map((segment) => {
        return {
          ...segment,
          //not all segments have audio attached
          audio: segment.audio
            ? `${process.env.PUBLIC_URL}/content/audio/${segment.audio}`
            : null,
        };
      }),
      key,
      image: `${process.env.PUBLIC_URL}/content/images/${sentence.image}_medium.png`,
    };
  }
}
export class ShapesResource extends Resource {
  constructor() {
    super();
    this.location = "shapes.json";
  }

  getAllShapes() {
    return Object.keys(this.content);
  }

  getAllColorsForShape(shape) {
    return Object.keys(this.content[shape]);
  }

  /**
   * Accepts a shape key and color. If either is not provided, chooses a random shape key or color
   * from the available shapes and their colors.
   * @param {string} [key] (optional) is the shape key associated with the image
   * @param {string}[color] (optional) is the color for the shape
   * @returns {Object} containing shape properties to support displaying the image
   */
  getShape(key, color) {
    let shape = "";
    if (!key) {
      // randomly choose shape
      const keys = Object.keys(this.content);
      shape = this.content[sample(keys)];
    } else {
      shape = this.content[key];
    }

    if (key && !this.content[key]) {
      console.log("Missing shape", key);
    }

    if (color && !shape[color]) {
      console.log("Missing color", color);
    }

    let image = "";
    if (!color) {
      // return random shape if no color given
      image = sample(shape);
    } else {
      image = shape[color];
    }
    return {
      key,
      image: `${process.env.PUBLIC_URL}/content/images/shapes/${image}`,
      imageOptions: Object.values(shape).map(
        (i) => `${process.env.PUBLIC_URL}/content/images/shapes/${i}`
      ),
    };
  }
}
class VocabLevelsResource extends Resource {
  constructor() {
    super();
    this.location = "vocab_levels.json";
  }
}

class KSEPQuestionsResource extends Resource {
  constructor() {
    super();
    this.location = "KSEP_assessment.json";
  }
}

class WordsToSoundsResource extends Resource {
  constructor() {
    super();
    this.location = "words_to_sounds.json";
  }
  getWord(word) {
    return this.content[word];
  }
}

class ShapesLessonGroupResource extends Resource {
  constructor() {
    super();
    this.location = "shapes_lesson_groups.json";
  }
  getContent() {
    return this.content.map((group) => {
      let groupCopy = { ...group };
      if (groupCopy.images) {
        groupCopy.images = groupCopy.images.map(
          (i) => process.env.PUBLIC_URL + i
        );
      }
      return groupCopy;
    });
  }
  getGroup(groupNum) {
    let group = { ...this.content[groupNum] };
    if (group.images) {
      group.images = group.images.map((i) => process.env.PUBLIC_URL + i);
    }
    return group;
  }
}
class ShapesLessonResource extends Resource {
  constructor() {
    super();
    this.location = "shapes_lessons.json";
  }
  getLesson(lessonNum) {
    return this.content[lessonNum];
  }
}

class ABCLessonGroupResource extends Resource {
  constructor() {
    super();
    this.location = "abc_lesson_groups.json";
  }
  getContent() {
    return this.content.map((group) => {
      let groupCopy = { ...group };
      if (groupCopy.images) {
        groupCopy.images = groupCopy.images.map(
          (i) => process.env.PUBLIC_URL + i
        );
      }
      return groupCopy;
    });
  }
  getGroup(groupNum) {
    let group = { ...this.content[groupNum] };
    if (group.images) {
      group.images = group.images.map((i) => process.env.PUBLIC_URL + i);
    }
    return group;
  }
}
class ABCLessonResource extends Resource {
  constructor() {
    super();
    this.location = "abc_lessons.json";
  }
  getContent() {
    return this.content.map((group) => {
      let groupCopy = { ...group };
      if (groupCopy.parameters.images) {
        groupCopy.parameters.images = groupCopy.parameters.images.map(
          (i) => process.env.PUBLIC_URL + i
        );
      }
      return groupCopy;
    });
  }
  getLesson(lessonNum) {
    return this.content[lessonNum];
  }
}

export default class ResourceRegistry {
  // TODO: Load these on demand instead of all at once.
  constructor() {
    this.lessonGroups = new LessonGroupResource();
    this.lessons = new LessonResource();

    this.vocabLessonGroups = new VocabLessonGroupResource();
    this.vocabLessons = new VocabLessonResource();

    this.shapesLessonGroups = new ShapesLessonGroupResource();
    this.shapesLessons = new ShapesLessonResource();

    this.ABCLessonGroups = new ABCLessonGroupResource();
    this.ABCLessons = new ABCLessonResource();

    this.phonicsLessonGroups = new PhonicsLessonGroupResource();
    this.phonicsLessons = new PhonicsLessonResource();
    this.phonics = new Phonics();

    this.flashcards = new FlashcardResource();
    this.describeItResource = new DescribeItResource();
    this.shapes = new ShapesResource();
    this.collectedWords = new CollectedWordsResource();
    this.vocabLevels = new VocabLevelsResource();
    this.ksepQuestions = new KSEPQuestionsResource();
    this.wordsToSoundsResource = new WordsToSoundsResource();
  }
  async populate() {
    await this.lessonGroups.populate();
    await this.lessons.populate();
    await this.flashcards.populate();
    await this.describeItResource.populate();
    await this.shapes.populate();
    await this.collectedWords.populate();
    await this.vocabLevels.populate();
    await this.ksepQuestions.populate();
    await this.vocabLessons.populate();
    await this.vocabLessonGroups.populate();
    await this.phonicsLessonGroups.populate();
    await this.phonicsLessons.populate();
    await this.phonics.populate();
    await this.shapesLessonGroups.populate();
    await this.shapesLessons.populate();
    await this.ABCLessonGroups.populate();
    await this.ABCLessons.populate();
    await this.wordsToSoundsResource.populate();
  }
}
