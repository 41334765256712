/**
 * These adapters are helper functions used in the generators to quickly
 * create MultipleChoiceQuestion props from various types of prompts and
 * answer data.
 */
import { shuffle } from "lodash";
import { activity } from "../../../../util/Analytics/events";

const getSubcomponentProps = (type, data, audio = null) => {
  const options = {
    text: { text: data, audio },
    number: { number: data, audio },
    image: { path: data, audio },
    letter: { text: data[0], audio },
    audio: { audio: data || audio },
  };

  return options[type];
};

const getPromptProps = (type, data, audio = null) => ({
  type,
  props: getSubcomponentProps(type, data, audio),
});

const getAnswersProps = (type, data) => {
  const props = data.map((data, index) => ({
    type,
    props: getSubcomponentProps(type, data),
    correct: index === 0, // Assumes the first answer is correct
  }));

  return shuffle(props);
};

export const getMCProps = (
  layoutType,
  promptType,
  promptData,
  answersType,
  answersData,
  promptAudio = null
) => ({
  questionType: activity.MULTIPLE_CHOICE,
  props: {
    layout: { type: layoutType },
    prompt: getPromptProps(promptType, promptData, promptAudio),
    answers: getAnswersProps(answersType, answersData),
    analytics: true, // Enables sending events to the backend
  },
});
