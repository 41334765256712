import {
    AuthedTopBar, BackButton, BottomBar, GameContainer, GameWrapper
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import React, {useState, useCallback} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAdminAccount} from "../../util/Profile/useProfile";
import styles from "./AdminAccountSettings.module.scss";
import {Modal, ModalBody} from "reactstrap";
import useFirebase from "../../util/Firebase/useFirebase";
import * as ROUTES from "../../constants/routes";
import {doLogoutAuthUser} from "../../actions/session";
import {useDispatch} from "react-redux";

export default () => {
    const history = useHistory();
    const firebase = useFirebase();
    const {deploymentId} = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();

    const goBack = () => history.push({
        state: {skipAuth: true}, pathname: `/deployment/${deploymentId}/adminDashboard/list`,
    });

    const onDeleteAccount = () => {
        setModalOpen(true);
    }

    const confirmDeleteAccount = async () => {
        await firebase.deleteAdminAccount();
        firebase.doSignOut();
        dispatch(doLogoutAuthUser());
    }

    const onCancel = useCallback(() => {
        setModalOpen(false);
    }, []);

    const adminAccount = useAdminAccount();
    console.log(adminAccount);

    const [username, setUsername] = useState(adminAccount.username);

    return <GameWrapper>
        <AuthedTopBar/>
        <GameContainer>
            <Modal isOpen={modalOpen}><ModalBody>

                <p>
                    Are you sure you would like to delete your account? This is not reversible.
                </p>

                <button className="btn btn-outline-info" onClick={onCancel}>Cancel</button>&nbsp;
                <button className="btn btn-danger" onClick={confirmDeleteAccount}>Delete account</button>

            </ModalBody></Modal>
            <div className={styles.adminAccountSettingsContainer}>
                <div className="form-group row mb-4">
                    <label className="col-3 col-form-label">Name</label>
                    <div className="col-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ex: Glen"
                            value={username}
                            disabled
                        />
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label className="col-3 col-form-label">Email</label>
                    <div className="col-6">
                        <input type="text" className="form-control" value={adminAccount.email} disabled/>
                    </div>
                </div>
                {errorMessage !== null ? errorMessage : null}
            </div>
            <button className="btn btn-danger" onClick={onDeleteAccount}>Delete account</button>
        </GameContainer>
        <BottomBar left={<BackButton onClick={goBack}/>}/>
    </GameWrapper>
}