import React from "react";
import styles from "./OptionCard.module.scss";
import classnames from "classnames";

/**
 * OptionCard creates cards for the SelectAssessment page.
 * To use, pass in an image, a description, whether it is active,
 * and the location it should go to when clicked
 */
export default ({
  description,
  image,
  isActive,
  onClick,
  withSpiral = true,
}) => {
  const onClickCallback = () => {
    if (!isActive) {
      return;
    }
    onClick();
  };

  return (
    <div className="mt-auto mb-auto col-6">
      <div
        className={classnames(styles.cardGroup, {
          [styles.active]: isActive,
          [styles.inactive]: !isActive,
        })}
        onClick={onClick ? onClickCallback : null}
      >
        {withSpiral && (
          <img
            className={styles.spiral}
            src={process.env.PUBLIC_URL + "/images/spiral.png"}
          />
        )}
        <div className={styles.imgGroup}>
          <img src={image} />
        </div>
      </div>

      {
        <div className={styles.cardTitle}>
          <div
            className={classnames(styles.cardTitleText, {
              [styles.inactive]: !isActive,
            })}
          >
            <div>{description}</div>
          </div>
        </div>
      }
    </div>
  );
};
