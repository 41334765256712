import React, { useEffect, useMemo, useState } from "react";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import LessonBManager from "./manager";
import { useComponentRender } from "../../util/hooks";
import sampleSize from "lodash/sampleSize";
import shuffle from "lodash/shuffle";
import RepeatedSoundClickGame from "./RepeatedSoundClickGame";
import GlenMatchWithAnalytics from "../../../../games/GlenMatch/GlenMatchWithAnalytics";
import ImageClickWithAnalytics from "../../../../games/ImageClick/ImageClickWithAnalytics";
import { useResourceFromRegistry } from "../../../../util/Resource/useResource";
import wait from "../../../../util/wait";
import ActiveDictionary from "../../../../games/ActiveDictionary/ActiveDictionary";
import DescribeItWithAnalytics from "../../../../games/DescribeIt/DescribeItWithAnalytics";

const Step1 = ({ manager, updateProgress, onComplete }) => {
  if (manager.shouldPlayMatch()) {
    const words = manager.getWordsForClass(1).slice(0, 4);
    const onGameFinished = () => {
      manager.incrementGlenMatchExposure();
      updateProgress().then(onComplete);
    };
    return <GlenMatchWithAnalytics words={words} onComplete={onGameFinished} />;
  } else {
    onComplete();
    return null;
  }
};

const Step2 = ({ manager, updateProgress, onComplete }) => {
  let class2Words = sampleSize(manager.getWordsForClass(2), 4);
  console.log("Playing sound click with class2 words: ", class2Words);
  const onRoundComplete = ({ wasCorrect, targetWord }) => {
    if (wasCorrect) {
      manager.incrementSuccess(2, targetWord);
    }
    updateProgress();
  };
  return (
    <RepeatedSoundClickGame
      words={class2Words}
      onRoundComplete={onRoundComplete}
      onComplete={onComplete}
    />
  );
};

const Step3 = ({ manager, updateProgress, onComplete }) => {
  const [gameState, setGameState] = useState(null);
  const initNewGame = (trialNum) => {
    let class2Words = manager.getWordsForClass(2);
    let lastWord = gameState && gameState.targetWord;

    //Make a list of concepts in Lesson3.Class2 with success_count < target_success_count (Lesson3.Class2)
    let wordsWithoutEnoughSuccess = manager.getWordsWithoutEnoughSuccess(2);
    console.log("Words without enough success", wordsWithoutEnoughSuccess);
    if (wordsWithoutEnoughSuccess.length === 0) {
      console.log("B HERE");
      onComplete();
      return;
    }

    //don't repeat the same word twice.
    if (lastWord && wordsWithoutEnoughSuccess.length > 1) {
      wordsWithoutEnoughSuccess = wordsWithoutEnoughSuccess.filter(
        (w) => w !== lastWord
      );
    }

    console.log("Playing image click");
    let targetWord = wordsWithoutEnoughSuccess[0];

    //play image click making the correct word the word with the least success.
    //the confusion words can be anything but that word.
    let confusionWords = class2Words
      .filter((w) => w !== targetWord)
      .splice(0, 3);
    setGameState({ trialNum, targetWord, confusionWords });
  };

  useEffect(() => initNewGame(1), []);

  const onRoundComplete = (wasCorrect) => {
    if (wasCorrect) {
      manager.incrementSuccess(2, gameState.targetWord);
    }
    updateProgress().then(() => {
      if (manager.getSuccessCriterion(2) >= 1) {
        onComplete();
      } else {
        initNewGame(gameState.trialNum + 1);
      }
    });
  };

  if (!gameState) {
    return null;
  }

  return (
    <ImageClickWithAnalytics
      key={gameState.trialNum}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      onComplete={(wasCorrect) => onRoundComplete(wasCorrect)}
    />
  );
};

const Step4 = ({ manager, updateProgress, onComplete }) => {
  const flashcardResource = useResourceFromRegistry("flashcards");
  const describeItResource = useResourceFromRegistry("describeItResource");
  const [showActiveDictionary, setShowActiveDictionary] = useState(false);
  const [gameState, setGameState] = useState(null);
  const initNextRound = (trialNum) => {
    setShowActiveDictionary(false);

    //takes the least exposed word from class3 via the least exposed group from class1.
    //For example, "walk" in class1 is least exposed.
    //Then pick the noun-verb combination (class3) with the smallest exposure count from among those: "boy walks", "girl walks" ect.
    let class1Word = manager.getWordsOrderedByExposure(1)[0];

    let class3RelatedWords = manager
      .getWordsOrderedByExposure(3)
      // in the case of "orange_color", we need to filter for just "orange".
      .filter((w) => w.includes(class1Word.split("_")[0]))
      .slice(0, 4);

    let class3Word = class3RelatedWords[0];

    //given class 3 word get the class2 word (e.g. red_shirt, find either "red" or "shirt" in class 2.)
    //e.g. "boy_walks", find "boy"
    let class3Segments = class3Word.split("_");
    let class2Word = manager
      .getWordsForClass(2)
      .find(
        (w) => w.includes(class3Segments[0]) || w.includes(class3Segments[1])
      );

    // get the sentence and override it with lesson-specific words
    let sentence = describeItResource.getSentence(class3Word);
    sentence.key = class3Word;

    // noun
    let noun = shuffle(manager.getWordsForClass(2)).find(
      (w) => w !== class2Word && w !== sentence.structure[0].answer
    );
    sentence.structure[0].options = [
      flashcardResource.getFlashcard(noun).meaning,
    ];

    // adjective
    let adjective = shuffle(manager.getWordsForClass(1)).find(
      (w) => w !== class1Word && w !== sentence.structure[1].answer
    );
    sentence.structure[1].options = [
      flashcardResource.getFlashcard(adjective).meaning,
    ];

    setGameState({
      trialNum,
      sentence,
      class1Word,
      class2Word,
      class3Word,
      class3RelatedWords,
    });
  };

  useEffect(() => {
    initNextRound(1);
  }, []);

  const onDescribeItRoundComplete = () => {
    manager.incrementExposure(1, gameState.class1Word);
    manager.incrementExposure(2, gameState.class2Word);
    manager.incrementExposure(3, gameState.class3Word);
    updateProgress().then(() => {
      setShowActiveDictionary(true);
    });
  };

  const onActiveDictionaryComplete = () => {
    console.log(
      "Active dictionary complete, progress is",
      manager.getCurrentProgress()
    );
    if (manager.getCurrentProgress() >= 1) {
      onComplete();
    } else {
      initNextRound(gameState.trialNum + 1);
    }
  };

  if (!gameState) {
    return null;
  }

  return (
    <>
      {!showActiveDictionary && (
        <DescribeItWithAnalytics
          key={gameState.trialNum}
          sentence={gameState.sentence}
          onComplete={onDescribeItRoundComplete}
        />
      )}
      {showActiveDictionary && (
        <ActiveDictionary
          key={gameState.trialNum}
          verb={gameState.class1Word}
          words={gameState.class3RelatedWords}
          onComplete={onActiveDictionaryComplete}
        />
      )}
    </>
  );
};

const useGameContent = (parameters, onComplete) => {
  const manager = useMemo(() => new LessonBManager(parameters), [parameters]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => {
    setCurrentProgress(manager.getCurrentProgress());
    return wait(200);
  };
  const incrementStep = () => setCurrentStep(currentStep + 1);

  const stepToComponent = {
    1: (
      <Step1
        manager={manager}
        updateProgress={updateProgress}
        onComplete={incrementStep}
      />
    ),
    2: (
      <Step2
        manager={manager}
        onComplete={incrementStep}
        updateProgress={updateProgress}
      />
    ),
    3: (
      <Step3
        manager={manager}
        onComplete={incrementStep}
        updateProgress={updateProgress}
      />
    ),
    4: (
      <Step4
        manager={manager}
        updateProgress={updateProgress}
        onComplete={onComplete}
      />
    ),
  };
  const toRender = useComponentRender(stepToComponent, currentStep);
  return [toRender, currentProgress];
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
