import { clearRequestInterval, requestInterval } from "./util";
import { Howl } from "howler";

class HTML5Audio {
  constructor(audioPath) {
    this.media = null;
    this.mediaInterval = null;
    this.cues = [];

    this.handleAudioOnOSEvent = () => {
      if (document.visibilityState === "hidden") {
        this.pause();
      } else if (this.soundId && document.visibilityState === "visible") {
        window.location.reload();
      }
    };

    this.soundId = undefined;

    this.media = new Howl({
      src: [audioPath],
    });
    //http://pupunzi.open-lab.com/2013/03/13/making-html5-audio-actually-work-on-mobile/
    //indicate to mobile devices intent to play audio
    // this.media.play();
    // this.media.pause();

    // when the app is paused by the OS, we need to pause the audio
    document.addEventListener(
      "visibilitychange",
      this.handleAudioOnOSEvent,
      false
    );
  }

  deconstruct() {
    window.removeEventListener("visibilitychange", this.handleAudioOnOSEvent);
  }

  clearCues() {
    this.cues = [];
  }

  play() {
    if (this.mediaInterval) {
      clearRequestInterval(this.mediaInterval);
    }

    this.mediaInterval = requestInterval(() => {
      let currentPosition = this.media.seek();
      if (currentPosition > -1) {
        //run through the cues and execute callbacks.
        this.cues.forEach((cue) => {
          let difference = currentPosition - cue.time;
          if (difference < 0.05 && difference > 0) {
            cue.cueCallback.call();
          }
        });
      }
    }, 0);

    this.soundId = this.media.play(this.soundId);
    return this;
  }

  pause() {
    if (this.mediaInterval) {
      clearRequestInterval(this.mediaInterval);
    }
    this.media.pause(this.soundId);
    return this;
  }

  seekTo(position) {
    this.media.seek(position, this.soundId);
    return this;
  }

  clearAudio() {
    this.media = null;
    this.cues = [];
    if (this.mediaInterval) {
      clearRequestInterval(this.mediaInterval);
    }
    return this;
  }

  atTime(time, cueCallback) {
    this.cues.push({ time: time, cueCallback });
    return this;
  }
}

export default HTML5Audio;
