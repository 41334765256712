import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import styles from "./A2.module.scss";
import classnames from "classnames";
import { LINES, MODE, TIMINGS } from "./util";
import AudioContext from "../../util/Audio/context";
import { ReactComponent as MusicIcon } from "./../../components/Svg/MusicIcon.svg";
import AudioSync from "../../util/SyncedAudio/audio/audio";
import CircleAnimation from "../../components/CircleAnimation/CircleAnimation";
import wait from "../../util/wait";
import {isIOS} from "../../util/deviceType";

export default ({
  mode = MODE.lower,
  findLetter = null,
  withIntro = true,
  onComplete = () => {},
}) => {
  const Audio = useMemo(
    () => new AudioSync("/content/audio/alphabet/ABCsong.mp3"),
    []
  );
  const needsHintTimeout = useRef(null);
  const [blinkHint, setBlinkHint] = useState(false);
  const audioService = useContext(AudioContext);
  const [currentLetter, setCurrentLetter] = useState(null);
  const [isCircled, setIsCircled] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [showReviewLetter, setShowReviewLetter] = useState(false);
  const [disableSelect, setDisableSelect] = useState(true);

  const findLetterResolved = useMemo(() => {
    return (
      findLetter || String.fromCharCode(97 + Math.floor(Math.random() * 26))
    );
  }, [findLetter]);

  useEffect(() => {
    const fx = async () => {
      if (withIntro) {
        setBlinkHint(true);
        await audioService.composeAndPlaySingleAudio(
          "narrator",
          "you_can_press_this_whenever_you_want_a_hint_from_the_abc_song"
        );
        await wait(900);
        setBlinkHint(false);
      }

      await audioService.composeAndPlayAudio([
        {
          directory: "narrator",
          files: ["can_you_find_the_letter"],
        },
        {
          directory: "letters",
          files: [findLetterResolved.toUpperCase()],
        },
      ]);
      setDisableSelect(false);

      needsHintTimeout.current = setTimeout(async () => {
        setBlinkHint(true);
        await audioService.composeAndPlaySingleAudio(
          "narrator",
          "would_you_like_a_hint_from_the_abc_song"
        );
      }, 5000);
    };
    fx();
    return () => Audio.pause();
  }, [Audio, audioService, findLetterResolved, withIntro]);

  const clickLetter = useCallback(
    (letter) => {
      clearTimeout(needsHintTimeout.current);
      setDisableSelect(true);
      const fx = async () => {
        if (disableSelect) {
          return;
        }
        setIsCircled(true);
        setSelectedLetter(letter);
        await audioService.composeAndPlayAudio([
          {
            directory: "soundfx",
            files: [letter === findLetterResolved ? "correct" : "incorrect"],
          },
          {
            directory: "letters",
            files: [findLetterResolved.toUpperCase()],
          },
        ]);

        setShowReviewLetter(true);
        await wait(2000);
        onComplete();
      };
      fx();
    },
    [audioService, findLetterResolved, onComplete, disableSelect]
  );

  const playHint = useCallback(() => {
    clearTimeout(needsHintTimeout.current);

    if (disableSelect) {
      return;
    }

    setDisableSelect(true);
    let matchingLine = LINES.find((match) =>
      match.includes(findLetterResolved)
    );

    // hack needed for web/android only.
    if (!isIOS()) {
      Audio.play().pause();
    }

    let startAt;
    TIMINGS.forEach((timing) => {
      if (matchingLine.includes(timing.letter)) {
        Audio.atTime(timing.startTime, () => {
          setCurrentLetter(timing.letter);
        });
      }

      if (timing.letter === matchingLine.slice(-1)) {
        Audio.atTime(timing.endTime, async () => {
          Audio.clearCues();
          Audio.pause();
          setCurrentLetter(null);
          setBlinkHint(false);
          await wait(1000);

          await audioService.composeAndPlayAudio([
            {
              directory: "narrator",
              files: ["can_you_find_the_letter"],
            },
            {
              directory: "letters",
              files: [findLetterResolved.toUpperCase()],
            },
          ]);

          setDisableSelect(false);
        });
      } else if (timing.letter === matchingLine.slice(0, 1)) {
        // saving the startAt time for iOS hack.
        startAt = timing.startTime;
        Audio.setCurrentAudioTime(timing.startTime);
      }
    });

    Audio.play();
    // iOS hack, seek needs to be after play. see
    // https://stackoverflow.com/questions/35458710/cordova-media-plugin-seekto-doesnt-work-the-first-time
    if(isIOS()) {
      setTimeout(() => {
        Audio.setCurrentAudioTime(startAt);
      }, 10);
    }

  }, [Audio, audioService, disableSelect, findLetterResolved]);

  return (
    <div className="d-flex flex-row">
      <div className={styles.alphabetContainer}>
        {LINES.map((line, lineIndex) => (
          <div key={lineIndex} className="d-flex flex-row h-100">
            <div key={line} className={styles.rowContainer}>
              {line.split("").map((letter) => {
                const clickedCorrect =
                  selectedLetter === letter && letter === findLetterResolved;
                const clickedIncorrect =
                  selectedLetter === letter && letter !== findLetterResolved;

                return (
                  <div
                    key={letter}
                    onClick={() => clickLetter(letter)}
                    className={classnames(styles.letter, {
                      [styles.activeLetter]: currentLetter === letter,
                    })}
                  >
                    {letter === findLetterResolved && isCircled && (
                      <CircleAnimation
                        offsets={{
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: "75%",
                        }}
                      />
                    )}
                    <div
                      className={classnames({
                        "animated bounce": clickedCorrect,
                        "animated shake": clickedIncorrect,
                        [styles.outlineRed]: clickedIncorrect,
                        [styles.outlineGreen]: clickedCorrect,
                      })}
                    >
                      {mode === MODE.lower ? letter : null}
                      {mode === MODE.upper && letter.toUpperCase()}
                      {mode === MODE.upperAndLower &&
                        `${letter.toUpperCase()}${letter}`}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.musicContainer}>
        <MusicIcon
          className={classnames({ "animated flash slow infinite": blinkHint })}
          onClick={playHint}
        />
        {showReviewLetter && (
          <div>
            {mode === MODE.lower ? findLetterResolved : null}
            {mode === MODE.upper && findLetterResolved.toUpperCase()}
            {mode === MODE.upperAndLower &&
              `${findLetterResolved.toUpperCase()}${findLetterResolved}`}
          </div>
        )}
      </div>
    </div>
  );
};
