import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";

import { PersistGate } from "redux-persist/integration/react";

import makeStore from "./store";
import App from "./pages/App";
import Firebase, { FirebaseContext } from "./util/Firebase";

import * as Sentry from "@sentry/browser";
import { AudioService } from "./util/Audio/Audio";
import AudioContext from "./util/Audio/context";
import ResourceContext from "./util/Resource/context";
import ResourceRegistry from "./util/Resource/resource";
import PreloadAssets from "./util/PreloadAssets/index";

// skips when in "development mode" (i.e., "yarn start")
// when "yarn build" command is run, this is production.
// See: https://create-react-app.dev/docs/adding-custom-environment-variables/
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://03ef45a7d2b44f6bacc98943305dbb7d@sentry.io/1777850",
    environment: process.env.REACT_APP_ENV_NAME,
  });
}

const { store, persistor } = makeStore();

const resourceRegistry = new ResourceRegistry();

const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PreloadAssets registry={resourceRegistry}>
          <FirebaseContext.Provider value={new Firebase()}>
            <AudioContext.Provider value={new AudioService()}>
              <ResourceContext.Provider value={resourceRegistry}>
                <App />
              </ResourceContext.Provider>
            </AudioContext.Provider>
          </FirebaseContext.Provider>
        </PreloadAssets>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener("deviceready", startApp, false);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
