import React from "react";
import { activity } from "../../../../util/Analytics/events";
import noop from "lodash/noop";
import PhonicAssess from "./PhonicAssess";
import { useTimedAnalytics } from "../../util/hooks";

export default ({ onStart = noop, onComplete = noop, wasManual, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.PHONIC_ASSESS,
    wasManual: wasManual,
    lessonGroupId: props.lessonGroupId,
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  const onCompleteOverride = (score) => {
    completeEvent({ ...eventData, score });
    onComplete(score);
  };
  return (
    <PhonicAssess
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
      {...props}
    />
  );
};
