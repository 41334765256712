import React, { useEffect, useMemo, useRef, useState } from "react";
import sample from "lodash/sample";
import { useHistory, useParams } from "react-router-dom";
import Guide from "../../../components/Guide/Guide";
import { activity } from "../../../util/Analytics/events";
import { useTimedAnalytics } from "../util/hooks";
import mapLessonTypeToFunction from "../../../components/Lessons/util/lessonToFunction";
import {
  getNumPhonicsInLesson,
  getNumWordsInLesson,
} from "../../../util/Lesson/util";
import Confetti, {
  ConfettiAnimation,
} from "../../../components/Confetti/Confetti";
import { gotoLessonGroupHome } from "../util/groups";
import { CertificateEvent } from "../../../util/Timeline/timeline";
import Interlude from "../Interlude/Interlude";
import CongratsAnimation, {
  CONTENT,
} from "../../CongratsAnimation/CongratsAnimation";
import { TYPES as types } from "../../../components/Lessons/util/groups";

const useLessonAnalytics = (lessonNum, type) => {
  const eventData = { activity: activity.LESSON, lessonNum, type };
  const { startEvent, completeEvent } = useTimedAnalytics();
  useEffect(() => startEvent(eventData), []); // eslint-disable-line react-hooks/exhaustive-deps
  return (extraData) => completeEvent({ ...eventData, ...extraData });
};

export default ({
  profile,
  lessonResource,
  lessonGroupResource,
  type,
  allowAssessStrengthen = true,
}) => {
  const [showGuide, setShowGuide] = useState(false);
  const [showInterlude, setShowInterlude] = useState(false);

  const { deploymentId, lesson } = useParams();
  const lessonNum = parseInt(lesson, 10);
  const createCompleteEvent = useLessonAnalytics(lessonNum, type);
  const profileBoxRef = useRef(null);

  const history = useHistory();

  const lessonData = lessonResource.getLesson(lesson);
  const lessonGroupData = lessonGroupResource.getGroup(lessonData.groupId);

  const onComplete = () => {
    const hasAlreadyMastered = profile.getLastMasteredLesson() >= lesson;

    let extraData = {};
    if (type === types.GLEN_LEARN) {
      const totalWordsLearned = !hasAlreadyMastered
        ? getNumWordsInLesson(lesson, lessonResource)
        : 0;
      extraData = { totalWordsLearned };
    } else if (type === types.GLEN_PHONICS) {
      const totalPhonicsLearned = !hasAlreadyMastered
        ? getNumPhonicsInLesson(lesson, lessonResource)
        : 0;
      extraData = { totalPhonicsLearned };
    }

    createCompleteEvent({ hasAlreadyMastered, ...extraData });
    setShowGuide(true);
  };

  const [showCertificateAnimation, setShowCertificateAnimation] = useState(
    false
  );

  const guideAudioFinished = async () => {
    const hasAlreadyMastered = profile.getLastMasteredLesson() >= lesson;

    profile.setLastMasteredLesson(
      Math.max(profile.getLastMasteredLesson(), lessonNum)
    );
    // when a lesson is complete, add either a timeline (learner log event) or a wall of fame event.
    if (!hasAlreadyMastered && lessonData.wallOfFameMilestone) {
      // this looks like:
      // "wallOfFameMilestone": {
      //   "totalWords": 25,
      //   "badgeColor": "BRONZE"
      // },
      // totalWords, totalShapes, totalSounds, AtoZ are the options. badgeColor is required.
      profile.addTimelineEvent(
        new CertificateEvent(type, lessonData.wallOfFameMilestone)
      );
      setShowCertificateAnimation(true);
    } else {
      showInterludeOrGoHome();
    }
  };

  const showInterludeOrGoHome = () => {
    if (lessonData.onComplete) {
      setShowInterlude(true);
    } else {
      goHome();
    }
  };

  const goHome = () => {
    let shouldStartAssessStrengthen = false;
    if (allowAssessStrengthen) {
      // if it was the last lesson in the group and they have not already assessed, start assessment.
      // otherwise the user will continue on their own.
      const isLastLessonOfGroup = lessonNum === lessonGroupData.end;
      const hasAlreadyCompletedAssessment = profile
        .getCompletedAssessmentGroupLessonIds()
        .includes(lessonData.groupId);
      shouldStartAssessStrengthen =
        isLastLessonOfGroup && !hasAlreadyCompletedAssessment;
    }

    gotoLessonGroupHome(
      history,
      deploymentId,
      lessonData.groupId,
      type,
      shouldStartAssessStrengthen
    );
  };

  const lessonComponent = useMemo(() => {
    return React.createElement(mapLessonTypeToFunction[lessonData.type], {
      parameters: lessonData.parameters,
      onComplete,
      profileBoxRef,
    });
    // the lesson component itself should never be re-created
    // i.e., it needs to not be re-rendered for profile updates, etc.
    // eslint-disable-next-line
  }, []);

  if (showInterlude) {
    return <Interlude parameters={lessonData.onComplete} onComplete={goHome} />;
  }

  return (
    <>
      {showGuide && (
        <Guide
          audio={
            lessonData.completionFeedback
              ? [lessonData.completionFeedback]
              : [sample(["good_job", "nice_work", "well_done"])]
          }
          onComplete={guideAudioFinished}
        />
      )}
      {showGuide && <Confetti animation={ConfettiAnimation.BASIC_CANNON} />}
      {showCertificateAnimation && (
        <CongratsAnimation
          elem={profileBoxRef.current}
          content={CONTENT.CERTIFICATE}
          audioSequence1={"congratulations"}
          audioSequence2={"you_won_a_prize"}
          onComplete={showInterludeOrGoHome}
        />
      )}
      {showCertificateAnimation && (
        <Confetti animation={ConfettiAnimation.FIREWORKS} />
      )}
      {lessonComponent}
    </>
  );
};
