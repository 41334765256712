import { useState } from "react";
import range from "lodash/range";
import wait from "./wait";

// creates an array of size "length" initialized as false, and linearly sets the array to true with "delay" intervals
export const useIncrementalDelay = (length, delay = 1200) => {
  const [values, setValues] = useState(range(0, length).map((_) => false));
  const startAnimation = async () => {
    for (let i = 1; i <= length; i++) {
      let newOptions = [
        ...range(0, i).map((_) => true),
        ...range(i, length).map((_) => false),
      ];
      setValues(newOptions);
      await wait(delay);
    }
  };
  return [values, startAnimation];
};
