import React from "react";
import classnames from "classnames";
import styles from "./AgeSelector.module.scss";

const AgeButton = ({ age, onClick, selected = false }) => (
  <button
    onClick={onClick}
    className={classnames("btn btn-lg", {
      "btn-outline-dark": !selected,
      "btn-primary animated pulse": selected,
    })}
  >
    {age}
  </button>
);

export default ({
  selectedAge,
  onSelectAge,
  ages = ["3", "4", "5", "6", "7+"],
}) => {
  return (
    <div className={styles.agesContainer}>
      {ages.map((age) => (
        <div key={age}>
          <AgeButton
            age={age}
            selected={selectedAge === age}
            onClick={() => onSelectAge(age)}
          />
        </div>
      ))}
    </div>
  );
};
