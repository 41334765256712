import { getPhonicsBetweenLessons } from "../../../../util/Lesson/util";

export const getPhonicLessonGroupInfo = (lessonResource, lessonGroup) => {
  const phonics = getPhonicsBetweenLessons(
    lessonResource,
    lessonGroup.start,
    lessonGroup.end
  );
  return {
    consonantWordMap: phonics,
    level: lessonGroup.assessment.level,
  };
};
