import React from "react";
import styles from "./ColumnLayout.module.scss";

const ColumnLayout = ({ prompt, answers }) => {
  const answerCards = answers.map((answer, idx) => (
    <div key={"card-" + idx} className={styles.answerCard}>
      {answer}
    </div>
  ));
  return (
    <div className={styles.cardContainer}>
      <div className={styles.answerCol}>{answerCards}</div>
      <div className={styles.promptCol}>
        {React.cloneElement(prompt, { size: "xl" })}
      </div>
    </div>
  );
};

export default ColumnLayout;
