import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenPhonicsProfile } from "../../../util/Profile/ProfileUtil";
import Strengthen from "../../../components/Lessons/Strengthen/Strengthen";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const lessonGroupResource = useResourceFromRegistry("phonicsLessonGroups");
  const lessonResource = useResourceFromRegistry("phonicsLessons");
  const glenPhonicsProfile = useGlenPhonicsProfile();
  return (
    <Strengthen
      profile={glenPhonicsProfile}
      lessonGroupResource={lessonGroupResource}
      lessonResource={lessonResource}
      type={types.GLEN_PHONICS}
    />
  );
};
