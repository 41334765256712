const INITIAL_STATE = {
  profileUser: {
    id: null,
    username: null,
    created: null,
    profile: {},
  },
};

function profileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "PROFILE_USER_SET": {
      return {
        ...state,
        profileUser: action.profileUser,
      };
    }
    case "PROFILE_USER_UPDATE": {
      return {
        profileUser: {
          ...state.profileUser,
          profile: action.profile,
        },
      };
    }
    default:
      return state;
  }
}

export default profileReducer;
