import React from "react";

export default ({ children, enable = false, style }) => {
  const disableEvent = React.useCallback(
    (e) => {
      e.persist();
      if (enable) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [enable]
  );

  return (
    <div
      onClick={disableEvent}
      onKeyDown={disableEvent}
      style={
        enable ? { pointerEvents: "none", userSelect: "none", ...style } : style
      }
    >
      {children}
    </div>
  );
};
