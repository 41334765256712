import React from "react";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";
import { activity } from "../../util/Analytics/events";
import noop from "lodash/noop";
import SoundClick2 from "./SoundClick2";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.SOUND_CLICK_2,
    ...props,
  };
  const onCompleteOverride = (wasCorrect) => {
    completeEvent({ ...eventData, wasCorrect });
    onComplete(wasCorrect);
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  return (
    <SoundClick2
      {...props}
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
    />
  );
};
