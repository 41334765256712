import sampleSize from "lodash/sampleSize";

export default class PhonicStrengthen0Manager {
  constructor(consonantWordMap, recentlyFailedSounds) {
    this.currentRound = 0;
    this.totalRounds = 4;
    this.consonantWordMap = consonantWordMap;
    this.pbOffset = 0;
    this.pbScale = 0.25;
    this.sounds = this._chooseConcepts(
      Object.keys(consonantWordMap),
      recentlyFailedSounds
    );
  }

  get isComplete() {
    return this.currentRound === this.totalRounds;
  }

  onRoundComplete() {
    this.pbOffset += this.pbScale;
  }

  get nextLessonC3() {
    this.currentRound++;

    //E.g.:
    // "parameters": {
    //     "content": {
    //         "buh": ["ball", "boy", "bird"],
    //         "duh": ["dog", "doll", "duck"]
    //     },
    //     "targetSoundCount": 2
    // }

    // choose two sounds and add them to the map
    const content = {};
    sampleSize(this.sounds, 2).forEach((sound) => {
      content[sound] = this.consonantWordMap[sound];
    });
    return {
      content,
      targetSoundCount: 1,
      pbOffset: this.pbOffset,
      pbScale: this.pbScale,
    };
  }

  // TODO: Refactor this to share implementation with PhonicStrengthen1
  _chooseConcepts(allSounds, recentlyFailedSounds) {
    // Choose N0 = min(4,length(sounds) sounds from “sounds” as follows
    // N1 = min(N0, size(recently_failed_sounds))
    // N2 = N0 – N1

    const allOtherSounds = allSounds.filter(
      (concept) => !recentlyFailedSounds.includes(concept)
    );

    let n0 = Math.min(4, allSounds.length);
    let n1 = Math.min(n0, recentlyFailedSounds.length);
    let n2 = n0 - n1;

    let sounds = [];
    sounds = sounds.concat(sampleSize(recentlyFailedSounds, n1));
    sounds = sounds.concat(sampleSize(allOtherSounds, n2));
    return sounds;
  }
}
