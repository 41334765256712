import React from "react";
import { Link } from "react-router-dom";
import styles from "./AdminLayout.module.scss";

const Navigation = () => (
  <div
    className={`d-flex flex-column flex-md-row align-items-center px-md-4 border-bottom shadow-sm ${styles.nav}`}
  >
    <Link
      to="/"
      className="my-0 mr-md-auto font-weight-normal"
      style={{ textDecoration: "none", color: "#000" }}
    >
      <img
        alt="Logo"
        src={process.env.PUBLIC_URL + "/images/GWlogo_spiral_300x195.png"}
        style={{ width: "4.5vw", height: "3.0vw" }}
      />
    </Link>
  </div>
);

export default ({ children }) => {
  return (
    <>
      <Navigation />
      <div className="container">{children}</div>
    </>
  );
};
