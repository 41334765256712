import React from "react";

import ColumnLayout from "./Layout/ColumnLayout";
import GridLayout from "./Layout/GridLayout";
import RowLayout from "./Layout/RowLayout";
import TextPrompt from "./Prompt/TextPrompt";
import ImagePrompt from "./Prompt/ImagePrompt";
import NumberPrompt from "./Prompt/NumberPrompt";
import AudioPrompt from "./Prompt/AudioPrompt";
import TextAnswer from "./Answers/TextAnswer/TextAnswer";
import ImageAnswer from "./Answers/ImageAnswer/ImageAnswer";
import NumberAnswer from "./Answers/NumberAnswer/NumberAnswer";
import NumberReview from "./Review/NumberReview/NumberReview";

/**
 * Mapping of subcomponent types to their respective components. For specific
 * subcomponent configuration, please see the subcomponent's prop signatures.
 */
const subcomponents = {
  layout: {
    column: ColumnLayout,
    grid: GridLayout,
    row: RowLayout,
  },
  prompt: {
    letter: TextPrompt,
    text: TextPrompt,
    image: ImagePrompt,
    number: NumberPrompt,
    audio: AudioPrompt,
  },
  answer: {
    letter: TextAnswer,
    text: TextAnswer,
    image: ImageAnswer,
    number: NumberAnswer,
  },
  review: {
    number: NumberReview,
  },
};

/**
 * Thrown when an invalid subcomponent type or category name is passed to
 * createSubcomponent.
 */
class InvalidSubcomponentError extends Error {
  constructor(message) {
    super(message);
    this.name = "InvalidSubcomponentError";
  }
}

/**
 * Factory method to create a subcomponent for the multiple choice question.
 * @param {string} category
 * @param {string} type
 * @param {Object} props
 */
export const createSubcomponent = (category, type, props) => {
  const subcategory = subcomponents[category];
  if (subcategory === undefined) {
    throw new InvalidSubcomponentError(`"${category}" is not a valid category`);
  }

  const Component = subcategory[type];
  if (Component === undefined) {
    throw new InvalidSubcomponentError(
      `"${type}" is not a valid ${category} type`
    );
  }

  return <Component {...props} />;
};
