import React, { useContext, useEffect, useState } from "react";
import noop from "lodash/noop";
import styles from "./LottieGuide.module.scss";
import AudioContext from "./../../util/Audio/context";
import wait from "../../util/wait";
import Lottie from "lottie-react-web";
import long2 from "./lottie/long_2.json";

export const GuideType = {
  CONSTANT_TALKING: { animation: long2, frames: [120, 150] },
};

export default ({
  characterData = GuideType.CONSTANT_TALKING,
  audio = [],
  onComplete = noop,
}) => {
  const audioService = useContext(AudioContext);

  const [animationClass, setAnimationClass] = useState("zoomIn");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: characterData.animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const playAudio = async () => {
      console.log("AUDIO", audio);
      await audioService.composeAndPlayAudio(audio);
      setAnimationClass("zoomOut");
      await wait(300);
      onComplete();
    };

    playAudio();
  }, []);

  return (
    <div className={`${styles.glenGuide} animated ${animationClass}`}>
      <Lottie options={defaultOptions} segments={characterData.frames} />
    </div>
  );
};
