import styles from "./TextAnswer.module.scss";
import React from "react";
import RoundedTextWithCheck from "../../../../components/RoundedTextWithCheck/RoundedTextWithCheck";
import AnswerStatus from "../../../../constants/answerStatus";
import noop from "lodash/noop";

/**
 * @component for displaying a card with text
 * @param {AnswerStatus} props.answerStatus is the status of the card
 * @param {string} props.text               is the text displayed
 * @param {function} props.onSelect         is the callback for clicking on the answer card
 * @return {JSX.Element}                    is a div containing the text card
 */
export default ({ answerStatus, text, onSelect = noop, ...props }) => {
  const clickedCorrect = answerStatus === AnswerStatus.CORRECT;
  const clickedIncorrect = answerStatus === AnswerStatus.INCORRECT;
  const showReviewCircle = answerStatus === AnswerStatus.REVIEW_CORRECT;

  return (
    <div key={text} className={styles.option}>
      <RoundedTextWithCheck
        onClick={onSelect}
        withCircle={showReviewCircle}
        isCorrect={clickedCorrect}
        isIncorrect={clickedIncorrect}
        {...props}
      >
        {text}
      </RoundedTextWithCheck>
    </div>
  );
};
