import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import React, { useEffect, useMemo, useState } from "react";
import LessonA2Manager from "./manager";
import GlenWriteWithAnalytics from "../../../../games/GlenWrite/GlenWriteWithAnalytics";
import wait from "../../../../util/wait";

const RepeatedGlenWriteGame = ({ manager, updateProgress, onComplete }) => {
  const [gameState, setGameState] = useState(null);

  const initNewGame = (trialNum) => {
    const currentWord = manager.getNextByExposureCount();
    setGameState({ trialNum, currentWord });
    manager.exposeWord(currentWord);
  };

  useEffect(() => initNewGame(1), []);

  const onRoundComplete = () => {
    updateProgress().then(() => {
      if (manager.getCurrentProgress() >= 1) {
        onComplete();
      } else {
        initNewGame(gameState.trialNum + 1);
      }
    });
  };

  if (!gameState) {
    return null;
  }

  return (
    <GlenWriteWithAnalytics
      key={gameState.trialNum}
      word={gameState.currentWord}
      onComplete={onRoundComplete}
    />
  );
};

const useGameContent = (parameters, onComplete) => {
  const manager = useMemo(() => new LessonA2Manager(parameters), [parameters]);
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => {
    setCurrentProgress(manager.getCurrentProgress());
    return wait(200);
  };
  const GameComponent = (
    <RepeatedGlenWriteGame
      manager={manager}
      updateProgress={updateProgress}
      onComplete={onComplete}
    />
  );
  return [GameComponent, currentProgress];
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
