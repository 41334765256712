import React from "react";
import SoundClick1 from "../SoundClick1/SoundClick1";

export default ({
  targetSound,
  correctLetter,
  confusionLetter,
  onStart,
  onComplete,
}) => {
  return (
    <SoundClick1
      targetSound={targetSound}
      correctWord={correctLetter}
      confusionWords={[confusionLetter]}
      onStart={onStart}
      onComplete={onComplete}
    />
  );
};
