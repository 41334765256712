import React, { useCallback, useEffect, useMemo, useState } from "react";
import VocabAssessManager from "./manager";
import wait from "../../../../util/wait";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ScoreText,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import WordClickWithAnalytics from "../../../../games/WordClick/WordClickWithAnalytics";
import ImageClickWithAnalytics from "../../../../games/ImageClick/ImageClickWithAnalytics";
import { useGlenLearnProfile } from "../../../../util/Profile/ProfileUtil";
import AssessComplete from "../AssessComplete/AssessComplete";

const RepeatedImageClickGame = ({
  textIndicator,
  manager,
  updateVocabAssessmentRecord,
  updateProgress,
  onComplete,
}) => {
  const [gameState, setGameState] = useState(null);

  const initNewGame = useCallback(
    (trialNum) => {
      const { targetWord, confusionWords } = manager.getNext();
      setGameState({ trialNum, targetWord, confusionWords });
    },
    [manager]
  );

  const onRoundComplete = useCallback(
    (wasCorrect) => {
      updateVocabAssessmentRecord(
        gameState.targetWord,
        textIndicator,
        wasCorrect
      );
      if (wasCorrect) {
        manager.addSuccess();
      }
      updateProgress().then(() => {
        if (manager.hasNext()) {
          initNewGame(gameState.trialNum + 1);
        } else {
          onComplete();
        }
      });
    },
    [updateProgress, manager, gameState, initNewGame, onComplete]
  );
  useEffect(() => initNewGame(1), [initNewGame]);
  if (!gameState) {
    return null;
  }
  return (
    <ImageClickWithAnalytics
      key={gameState.trialNum}
      shouldShowReview={false}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      onComplete={onRoundComplete}
    />
  );
};

const RepeatedWordClickGame = ({
  textIndicator,
  manager,
  updateProgress,
  onComplete,
}) => {
  const glenLearnProfile = useGlenLearnProfile();
  const [gameState, setGameState] = useState(null);

  const initNewGame = useCallback(
    (trialNum) => {
      const { targetWord, confusionWords } = manager.getNext();
      setGameState({ trialNum, targetWord, confusionWords });
    },
    [manager]
  );

  const onRoundComplete = useCallback(
    (wasCorrect) => {
      glenLearnProfile.updateVocabAssessmentRecord(
        gameState.targetWord,
        textIndicator,
        wasCorrect
      );
      if (wasCorrect) {
        manager.addSuccess();
      }
      updateProgress().then(() => {
        if (manager.hasNext()) {
          initNewGame(gameState.trialNum + 1);
        } else {
          onComplete();
        }
      });
    },
    [updateProgress, manager, gameState, initNewGame, onComplete]
  );
  useEffect(() => initNewGame(1), [initNewGame]);
  if (!gameState) {
    return null;
  }
  return (
    <WordClickWithAnalytics
      key={gameState.trialNum}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      shouldShowReview={false}
      onComplete={onRoundComplete}
    />
  );
};

export default ({
  concepts,
  groups,
  textIndicator,
  updateVocabAssessmentRecord,
  getVocabAssessmentRecord,
  onStart,
  onComplete,
}) => {
  useEffect(onStart, []);
  const manager = useMemo(
    () =>
      new VocabAssessManager(
        concepts,
        groups,
        getVocabAssessmentRecord,
        textIndicator
      ),
    []
  );
  const [currentScore, setCurrentScore] = useState(0);
  const [isAssessComplete, setIsAssessComplete] = useState(false);
  const updateProgress = () => {
    setCurrentScore(manager.getCurrentScore());
    return wait(200);
  };
  const onAssessComplete = () => setIsAssessComplete(true);

  let GameComponent;
  if (isAssessComplete) {
    GameComponent = (
      <AssessComplete
        score={currentScore}
        outOf={manager.getOutOf()}
        onFinished={() => onComplete(currentScore)}
      />
    );
  } else {
    if (textIndicator) {
      GameComponent = (
        <RepeatedWordClickGame
          textIndicator={textIndicator}
          manager={manager}
          updateProgress={updateProgress}
          onComplete={onAssessComplete}
        />
      );
    } else {
      GameComponent = (
        <RepeatedImageClickGame
          textIndicator={textIndicator}
          manager={manager}
          updateVocabAssessmentRecord={updateVocabAssessmentRecord}
          updateProgress={updateProgress}
          onComplete={onAssessComplete}
        />
      );
    }
  }

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar
        left={
          !isAssessComplete && (
            <div className="w-50">
              <ScoreText score={currentScore} outOf={manager.getOutOf()} />
            </div>
          )
        }
      />
    </GameWrapper>
  );
};
