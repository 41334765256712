import React, { useState } from "react";
import PinInput from "react-pin-input";

export default ({ password, children }) => {
  const [isLocked, setLocked] = useState(true);
  if (isLocked) {
    return (
      <div style={{ width: "100%", textAlign: "center", color: "#fff" }}>
        <h5 className={"mt-4"}>
          Please enter your passcode (numbers one, two, three, four)
        </h5>
        <PinInput
          length={4}
          initialValue=""
          secret
          onChange={(value, index) => {}}
          type="numeric"
          style={{ padding: "20px" }}
          inputStyle={{
            borderColor: "white",
            borderRadius: "50%",
            border: "2px solid",
            backgroundColor: "white",
          }}
          inputFocusStyle={{ borderColor: "blue" }}
          onComplete={(value, index) => {
            if (value === password) {
              setLocked(!isLocked);
            }
          }}
        />
      </div>
    );
  } else {
    return children;
  }
};
