import noop from "lodash/noop";
import { activity } from "../../util/Analytics/events";
import { GlenMatch, GlenMatchWithFlashcards } from "./GlenMatch";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";
import { useCallback, useEffect } from "react";

const eventData = { activity: activity.GLEN_MATCH };

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();

  useEffect(() => {
    if (props.words) {
      eventData.words = props.words;
    }
  }, [props.words]);

  const onCompleteOverride = useCallback(() => {
    completeEvent(eventData);
    onComplete();
  }, [completeEvent, onComplete]);

  const onStartOverride = useCallback(() => {
    startEvent(eventData);
    onStart();
  }, [onStart, startEvent]);

  // two variants of GLEN Match - one accepts flashcards, the other accepts
  // arbitrary components
  const Component = props.words ? GlenMatchWithFlashcards : GlenMatch;
  return (
    <Component
      {...props}
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
    />
  );
};
