import React from "react";
import A2 from "./A2";
import { MODE } from "./util";

export default ({ onComplete = () => {}, findLetter, withIntro = true }) => {
  return (
    <A2
      mode={MODE.upper}
      withIntro={withIntro}
      onComplete={onComplete}
      findLetter={findLetter}
    />
  );
};
