import React, { useEffect, useMemo } from "react";
import PhonicStrengthen1Manager from "../PhonicStrengthen1/manager";
import LessonC1 from "../../../LessonTypes/LessonC1/LessonC1";

// phonic strengthen 2 uses phonic strengthen 1.
export default ({
  consonantWordMap,
  recentlyFailedSounds,
  onStart,
  onComplete,
}) => {
  const manager = useMemo(
    () => new PhonicStrengthen1Manager(consonantWordMap, recentlyFailedSounds),
    []
  );
  useEffect(onStart, []);
  return (
    <LessonC1
      parameters={manager.lessonCParameterMap}
      onComplete={onComplete}
    />
  );
};
