import React, {useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GameWrapper,
  BottomBar,
  GameContainer,
  AuthedTopBar,
  MaterialButtonWithText,
  BottomButton,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import useFirebase from "../../../util/Firebase/useFirebase";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { doLogoutAuthUser } from "../../../actions/session";
import {
  selectProfileUser,
  signOutProfileUser,
} from "../../../actions/profile";
import styles from "./ProfileList.module.scss";
import { AvatarWithText } from "../../../components/Avatar/Avatar";
import { useProfilesForDeployment } from "../../../util/Profile/useProfile";
import {Modal, ModalBody} from "reactstrap";

const NewUserButton = ({ onClick }) => (
  <div
    onClick={onClick}
    className={`${styles.newUserButtonWrapper} animated pulse infinite`}
  >
    <i className={`material-icons md-75 ${styles.addButtonContainer}`}>add</i>
    <div className={styles.addText}>Add</div>
  </div>
);

export const List = ({ deploymentId, editEnabled }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const history = useHistory();

  const onAvatarClick = (user) => {
    if (editEnabled) {
      history.push(`/deployment/${deploymentId}/profiles/edit/${user.id}`);
    } else {
      // log the user in
      dispatch(selectProfileUser(user));
      history.push(`/deployment/${deploymentId}/glenLearn`);
    }
  };

  const [value, loading, error] = useProfilesForDeployment(deploymentId);

  const onClickNewUserButton = () =>
    history.push(`/deployment/${deploymentId}/profiles/add`);
  const onDelete = (id) => firebase.deleteDeploymentAccount(id);

  if (error) {
    return <strong>Error: {JSON.stringify(error)}</strong>;
  }

  return (
    <>
      <div className="text-center" style={{ marginBottom: "2vh" }}>
        {loading && <ClipLoader size={75} />}
        {value && (
          <>
            {value.docs.length === 0 && (
              <h2 className={"mb-4"}>
                Get started by adding a user profile below
              </h2>
            )}
            <div className="mt-5 ml-5 mr-5">
              <div className="row justify-content-center">
                {value.docs
                  .map((doc) => {
                    return { id: doc.id, ...doc.data() };
                  })
                  .filter((profile) => profile.deleted !== true)
                  .map((profile) => {
                    return (
                      <div key={profile.id} className="col-3 mb-3">
                        <AvatarWithText
                          deleteEnabled={editEnabled}
                          onDeleteClick={() => onDelete(profile.id)}
                          text={profile.username}
                          avatarId={profile.avatarId}
                          onClick={() => onAvatarClick(profile)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
        <div className={styles.newButtonContainer}>
          <NewUserButton onClick={onClickNewUserButton} />
        </div>
      </div>
    </>
  );
};

export default () => {
  const { deploymentId } = useParams();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const history = useHistory();
  const { editEnabled = false } = history.location.state || {};

  const [logOutModalOpen, setLogOutModalOpen] = useState(false);

  // when delete is enabled, the user is already authed as a parent/teacher.
  const gotoParentsTeachers = () =>
    history.push({
      state: { skipAuth: editEnabled },
      pathname: `/deployment/${deploymentId}/adminDashboard/list`,
    });

  const confirmSignOut = () => {
    firebase.doSignOut();
    // this dispatch will cause the user to be redirected (see AuthAndProfileRoute)
    dispatch(signOutProfileUser());
    dispatch(doLogoutAuthUser());
  };

  const onSignOut = () => {
    setLogOutModalOpen(true);
  }

  const onSignOutCancel = () => {
    setLogOutModalOpen(false);
  }



  const LeftIcon = () => (
    <MaterialButtonWithText
      size={75}
      text={"Parents/Teachers"}
      icon={"assessment"}
      onClick={gotoParentsTeachers}
    />
  );
  return (
    <GameWrapper>
      <Modal isOpen={logOutModalOpen}>
        <ModalBody>
        <p>Are you sure you want to sign out?</p>
        <button className="btn btn-outline-info" onClick={onSignOutCancel}>Cancel</button>&nbsp;
        <button className="btn btn-danger" onClick={confirmSignOut}>Sign out</button>
        </ModalBody>
      </Modal>

      <AuthedTopBar left={<LeftIcon />} />
      <GameContainer>
        <List deploymentId={deploymentId} editEnabled={editEnabled} />
      </GameContainer>
      <BottomBar
        left={
          <BottomButton
            src={
              !editEnabled
                ? `${process.env.PUBLIC_URL}/images/logout.png`
                : `${process.env.PUBLIC_URL}/images/back.png`
            }
            onClick={!editEnabled ? onSignOut : gotoParentsTeachers}
          />
        }
      />
    </GameWrapper>
  );
};
