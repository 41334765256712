import React, { useContext, useEffect, useMemo, useState } from "react";
import shuffle from "lodash/shuffle";
import sample from "lodash/sample";
import { useResourceFromRegistry } from "../../util/Resource/useResource";
import AudioContext from "../../util/Audio/context";
import styles from "./ImageClick.module.scss";
import { ClipLoader } from "react-spinners";
import wait from "../../util/wait";
import Card from "../../components/Card/Card";
import WordReview from "../../components/WordReview/WordReview";
import RoundedText from "../../components/RoundedText/RoundedText";
import DisableOverlay from "../../components/DisableOverlay/DisableOverlay";
import classnames from "classnames";

const ImageClickGame = ({
  correctWord,
  cards,
  shouldShowReview,
  onComplete,
}) => {
  const [clickedWord, setClickedWord] = useState(null);
  const audioService = useContext(AudioContext);
  const playAudio = () => audioService.playAudio(correctWord.audio);

  const onClickOption = async (key) => {
    setClickedWord(key);
    if (key === correctWord.key) {
      audioService.composeAndPlaySingleAudio("soundfx", "correct");
      //await audioService.playFX(getRandomEncouragementFX());
      await wait(1700);
      onComplete(true);
    } else {
      audioService.composeAndPlaySingleAudio("soundfx", "incorrect");
      await wait(1800);
      onComplete(false);
    }
  };

  const renderCards = (cards) => {
    return cards.map((card) => {
      const clickedCorrect =
        clickedWord === card.key && card.key === correctWord.key;
      const clickedIncorrect =
        clickedWord === card.key && card.key !== correctWord.key;
      return (
        <div key={card.key} className={styles.imageClickOption}>
          <Card
            outline={classnames({
              green: clickedCorrect,
              red: clickedIncorrect,
            })}
            animationClass={classnames({
              bounce: clickedCorrect,
              shake: clickedIncorrect,
            })}
            withCheck={clickedCorrect}
            withCross={clickedIncorrect}
            withCircle={
              shouldShowReview &&
              clickedWord !== null &&
              clickedWord !== card.key &&
              card.key === correctWord.key
            }
            image={card.image}
            onClick={() => onClickOption(card.key)}
          />
        </div>
      );
    });
  };

  return (
    <DisableOverlay enable={clickedWord !== null}>
      <div className={`${styles.cardContainer} row`}>
        <div className="col-5">{renderCards(cards.slice(2, 4).reverse())}</div>
        <div className="col-2">
          <div className={styles.middle}>
            <RoundedText onClick={playAudio} bg="white" size="md" color="black">
              {correctWord.meaning}
            </RoundedText>
          </div>
        </div>
        <div className="col-5">{renderCards(cards.slice(0, 2).reverse())}</div>
      </div>
    </DisableOverlay>
  );
};

export default ({
  targetWord,
  targetOverride = null,
  confusionWords,
  shouldShowReview = true,
  onStart,
  onComplete,
}) => {
  const audioService = useContext(AudioContext);
  const flashcardResource = useResourceFromRegistry("flashcards");
  const [cards, setCards] = useState([]);
  const [wasCorrectAnswer, setWasCorrectAnswer] = useState(null);
  const flashcard = useMemo(
    () => ({
      ...flashcardResource.getFlashcard(targetWord),
      ...targetOverride,
      // See targetOverride usage in WordClick.js
    }),
    [flashcardResource, targetWord, targetOverride]
  );
  const [showReview, setShowReview] = useState(false);

  // the review flashcard should not be the same image as the original flashcard.
  const reviewFlashcard = useMemo(() => {
    return {
      ...flashcard,
      // not all flashcards hve more than one image. in those cases, use the original flashcard.
      image:
        sample(flashcard.imageOptions.filter((i) => i !== flashcard.image)) ||
        flashcard.image,
    };
  }, [flashcard]);

  const onGameFinished = async (wasCorrect) => {
    setWasCorrectAnswer(wasCorrect);
    if (shouldShowReview && !wasCorrect) {
      await audioService.playAudio(flashcard.audio);
      await wait(1500);
    }
    if (shouldShowReview) {
      setShowReview(true);
    } else {
      onComplete(wasCorrect);
    }
  };

  useEffect(() => {
    onStart();
    setCards(
      shuffle([
        flashcard,
        ...confusionWords.map((word) => flashcardResource.getFlashcard(word)),
      ])
    );
    audioService.playAudio(flashcard.audio);
  }, []);

  if (cards.length === 0) {
    return <ClipLoader size={75} />;
  }

  return (
    <>
      {!showReview && (
        <ImageClickGame
          correctWord={flashcard}
          cards={cards}
          shouldShowReview={shouldShowReview}
          onComplete={onGameFinished}
        />
      )}
      {showReview && (
        <WordReview
          flashcard={reviewFlashcard}
          onComplete={() => onComplete(wasCorrectAnswer)}
        />
      )}
    </>
  );
};
