import shuffle from "lodash/shuffle";
import sampleSize from "lodash/sampleSize";

export default class ShapesStrengthenManager {
  constructor(concepts) {
    this.targetSuccessCount = 1;
    this.concepts = concepts;
    this.currentWord = null;
    this.successMap = {};
    this.concepts.forEach((concept) => {
      this.successMap[concept] = 0;
    });
  }

  onRoundComplete(targetWord, wasCorrect) {
    if (wasCorrect) {
      this.successMap[targetWord]++;
    }
  }

  getNext() {
    const targetWordOptions = shuffle(this.concepts).sort(
      (a, b) => this.successMap[a] - this.successMap[b]
    );
    const targetWord = targetWordOptions[0];
    this.currentWord = targetWord;

    return {
      targetWord,
      confusionWords: sampleSize(
        this.concepts.filter((c) => c !== targetWord),
        3
      ),
    };
  }

  hasNext() {
    return this.getSuccessCriterion() < 1;
  }

  getSuccessCriterion() {
    //success_criterion = {number of concepts with success_count  >= target_success_count}/{number_of_concepts}
    return (
      this.concepts.filter((c) => this.successMap[c] >= this.targetSuccessCount)
        .length / this.concepts.length
    );
  }

  getCurrentProgress() {
    return this.getSuccessCriterion();
  }
}
