import sampleSize from "lodash/sampleSize";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import LessonA1Manager from "./manager";
import GlenMatchWithAnalytics from "../../../../games/GlenMatch/GlenMatchWithAnalytics";
import WordClickWithAnalytics from "../../../../games/WordClick/WordClickWithAnalytics";
import { useComponentRender } from "../../util/hooks";
import wait from "../../../../util/wait";

const GlenMatchGame = ({ manager, updateProgress, onComplete }) => {
  const onMatch = (word) => {
    manager.addExposureForWord(word);
    updateProgress();
  };
  const words = sampleSize(manager.getWordsOrderedByExposure(), 4);
  return (
    <GlenMatchWithAnalytics
      isImageTextMatching={true}
      words={words}
      onMatch={onMatch}
      onComplete={onComplete}
    />
  );
};

const RepeatedWordClickGame = ({ manager, updateProgress, onComplete }) => {
  const [gameState, setGameState] = useState(null);

  const wordBlacklist = useRef([]);

  const wordSelectionFunc = useCallback(() => {
    let exposedWords = manager.getExposedWords();

    let targetWords = exposedWords.sort((a, b) => {
      return manager.getSuccessCount(a) - manager.getSuccessCount(b);
    });

    // choose first non-blacklisted, or if they're all blacklisted, the least successful
    let targetWord =
      targetWords.find((w) => !wordBlacklist.current.includes(w)) ||
      targetWords[0];

    let confusionWords = sampleSize(
      exposedWords.filter((e) => e !== targetWord),
      3
    );
    return [targetWord, confusionWords];
  }, [manager]);

  const shouldRepeatFunc = useCallback(
    (nextTrial) => {
      let exposedConcepts = manager.getExposedWords();

      let shouldRepeat = manager.getCurrentProgress() < 1;

      // when glen match is enabled, we need a stopping point to continue to GLEN match.
      // otherwise, we continue word click until completion
      if (manager.glenMatchEnabled) {
        shouldRepeat =
          shouldRepeat && nextTrial <= Math.min(exposedConcepts.length, 4);
      }
      return shouldRepeat;
    },
    [manager]
  );

  const initNewGame = useCallback(
    (trialNum) => {
      const [targetWord, confusionWords] = wordSelectionFunc();
      setGameState({ trialNum, targetWord, confusionWords });
      wordBlacklist.current.push(targetWord);
    },
    [wordSelectionFunc]
  );

  const onRoundComplete = useCallback(
    (wasCorrect) => {
      if (wasCorrect) {
        manager.addSuccessForWord(gameState.targetWord);
      }
      updateProgress().then(() => {
        if (shouldRepeatFunc(gameState.trialNum + 1)) {
          initNewGame(gameState.trialNum + 1);
        } else {
          onComplete();
        }
      });
    },
    [
      gameState,
      initNewGame,
      manager,
      onComplete,
      shouldRepeatFunc,
      updateProgress,
    ]
  );

  useEffect(() => initNewGame(1), [initNewGame]);

  if (!gameState) {
    return null;
  }

  return (
    <WordClickWithAnalytics
      key={gameState.trialNum}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      onComplete={onRoundComplete}
    />
  );
};

const useGameContent = (parameters, onComplete) => {
  const manager = useMemo(() => new LessonA1Manager(parameters), [parameters]);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = useCallback(() => {
    setCurrentProgress(manager.getCurrentProgress());
    return wait(200);
  }, [manager]);
  const incrementStep = useCallback(() => setCurrentStep(currentStep + 1), [
    currentStep,
  ]);

  const stepToComponent = useMemo(() => {
    let steps = [];
    if (manager.glenMatchEnabled) {
      steps.push(
        <GlenMatchGame
          manager={manager}
          updateProgress={updateProgress}
          onComplete={incrementStep}
        />
      );
    }
    steps.push(
      <RepeatedWordClickGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={incrementStep}
      />
    );
    steps.push(() => {
      if (currentProgress >= 1) {
        onComplete();
      } else if (manager.getExposureCriterion() < 1) {
        setCurrentStep(0);
      } else {
        setCurrentStep(1);
      }
      return <div />;
    });
    return steps;
  }, [currentProgress, incrementStep, manager, onComplete, updateProgress]);

  const toRender = useComponentRender(stepToComponent, currentStep);
  return [toRender, currentProgress];
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
