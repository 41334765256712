export const LESSON_TYPE = {
  A: "A",
  A1: "A1",
  A2: "A2",
  A3: "A3",
  B: "B",
  C: "C",
  C1: "C1",
  C2: "C2",
  C3: "C3",
  S: "S",
  N0: "N0",
  DYNAMIC: "DYNAMIC",
};

export const LESSON_A_GROUP = [
  LESSON_TYPE.A,
  LESSON_TYPE.A1,
  LESSON_TYPE.A2,
  LESSON_TYPE.A3,
];
