import sampleSize from "lodash/sampleSize";
import React, { useEffect, useMemo, useRef, useState } from "react";
import LessonAManager from "./manager";
import { useComponentRender } from "../../util/hooks";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import wait from "../../../../util/wait";
import GlenMatchWithAnalytics from "../../../../games/GlenMatch/GlenMatchWithAnalytics";
import ImageClickWithAnalytics from "../../../../games/ImageClick/ImageClickWithAnalytics";

const RepeatedImageClickGame = ({ manager, onComplete, updateProgress }) => {
  const [gameState, setGameState] = useState(null);
  const wordBlacklist = useRef([]);

  const shouldRepeatFunc = (numTrials) => {
    const exposedConcepts = manager.getExposedWords();
    return (
      numTrials < Math.min(exposedConcepts.length, 4) &&
      manager.getCurrentProgress() < 1
    );
  };

  const wordSelectionFunc = () => {
    let words = manager
      .getWordsOrderedBySuccess()
      .filter((w) => !wordBlacklist.current.includes(w));
    console.log("WORDS", words);

    //if too many words were removed, add blacklisted words back to max size of 4.
    if (words.length < 4) {
      let i = 0;
      while (words.length < 4) {
        words.push(wordBlacklist.current[i++]);
      }
    }
    console.log("NEW WORDS", words);
    console.log("blackslit", wordBlacklist.current);
    console.log("blacklisting", words[0]);

    wordBlacklist.current.push(words[0]);
    return [words[0], words.slice(1, 4)];
  };

  const onRoundComplete = (wasCorrect) => {
    manager.addExposureForWord(gameState.targetWord);
    if (wasCorrect) {
      manager.addSuccessForWord(gameState.targetWord);
      manager.incrementAssessmentCount();
    }
    updateProgress().then(() => {
      if (shouldRepeatFunc(gameState.trialNum + 1)) {
        initNewGame(gameState.trialNum + 1);
      } else {
        onComplete();
      }
    });
  };

  const initNewGame = (trialNum) => {
    const [targetWord, confusionWords] = wordSelectionFunc();
    setGameState({ trialNum, targetWord, confusionWords });
  };

  useEffect(() => initNewGame(1), []);

  if (!gameState) {
    return null;
  }

  // updating the key will re-mount the component.
  // see: https://medium.com/@albertogasparin/forcing-state-reset-on-a-react-component-by-using-the-key-prop-14b36cd7448e
  return (
    <ImageClickWithAnalytics
      key={gameState.trialNum}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      onComplete={(wasCorrect) => onRoundComplete(wasCorrect)}
    />
  );
};

const GlenMatchGame = ({ manager, updateProgress, onComplete }) => {
  const onMatch = (word) => {
    manager.addExposureForWord(word);
    updateProgress();
  };
  return (
    <GlenMatchWithAnalytics
      words={sampleSize(manager.words, 4)}
      onMatch={onMatch}
      onComplete={onComplete}
    />
  );
};

const useGameContent = (parameters, onComplete) => {
  const manager = useMemo(() => new LessonAManager(parameters), [parameters]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => {
    setCurrentProgress(manager.getCurrentProgress());
    return wait(200);
  };
  const incrementStep = () => setCurrentStep(currentStep + 1);
  const stepToComponent = {
    1: (
      <GlenMatchGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={incrementStep}
      />
    ),
    2: (
      <RepeatedImageClickGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={incrementStep}
      />
    ),
    3: () => {
      if (currentProgress >= 1) {
        onComplete();
      } else if (manager.getExposureCriterion() < 1) {
        setCurrentStep(1);
      } else {
        setCurrentStep(2);
      }
      return null;
    },
  };
  const toRender = useComponentRender(stepToComponent, currentStep);
  return [toRender, currentProgress];
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
