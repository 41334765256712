import React, { useContext, useEffect, useState } from "react";
import styles from "./DescribeIt.module.scss";
import Card from "../../components/Card/Card";
import sample from "lodash/sample";
import RoundedText from "../../components/RoundedText/RoundedText";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import Backend, { Preview } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import shuffle from "lodash/shuffle";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";
import { useResourceFromRegistry } from "../../util/Resource/useResource";

const DragBox = ({ name, onClick, onDrag, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { name, type: "word" },
    begin: onDrag,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item.name);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  return (
    <div ref={drag} className="mb-2" style={{ opacity }} onClick={onClick}>
      <RoundedText color="black" size="xl">
        {name}
      </RoundedText>
    </div>
  );
};

const DropBox = ({ showRedHighlight = false, showGreenHighlight = false }) => {
  // eslint-disable-next-line
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "word",
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <div ref={drop}>
      <RoundedText
        withRedHighlightAnimation={showRedHighlight}
        withGreenHighlightAnimation={showGreenHighlight}
        color="black"
      >
        ?
      </RoundedText>
    </div>
  );
};

export default ({ sentence, onStart, onComplete }) => {
  const audioService = useContext(AudioContext);
  const flashcardResource = useResourceFromRegistry("flashcards");

  const [dropLocation, setDropLocation] = useState("left");
  const [showDrop, setShowDrop] = useState(true);
  const [greenHighlightDrop, setGreenHighlightDrop] = useState(false);
  const [redHighlightDrop, setRedHighlightDrop] = useState(false);
  const [segment, setSegment] = useState(0);
  const [options, setOptions] = useState(null);
  const [currentSentence, setCurrentSentence] = useState("");

  const onDrop = async (option) => {
    const currentSentenceSegment = sentence.structure[segment];
    if (option === currentSentenceSegment.answer) {
      setGreenHighlightDrop(true);
      await wait(90);
      setGreenHighlightDrop(false);
      setShowDrop(false);
      setCurrentSentence(currentSentenceSegment.output);
      audioService.composeAndPlaySingleAudio("soundfx", "correct");
      await wait(800);
      if (currentSentenceSegment.audio) {
        await audioService.playAudio(currentSentenceSegment.audio);
      }
      setSegment(segment + 1);
    } else {
      audioService.composeAndPlaySingleAudio("soundfx", "incorrect");
      setRedHighlightDrop(true);
      await wait(1000);
      setRedHighlightDrop(false);
    }
  };

  const onClick = (option) => {
    audioService.playAudio(flashcardResource.getFlashcard(option).audio);
  };

  const onDrag = (option) => {
    audioService.playAudio(flashcardResource.getFlashcard(option).audio);
  };

  useEffect(() => {
    onStart();
  }, []);

  useEffect(() => {
    if (segment === sentence.structure.length) {
      audioService
        .playAudio(sentence.audio)
        .then(() => wait(800))
        .then(onComplete);
    } else {
      const currentSentenceSegment = sentence.structure[segment];
      const split = currentSentenceSegment.sentence.split(" ");
      console.log("SPLIT", split);
      setDropLocation(split[0] === "%b" ? "left" : "right");
      setOptions(
        shuffle([
          currentSentenceSegment.answer,
          sample(currentSentenceSegment.options),
        ])
      );
      setShowDrop(true);
    }
  }, [segment]);

  if (!options) {
    return null;
  }

  const GeneratePreview = () => {
    const { style, item } = useContext(Preview.Context);
    return (
      <div style={{ ...style, width: "25vw", zIndex: 999 }}>
        <RoundedText size="xl" color="black">
          {item.name}
        </RoundedText>
      </div>
    );
  };

  return (
    <DndProvider backend={Backend} options={HTML5toTouch}>
      <Preview>
        <GeneratePreview />
      </Preview>
      <div className="row mt-4">
        <div className="offset-2 col-4">
          <Card size="lg" image={sentence.image} />
        </div>
        <div className="offset-1 col-4">
          {showDrop &&
            options.map((option) => (
              <DragBox
                key={option}
                name={option}
                onClick={() => onClick(option)}
                onDrag={() => onDrag(option)}
                onDrop={() => onDrop(option)}
              />
            ))}
        </div>
      </div>
      <div>
        <div className={`${styles.dropOuterBox}`}>
          <div className={styles.dropArea}>
            <div className={styles.dropInnerContainer}>
              {dropLocation === "right" && (
                <>
                  <div style={{ marginRight: "1vw" }}>{currentSentence}</div>
                  {showDrop && (
                    <div style={{ width: "17vw" }}>
                      <DropBox
                        showRedHighlight={redHighlightDrop}
                        showGreenHighlight={greenHighlightDrop}
                      />
                    </div>
                  )}
                </>
              )}
              {dropLocation === "left" && (
                <>
                  {showDrop && (
                    <div style={{ width: "17vw", marginRight: "1vw" }}>
                      <DropBox
                        showRedHighlight={redHighlightDrop}
                        showGreenHighlight={greenHighlightDrop}
                      />
                    </div>
                  )}
                  <div>{currentSentence}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};
