import React, { useEffect, useState } from "react";
import { useGlenLearnProfile } from "../../../util/Profile/ProfileUtil";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import LessonGroups from "../../../components/Lessons/LessonGroups/LessonGroups";
import { TYPES as types } from "../../../components/Lessons/util/groups";
import {
  BottomBar,
  BottomButton,
  BottomMultiple,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import Guide from "../../../components/Guide/Guide";
import { useHistory, useParams } from "react-router-dom";

const RightIcons = () => {
  const history = useHistory();
  const { deploymentId } = useParams();
  const gotoVocab = () => history.push(`/deployment/${deploymentId}/glenVocab`);
  const gotoPhonics = () =>
    history.push(`/deployment/${deploymentId}/glenPhonics`);
  const gotoBooks = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/glenBooks/categories`);
  const gotoRhymes = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/glenRhymes`);
  const gotoShapes = () =>
    history.push(`/deployment/${deploymentId}/glenShapes`);
  const gotoABC = () => history.push(`/deployment/${deploymentId}/glenABC`);
  return (
    <BottomMultiple>
      <BottomButton
        src={process.env.PUBLIC_URL + "/images/app_icons/GLENvocab.png"}
        onClick={gotoVocab}
      />
      <BottomButton
        src={process.env.PUBLIC_URL + "/images/app_icons/GLENshapes.png"}
        onClick={gotoShapes}
      />
      <BottomButton
        src={process.env.PUBLIC_URL + "/images/app_icons/GLENabc.png"}
        onClick={gotoABC}
      />
      <BottomButton
        src={process.env.PUBLIC_URL + "/images/app_icons/GLENsounds.png"}
        onClick={gotoPhonics}
      />
      <BottomButton
        src={process.env.PUBLIC_URL + "/images/app_icons/GLENrhyme.png"}
        onClick={gotoRhymes}
      />
      <BottomButton
        src={process.env.PUBLIC_URL + "/images/app_icons/GLENbooks.png"}
        onClick={gotoBooks}
      />
    </BottomMultiple>
  );
};

const BottomBarWithOptions = ({ showWelcomeGuide }) => {
  const history = useHistory();
  const { deploymentId } = useParams();
  const gotoSelectAssessment = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/selectAssessment`);
  const logOut = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/pinLogout`);

  return (
    <BottomBar
      left={
        <BottomMultiple justifyContentBetween={false}>
          <BottomButton
            src={process.env.PUBLIC_URL + "/images/logout.png"}
            onClick={logOut}
          />
          <BottomButton
            src={
              process.env.PUBLIC_URL + "/images/app_icons/GLENassessment.png"
            }
            onClick={gotoSelectAssessment}
            blink={showWelcomeGuide}
          />
        </BottomMultiple>
      }
      right={<RightIcons />}
    />
  );
};

const useWelcomeAnimation = (profile) => {
  const [showGuide, setShowGuide] = useState(false);
  useEffect(() => {
    if (!profile.getHasPlayedWelcomeAnimation()) {
      setShowGuide(true);
      profile.setHasPlayedWelcomeAnimation(true);
    }
  }, [profile]);
  return showGuide;
};

export default () => {
  const resource = useResourceFromRegistry("lessonGroups");
  const glenLearnProfile = useGlenLearnProfile();
  const showWelcomeGuide = useWelcomeAnimation(glenLearnProfile);

  return (
    <>
      {showWelcomeGuide && (
        <Guide audio={["glenda_happy_to_have_you", "lets_get_started"]} />
      )}
      <LessonGroups
        profile={glenLearnProfile}
        lessonGroupsResource={resource}
        type={types.GLEN_LEARN}
        bottomBar={<BottomBarWithOptions showWelcomeGuide={showWelcomeGuide} />}
      />
    </>
  );
};
