import React, { useContext, useEffect, useState } from "react";
import { useResourceFromRegistry } from "../../util/Resource/useResource";
import RoundedText from "../../components/RoundedText/RoundedText";
import Card from "../../components/Card/Card";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";

export default ({ verb, words, onComplete }) => {
  const flashcardResource = useResourceFromRegistry("flashcards");
  const describeItResource = useResourceFromRegistry("describeItResource");
  const audioService = useContext(AudioContext);
  const [activeWordIndex, setActiveWordIndex] = useState(null);

  const wordsWithIndex = words.map((word, index) => {
    return { word, index };
  });

  useEffect(() => {
    const animation = async (index) => {
      if (index === words.length) {
        onComplete();
        return;
      }
      setActiveWordIndex(index);
      const sentence = describeItResource.getSentence(words[index]);
      await audioService.playAudio(sentence.audio);
      await wait(1000);
      animation(index + 1);
    };
    animation(0);
  }, []);

  const renderCards = (wordsWithIndex) => {
    return wordsWithIndex.map((w) => {
      const sentence = describeItResource.getSentence(w.word);
      const isActiveHighlight = activeWordIndex === w.index;
      return (
        <div key={w.word} style={{ marginBottom: "1vh" }}>
          <Card
            image={sentence.image}
            textSize="small"
            text={sentence.structure[sentence.structure.length - 1].output}
            textColor={isActiveHighlight ? "green" : null}
            textBold={isActiveHighlight}
            outline={isActiveHighlight ? "green" : null}
          />
        </div>
      );
    });
  };

  return (
    <div className="row p-3">
      <div className="col-4">{renderCards(wordsWithIndex.slice(0, 2))}</div>
      <div className="col-4">
        <div style={{ margin: "0 auto", marginTop: "50%", width: "60%" }}>
          <RoundedText color="white">
            {flashcardResource.getFlashcard(verb).meaning}
          </RoundedText>
        </div>
      </div>
      <div className="col-4">{renderCards(wordsWithIndex.slice(2, 4))}</div>
    </div>
  );
};
