import React, { useCallback, useMemo, useState } from "react";
import styles from "./GroupCard.module.scss";
import classnames from "classnames";
import noop from "lodash/noop";
import { ReactComponent as CheckIcon } from "./../Svg/Check.svg";
import { ReactComponent as PlayIcon } from "./../Svg/Play.svg";
import LottieGuide from "../Guide/LottieGuide";

export default ({
  group,
  isActive,
  isPlayable,
  isCompleted,
  onClick,
  onGuideStart = noop,
  onGuideStop = noop,
  withSpiral = true,
  withRangeText = true,
  isLarge = false,
  shouldSkipIntro = false,
}) => {
  const [lottieGuideActive, setLottieGuideActive] = useState(false);

  const onClickCallback = useCallback(() => {
    if (!isActive && !isCompleted) {
      return;
    }
    if (group.introAudio && !shouldSkipIntro) {
      onGuideStart();
      setLottieGuideActive(true);
    } else {
      onClick();
    }
  }, [group]);

  const onGuideFinished = useCallback(() => {
    onGuideStop();
    onClick();
  }, [onClick]);

  const lottieAudio = useMemo(
    () => [
      {
        directory: "narrator",
        files: group.introAudio,
      },
    ],
    [group]
  );

  return (
    <div
      className={classnames({
        [styles.active]: isActive,
        [styles.inactive]: !isActive,
        "animated pulse": lottieGuideActive,
      })}
      onClick={onClickCallback}
    >
      <div
        className={classnames(styles.cardGroup, {
          [styles.isLarge]: isLarge,
        })}
      >
        {lottieGuideActive && (
          <LottieGuide audio={lottieAudio} onComplete={onGuideFinished} />
        )}
        {isCompleted && <CheckIcon className={styles.completed} />}
        {isPlayable && <PlayIcon className={styles.play} />}
        {withSpiral && (
          <img
            className={styles.spiral}
            src={process.env.PUBLIC_URL + "/images/spiral.png"}
          />
        )}
        {group && group.words && (
          <div className={styles.wordGroup}>
            <div className="row ml-0 mr-0 h-100">
              {group.words.map((word) => (
                <div
                  key={word}
                  className={`col-6 pl-0 pr-0 ${styles.cardGroupCol}`}
                >
                  {!group.firstLetterBold && <div>{word}</div>}
                  {group.firstLetterBold && (
                    <div>
                      <strong>{word[0]}</strong>
                      {word.substr(1)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {group && group.images && (
          <div className={styles.imgGroup}>
            <div className="d-flex flex-row flex-wrap justify-content-center h-100">
              {group.images.map((image) => (
                <div key={image} className={`${styles.cardGroupCol}`}>
                  <img className={styles.groupImage} src={image} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {withRangeText && (
        <div className={styles.cardTitle}>
          <div
            className={classnames(styles.cardTitleText, {
              [styles.inactive]: !isActive,
            })}
          >
            {group && (
              <div>
                {group.start}-{group.end}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
