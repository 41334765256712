import { useHistory, useParams } from "react-router-dom";

import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BottomButton,
  BottomMultiple,
  GameContainer,
  GameWrapper,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import React, { useCallback, useMemo, useState } from "react";
import Shelf from "../../../components/Shelf/Shelf";
import LessonCard from "../../../components/Lessons/LessonCard/LessonCard";
import {
  getLessons,
  gotoAssess,
  gotoStrengthen,
  useAssessStrengthenLoop,
} from "../util/groups";
import DisableOverlay from "../../DisableOverlay/DisableOverlay";

export default ({ profile, type, lessonResource, lessonGroupResource }) => {
  const { deploymentId, group } = useParams();

  const groupIdParsed = parseInt(group, 10);
  const history = useHistory();
  const lessonGroup = useMemo(
    () => lessonGroupResource.getGroup(groupIdParsed),
    [groupIdParsed, lessonGroupResource]
  );
  const lastMasteredLesson = profile.getLastMasteredLesson();
  const allowAssess = !!lessonGroup.assessment;
  const allowStrengthen = !!lessonGroup.strengthening;
  const [clicksDisabled, setClicksDisabled] = useState(false);

  // redirects
  const goBack = () => history.push(`/deployment/${deploymentId}/${type}`);
  const gotoLesson = useCallback(
    (lessonNum) =>
      history.push(`/deployment/${deploymentId}/${type}/lesson/${lessonNum}`),
    [deploymentId, history, type]
  );

  const cards = useMemo(() => {
    const lessons = getLessons(lessonResource, lessonGroup);
    return lessons.map((lesson) => (
      <LessonCard
        onClick={() => gotoLesson(lesson.number)}
        onGuideStart={() => setClicksDisabled(true)}
        onGuideStop={() => setClicksDisabled(false)}
        lesson={lesson}
        isEnabled={lesson.number <= lastMasteredLesson + 1}
        isMastered={lesson.number <= lastMasteredLesson}
        isActive={lesson.number === lastMasteredLesson + 1}
      />
    ));
  }, [lessonResource, lessonGroup, lastMasteredLesson, gotoLesson]);

  const isActiveGroup =
    lastMasteredLesson >= lessonGroup.start &&
    lastMasteredLesson < lessonGroup.end;
  const activeCardIndex = isActiveGroup
    ? lastMasteredLesson - lessonGroup.start
    : null;

  const [blinkAssessForLoop, blinkStrengthenForLoop] = useAssessStrengthenLoop(
    groupIdParsed,
    deploymentId,
    type
  );

  // we only want to do a assess blink for lesson mastery if we're not in an assess/strengthen loop.
  const hasCompletedAllLessonsInGroup =
    profile.getLastMasteredLesson() === lessonGroup.end;

  const hasAlreadyCompletedAssessment = profile
    .getCompletedAssessmentGroupLessonIds()
    .includes(groupIdParsed);

  const assessButtonBlink =
    hasCompletedAllLessonsInGroup &&
    !hasAlreadyCompletedAssessment &&
    (!blinkStrengthenForLoop || blinkAssessForLoop);

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>
        <DisableOverlay enable={clicksDisabled}>
          <Shelf
            autoscroll={false}
            infinite={false}
            items={cards}
            gotoSlideNum={activeCardIndex}
          />
        </DisableOverlay>
      </GameContainer>
      <BottomBar
        left={
          <BottomMultiple justifyContentBetween={false}>
            <BottomButton
              src={process.env.PUBLIC_URL + "/images/back.png"}
              onClick={goBack}
            />
            {allowAssess &&
              profile.getLastMasteredLesson() >= lessonGroup.end && (
                <BottomButton
                  src={process.env.PUBLIC_URL + "/images/assessment.png"}
                  onClick={() =>
                    gotoAssess(history, deploymentId, groupIdParsed, type)
                  }
                  blink={assessButtonBlink}
                />
              )}
            {allowStrengthen && (
              <BottomButton
                src={process.env.PUBLIC_URL + "/images/strengthen.png"}
                onClick={() =>
                  gotoStrengthen(history, deploymentId, groupIdParsed, type)
                }
                blink={blinkStrengthenForLoop}
              />
            )}
          </BottomMultiple>
        }
      />
    </GameWrapper>
  );
};
