import useProfile from "../Profile/useProfile";
import useFirebase from "../Firebase/useFirebase";
import { events, LESSON_EXERCISES } from "./events";
import { useStatisticsProfile } from "../Profile/ProfileUtil";

export const useAnalytics = () => {
  const statisticsProfile = useStatisticsProfile();
  const userProfile = useProfile();
  const firebase = useFirebase();
  return (event, data, uuid) => {
    const eventData = {
      action: event,
      deploymentId: userProfile.deploymentId,
      deploymentAccountId: userProfile.id,
      meta: data,
      time: new Date().getTime(),
      uuid,
    };
    if (
      event === events.COMPLETE_ACTIVITY &&
      data.activity &&
      LESSON_EXERCISES.includes(data.activity)
    ) {
      statisticsProfile.incrementCompletedExercises();
    }
    firebase.createActivityEvent(eventData);
  };
};
