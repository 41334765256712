import React from "react";
import BasePrompt from "./BasePrompt";
import NumberAnswer from "../Answers/NumberAnswer/NumberAnswer";

const NumberPrompt = ({
  number,
  color,
  shape,
  audio = null,
  delays = null,
  onPromptFinished,
}) => {
  return (
    <BasePrompt
      audio={audio}
      delays={delays}
      onPromptFinished={onPromptFinished}
    >
      <NumberAnswer number={number} color={color} shape={shape} />
    </BasePrompt>
  );
};

export default NumberPrompt;
