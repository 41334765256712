import React from "react";
import { useGlenShapesProfile } from "../../../util/Profile/ProfileUtil";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import LessonGroups from "../../../components/Lessons/LessonGroups/LessonGroups";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const resource = useResourceFromRegistry("shapesLessonGroups");
  const profile = useGlenShapesProfile();
  return (
    <LessonGroups
      profile={profile}
      lessonGroupsResource={resource}
      type={types.GLEN_SHAPES}
    />
  );
};
