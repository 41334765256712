export const LANDING = "/";
export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const RESET_PASSWORD = "/resetPassword";
export const ProfileList = "/deployment/:deploymentId/profiles";
export const ProfileAdd = "/deployment/:deploymentId/profiles/add";
export const ProfileEdit =
  "/deployment/:deploymentId/profiles/edit/:deploymentAccountId";
export const GLENLearnProfilePinLogout =
  "/deployment/:deploymentId/glenLearn/pinLogout";
export const GLENLearnStrengthen =
  "/deployment/:deploymentId/glenLearn/strengthen/:groupId";
export const GLENLearnAssess =
  "/deployment/:deploymentId/glenLearn/assess/:groupId";
export const GLENLearnComprehensiveAssessment =
  "/deployment/:deploymentId/glenLearn/assessment/comprehensive";
export const GLENLearnKSEPAssessment =
  "/deployment/:deploymentId/glenLearn/assessment/ksep";
export const GLENLearnSelectAssessment =
  "/deployment/:deploymentId/glenLearn/selectAssessment";
export const GLENLearnUserDashboard =
  "/deployment/:deploymentId/glenLearn/userDashboard";
export const GLENLearnUserDashboardWallOfFamePage =
  "/deployment/:deploymentId/glenLearn/userDashboard/wallOfFame";
export const GLENLearnUserDashboardTimelinePage =
  "/deployment/:deploymentId/glenLearn/userDashboard/timeline";
export const GLENLearnAdminDashboardCollectedWords =
  "/deployment/:deploymentId/adminDashboard/user/:userId/collectedWords";
export const AdminAccountSettings = "/deployment/:deploymentId/adminAccountSettings";
export const GLENLearnAdminDashboard =
  "/deployment/:deploymentId/adminDashboard/user/:userId";
export const ABOUT = "/about/:app";
export const FAQ = "/faq/:app";

export const GLENLearnAdminDashboardUserList =
  "/deployment/:deploymentId/adminDashboard/list";
export const GLENLearnCollectedWords =
  "/deployment/:deploymentId/glenLearn/collectedWords";
export const GLENLearn = "/deployment/:deploymentId/glenLearn";
export const GLENLearnGroup =
  "/deployment/:deploymentId/glenLearn/group/:group";
export const GLENLearnLesson =
  "/deployment/:deploymentId/glenLearn/lesson/:lesson";

export const GLENVocab = "/deployment/:deploymentId/glenVocab";
export const GLENVocabGroup =
  "/deployment/:deploymentId/glenVocab/group/:group";
export const GLENVocabLesson =
  "/deployment/:deploymentId/glenVocab/lesson/:lesson";
export const GLENVocabStrengthen =
  "/deployment/:deploymentId/glenVocab/strengthen/:groupId";
export const GLENVocabAssess =
  "/deployment/:deploymentId/glenVocab/assess/:groupId";

export const GLENShapes = "/deployment/:deploymentId/glenShapes";
export const GLENShapesGroup =
  "/deployment/:deploymentId/glenShapes/group/:group";
export const GLENShapesLesson =
  "/deployment/:deploymentId/glenShapes/lesson/:lesson";
export const GLENShapesStrengthen =
  "/deployment/:deploymentId/glenShapes/strengthen/:groupId";
export const GLENShapesAssess =
  "/deployment/:deploymentId/glenShapes/assess/:groupId";

export const GLENPhonics = "/deployment/:deploymentId/glenPhonics";
export const GLENPhonicsGroup =
  "/deployment/:deploymentId/glenPhonics/group/:group";
export const GLENPhonicsLesson =
  "/deployment/:deploymentId/glenPhonics/lesson/:lesson";
export const GLENPhonicsStrengthen =
  "/deployment/:deploymentId/glenPhonics/strengthen/:groupId";
export const GLENPhonicsAssess =
  "/deployment/:deploymentId/glenPhonics/assess/:groupId";

export const GLENBooksCategories =
  "/deployment/:deploymentId/glenLearn/glenBooks/categories";
export const GLENBooksCategory =
  "/deployment/:deploymentId/glenLearn/glenBooks/category/:category";
export const GLENBooksItem =
  "/deployment/:deploymentId/glenLearn/glenBooks/book/:book";

export const GLENRhymesCategory =
  "/deployment/:deploymentId/glenLearn/glenRhymes";
export const GLENRhymesItem =
  "/deployment/:deploymentId/glenLearn/glenRhymes/rhyme/:book";

export const GLENABC = "/deployment/:deploymentId/glenABC";
export const GLENABCGroup = "/deployment/:deploymentId/glenABC/group/:group";
export const GLENABCLesson = "/deployment/:deploymentId/glenABC/lesson/:lesson";

// no auth URLs
export const GLENBooksCategoriesNoAuth = "/glenBooks/categories";
export const GLENBooksCategoryNoAuth = "/glenBooks/category/:category";
export const GLENBooksItemNoAuth = "/glenBooks/book/:book";

export const GLENRhymesCategoryNoAuth = "/glenRhymes";
export const GLENRhymesItemNoAuth = "/glenRhymes/rhyme/:book";
