import { clearRequestInterval, requestInterval } from "./util";

class Cordova {
  constructor(audioPath) {
    this.media = null;
    this.footnotes = [];
    this.mediaInterval = null;
    this.cues = [];
    this.pauseAudioOnOSEvent = () => this.pause();

    this.media = new window.Media(
      audioPath,
      () => {},
      (error) => console.log("ERROR PLAYING AUDIO", error)
    );

    // when the app is paused by the OS, we need to pause the audio
    document.addEventListener("pause", this.pauseAudioOnOSEvent, false);
    return this;
  }

  deconstruct() {
    window.removeEventListener("pause", this.pauseAudioOnOSEvent);
  }

  clearCues() {
    this.cues = [];
  }

  play() {
    if (this.mediaInterval) {
      clearRequestInterval(this.mediaInterval);
    }

    //update footnotes according to current position (not very accurate..)
    this.mediaInterval = requestInterval(() => {
      this.media.getCurrentPosition((currentPosition) => {
        if (currentPosition > -1) {
          //run through the cues and execute callbacks.
          this.cues.forEach((cue) => {
            var difference = currentPosition - cue.time;
            if (difference <= 0.05 && difference > 0) {
              cue.cueCallback.call();
            }
          });
        }
      });
    }, 0);

    this.media.play();

    return this;
  }

  pause() {
    this.media.pause();
    if (this.mediaInterval) {
      clearRequestInterval(this.mediaInterval);
    }
    return this;
  }

  seekTo(position) {
    //in milliseconds for cordova
    this.media.seekTo(position * 1000);
    return this;
  }

  clearAudio() {
    this.media = null;
    this.cues = [];
    if (this.mediaInterval) {
      clearRequestInterval(this.mediaInterval);
    }
    return this;
  }

  atTime(time, cueCallback) {
    this.cues.push({ time: time, cueCallback });
    return this;
  }
}

export default Cordova;
