import { getPhonicsBetweenLessons } from "../../../../util/Lesson/util";
import sample from "lodash/sample";

export const getPhonicStrengthenInfo = (
  lessonResource,
  lessonGroup,
  getPhonicsAssessmentRecord
) => {
  const phonics = getPhonicsBetweenLessons(
    lessonResource,
    lessonGroup.start,
    lessonGroup.end
  );
  return {
    consonantWordMap: phonics,
    recentlyFailedSounds: Object.keys(phonics).filter((sound) => {
      const records = getPhonicsAssessmentRecord(sound);
      return records.length > 0 && records[records.length - 1] === false;
    }),
    selectedVariant: sample(lessonGroup.strengthening.variants),
  };
};
