import React from "react";
import { useGlenVocabProfile } from "../../../util/Profile/ProfileUtil";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import LessonGroups from "../../../components/Lessons/LessonGroups/LessonGroups";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const resource = useResourceFromRegistry("vocabLessonGroups");
  const glenVocabProfile = useGlenVocabProfile();
  return (
    <LessonGroups
      profile={glenVocabProfile}
      lessonGroupsResource={resource}
      type={types.GLEN_VOCAB}
    />
  );
};
