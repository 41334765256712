import React from "react";
import $ from "jquery";
import "./turn.js";

let responsiveTurnJs = {
  init: function (book, body, options) {
    let self = this;
    this.book = book;
    this.body = body;

    //perform initial resize of container
    this.resize();

    //initialize TurnJS
    this.initializeTurn(options);

    //on window resize, perform TurnJS resize
    $(window).resize(function () {
      self.doResize();
    });

    //on device rotation, perform TurnJS resize
    $(window).on("orientationchange", function () {
      self.doResize();
    });

    return this;
  },
  doResize: function () {
    let size = this.resize();
    $(this.book).turn("size", size.width, size.height);
  },
  resize: function () {
    // reset the width and height to the css defaults
    $(this.book).width("");
    $(this.book).height("");

    let width = $(this.book).width();
    let height = Math.round(this.body.height() * 0.65);

    // set the width and height matching the aspect ratio
    $(this.book).width(width + "px");
    $(this.book).height(height + "px");

    return {
      width: width,
      height: height,
    };
  },
  initializeTurn: function (options) {
    $(this.book).turn(options);

    // hide the body overflow
    this.body.css("overflow", "hidden");
  },

  destroy: function () {
    $(window).off("resize");
    $(this.book).turn("destroy");
  },
};

class TurnClass extends React.Component {
  constructor() {
    super();
    this.leftTurn = this.leftTurn.bind(this);
    this.rightTurn = this.rightTurn.bind(this);
  }

  componentDidMount() {
    this.responsive();
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    this.destroy();
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      this.leftTurn();
    }
    if (event.keyCode === 39) {
      this.rightTurn();
    }
  };

  leftTurn() {
    $(this.el).turn("previous");
  }

  rightTurn() {
    $(this.el).turn("next");
  }

  peelBottomRight() {
    $(this.el).turn("peel", "br");
  }

  getView() {
    const view = $(this.el).turn("view");
    return view;
  }

  responsive() {
    responsiveTurnJs.init(this.el, $("body"), this.props.options);
  }

  destroy() {
    responsiveTurnJs.destroy(this.el, $("body"));
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        ref={(el) => (this.el = el)}
      >
        {this.props.children}
      </div>
    );
  }
}

export default TurnClass;
