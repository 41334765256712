import { useEffect, useMemo, useRef, useState } from "react";
import { useAnalytics } from "../../../util/Analytics/hook";
import { events } from "../../../util/Analytics/events";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const useComponentRender = (stepToComponent, currentStep) => {
  const [toRender, setToRender] = useState(null);
  useEffect(() => {
    setToRender(stepToComponent[currentStep]);
  }, [currentStep]);
  return toRender;
};

export const useTimedAnalytics = () => {
  const createEvent = useAnalytics();
  let startTime = useRef(null);
  return useMemo(
    () => {
      const identifier = uuidv4().slice(0, 8);
      return {
        startEvent: (eventData) => {
          createEvent(events.START_ACTIVITY, eventData, identifier);
          startTime.current = new Date().getTime();
        },
        completeEvent: (eventData) => {
          createEvent(
            events.COMPLETE_ACTIVITY,
            {
              ...eventData,
              duration: new Date().getTime() - startTime.current,
            },
            identifier
          );
        },
      };
    },
    // eslint-disable-next-line
    []
  );
};

export const useCongratsStarAnimation = () => {
  const history = useHistory();
  const { groupComplete } = history.location.state || {};
  const [showAnimation, setShowAnimation] = useState(false);
  useEffect(() => {
    if (groupComplete) {
      setShowAnimation(true);
    }
  }, [groupComplete]);
  return showAnimation;
};
