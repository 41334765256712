import React, { useMemo, useState, useContext, useCallback } from "react";
import shuffle from "lodash/shuffle";
import AnswerStatus from "../../constants/answerStatus";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";
import DisableOverlay from "../../components/DisableOverlay/DisableOverlay";
import { createSubcomponent } from "./subcomponents";

const SHOW_CORRECT_ANSWER_DELAY = 1000;
const END_OF_QUESTION_DELAY = 1500;

/**
 * Returns a copy of arr, where the element at `index` is replaced with `newValue`.
 * @param {Array} arr
 * @param {Number} index
 * @param {*} newValue
 */
const arrayReplace = (arr, index, newValue) =>
  arr.map((oldValue, i) => (i === index ? newValue : oldValue));

/**
 * Full documentation and PropTypes are in index.js. Please do not directly import
 * this component! Instead, import from "games/MultipleChoice".
 */
const MultipleChoiceQuestion = ({
  onComplete,
  layout,
  prompt,
  answers,
  review,
  showCorrectAnswer = false,
}) => {
  let answersShuffled = useMemo(() => shuffle(answers), [answers]);
  const audioService = useContext(AudioContext);

  const [selectedCorrectAnswer, setSelectedCorrectAnswer] = useState(false);
  const [showReviewScreen, setShowReviewScreen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isPromptFinished, setIsPromptFinished] = useState(false);

  // All answer statuses are initialized to AnswerStatus.NONE
  const [answerStatuses, setAnswerStatuses] = useState(
    answersShuffled.map(() => AnswerStatus.NONE)
  );
  const setAnswerStatus = (index, status) => {
    setAnswerStatuses((answerStatuses) =>
      arrayReplace(answerStatuses, index, status)
    );
  };
  const onAnswerSelect = async (index) => {
    if (completed) {
      return;
    }
    setCompleted(true);

    // Stop all audio in progress when an answer is selected
    audioService.pauseCurrentlyPlaying();

    const choseCorrect = answersShuffled[index].correct;
    // Update selected answer to show correct/incorrect
    const selectedStatus = choseCorrect
      ? AnswerStatus.CORRECT
      : AnswerStatus.INCORRECT;
    setAnswerStatus(index, selectedStatus);

    const file = choseCorrect ? "correct" : "incorrect";
    audioService.composeAndPlaySingleAudio("soundfx", file);

    if (showCorrectAnswer && !choseCorrect) {
      const correctIndex = answersShuffled.findIndex(
        (answer) => answer.correct
      );
      setAnswerStatus(correctIndex, AnswerStatus.REVIEW_CORRECT);
      await wait(SHOW_CORRECT_ANSWER_DELAY);
    }

    await wait(END_OF_QUESTION_DELAY);

    if (review) {
      // save the state for review screen
      setSelectedCorrectAnswer(choseCorrect);
      setShowReviewScreen(true);
    } else {
      onComplete(choseCorrect);
    }
  };

  const onPromptFinished = useCallback(() => {
    setIsPromptFinished(true);
  }, []);

  const renderedPrompt = createSubcomponent("prompt", prompt.type, {
    ...prompt.props,
    onPromptFinished,
  });

  const renderedAnswers = answersShuffled.map(({ type, props }, index) =>
    createSubcomponent("answer", type, {
      ...props,
      answerStatus: answerStatuses[index],
      onSelect: () => onAnswerSelect(index),
      key: index,
    })
  );

  const renderedLayout = createSubcomponent("layout", layout.type, {
    ...layout.props,
    prompt: renderedPrompt,
    answers: renderedAnswers,
  });

  const renderedReview = review
    ? createSubcomponent("review", review.type, {
        ...review.props,
        onComplete: () => onComplete(selectedCorrectAnswer),
      })
    : null;

  // We use DisableOverlay here to disable the pointer cursor when the question is completed
  return (
    <DisableOverlay
      enable={completed || !isPromptFinished}
      style={{ width: "100%", height: "100%" }}
    >
      {showReviewScreen && renderedReview}
      {!showReviewScreen && renderedLayout}
    </DisableOverlay>
  );
};

export default MultipleChoiceQuestion;
