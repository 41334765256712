export default class LessonSManager {
  constructor(parameters) {
    this.parameters = parameters;
    this.currentIndex = 0;
  }

  getRound() {
    return this.currentIndex;
  }

  getNext() {
    return this.parameters[this.currentIndex++];
  }

  hasNext() {
    return this.currentIndex < this.parameters.length;
  }

  getCurrentProgress() {
    return this.currentIndex / this.parameters.length;
  }
}
