import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./ConsonantSounds.module.scss";
import { useResourceFromRegistry } from "../../util/Resource/useResource";
import AudioContext from "../../util/Audio/context";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import Backend, { Preview } from "react-dnd-multi-backend";
import wait from "../../util/wait";
import { useIncrementalDelay } from "../../util/incrementalDelay";
import classnames from "classnames";
import noop from "lodash/noop";
import AudioRepeat from "../../components/AudioRepeat/AudioRepeat";
import HTML5toTouch from "react-dnd-multi-backend/dist/cjs/HTML5toTouch";
import shuffle from "lodash/shuffle";
import DisableOverlay from "../../components/DisableOverlay/DisableOverlay";

const DropBox = ({ withRedFlash }) => {
  // eslint-disable-next-line
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "word",
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop}>
      <div className={styles.cardContainer}>
        <div
          className={classnames(styles.card, {
            [styles.redFlash]: withRedFlash,
          })}
        >
          <div className={`${styles.dropImageContainer}`}>
            <img
              className={`${styles.bottomCard} ${styles.cardImage}`}
              src={process.env.PUBLIC_URL + "/images/cardfront.png"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CorrectOption = ({
  flashcard,
  showMeaning = true,
  isReview = false,
  withGreenFlash = false,
  playAudioOnConstruct = true,
  allowAudioOnClick = false,
}) => {
  const audioService = useContext(AudioContext);
  const playAudio = useCallback(() => audioService.playAudio(flashcard.audio), [
    audioService,
    flashcard,
  ]);
  useEffect(() => {
    //we play the audio when the component is constructed
    if (isReview || playAudioOnConstruct) {
      playAudio();
    }
  }, [isReview, playAudio, playAudioOnConstruct]);
  return (
    <div
      className={styles.cardContainer}
      onClick={allowAudioOnClick ? playAudio : null}
    >
      <div
        className={classnames(styles.card, {
          [styles.greenFlash]: withGreenFlash,
        })}
      >
        <div className={styles.cardImageContainer}>
          <img
            className={`${styles.cardImage} ${styles.bottomCard}`}
            src={flashcard.image}
          />
        </div>
        {showMeaning && (
          <div className={styles.cardText}>
            <div className={styles.text}>
              {!isReview && flashcard.meaning}
              {isReview && (
                <div>
                  <strong>{flashcard.meaning[0]}</strong>
                  {flashcard.meaning.substr(1)}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DragOption = ({ flashcard, onDrop, showMeaning = true }) => {
  const audioService = useContext(AudioContext);
  const playAudio = () => {
    audioService.playAudio(flashcard.audio);
  };

  const [{ isDragging }, drag] = useDrag({
    item: { card: flashcard, type: "word" },
    begin: playAudio,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item.card.key, showMeaning);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  return (
    <div
      ref={drag}
      style={{ opacity }}
      className={styles.cardContainer}
      onClick={playAudio}
    >
      <div className={styles.card}>
        <div className={styles.cardImageContainer}>
          <img
            className={`${styles.cardImage} ${styles.topCard}`}
            src={flashcard.image}
          />
        </div>
        {
          <div className={styles.cardText}>
            <div className={styles.text}>{flashcard.meaning}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default ({
  consonant,
  answer,
  confusionWord,
  correctWords,
  showMeaning = true,
  targetOverride = null,

  // incorrect answer continues exercise when true
  allowIncorrect = true,
  onStart = noop,
  onComplete = noop,
}) => {
  const audioService = useContext(AudioContext);
  const flashcardResource = useResourceFromRegistry("flashcards");

  const correctFlashcards = useMemo(
    () => correctWords.map((fc) => flashcardResource.getFlashcard(fc)),
    [correctWords, flashcardResource]
  );

  const candidateFlashcards = useMemo(() => {
    return shuffle([answer, confusionWord]).map((word) =>
      flashcardResource.getFlashcard(word)
    );
  }, [answer, confusionWord, flashcardResource]);

  const answerFlashcard = useMemo(
    () => candidateFlashcards.find((cf) => cf.key === answer),
    [answer, candidateFlashcards]
  );

  const [clicksEnabled, setClicksEnabled] = useState(false);
  const [dropped, setDropped] = useState(false);

  const [showRepeat, setShowRepeat] = useState(false);
  const [selectedCorrectOption, setSelectedCorrectOption] = useState(false);
  const [selectedIncorrectOption, setSelectedIncorrectOption] = useState(false);
  // +1 for the drop box
  const [
    correctOptionsVisibility,
    correctOptionsAnimation,
  ] = useIncrementalDelay(correctWords.length + 1);
  const [dragOptionsVisibility, dragOptionsAnimation] = useIncrementalDelay(
    candidateFlashcards.length
  );
  const [reviewBounce, reviewBounceAnimation] = useIncrementalDelay(
    candidateFlashcards.length + 1
  );

  const playChosenConsonantAudio = useCallback(
    () =>
      audioService.composeAndPlayAudio(
        targetOverride
          ? [
              {
                directory: "prompts",
                files: [targetOverride.audio[0]], // targetOverride.audio contains prompt and consonant.
              },
              {
                directory: "consonants",
                files: [targetOverride.audio[1]],
              },
            ]
          : [
              {
                directory: "consonants",
                files: [consonant],
              },
            ]
      ),
    [audioService, consonant]
  );

  const onDrop = async (option, shouldReview) => {
    if (option === answer) {
      setDropped(true);
      setSelectedIncorrectOption(false);
      setSelectedCorrectOption(true);
      await audioService.composeAndPlaySingleAudio("soundfx", "correct");

      if (shouldReview) {
        await playChosenConsonantAudio();
        await reviewBounceAnimation();
        await wait(200);
      }
      onComplete(true);
    } else {
      setSelectedIncorrectOption(true);
      await audioService.composeAndPlaySingleAudio("soundfx", "incorrect");
      setSelectedIncorrectOption(false);
      if (allowIncorrect) {
        onComplete(false);
      }
    }
  };

  useEffect(() => {
    onStart();
    const animation = async () => {
      setShowRepeat(true);
      await wait(200);
      await playChosenConsonantAudio();
      await wait(1000);
      await correctOptionsAnimation();
      setClicksEnabled(true);
      await dragOptionsAnimation();
    };
    animation();
  }, []);

  const GeneratePreview = () => {
    const { style, item } = useContext(Preview.Context);
    return (
      <div style={{ ...style, width: "11vw", zIndex: 999 }}>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.cardImageContainer}>
              <img className={styles.cardImage} src={item.card.image} />
            </div>
            <div className={styles.cardText}>
              <div className={styles.text}>{item.card.meaning}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <DisableOverlay
      enable={dropped || !clicksEnabled}
      style={{ height: "100%" }}
    >
      <DndProvider backend={Backend} options={HTML5toTouch}>
        <Preview>
          <GeneratePreview />
        </Preview>
        <div className="d-flex flex-column h-100">
          <div className="h-25 row mt-4">
            <div className="col-5">
              <div className={`${styles.audioRepeatContainer}`}>
                {showRepeat && (
                  <AudioRepeat
                    onClick={async () => {
                      if (!selectedCorrectOption) {
                        setClicksEnabled(false);
                        await playChosenConsonantAudio();
                        setClicksEnabled(true);
                      }
                    }}
                  />
                )}
              </div>
            </div>
            {!selectedCorrectOption &&
              candidateFlashcards.map((fc, i) => {
                return (
                  <div key={fc.key} className={`col-3 mt-auto mb-auto pl-5`}>
                    {dragOptionsVisibility[i] && (
                      <div className={"animated fadeIn"}>
                        <DragOption
                          flashcard={fc}
                          onDrop={onDrop}
                          showMeaning={showMeaning}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="h-75 row mt-3">
            {correctFlashcards.map((flashcard, i) => {
              return (
                <div key={flashcard.key} className={`col-4 mt-auto mb-auto `}>
                  {correctOptionsVisibility[i] && (
                    <div
                      className={classnames("animated", {
                        fadeIn: !reviewBounce[i],
                        bounce: reviewBounce[i],
                      })}
                    >
                      <CorrectOption
                        showMeaning={showMeaning}
                        flashcard={flashcard}
                        isReview={reviewBounce[i]}
                        allowAudioOnClick={!selectedCorrectOption}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            <div className={"col-4 mt-auto mb-auto"}>
              {correctOptionsVisibility[correctWords.length] && (
                <div
                  className={classnames("animated", {
                    fadeIn: !reviewBounce[correctWords.length],
                    bounce: reviewBounce[correctWords.length],
                  })}
                >
                  {!selectedCorrectOption && (
                    <DropBox withRedFlash={selectedIncorrectOption} />
                  )}
                  {selectedCorrectOption && (
                    <CorrectOption
                      showMeaning={showMeaning}
                      withGreenFlash={true}
                      playAudioOnConstruct={false}
                      flashcard={answerFlashcard}
                      allowAudioOnClick={false}
                      isReview={reviewBounce[correctWords.length]}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </DndProvider>
    </DisableOverlay>
  );
};
