import sampleSize from "lodash/sampleSize";
import sample from "lodash/sample";
import shuffle from "lodash/shuffle";
import { activity } from "../../../../util/Analytics/events";

export const PHONIC_ASSESS_LEVELS = {
  LEVEL_1: 1,
  LEVEL_2: 2,
};

export default class PhonicAssessManager {
  constructor(consonantWordMap, level, phonicAssessRecordFunc) {
    this.consonantWordMap = consonantWordMap;
    this.phonicAssessRecordFunc = phonicAssessRecordFunc;
    this.level = level;
    this.currentRound = 0;

    // for the selected consonants, builds a map like:
    // kuh: {required: 3, exposed: 1}
    //...
    const consonants = this._chooseSounds(
      shuffle(Object.keys(this.consonantWordMap))
    );

    // take
    this.soundMap = {};
    const consonantCount = Math.min(4, consonants.length);
    for (let i = 0; i < consonantCount; i++) {
      // for i = 0 & 1, required count should be 3
      // for i = 3+, required count should be 2.
      const required = i < 2 ? 3 : 2;
      this.soundMap[consonants[i]] = { required, exposed: 0 };
    }

    this.score = 0;
    this.outOf = 20;
  }

  getOutOf() {
    return this.outOf;
  }

  getCurrentScore() {
    return this.score;
  }

  addSuccess() {
    this.score += 2;
  }

  hasNext() {
    return Object.keys(this.soundMap).some(
      (k) => this.soundMap[k].exposed !== this.soundMap[k].required
    );
  }

  getNextGame(currentSound) {
    //get all sounds that are eligible for the next play
    //order it by sounds that weren't the last play.
    const availableSounds = shuffle(Object.keys(this.soundMap))
      .filter((s) => {
        return this.soundMap[s].exposed < this.soundMap[s].required;
      })
      .sort((a, b) => {
        return a !== currentSound;
      });

    //choose the target sound from the top of the sorted list.
    const targetSound = availableSounds[0];
    this.soundMap[targetSound].exposed++;

    // choose three random confusion sounds (excluding the target sound)
    const allSounds = Object.keys(this.consonantWordMap);

    //find a single correct word for the target sound
    const correctWord = sample(Array.from(this.consonantWordMap[targetSound]));
    const confusionSounds = sampleSize(
      allSounds.filter((s) => s !== targetSound),
      3
    );
    //find an incorrect word for each of the three confusion sounds.
    const confusionWords = confusionSounds.map((s) =>
      sample(Array.from(this.consonantWordMap[s]))
    );

    if (this.level === PHONIC_ASSESS_LEVELS.LEVEL_2) {
      return {
        game: activity.SOUND_CLICK_1,
        parameters: { targetSound, correctWord, confusionWords },
      };
    } else if (this.level === PHONIC_ASSESS_LEVELS.LEVEL_1) {
      let p = [1, 1, 1, 1, 0.66, 0.66, 0.66, 0.5, 0.5, 0.5];

      if (Math.random() < p[this.currentRound++]) {
        return {
          game: activity.SOUND_CLICK_2,
          parameters: {
            targetSound,
            correctLetter: correctWord.slice(0, 1),
            // choose any confusion word at random
            confusionLetter: confusionWords[0].slice(0, 1),
          },
        };
      } else {
        return {
          game: activity.SOUND_CLICK_1,
          parameters: { targetSound, correctWord, confusionWords },
        };
      }
    }
  }

  _chooseSounds(allPossibleSounds) {
    if (allPossibleSounds.length < 4) {
      return allPossibleSounds;
    }

    const recentlyFailedSounds = this.getRecentlyFailedSounds(
      allPossibleSounds
    );

    if (recentlyFailedSounds.length >= 4) {
      return sampleSize(recentlyFailedSounds, 4);
    }

    let remainingSounds = allPossibleSounds
      .filter((c) => !recentlyFailedSounds.includes(c))
      .sort((a, b) => {
        return (
          this.phonicAssessRecordFunc(a).length -
          this.phonicAssessRecordFunc(b).length
        );
      });
    const remainingToTarget = sampleSize(
      remainingSounds,
      4 - recentlyFailedSounds.length
    );
    return [...recentlyFailedSounds, ...remainingToTarget];
  }

  getRecentlyFailedSounds(sounds) {
    return sounds.filter((concept) => {
      const records = this.phonicAssessRecordFunc(concept);
      return records.length > 0 && records[records.length - 1] === false;
    });
  }
}
