import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./SoundClick.module.scss";
import { useResourceFromRegistry } from "../../util/Resource/useResource";
import Card from "../../components/Card/Card";
import AudioContext from "../../util/Audio/context";
import shuffle from "lodash/shuffle";
import wait from "../../util/wait";
import WordReview from "../../components/WordReview/WordReview";
import sample from "lodash/sample";
import DisableOverlay from "../../components/DisableOverlay/DisableOverlay";
import classnames from "classnames";
import AudioRepeat from "../../components/AudioRepeat/AudioRepeat";

const SoundClickGame = ({
  flashcard,
  confusionWords,
  shouldShowReview,
  onComplete,
}) => {
  const audioService = useContext(AudioContext);
  const flashcardResource = useResourceFromRegistry("flashcards");

  const [shuffledCards, setShuffledCards] = useState([]);
  const [clickedWord, setClickedWord] = useState(null);

  useEffect(() => {
    audioService.playAudio(flashcard.audio);
    setShuffledCards(
      shuffle([
        flashcard,
        ...confusionWords.map((word) => flashcardResource.getFlashcard(word)),
      ])
    );
  }, []);

  const onClick = async (key) => {
    setClickedWord(key);
    if (key === flashcard.key) {
      audioService.composeAndPlaySingleAudio("soundfx", "correct");
      await wait(1700);
      onComplete(true);
    } else {
      audioService.composeAndPlaySingleAudio("soundfx", "incorrect");
      await wait(1800);
      onComplete(false);
    }
  };

  const playTargetSound = () => audioService.playAudio(flashcard.audio);

  const slice1 = useMemo(() => shuffledCards.slice(0, 2), [shuffledCards]);
  const slice2 = useMemo(() => shuffledCards.slice(2, 4), [shuffledCards]);
  const renderSlice = (cards) => {
    return cards.map((word) => {
      const clickedCorrect =
        clickedWord === word.key && word.key === flashcard.key;
      const clickedIncorrect =
        clickedWord === word.key && word.key !== flashcard.key;
      return (
        <div
          key={word.image}
          className={`animated bounce ${styles.cardContainer}`}
        >
          <Card
            image={word.image}
            text={word.meaning}
            outline={classnames({
              green: clickedCorrect,
              red: clickedIncorrect,
            })}
            animationClass={classnames({
              bounce: clickedCorrect,
              shake: clickedIncorrect,
            })}
            withCheck={clickedCorrect}
            withCross={clickedIncorrect}
            withCircle={
              shouldShowReview &&
              clickedWord !== null &&
              clickedWord !== word.key &&
              word.key === flashcard.key
            }
            onClick={() => onClick(word.key)}
          />
        </div>
      );
    });
  };
  return (
    <DisableOverlay enable={clickedWord !== null}>
      <div className="row mt-3">
        <div className="col-5">{renderSlice(slice1)}</div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <AudioRepeat onClick={playTargetSound} />
        </div>
        <div className="col-5">{renderSlice(slice2)}</div>
      </div>
    </DisableOverlay>
  );
};

export default ({
  targetWord,
  confusionWords,
  shouldShowReview = true,
  onStart,
  onComplete,
}) => {
  const audioService = useContext(AudioContext);
  const [showReview, setShowReview] = useState(false);
  const [wasCorrectAnswer, setWasCorrectAnswer] = useState(null);
  const flashcardResource = useResourceFromRegistry("flashcards");

  const flashcard = useMemo(() => flashcardResource.getFlashcard(targetWord), [
    flashcardResource,
    targetWord,
  ]);

  // the review flashcard should not be the same image as the original flashcard.
  const reviewFlashcard = useMemo(() => {
    return {
      ...flashcard,

      // not all flashcards hve more than one image. in those cases, use the original flashcard.
      image:
        sample(flashcard.imageOptions.filter((i) => i !== flashcard.image)) ||
        flashcard.image,
    };
  }, [flashcard]);

  const onGameFinished = async (wasCorrect) => {
    setWasCorrectAnswer(wasCorrect);
    if (shouldShowReview && !wasCorrect) {
      await audioService.playAudio(flashcard.audio);
      await wait(1500);
    }
    if (shouldShowReview) {
      setShowReview(true);
    } else {
      onComplete(wasCorrect);
    }
  };

  useEffect(onStart, []);

  return (
    <>
      {!showReview && (
        <SoundClickGame
          flashcard={flashcard}
          confusionWords={confusionWords}
          shouldShowReview={shouldShowReview}
          onComplete={onGameFinished}
        />
      )}
      {showReview && (
        <WordReview
          flashcard={reviewFlashcard}
          onComplete={() => onComplete(wasCorrectAnswer)}
        />
      )}
    </>
  );
};
