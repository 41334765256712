import React from "react";
import noop from "lodash/noop";
import Assessment from "./Assessment";
import { useTimedAnalytics } from "../../../components/Lessons/util/hooks";

export default ({ onLevelStart = noop, onLevelComplete = noop, ...props }) => {
  const levelEvent = useTimedAnalytics();
  const eventData = { activity: props.activity }; // either activity.COMPREHENSIVE_ASSESSMENT or activity.KSEP_ASSESSMENT (from util/events.js), depending on which one the user clicks.

  /**
   * @param {Object} assessmentData contains the level for a comprehensive assessment.
   */
  const onLevelStartOverride = (assessmentData) => {
    levelEvent.startEvent({
      ...eventData,
      ...assessmentData,
      wasManual: props.wasManual,
    });
    onLevelStart();
  };

  /**
   * @param {Object} assessmentData contains the level a comprehensive assessment and score for both KSEP and comprehensive assessments.
   */
  const onLevelCompleteOverride = (assessmentData) => {
    levelEvent.completeEvent({
      ...eventData,
      ...assessmentData,
      wasManual: props.wasManual,
    });
    onLevelComplete();
  };

  return (
    <Assessment
      onLevelStart={onLevelStartOverride}
      onLevelComplete={onLevelCompleteOverride}
      {...props}
    />
  );
};
