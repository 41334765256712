import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenPhonicsProfile } from "../../../util/Profile/ProfileUtil";
import { TYPES as types } from "../../../components/Lessons/util/groups";
import Lesson from "../../../components/Lessons/Lesson/Lesson";

export default () => {
  const profile = useGlenPhonicsProfile();
  const lessonResource = useResourceFromRegistry("phonicsLessons");
  const lessonGroupResource = useResourceFromRegistry("phonicsLessonGroups");
  return (
    <Lesson
      profile={profile}
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      type={types.GLEN_PHONICS}
    />
  );
};
