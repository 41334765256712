// events for display on Timeline only
import { v4 as uuidv4 } from "uuid";

export const events = {
  GROUP_COMPLETED: "group_completed",
  WORD_MILESTONE: "word_milestone",
  CERTIFICATE: "certificate",
};

class TimelineEvent {
  constructor(type, meta, time) {
    this.type = type;
    this.meta = meta;
    this.time = time;
    this.id = uuidv4();
  }
  toJson() {
    return { type: this.type, meta: this.meta, id: this.id, time: this.time };
  }
}

export class GroupCompletedEvent extends TimelineEvent {
  constructor(lessonType, lessonStart, lessonEnd, time = Date.now()) {
    super(events.GROUP_COMPLETED, { lessonType, lessonStart, lessonEnd }, time);
  }
}

export class CertificateEvent extends TimelineEvent {
  constructor(lessonType, meta, time = Date.now()) {
    super(events.CERTIFICATE, { ...meta, lessonType }, time);
  }
}
