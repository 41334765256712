import React, { useState } from "react";
import useFirebase from "../../util/Firebase/useFirebase";

export default () => {
  let [email, setEmail] = useState("");
  let [isSent, setIsSent] = useState(false);

  const firebase = useFirebase();

  const doReset = () => {
    setIsSent(true);
    firebase.doSendPasswordResetEmail(email);
  };

  if (isSent) {
    return <h2>A reset link will arrive in your inbox shortly.</h2>;
  }

  return (
    <div className="w-50">
      <h1>Reset your password</h1>
      <p>Please input your e-mail below</p>
      <input
        name="email"
        className="form-control"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="Email Address"
        required
      />
      <p className="mt-2">
        <button onClick={doReset}>Reset</button>
      </p>
    </div>
  );
};
