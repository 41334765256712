import { LESSON_TYPE } from "../LessonTypes/constants";
import LessonA1 from "../LessonTypes/LessonA1/LessonA1";
import LessonA from "../LessonTypes/LessonA/LessonA";
import LessonA2 from "../LessonTypes/LessonA2/LessonA2";
import LessonA3 from "../LessonTypes/LessonA3/LessonA3";
import LessonB from "../LessonTypes/LessonB/LessonB";
import LessonC from "../LessonTypes/LessonC/LessonC";
import LessonC1 from "../LessonTypes/LessonC1/LessonC1";
import LessonC2 from "../LessonTypes/LessonC2/LessonC2";
import LessonC3 from "../LessonTypes/LessonC3/LessonC3";
import LessonS from "../LessonTypes/LessonS/LessonS";
import Dynamic from "../LessonTypes/Dynamic/Dynamic";
import LessonN0 from "../LessonTypes/LessonN0/LessonN0";

const mapLessonTypeToFunction = {
  [LESSON_TYPE.A]: LessonA,
  [LESSON_TYPE.A1]: LessonA1,
  [LESSON_TYPE.A2]: LessonA2,
  [LESSON_TYPE.A3]: LessonA3,
  [LESSON_TYPE.B]: LessonB,
  [LESSON_TYPE.C]: LessonC,
  [LESSON_TYPE.C1]: LessonC1,
  [LESSON_TYPE.C2]: LessonC2,
  [LESSON_TYPE.C3]: LessonC3,
  [LESSON_TYPE.S]: LessonS,
  [LESSON_TYPE.N0]: LessonN0,
  [LESSON_TYPE.DYNAMIC]: Dynamic,
};

export default mapLessonTypeToFunction;
