import React from "react";
import {
  AuthedTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useHistory, useParams } from "react-router-dom";
import {
  useGlenLearnProfile,
  useStatisticsProfile,
} from "../../../../util/Profile/ProfileUtil";
import { getNumWordsLearned } from "../../../../util/Lesson/util";
import { useResourceFromRegistry } from "../../../../util/Resource/useResource";
import Timeline from "./Timeline/Timeline";
import Avatar from "../../../../components/Avatar/Avatar";
import styles from "./DashboardForUser.module.scss";
import { useProfileForUserId } from "../../../../util/Profile/useProfile";
import { useTimelineEvents } from "../../../../util/Timeline/useTimelineEvents";

const Dashboard = () => {
  const lessonResource = useResourceFromRegistry("lessons");
  const history = useHistory();
  const { deploymentId } = useParams();
  const { userId } = useParams();
  // eslint-disable-next-line
  const [profile, loading, error] = useProfileForUserId(userId);
  const timelineEvents = useTimelineEvents(profile);
  const statisticsProfile = useStatisticsProfile(profile);
  const glenLearnProfile = useGlenLearnProfile(profile);

  if (loading) {
    return null;
  }

  const gotoCollectedWords = () =>
    history.push(
      `/deployment/${deploymentId}/adminDashboard/user/${userId}/collectedWords`
    );
  const numWordsLearned = () =>
    getNumWordsLearned(
      glenLearnProfile.getLastMasteredLesson(),
      lessonResource
    );

  return (
    <div className={styles["dashboard-container"]}>
      <div className={styles.progressHeader}>
        Learning progress for {profile.username}{" "}
        <Avatar avatarId={profile.avatarId} size={"xs"} />{" "}
      </div>
      <div className={styles["timeline-container"]}>
        <Timeline profile={profile} timelineEvents={timelineEvents} />
      </div>
      <div className={styles["bottom-stats"]}>
        <div className="row">
          <div className="col-6" onClick={gotoCollectedWords}>
            Words learned: {numWordsLearned()}
          </div>
          <div className="col-6">
            Activities completed:{" "}
            {statisticsProfile.getCompletedExercisesCount()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const history = useHistory();
  const { deploymentId } = useParams();
  const goBack = () =>
    history.push({
      state: {
        skipAuth: true,
      },
      pathname: `/deployment/${deploymentId}/adminDashboard/list`,
    });
  return (
    <GameWrapper>
      <AuthedTopBar />
      <GameContainer>
        <Dashboard />
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} />
    </GameWrapper>
  );
};
