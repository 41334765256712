import React, { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GroupCompletedEvent } from "../../../util/Timeline/timeline";
import VocabAssessWithAnalytics from "../../../components/Lessons/Assess/VocabAssess/VocabAssessWithAnalytics";
import PhonicAssessWithAnalytics from "../../../components/Lessons/Assess/PhonicAssess/PhonicAssessWithAnalytics";
import { getVocabLessonGroupInfo } from "./VocabAssess/util";
import { getPhonicLessonGroupInfo } from "./PhonicAssess/util";
import ShapesAssessWithAnalytics from "./ShapesAssess/ShapesAssessWithAnalytics";

export default ({ profile, lessonResource, lessonGroupResource, type }) => {
  const { groupId, deploymentId } = useParams();
  const groupIdParsed = parseInt(groupId, 10);
  const lessonGroup = useMemo(
    () => lessonGroupResource.getGroup(groupIdParsed),
    [groupIdParsed, lessonGroupResource]
  );

  const history = useHistory();
  const {
    onCompleteRedirect = `/deployment/${deploymentId}/${type}`, //for when not specified, e.g. direct url access
    isAssessStrengthenLoop = false,
  } = history.location.state || {};

  const onComplete = useCallback(
    (score) => {
      const passedAssessment = score >= 16;

      if (passedAssessment) {
        profile.markLessonGroupIdAsAssessed(groupIdParsed);
      }

      if (isAssessStrengthenLoop) {
        if (passedAssessment) {
          profile.addTimelineEvent(
            new GroupCompletedEvent(type, lessonGroup.start, lessonGroup.end)
          );

          // when successful, we redirect to all groups and play star animation
          const state = { groupComplete: true };
          history.push({
            pathname: `/deployment/${deploymentId}/${type}`,
            state,
          });
        } else {
          // when not successful, we go back to lesson group and start strengthening
          const state = { isAssessStrengthenLoop: true, doStrengthen: true };
          history.push({
            pathname: `/deployment/${deploymentId}/${type}/group/${groupIdParsed}`,
            state,
          });
        }
      } else {
        history.push(onCompleteRedirect);
      }
    },
    [
      deploymentId,
      groupIdParsed,
      history,
      isAssessStrengthenLoop,
      lessonGroup,
      onCompleteRedirect,
      profile,
      type,
    ]
  );

  const wasManual = !isAssessStrengthenLoop;

  if (lessonGroup.assessment.type === "vocab") {
    const { concepts, groups, textIndicator } = useMemo(
      () => getVocabLessonGroupInfo(lessonResource, lessonGroup),
      [lessonGroup, lessonResource]
    );
    return (
      <VocabAssessWithAnalytics
        concepts={concepts}
        groups={groups}
        textIndicator={textIndicator}
        wasManual={wasManual}
        lessonGroupId={groupId}
        updateVocabAssessmentRecord={profile.updateVocabAssessmentRecord}
        getVocabAssessmentRecord={profile.getVocabAssessmentRecord}
        onComplete={onComplete}
      />
    );
  } else if (lessonGroup.assessment.type === "phonic") {
    const { consonantWordMap, level } = useMemo(
      () => getPhonicLessonGroupInfo(lessonResource, lessonGroup),
      [lessonGroup, lessonResource]
    );
    return (
      <PhonicAssessWithAnalytics
        consonantWordMap={consonantWordMap}
        wasManual={wasManual}
        level={level}
        lessonGroupId={groupId}
        updatePhonicsAssessmentRecord={profile.updatePhonicsAssessmentRecord}
        getPhonicsAssessmentRecord={profile.getPhonicsAssessmentRecord}
        onComplete={onComplete}
      />
    );
  } else if (lessonGroup.assessment.type === "shapes") {
    let concepts = useMemo(
      () => ["circle", "star", "triangle", "square", "rectangle"],
      []
    );
    return (
      <ShapesAssessWithAnalytics
        concepts={concepts}
        wasManual={wasManual}
        lessonGroupId={groupId}
        updateShapesAssessmentRecord={profile.updateShapesAssessmentRecord}
        getShapesAssessmentRecord={profile.getShapesAssessmentRecord}
        onComplete={onComplete}
      />
    );
  }
};
