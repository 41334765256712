import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenPhonicsProfile } from "../../../util/Profile/ProfileUtil";
import Assess from "../../../components/Lessons/Assess/Assess";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const lessonResource = useResourceFromRegistry("phonicsLessons");
  const lessonGroupResource = useResourceFromRegistry("phonicsLessonGroups");
  const glenPhonicsProfile = useGlenPhonicsProfile();
  return (
    <Assess
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      profile={glenPhonicsProfile}
      type={types.GLEN_PHONICS}
    />
  );
};
