import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import AssessmentWithAnalytics from "../Assessment/AssessmentWithAnalytics";
import useFirebase from "../../../util/Firebase/useFirebase";
import { activity } from "../../../util/Analytics/events";
import { ClipLoader } from "react-spinners";

export default (props) => {
  const [distribution, setDistribution] = useState(null);

  const firebase = useFirebase();
  const history = useHistory();
  const { deploymentId } = useParams();

  const { onCompleteRedirect = null, wasManual = true } =
    history.location.state || {};

  const onComplete = useCallback(() => {
    if (onCompleteRedirect) {
      history.push(onCompleteRedirect);
    } else {
      console.log(
        "Comprehensive assessment manually invoked, redirecting to homepage"
      );
      history.push(`/deployment/${deploymentId}/glenLearn`);
    }
  }, [deploymentId, history, onCompleteRedirect]);

  useEffect(() => {
    if (props.activity === activity.KSEP_ASSESSMENT) {
      // the user clicked on the KSEP assessment -> fetch the KSEP distribution from Cloud Firestore
      const assessment = props.activity;
      firebase
        .getAssessmentCategoryDistribution(deploymentId, assessment)
        .then((res) => {
          setDistribution(res);
        })
        .catch((err) => {
          throw err;
        });
    } else {
      // the user clicked on an assessment without a configurable distribution
      setDistribution({});
    }
  }, []);

  return (
    <div>
      {/* only once the useEffect has run and the distribution has been set, should we begin the assessment */}
      {distribution ? (
        <AssessmentWithAnalytics
          onComplete={onComplete}
          wasManual={wasManual}
          distribution={distribution}
          {...props}
        />
      ) : (
        <div
          className="text-center"
          style={{ paddingTop: "40vh", paddingBottom: "40vh" }}
        >
          <ClipLoader size={100} />
        </div>
      )}
    </div>
  );
};
