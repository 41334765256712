import sample from "lodash/sample";
import sampleSize from "lodash/sampleSize";
import concat from "lodash/concat";
import round from "lodash/round";

export default class LessonC2Manager {
  constructor(parameters) {
    this.content = parameters.content;

    this.sounds = Object.keys(this.content);
    this.targetSound = this.sounds[0];

    this.currentTraceAndSoundWordIndex = 0;

    this.numberWords = this.content[this.targetSound].length;
    this.numberTests = Math.min(parameters.targetSoundCount, this.numberWords);

    this.alpha = 0.3;
    this.beta = 0.8;

    this.weightedNumberActivities =
      this.alpha * this.numberWords + (1 - this.alpha) * this.numberTests;
    this.desiredSuccessCount = 1;
    this.weightedActivityCount = 0;
    this.nSuccess = 0;
    this.nExposure = 0;

    this.soundClick1Blacklist = [];
  }

  onTraceAndSoundFinished() {
    this.weightedActivityCount += this.alpha;
  }

  get traceAndSoundComplete() {
    return this.currentTraceAndSoundWordIndex === this.numberWords;
  }

  get nextTraceAndSoundWord() {
    return this.content[this.targetSound][this.currentTraceAndSoundWordIndex++];
  }

  get progressBarLength() {
    return round(
      this.beta * this.activityBarLength +
        (1 - this.beta) * this.successBarLength,
      2
    );
  }

  get successBarLength() {
    return Math.min(1, this.nSuccess / this.desiredSuccessCount);
  }

  get activityBarLength() {
    return Math.min(
      1,
      this.weightedActivityCount / this.weightedNumberActivities
    );
  }

  onSoundClick1Complete(wasSuccess) {
    this.weightedActivityCount += 1 - this.alpha;
    this.nExposure++;
    if (wasSuccess) {
      this.nSuccess++;
    }
  }

  get nextSoundClick1() {
    const confusionConsonants = Object.keys(this.content).filter(
      (key) => key !== this.targetSound
    );
    const allConfusionWords = concat(
      ...confusionConsonants.map((s) => this.content[s])
    );

    // sample a word not in the blacklist
    let correctWord;
    const nonBlacklistWords = this.content[this.targetSound].filter(
      (word) => !this.soundClick1Blacklist.includes(word)
    );

    // if we have non-blacklisted words, choose one
    if (nonBlacklistWords.length > 0) {
      correctWord = sample(nonBlacklistWords);
      this.soundClick1Blacklist.push(correctWord);
    } else {
      // everything is blacklisted - choose a blacklist word at random
      correctWord = sample(this.soundClick1Blacklist);
      this.soundClick1Blacklist = [correctWord];
    }

    return {
      targetSound: this.targetSound,
      correctWord: correctWord,
      confusionWords: sampleSize(allConfusionWords, 3),
    };
  }

  get soundClick1Complete() {
    return (
      this.nExposure >= this.numberTests &&
      this.nSuccess >= this.desiredSuccessCount
    );
  }
}
