import sample from "lodash/sample";
import shuffle from "lodash/shuffle";

export default class LessonC3Manager {
  constructor({ content, targetSoundCount, pbOffset = 0, pbScale = 1 }) {
    this.content = content;

    // get the minimum of target success count, and the length of each sound.
    let minimums = [
      targetSoundCount,
      ...Object.values(this.content).map((v) => v.length),
    ];
    this.desiredSuccessCount = Math.min(...minimums);
    this.nExposure = 0;
    this.nSuccess = 0;
    this.beta = 0.5;

    this.sounds = shuffle(Object.keys(this.content));
    this.currentSoundIndex = 0;

    this.wordBlackList = [];
    this.wordBlackList[this.sounds[0]] = [];
    this.wordBlackList[this.sounds[1]] = [];

    this.activityBarLength = 0;

    this.pbOffset = pbOffset;
    this.pbScale = pbScale;
  }
  onTraceAndSoundFinished() {
    this.nExposure += 1;
    this.activityBarLength = Math.min(
      1,
      this.activityBarLength + this.nExposure / (2 * this.desiredSuccessCount)
    );
  }

  get traceAndSoundComplete() {
    return this.currentSoundIndex === this.sounds.length;
  }

  get nextTraceAndSound() {
    let targetSound = this.sounds[this.currentSoundIndex++];
    let nonBlacklistWords = this.content[targetSound].filter(
      (w) => !this.wordBlackList[targetSound].includes(w)
    );

    let word;
    // if we have non-blacklisted words, choose one
    if (nonBlacklistWords.length > 0) {
      word = sample(nonBlacklistWords);
      this.wordBlackList[targetSound].push(word);
    } else {
      // everything is blacklisted - choose a blacklist word at random
      word = sample(this.wordBlackList[targetSound]);
      this.wordBlackList[targetSound] = [word];
    }

    return { targetSound, word };
  }

  get isComplete() {
    return this._progressBarLengthInternal >= 1;
  }

  onSoundClick2Complete(wasSuccess) {
    if (wasSuccess) {
      this.nSuccess += 1;
    }
    this.sounds = shuffle(Object.keys(this.content));
    this.currentSoundIndex = 0;
  }

  get nextSoundClick2() {
    return {
      targetSound: this.sounds[0],
      correctLetter: this.content[this.sounds[0]][0].charAt(0),
      confusionLetter: this.content[this.sounds[1]][0].charAt(0),
    };
  }

  get successBarLength() {
    return Math.min(1, this.nSuccess / this.desiredSuccessCount);
  }

  get _progressBarLengthInternal() {
    return Math.min(
      1,
      this.beta * this.activityBarLength +
        (1 - this.beta) * this.successBarLength
    );
  }

  get progressBarLength() {
    return this.pbOffset + this.pbScale * this._progressBarLengthInternal;
  }
}
