import React, { useContext, useEffect, useMemo, useState } from "react";
import once from "lodash/once";
import AudioSync from "../../util/SyncedAudio/audio/audio";
import styles from "./A1.module.scss";
import classnames from "classnames";
import { LINES, MODE, TIMINGS } from "./util";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";

const SONG_COMPLETE = 32;

export default ({ mode = MODE.lower, onComplete = () => {} }) => {
  const [activeLetter, setActiveLetter] = useState(null);
  const [isPlayoutFinished, setIsPlayoutFinished] = useState(false);
  const onCompleteDebounced = useMemo(() => once(onComplete), [onComplete]);
  const audioService = useContext(AudioContext);

  const Audio = useMemo(() => {
    const audio = new AudioSync("/content/audio/alphabet/ABCsong.mp3");

    audio.atTime(SONG_COMPLETE, () => {
      setTimeout(() => {
        // atTime callbacks are at least once, which means we don't want to invoke the onComplete method more
        // than once
        onCompleteDebounced();
      }, 1000);
    });

    // active letter timings
    TIMINGS.forEach((t, i) => {
      audio.atTime(t.startTime, () => {
        setActiveLetter(t.letter);
      });

      if (i === TIMINGS.length - 1) {
        audio.atTime(t.endTime, () => {
          setActiveLetter(null);
          setIsPlayoutFinished(true);
        });
      }
    });
    return audio;
  }, [onCompleteDebounced]);

  useEffect(() => {
    const fx = async () => {
      await wait(500);
      Audio.play();
    };
    fx();
    return () => Audio.pause();
  }, [audioService, Audio]);

  return (
    <div className={styles.alphabetContainer}>
      {LINES.map((line, lineIndex) => (
        <div className="d-flex flex-row">
          <div key={line} className={styles.rowContainer}>
            {line.split("").map((letter) => {
              return (
                <div
                  key={letter}
                  className={classnames(styles.letter, {
                    [`animated fastest fadeInUp`]: letter <= activeLetter,
                    invisible: !isPlayoutFinished && letter > activeLetter,
                    [styles.activeLetter]: letter === activeLetter,
                  })}
                >
                  {mode === MODE.lower ? letter : null}
                  {mode === MODE.upper && letter.toUpperCase()}
                  {mode === MODE.upperAndLower &&
                    `${letter.toUpperCase()}${letter}`}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
