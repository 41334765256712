import shuffle from "lodash/shuffle";
import sampleSize from "lodash/sampleSize";

export default class LessonA3Manager {
  constructor(parameters) {
    this.words = shuffle(parameters.content);
    this.targetSuccessCount = parameters.targetSuccessCount;

    this.conceptToExposureMap = {};
    this.words.forEach((word) => {
      this.conceptToExposureMap[word] = { success: 0, failure: 0 };
    });
  }

  getNextGame(blacklist) {
    // word list shouldn't contain anything from the blacklist.
    // if nothing is left, ignore the blacklist altogether.
    let wordList = this.words.filter((w) => !blacklist.includes(w));
    if (wordList.length === 0) {
      wordList = this.words;
    }

    const conceptsOrderedBySuccess = shuffle(wordList).sort((a, b) => {
      return (
        this.conceptToExposureMap[a].success -
        this.conceptToExposureMap[b].success
      );
    });
    const targetWord = conceptsOrderedBySuccess[0];

    // confusion word selection unaffected by blacklist
    let confusionWords = shuffle(this.words).filter((w) => w !== targetWord);
    confusionWords = sampleSize(confusionWords, 3);
    console.log("next game", { targetWord, confusionWords });

    return { targetWord, confusionWords };
  }

  markWordFailure(word) {
    this.conceptToExposureMap[word].failure++;
  }

  markWordSuccess(word) {
    this.conceptToExposureMap[word].success++;
  }

  getSuccessCriterion() {
    let wordsWithEnoughSuccess = Object.values(
      this.conceptToExposureMap
    ).filter((word) => word.success >= this.targetSuccessCount);
    return wordsWithEnoughSuccess.length / this.words.length;
  }

  getCurrentProgress() {
    return Math.min(this.getSuccessCriterion(), 1);
  }
}
