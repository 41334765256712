import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectAnswerWithAnalytics from "../../../../games/SelectAnswer/SelectAnswerWithAnalytics";
import LessonSManager from "./manager";

export default ({ parameters, onComplete, profileBoxRef }) => {
  const manager = useMemo(() => new LessonSManager(parameters), [parameters]);
  const [params, setParams] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);

  const initNewGame = useCallback(() => setParams(manager.getNext()), [
    manager,
  ]);

  const onRoundComplete = useCallback(() => {
    setCurrentProgress(manager.getCurrentProgress());
    if (manager.hasNext()) {
      initNewGame();
    } else {
      onComplete();
    }
  }, [initNewGame, manager, onComplete]);

  // the game component changes whenever the game params are updated.
  const gameComponent = useMemo(() => {
    return (
      <SelectAnswerWithAnalytics
        key={manager.getRound()}
        onComplete={onRoundComplete}
        {...params}
      />
    );
  }, [manager, onRoundComplete, params]);

  useEffect(initNewGame, []);

  if (!params) {
    return null;
  }

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{gameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
