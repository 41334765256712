import React, { useContext, useEffect } from "react";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";
import styles from "./WordReview.module.scss";

export default ({ flashcard, onComplete }) => {
  const audioService = useContext(AudioContext);
  useEffect(() => {
    const playAudioAndComplete = async () => {
      await audioService.playAudio(flashcard.audio);
      await wait(1500);
      onComplete();
    };
    playAudioAndComplete();
  }, []);

  return (
    <div className={`${styles.cardContainer} row`}>
      <div className="offset-1 col-3">
        <div
          className={`${styles.dictionaryWord} ${styles.mid}`}
          onClick={() => audioService.playAudio(flashcard.audio)}
        >
          <div className={styles.wordText}>{flashcard.meaning}</div>
        </div>
      </div>
      <div className="col-8">
        <div className={styles.cardReview}>
          <div
            className={`${styles.card} ${styles.fullSize} ${styles.highlight}`}
          >
            <div className={styles.cardImage}>
              <div>
                <img src={flashcard.image} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
