import shuffle from "lodash/shuffle";

export default class LessonCManager {
  constructor(parameters) {
    this.content = parameters.content;
    this.currentRound = 0;
    this.correctWordExposureMap = {};

    let consonants = this.getConsonants();

    //initialize all words with 0 exposure
    consonants.forEach((c) => {
      this.content[c]["words"].forEach((w) => {
        this.correctWordExposureMap[w] = 0;
      });
    });

    //calculate total rounds by summing all counts
    this.totalRounds = consonants
      .map((c) => this.content[c]["count"])
      .reduce((a, b) => a + b, 0);
  }

  incrementRound() {
    this.currentRound++;
  }

  gameIsComplete() {
    return this.currentRound === this.totalRounds;
  }

  getConsonants() {
    return shuffle(Object.keys(this.content));
  }

  getWordsForConsonant(consonant) {
    return shuffle(this.content[consonant].words);
  }

  //calculates the number of times a consonant has been exposed
  getTimesHasExposedForConsonant(consonant) {
    let totalExposures = 0;
    this.getWordsForConsonant(consonant).forEach((w) => {
      totalExposures += this.correctWordExposureMap[w];
    });
    return totalExposures;
  }

  addExposureToWord(word) {
    this.correctWordExposureMap[word]++;
  }

  //gets the next consonant to expose, by putting the least exposed consonants first.
  getNextConsonant() {
    let sorted = this.getConsonants().sort(
      (a, b) =>
        this.getTimesHasExposedForConsonant(a) -
        this.getTimesHasExposedForConsonant(b)
    );
    console.log("SORTED CONSONANTS", sorted);
    return sorted[0];
  }

  //chooses the least exposed word for a consonant.
  wordsForConsonantOrderedByExposure(consonant) {
    return this.getWordsForConsonant(consonant).sort(
      (a, b) => this.correctWordExposureMap[a] - this.correctWordExposureMap[b]
    );
  }

  getCurrentProgress() {
    console.log(
      "Current progress",
      this.currentRound,
      this.totalRounds,
      this.currentRound / this.totalRounds
    );
    return this.currentRound / this.totalRounds;
  }
}
