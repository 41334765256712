import React from "react";
import BasePrompt from "./BasePrompt";
import ImageAnswer from "../Answers/ImageAnswer/ImageAnswer";

const ImagePrompt = ({
  word = null,
  path = null,
  audio = null,
  delays = null,
  onPromptFinished,
  ...props
}) => {
  return (
    <BasePrompt
      audio={audio}
      delays={delays}
      onPromptFinished={onPromptFinished}
    >
      <ImageAnswer word={word} path={path} {...props} />
    </BasePrompt>
  );
};

export default ImagePrompt;
