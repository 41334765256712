import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenShapesProfile } from "../../../util/Profile/ProfileUtil";
import Strengthen from "../../../components/Lessons/Strengthen/Strengthen";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const profile = useGlenShapesProfile();
  const lessonResource = useResourceFromRegistry("shapesLessons");
  const lessonGroupResource = useResourceFromRegistry("shapesLessonGroups");
  return (
    <Strengthen
      profile={profile}
      lessonGroupResource={lessonGroupResource}
      lessonResource={lessonResource}
      type={types.GLEN_SHAPES}
    />
  );
};
