import styles from "./NumberAnswer.module.scss";
import classnames from "classnames";
import React, { useMemo } from "react";
import AnswerStatus from "../../../../constants/answerStatus";
import { useResourceFromRegistry } from "../../../../util/Resource/useResource";
import noop from "lodash/noop";
import Card from "../../../../components/Card/Card";

// /**
//  * @component for displaying a card with a number of shapes
//  * @param {AnswerStatus=} props.answerStatus is the status of the card
//  * @param {int} props.number                is the number of shapes to display
//  * @param {string=} props.color             is the color of the shapes (optional)
//  * @param {string=} props.shape             is the shape of the shapes (optional)
//  * @param {function} props.onSelect         is the callback for clicking on the answer card
//  * @return {JSX.Element}                    is a div containing the number card
//  */
export default ({
  answerStatus = null,
  number,
  color = null,
  shape = null,
  onSelect = noop,
}) => {
  const clickedCorrect = answerStatus === AnswerStatus.CORRECT;
  const clickedIncorrect = answerStatus === AnswerStatus.INCORRECT;
  const showReviewCircle = answerStatus === AnswerStatus.REVIEW_CORRECT;

  const shapeResource = useResourceFromRegistry("shapes");
  // if shape and/or color are empty, returns random shape
  const shapeObject = useMemo(() => shapeResource.getShape(shape, color), [
    shapeResource,
    shape,
    color,
  ]);
  const imagePath = useMemo(() => shapeObject.image, [shapeObject]);

  // create array of props.number of images
  const images = [];
  for (let i = 0; i < number; i++) {
    images.push(
      <img alt="icon" key={i} className={styles.shapeImage} src={imagePath} />
    );
  }

  const component = <div className={styles.imageContainer}>{images}</div>;

  return (
    <Card
      withCheck={clickedCorrect}
      withCross={clickedIncorrect}
      withCircle={showReviewCircle}
      outline={classnames({
        green: clickedCorrect,
        red: clickedIncorrect,
      })}
      animationClass={classnames({
        "animated bounce": clickedCorrect,
        "animated shake": clickedIncorrect,
      })}
      component={component}
      onClick={onSelect}
    />
  );
};
