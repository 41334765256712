import React from "react";
import styles from "./Avatar.module.scss";
import classnames from "classnames";
import noop from "lodash/noop";

export const AvatarWithText = ({
  text,
  deleteEnabled = false,
  onDeleteClick = noop,
  ...props
}) => (
  <div className={styles.pointerContainer}>
    {deleteEnabled && (
      <i
        className={`material-icons md-36 ${styles.clearIcon} animated heartBeat infinite`}
        onClick={onDeleteClick}
      >
        clear
      </i>
    )}
    <Avatar {...props} />
    <div className={styles.avatarText}>{text}</div>
  </div>
);

const Avatar = ({ avatarId, onClick, selected = false, size = "lg" }) => (
  <img
    className={classnames(styles.pointerContainer, {
      [styles.avatarLg]: size === "lg",
      [styles.avatarSm]: size === "sm",
      [styles.avatarMd]: size === "md",
      [styles.avatarXs]: size === "xs",
      [styles.selected]: selected,
      "animated bounce": selected,
    })}
    src={`${process.env.PUBLIC_URL}/images/avatars/avatar${avatarId}.png`}
    onClick={onClick}
    alt="Profile avatar"
  />
);

export default Avatar;
