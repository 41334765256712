import React, { useEffect, useRef, useMemo } from "react";
import Slider from "react-slick";
import classnames from "classnames";
import "./Shelf.scss";

export default ({
  items,
  autoscroll = true,
  infinite = true,
  centerMode = false,
  gotoSlideNum = null,
  booksPerShelf,
  noBackground = false,
}) => {
  const sliderRef = useRef(null);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: infinite,
      speed: 300,
      autoplaySpeed: 2000,
      adaptiveHeight: true,
      lazyLoad: false,
      autoplay: autoscroll,
      slidesToShow: booksPerShelf ? booksPerShelf : 3,
      centerMode: centerMode,
      slidesToScroll: 1,
      pauseOnHover: true,
      rows: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [autoscroll, booksPerShelf, centerMode, infinite]
  );

  useEffect(() => {
    if (gotoSlideNum) {
      const timer = setTimeout(() => {
        sliderRef.current.slickGoTo(gotoSlideNum);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [gotoSlideNum]);
  return (
    <div
      className={classnames("shelf-container pt-3 pl-4 pr-4", {
        "shelf-no-background": noBackground,
      })}
    >
      <Slider {...settings} ref={sliderRef}>
        {items.map((i) => (
          <div key={i}>{i}</div>
        ))}
      </Slider>
    </div>
  );
};
