import sampleSize from "lodash/sampleSize";
import shuffle from "lodash/shuffle";

export default class ShapesAssessManager {
  constructor(concepts, updateShapesAssessmentRecordFunc) {
    //currently not used
    this.updateShapesAssessmentRecordFunc = updateShapesAssessmentRecordFunc;
    this.concepts = concepts;
    this.requiredExposurePerConcept = 2;
    this.score = 0;
    this.outOf = 20;
    this.currentWord = null;
    this.exposureMap = {};
    this.concepts.forEach((c) => (this.exposureMap[c] = 0));
  }

  getOutOf() {
    return this.outOf;
  }

  getCurrentScore() {
    return this.score;
  }

  onRoundComplete(targetWord, wasCorrect) {
    this.exposureMap[targetWord]++;
    this.updateShapesAssessmentRecordFunc(targetWord, wasCorrect);
    if (wasCorrect) {
      this.score += 2;
    }
  }

  hasNext() {
    return this.concepts.some(
      (c) => this.exposureMap[c] < this.requiredExposurePerConcept
    );
  }

  getNext() {
    const targetWordOptions = shuffle(this.concepts).sort(
      (a, b) => this.exposureMap[a] - this.exposureMap[b]
    );
    const targetWord = targetWordOptions[0];
    this.currentWord = targetWord;

    return {
      targetWord,
      confusionWords: sampleSize(
        this.concepts.filter((c) => c !== targetWord),
        3
      ),
    };
  }
}
