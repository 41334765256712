import React, { useCallback, useEffect, useMemo, useState } from "react";
import VocabStrengthenManager from "./manager";
import wait from "../../../../util/wait";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import WordClickWithAnalytics from "../../../../games/WordClick/WordClickWithAnalytics";
import ImageClickWithAnalytics from "../../../../games/ImageClick/ImageClickWithAnalytics";

const RepeatedImageClickGame = ({ manager, updateProgress, onComplete }) => {
  const [gameState, setGameState] = useState(null);

  const initNewGame = useCallback(
    (trialNum) => {
      const { targetWord, confusionWords } = manager.getNext();
      setGameState({ trialNum, targetWord, confusionWords });
    },
    [manager]
  );

  const onRoundComplete = useCallback(
    (wasCorrect) => {
      if (wasCorrect) {
        manager.addSuccessForWord(gameState.targetWord);
      }
      updateProgress().then(() => {
        if (manager.hasNext()) {
          initNewGame(gameState.trialNum + 1);
        } else {
          onComplete();
        }
      });
    },
    [updateProgress, manager, gameState, initNewGame, onComplete]
  );
  useEffect(() => initNewGame(1), [initNewGame]);
  if (!gameState) {
    return null;
  }
  return (
    <ImageClickWithAnalytics
      key={gameState.trialNum}
      shouldShowReview={true}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      onComplete={onRoundComplete}
    />
  );
};

const RepeatedWordClickGame = ({ manager, updateProgress, onComplete }) => {
  const [gameState, setGameState] = useState(null);

  const initNewGame = useCallback(
    (trialNum) => {
      const { targetWord, confusionWords } = manager.getNext();
      setGameState({ trialNum, targetWord, confusionWords });
    },
    [manager]
  );

  const onRoundComplete = useCallback(
    (wasCorrect) => {
      if (wasCorrect) {
        manager.addSuccessForWord(gameState.targetWord);
      }
      updateProgress().then(() => {
        if (manager.hasNext()) {
          initNewGame(gameState.trialNum + 1);
        } else {
          onComplete();
        }
      });
    },
    [updateProgress, manager, gameState, initNewGame, onComplete]
  );
  useEffect(() => initNewGame(1), [initNewGame]);
  if (!gameState) {
    return null;
  }
  return (
    <WordClickWithAnalytics
      key={gameState.trialNum}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      shouldShowReview={true}
      onComplete={onRoundComplete}
    />
  );
};

export default ({
  concepts,
  recentlyFailedConcepts,
  groups,
  textIndicator,
  onStart,
  onComplete,
}) => {
  const manager = useMemo(
    () => new VocabStrengthenManager(concepts, recentlyFailedConcepts, groups),
    []
  );
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => {
    setCurrentProgress(manager.getCurrentProgress());
    return wait(200);
  };

  useEffect(onStart, []);

  let GameComponent;
  if (textIndicator) {
    GameComponent = (
      <RepeatedWordClickGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={onComplete}
      />
    );
  } else {
    GameComponent = (
      <RepeatedImageClickGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={onComplete}
      />
    );
  }

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
