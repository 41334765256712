import React from "react";
import { activity } from "../../../../../util/Analytics/events";
import noop from "lodash/noop";
import PhonicStrengthen2 from "./PhonicStrengthen2";
import { useTimedAnalytics } from "../../../util/hooks";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.PHONIC_STRENGTHEN,
    wasManual: props.wasManual,
    lessonGroupId: props.lessonGroupId,
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  const onCompleteOverride = () => {
    completeEvent({ ...eventData });
    onComplete();
  };
  return (
    <PhonicStrengthen2
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
      {...props}
    />
  );
};
