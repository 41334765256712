import shuffle from "lodash/shuffle";

export default class LessonBManager {
  constructor(parameters) {
    this.classes = parameters.classes;
    this.playMatch = parameters.playMatch;
    this.glenMatchExposure = 0;
    this.classMap = {};

    let classNumber = 1;
    this.classes.forEach((classGroup) => {
      var map = {};
      shuffle(classGroup).forEach((w) => {
        map[w] = {
          exposure: 0,
          success: 0,
          failure: 0,
        };
      });
      this.classMap[classNumber++] = map;
    });

    //map of class number to target success count
    this.targetSuccessCount = {
      1: 1,
      2: 1,
    };
    //map of class number to target exposure count
    this.targetExposureCount = {
      1: 1,
      2: 1,
    };
  }

  incrementGlenMatchExposure() {
    this.glenMatchExposure++;
  }

  shouldPlayMatch() {
    return this.playMatch;
  }

  getCurrentProgress() {
    return (
      0.05 * this.glenMatchExposure +
      0.15 * this.getSuccessCriterion(2) +
      0.4 * this.getExposureCriterion(1) +
      0.4 * this.getExposureCriterion(2)
    );
  }

  getWordsOrderedByExposure(classNum) {
    const classMap = this.classMap[classNum];
    const classWords = this.getWordsForClass(classNum);

    classWords.sort(function (a, b) {
      return classMap[a]["exposure"] - classMap[b]["exposure"];
    });

    return classWords;
  }

  getLeastExposedFromClass(classNum, word) {
    //get class3 words that contain the least exposed class1 word. for example, "walk" (class1) => "girl_walks", "boy_walks", etc.
    //order the result by exposure and take the least exposed.
    let classWords = this.getWordsForClass(classNum).filter((w) =>
      w.includes(word)
    );
    classWords.sort(function (a, b) {
      return classWords[a]["exposure"] - classWords[b]["exposure"];
    });
    return classWords[0];
  }

  getTargetSuccessCount(classNum) {
    return this.targetSuccessCount[classNum];
  }

  getTargetExposureCount(classNum) {
    return this.targetExposureCount[classNum];
  }

  getExposureCriterion(classNum) {
    //exposure_criterion (class) = {exposed_concepts (class)}/{number of concepts in class} (only need to maintain this for Lesson3.Class1 for this lesson)
    return this.getExposedConcepts(classNum) / this.getTotalConcepts(classNum);
  }

  getSuccessCriterion(classNum) {
    //success_criterion (class) = {number of concepts with success_count  >= target_success_count (class)}/{number of concepts in class} (only need to maintain this for Lesson3.Class2 for this lesson)
    const target = this.getTargetSuccessCount(classNum);
    const classMap = this.classMap[classNum];
    return (
      this.getWordsForClass(classNum).filter(
        (key) => classMap[key]["success"] >= target
      ).length / this.getTotalConcepts(classNum)
    );
  }

  incrementSuccess(classNum, concept) {
    this.classMap[classNum][concept]["success"]++;
  }

  incrementExposure(classNum, concept) {
    this.classMap[classNum][concept]["exposure"]++;
  }

  incrementFailure(classNum, concept) {
    this.classMap[classNum][concept]["failure"]++;
  }

  getTotalConcepts(classNum) {
    return this.getWordsForClass(classNum).length;
  }

  getExposedConcepts(classNum) {
    const classMap = this.classMap[classNum];
    return this.getWordsForClass(classNum)
      .map((key) => classMap[key]["exposure"])
      .reduce((a, b) => a + b, 0);
  }

  getWordsWithoutEnoughSuccess(classNum) {
    //Make a list of concepts in Lesson3.Class2 with success_count < target_success_count (Lesson3.Class2)
    //order the list by success count.
    const target = this.getTargetSuccessCount(classNum);
    const classMap = this.classMap[classNum];

    const wordsWithoutEnoughSuccess = this.getWordsForClass(classNum).filter(
      (key) => classMap[key]["success"] < target
    );
    return shuffle(wordsWithoutEnoughSuccess);
  }

  getWordsForClass(classNum) {
    return Object.keys(this.classMap[classNum]);
  }
}
