import noop from "lodash/noop";
import GlenBooks from "./GlenBooks";
import React, { useCallback, useMemo } from "react";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";

export default ({ activity, onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = useMemo(() => ({ activity, contentId: props.bookId }), [
    activity,
    props.bookId,
  ]);
  const onAnalyticComplete = useCallback(() => {
    completeEvent(eventData);
  }, [completeEvent, eventData]);
  const onStartOverride = useCallback(() => {
    startEvent(eventData);
    onStart();
  }, [eventData, onStart, startEvent]);
  return (
    <GlenBooks
      {...props}
      onStart={onStartOverride}
      onAnalyticComplete={onAnalyticComplete}
      onComplete={onComplete}
    />
  );
};
