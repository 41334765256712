import { useContext, useMemo } from "react";
import ResourceContext from "./context";

export const useResourceFromRegistry = (name) => {
  const resourceRegistry = useContext(ResourceContext);
  return useMemo(() => resourceRegistry[name], [name, resourceRegistry]);
};
//
// export const useResource = (resourcePath) => {
//     const [loaded, setIsLoaded] = useState(false);
//     const resource = useResourceFromRegistry(resourcePath);
//     useEffect(() => {
//         const getContent = async () => {
//             // if not yet loaded, populate and set
//             //otherwise, use the existing data
//             if (!resource.isPopulated()) {
//                 resource.populate().then(() => {
//                     setIsLoaded(true);
//                 })
//             } else {
//                 setIsLoaded(true);
//             }
//         };
//         getContent();
//     }, []);
//     return [loaded, resource];
// };
