import React from "react";
import range from "lodash/range";
import Avatar from "../../../../components/Avatar/Avatar";

const avatarIds = range(1, 12);

export default ({ selectedAvatar, onSelect }) => (
  <div className={"row"}>
    {avatarIds.map((id) => {
      return (
        <div key={id} className="col-2 mb-2">
          <Avatar
            size="md"
            selected={selectedAvatar === id}
            avatarId={id}
            onClick={() => onSelect(id)}
          />
        </div>
      );
    })}
  </div>
);
