import range from "lodash/range";
import sampleSize from "lodash/sampleSize";
import sample from "lodash/sample";
import shuffle from "lodash/shuffle";
import numberToWord from "../../../../constants/numberToWord";
import GCard from "../../../Card/Card";
import React from "react";
import { ShapesResource } from "../../../../util/Resource/resource";
import NumberAnswer from "../../../../games/MultipleChoice/Answers/NumberAnswer/NumberAnswer";
import { GlenMatchParameters } from "../../../../games/GlenMatch/GlenMatch";

export interface Inputs {
  content: number[];
  targetSuccessCount: number;
  imageClickDiversity: boolean;
  glenMatchVariant: number;
  glenMatchCount: number;
}

export interface GlenMatchGame {
  parameters: any;
}

export default class LessonN0Manager {
  inputs: Inputs;
  private assessmentCount: number;
  private glenMatchInstances: number;
  private imageClickInstances: number;
  private imageClickTargetNumberBlacklist: number[];
  private shapesResource: ShapesResource;

  private successMap: { [number: number]: number } = {};
  private failureMap: { [number: number]: number } = {};
  private currentTargetNumber: number = -1;

  constructor(inputs: Inputs, shapesResource: ShapesResource) {
    this.inputs = inputs;
    this.shapesResource = shapesResource;

    const numberRange = range(
      this.inputs.content[0],
      this.inputs.content[1] + 1
    );
    numberRange.forEach((number) => (this.successMap[number] = 0));
    numberRange.forEach((number) => (this.failureMap[number] = 0));

    this.assessmentCount = 0;
    this.glenMatchInstances = 0;
    this.imageClickInstances = 0;
    this.imageClickTargetNumberBlacklist = [];
  }

  private getRandomNumbers(): number[] {
    const [number1, number2] = this.inputs.content;
    return sampleSize(range(number1, number2 + 1), 4);
  }

  getGlenMatchProps(): GlenMatchParameters {
    this.glenMatchInstances++;
    return [this.getGlenMatch1, this.getGlenMatch2, this.getGlenMatch3][
      this.inputs.glenMatchVariant - 1
    ].bind(this)();
  }

  private getGlenMatch1(): GlenMatchParameters {
    // GLEN Match with numbers selected from “content,” showing only the numbers
    const numbers = this.getRandomNumbers();
    const options = numbers.map((num) => ({
      matchKey: num,
      // @ts-ignore
      audio: `${process.env.PUBLIC_URL}/content/audio/numbers/${numberToWord[num]}.mp3`,
      component: <GCard bg="white" image={null} text={num} />,
    }));
    return {
      components: [...options, ...options],
    };
  }

  getGlenMatch2(): GlenMatchParameters {
    // GLEN Match with numbers matched to numbers_card (“shape” and “color” chosen randomly,
    // but are the same for all the numbers chosen)
    const numbers = this.getRandomNumbers();
    const randomShape = sample(this.shapesResource.getAllShapes());
    const randomColor = sample(
      this.shapesResource.getAllColorsForShape(randomShape)
    );
    return {
      components: numbers
        .map((num) => {
          return [
            {
              matchKey: num,
              // @ts-ignore
              audio: `${process.env.PUBLIC_URL}/content/audio/numbers/${numberToWord[num]}.mp3`,
              component: <GCard bg="white" image={null} text={num} />,
            },
            {
              matchKey: num,
              // @ts-ignore
              audio: `${process.env.PUBLIC_URL}/content/audio/numbers/${numberToWord[num]}.mp3`,
              component: (
                <NumberAnswer
                  number={num}
                  shape={randomShape}
                  color={randomColor}
                />
              ),
            },
          ];
        })
        .flat(),
    };
  }

  getGlenMatch3(): GlenMatchParameters {
    // GLEN Match with numbers matched to numbers_card (“shape” and “color” chosen randomly for each number,
    // and hence may be different for different numbers)
    const numbers = this.getRandomNumbers();
    return {
      components: numbers
        .map((num) => {
          return [
            {
              matchKey: num,
              // @ts-ignore
              audio: `${process.env.PUBLIC_URL}/content/audio/numbers/${numberToWord[num]}.mp3`,
              component: <GCard bg="white" image={null} text={num} />,
            },
            {
              matchKey: num,
              // @ts-ignore
              audio: `${process.env.PUBLIC_URL}/content/audio/numbers/${numberToWord[num]}.mp3`,
              component: <NumberAnswer number={num} />,
            },
          ];
        })
        .flat(),
    };
  }

  markImageClickFeedback(wasSuccess: boolean) {
    if (wasSuccess) {
      this.successMap[this.currentTargetNumber]++;
    } else {
      this.failureMap[this.currentTargetNumber]++;
    }
  }

  getImageClickProps(trialNum: number) {
    if (trialNum === 4) {
      this.imageClickTargetNumberBlacklist = [];
    }
    // Choose target number as one with smallest value of success_count
    // (choose randomly from non-empty bin with smallest success_count)
    let targetNumber = shuffle(
      Object.keys(this.successMap).map((key) => parseInt(key, 10))
    )
      .filter((key) => !this.imageClickTargetNumberBlacklist.includes(key))
      .sort((a, b) => this.successMap[a] - this.successMap[b])[0];

    this.currentTargetNumber = targetNumber;

    // confusion numbers are chosen at random from the remaining numbers)
    let confusionNumbers = sampleSize(
      Object.keys(this.successMap)
        .map((number) => parseInt(number, 10))
        .filter((number) => number !== targetNumber),
      3
    );

    // Add target number to blacklist (to ensure no repetition within a sequence)
    this.imageClickTargetNumberBlacklist.push(targetNumber);

    // If image_click_diversity = 0,
    //     Generate at random “color” and “shape” to be used for all selected numbers;
    // Endif
    // If image_click_diversity = 1,
    //     Generate at random “color” and “shape” independently for the selected numbers
    // endif
    let colorShapeProps = {};
    if (!this.inputs.imageClickDiversity) {
      let randomShape = sample(this.shapesResource.getAllShapes());
      let randomColor = sample(
        this.shapesResource.getAllColorsForShape(randomShape)
      );
      colorShapeProps = {
        color: randomColor,
        shape: randomShape,
      };
    }

    return {
      layout: {
        type: "grid",
        props: {},
      },
      prompt: {
        type: "text",
        props: {
          text: targetNumber,
          audio: `${process.env.PUBLIC_URL}/content/audio/numbers/${numberToWord[targetNumber]}.mp3`,
        },
      },
      answers: [
        ...confusionNumbers.map((num) => ({
          type: "number",
          props: { number: num, ...colorShapeProps },
          correct: false,
        })),
        {
          type: "number",
          props: { number: targetNumber, ...colorShapeProps },
          correct: true,
        },
      ],
      showCorrectAnswer: true,
      review: {
        type: "number",
        props: {
          number: targetNumber,
        },
      },
    };
  }

  hasNext(): boolean {
    return this.progressBarLength < 1;
  }

  private get successCriterion(): number {
    //success_criterion = {number of concepts with success_count  >= target_success_count}/{number of concepts}
    const keys = Object.keys(this.successMap).map((number) =>
      parseInt(number, 10)
    );
    const successCount = keys.filter(
      (key) => this.successMap[key] >= this.inputs.targetSuccessCount
    ).length;
    return successCount / keys.length;
  }

  get progressBarLength(): number {
    if (this.inputs.glenMatchCount === 0) {
      return Math.min(this.successCriterion, 1);
    }

    if (this.inputs.targetSuccessCount === 0) {
      return this.glenMatchInstances / this.inputs.glenMatchCount;
    } else {
      return (
        (0.3 * this.glenMatchInstances) / this.inputs.glenMatchCount +
        0.7 * this.successCriterion
      );
    }
  }

  get isComplete() {
    return this.progressBarLength >= 1;
  }
}
