import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenShapesProfile } from "../../../util/Profile/ProfileUtil";
import Assess from "../../../components/Lessons/Assess/Assess";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const profile = useGlenShapesProfile();
  const lessonResource = useResourceFromRegistry("shapesLessons");
  const lessonGroupResource = useResourceFromRegistry("shapesLessonGroups");

  return (
    <Assess
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      profile={profile}
      type={types.GLEN_SHAPES}
    />
  );
};
