import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withFirebase } from "../../util/Firebase";
import * as ROUTES from "../../constants/routes";
import "./index.scss";
import { compose } from "recompose";
import { connect } from "react-redux";
import { doLoginAuthUser } from "../../actions/session";

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null,
  isOldEnough: false,
  agreedToTerms: false,
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const openUrl = (url) => {
  if (window.cordova) {
    window.cordova.InAppBrowser.open(url, "_blank", "location=yes");
  } else {
    window.open(url);
  }
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = async (event) => {
    event.preventDefault();

    const {
      username,
      email,
      passwordOne,
      agreedToTerms,
      isOldEnough,
    } = this.state;

    if (!agreedToTerms || !isOldEnough) {
      this.setState({
        error: {
          message:
            "Please verify your age and agree to our terms before continuing.",
        },
      });
      return false;
    }

    // create a Firebase auth account
    let authUser;
    try {
      authUser = await this.props.firebase.doCreateUserWithEmailAndPassword(
        email,
        passwordOne
      );
    } catch (error) {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      this.setState({ error });
      return;
    }

    // create a deployment for the account and add an admin account record
    const deployment = await this.props.firebase.createDeployment(
      `${username}`
    );
    const adminAccount = await this.props.firebase.createAdminAccount(
      authUser.user.uid,
      username,
      email,
      {
        [deployment.id]: "admin",
      }
    );
    this.setState({ ...INITIAL_STATE });
    this.props.dispatch(doLoginAuthUser(authUser.user, adminAccount));
    this.props.history.push(ROUTES.LANDING);
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <div className="container form-signup w-75">
        <h1 className="h3 mb-3 font-weight-normal">Sign up</h1>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <input
              name="username"
              className="form-control"
              value={username}
              onChange={this.onChange}
              type="text"
              placeholder="First Name"
            />
          </div>
          <div className="form-group">
            <input
              name="email"
              className="form-control"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </div>
          <div className="form-group">
            <input
              name="passwordOne"
              className="form-control"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="form-group">
            <input
              name="passwordTwo"
              className="form-control"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
            />
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              onChange={(e) => this.setState({ isOldEnough: e.target.value })}
              className="form-check-input"
              id="certify1"
            />
            <label className="form-check-label" htmlFor="certify1">
              I certify that I am 13 years of age or older.
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              onChange={(e) => this.setState({ agreedToTerms: e.target.value })}
              className="form-check-input"
              id="certify2"
            />
            <label className="form-check-label" htmlFor="certify2">
              I have read and agree to the
              <button
                className="btn btn-xs btn-link"
                style={{ verticalAlign: "top", lineHeight: 0.7 }}
                onClick={() => openUrl("https://glenworld.org/tos/")}
              >
                Terms of Service
              </button>
              and
              <button
                className="btn btn-link"
                style={{ verticalAlign: "top", lineHeight: 0.7 }}
                onClick={() => openUrl("https://glenworld.org/privacy-policy/")}
              >
                Privacy Policy
              </button>
            </label>
          </div>
          <button
            className="btn btn-lg btn-primary btn-block mt-2"
            disabled={isInvalid}
            type="submit"
          >
            Sign Up
          </button>
          <div className="h6 text-muted mt-3">
            {error && <p>{error.message}</p>}
          </div>
        </form>
      </div>
    );
  }
}

const SignUpForm = compose(withRouter, withFirebase, connect())(SignUpFormBase);
export default SignUpForm;

export { SignUpForm };
