import sample from "lodash/sample";
import sampleSize from "lodash/sampleSize";

export default class LessonC1Manager {
  constructor(parameters) {
    this.currentRound = 0;
    this.content = parameters.content;
    this.soundExposureMap = {};

    //initialize all words with 0 exposure
    Object.keys(this.content).forEach((w) => {
      this.soundExposureMap[w] = 0;
    });

    //calculate total rounds by summing counting the total number of consonants times count
    this.totalRounds = Object.keys(this.content)
      .map((c) => this.content[c]["count"])
      .reduce((a, b) => a + b, 0);
  }

  incrementRound() {
    this.currentRound++;
  }

  gameIsComplete() {
    return this.currentRound === this.totalRounds;
  }

  addExposureToWord(word) {
    this.soundExposureMap[word]++;
  }

  getNextGame() {
    const targetSound = this.getNextConsonant();
    const confusionSounds = sampleSize(
      Object.keys(this.content).filter((c) => c !== targetSound),
      3
    );

    const correctWord = sample(this.content[targetSound].words);
    const confusionWords = confusionSounds.map((s) =>
      sample(this.content[s].words)
    );

    return { targetSound, correctWord, confusionWords };
  }

  //gets the next consonant to expose, by putting the least exposed consonants first.
  getNextConsonant() {
    let sorted = Object.keys(this.soundExposureMap).sort(
      (a, b) => this.soundExposureMap[a] > this.soundExposureMap[b]
    );
    console.log("SORTED CONSONANTS", sorted);
    return sorted[0];
  }

  getCurrentProgress() {
    console.log(
      "Current progress",
      this.currentRound,
      this.totalRounds,
      this.currentRound / this.totalRounds
    );
    return this.currentRound / this.totalRounds;
  }
}
