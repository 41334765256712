import Turn from "../../games/GlenBooks/Turner";
import styles from "../../games/GlenBooks/GlenBooks.module.scss";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import AudioContext from "../../util/Audio/context";

export type PageProps = {
  onComplete: () => void;
};

const TurnWrapper = ({
  children,
  pageNum,
  totalPages,
  turnLeft,
  turnRight,
}: {
  children: object;
  pageNum: number;
  totalPages: number;
  turnLeft: () => void;
  turnRight: () => void;
}) => {
  return (
    <div className={styles.bookContainer}>
      <div className={styles.bookHolder}>
        <div className={styles.book}>
          {children}
          {pageNum >= 0 && pageNum <= totalPages - 1 && (
            <div className={styles.uiPrevious} onClick={turnLeft}>
              <div className={`material-icons md-60`}>keyboard_arrow_left</div>
            </div>
          )}
          {pageNum <= totalPages - 1 && (
            <div className={styles.uiNext} onClick={turnRight}>
              <div className={`material-icons md-60`}>keyboard_arrow_right</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ({
  cover,
  pages,
  credits,
  onComplete,
}: {
  cover: object;
  pages: React.FunctionComponent<PageProps>[];
  credits?: object;
  onComplete: () => void;
}) => {
  const audioService = useContext(AudioContext);
  const totalPages = pages.length + 2;
  const [pageNumber, setPageNumber] = useState<number>(0);
  const inputRef = useRef(null);
  // @ts-ignore
  const peelRight = useCallback(() => inputRef.current.peelBottomRight(), []);
  // @ts-ignore
  const turnRight = useCallback(() => inputRef.current.rightTurn(), []);
  // @ts-ignore
  const turnLeft = useCallback(() => inputRef.current.leftTurn(), []);

  const options = useMemo(
    () => ({
      gradients: true,
      acceleration: false,
      autoCenter: true,
      display: "single",
      when: {
        turning: function (e: any, page: any, view: number[]) {
          // @ts-ignore
          audioService.pauseCurrentlyPlaying();
          if (view[0] === totalPages) {
            window.setTimeout(function () {
              onComplete();
            }, 100);
          } else {
            // @ts-ignore
            setPageNumber(view[0]);
          }
        },
      },
    }),
    [audioService, onComplete, totalPages]
  );

  return (
    <TurnWrapper
      pageNum={pageNumber}
      totalPages={totalPages}
      turnLeft={turnLeft}
      turnRight={turnRight}
    >
      <Turn ref={inputRef} options={options} className={styles.book}>
        <div className={(styles.page, "hard")}>
          <div className={styles.bookCover}>{cover}</div>
        </div>
        {pages.map((page, i) => (
          <div key={i} className={styles.pageInner}>
            <div className={styles.leftdepth}></div>
            {i === pageNumber - 2 &&
              React.createElement(page, { onComplete: peelRight })}
            <div className={styles.depth}></div>
          </div>
        ))}
        <div className={(styles.page, "hard")}>
          <div className={(styles.bookCover, styles.credits)}>{credits}</div>
        </div>
      </Turn>
    </TurnWrapper>
  );
};
