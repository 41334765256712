import React from "react";
import {
  AuthedAndProfiledTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useHistory, useParams } from "react-router-dom";
import Certificate from "./Certificate";
import styles from "./WallOfFame.module.scss";
import { Modal, ModalBody } from "reactstrap";
import useTimelineModal from "../../../../util/Timeline/useTimelineModal";
import { useWallOfFameEvents } from "../../../../util/Timeline/useTimelineEvents";
import useProfile from "../../../../util/Profile/useProfile";

export default () => {
  const { deploymentId } = useParams();
  const profileUser = useProfile();
  const wallOfFameEvents = useWallOfFameEvents();
  const history = useHistory();
  const {
    selectedCertIndex,
    modalOpen,
    openModal,
    closeModal,
  } = useTimelineModal(wallOfFameEvents);

  const goBack = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/userDashboard`);

  const closeBtn = (
    <i
      className="material-icons md-48 text-right p-2 float-right"
      style={{ cursor: "pointer" }}
      onClick={closeModal}
    >
      close
    </i>
  );

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>
        <Modal
          isOpen={modalOpen}
          toggle={closeModal}
          style={{ maxHeight: "75vh", maxWidth: "60vw" }}
        >
          {closeBtn}
          <ModalBody>
            {selectedCertIndex !== null && (
              <Certificate
                learnerName={profileUser.username}
                dateString={new Date(
                  wallOfFameEvents[selectedCertIndex].time
                ).toLocaleDateString("en-US")}
                meta={wallOfFameEvents[selectedCertIndex].meta}
              />
            )}
          </ModalBody>
        </Modal>
        <div className="row justify-content-center">
          {wallOfFameEvents.map((event, i) => (
            <div className="col-3" key={event.id || i}>
              <div
                className={styles.certificateWrapper}
                onClick={() => openModal(event.id)}
              >
                <div className={styles.frame}>
                  <Certificate
                    learnerName={profileUser.username}
                    dateString={new Date(event.time).toLocaleDateString(
                      "en-US"
                    )}
                    meta={event.meta}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} right={null} />
    </GameWrapper>
  );
};
