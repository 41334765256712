import React from "react";
import {
  AuthedAndProfiledTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useHistory, useParams } from "react-router-dom";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import styles from "./UserDashboard.module.scss";
import { useGlenLearnProfile } from "../../../util/Profile/ProfileUtil";
import CategoryIcon from "../../../components/CategoryIcon/CategoryIcon";
import CertificateIcon from "./certificate-icon.png";
import TimelineIcon from "./timeline-icon.png";

export default () => {
  const history = useHistory();
  const { deploymentId } = useParams();
  const lessonResource = useResourceFromRegistry("lessons");

  const glenLearnProfile = useGlenLearnProfile();

  const lastMasteredLesson = glenLearnProfile.getLastMasteredLesson();

  const currentGroupId = lessonResource.getLesson(
    lastMasteredLesson === 0 ? 1 : lastMasteredLesson
  ).groupId;
  const goBack = () => history.push(`/deployment/${deploymentId}/glenLearn`);
  const gotoCollectedWords = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/collectedWords`);

  const gotoAssess = () => {
    if (currentGroupId === 0) {
      return;
    }
    const randomCompletedGroupId = Math.floor(Math.random() * currentGroupId);
    const location = {
      pathname: `/deployment/${deploymentId}/glenLearn/assess/${randomCompletedGroupId}`,
      state: {
        wasManual: true,
        // redirect back to user dashboard when complete
        onCompleteRedirect: `/deployment/${deploymentId}/glenLearn/userDashboard`,
      },
    };
    history.push(location);
  };

  const gotoPractice = () => {
    if (currentGroupId === 0) {
      return;
    }
    const randomCompletedGroupId = Math.floor(Math.random() * currentGroupId);
    const location = {
      pathname: `/deployment/${deploymentId}/glenLearn/strengthen/${randomCompletedGroupId}`,
      state: {
        wasManual: true,
        // redirect back to user dashboard when complete
        onCompleteRedirect: `/deployment/${deploymentId}/glenLearn/userDashboard`,
      },
    };
    history.push(location);
  };

  const gotoWallOfFame = () => {
    history.push(
      `/deployment/${deploymentId}/glenLearn/userDashboard/wallOfFame`
    );
  };
  const gotoTimeline = () => {
    history.push(
      `/deployment/${deploymentId}/glenLearn/userDashboard/timeline`
    );
  };

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>
        <div className={styles.dashboardWrapper}>
          <div>
            <div className="row text-white">
              <div className="col-6">
                <span>Wall of Fame</span>
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.selectionWrapper} ${styles.wallOfFame}`}
                  onClick={gotoWallOfFame}
                >
                  <div className="p-2">
                    <img src={CertificateIcon} />
                  </div>
                  <div className="p-2">
                    <img src={CertificateIcon} />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <span>Learning Log</span>
                <div
                  className={`row ${styles.selectionWrapper} ${styles.timeline}`}
                  onClick={gotoTimeline}
                >
                  <img src={TimelineIcon} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`row text-center`}>
              <div className="col-4">
                <CategoryIcon
                  imageSrc={process.env.PUBLIC_URL + "/images/assessment.png"}
                  imageText={"Quiz"}
                  onClick={gotoAssess}
                />
              </div>
              <div className="col-4">
                <CategoryIcon
                  imageSrc={process.env.PUBLIC_URL + "/images/strengthen.png"}
                  imageText={"Practice"}
                  onClick={gotoPractice}
                />
              </div>
              <div className="col-4">
                <CategoryIcon
                  imageSrc={process.env.PUBLIC_URL + "/images/bag.png"}
                  imageText={"Words"}
                  onClick={gotoCollectedWords}
                />
              </div>
            </div>
          </div>
        </div>
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} right={null} />
    </GameWrapper>
  );
};
