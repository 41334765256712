import {
  getConceptsBetweenLessons,
  getGroupsBetween,
} from "../../../../util/Lesson/util";

export const getVocabLessonGroupInfo = (lessonResource, lessonGroup) => {
  const textIndicator = lessonGroup.assessment.text_indicator;
  const concepts = getConceptsBetweenLessons(
    lessonResource,
    lessonGroup.start,
    lessonGroup.end
  );
  const groups = getGroupsBetween(
    lessonResource,
    lessonGroup.start,
    lessonGroup.end
  );
  return {
    concepts,
    groups,
    textIndicator,
  };
};
