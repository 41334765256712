import React from "react";
import styles from "./GridLayout.module.scss";

const GridLayout = ({ prompt, answers }) => {
  const leftAnswers = answers.slice(0, Math.round(answers.length / 2));
  const rightAnswers = answers.slice(
    Math.round(answers.length / 2),
    answers.length
  );
  const leftAnswerCards = leftAnswers.map((answer, idx) => (
    <div key={"left-" + idx} className={styles.answerCard}>
      {answer}
    </div>
  ));
  const rightAnswerCards = rightAnswers.map((answer, idx) => (
    <div key={"right-" + idx} className={styles.answerCard}>
      {answer}
    </div>
  ));
  return (
    <div className={styles.cardContainer}>
      <div className={styles.answerCol}>{leftAnswerCards}</div>
      <div className={styles.promptCol}>
        {React.cloneElement(prompt, { size: "sm" })}
      </div>
      <div className={styles.answerCol}>{rightAnswerCards}</div>
    </div>
  );
};

export default GridLayout;
