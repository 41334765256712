import React from "react";
import LessonGroupCard from "../../../components/OptionCard/OptionCard.js";
import { useHistory, useParams } from "react-router-dom";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  BackButton,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";

/**
 * SelectAssessment displays the different types of assessments
 * that are available to the student.
 */
export default () => {
  const { deploymentId } = useParams();
  const history = useHistory();
  const gotoKSEPAssessment = () =>
    history.push(`/deployment/${deploymentId}/glenLearn/assessment/ksep`);
  const gotoComprehensiveAssessment = () =>
    history.push(
      `/deployment/${deploymentId}/glenLearn/assessment/comprehensive`
    );
  const goBack = () => history.push(`/deployment/${deploymentId}/glenLearn`);

  return (
    <div>
      <GameWrapper>
        <AuthedAndProfiledTopBar />

        <GameContainer>
          <div className="row h-100">
            <LessonGroupCard
              description={"Words"}
              isActive={true}
              image={
                process.env.PUBLIC_URL +
                "/images/selection_screen/comprehensive_assessment.png"
              }
              onClick={gotoComprehensiveAssessment}
              withSpiral={true}
            />

            <LessonGroupCard
              description={"Ready for school?"}
              isActive={true}
              image={
                process.env.PUBLIC_URL + "/images/selection_screen/ksep.png"
              }
              onClick={gotoKSEPAssessment}
              withSpiral={true}
            />
          </div>
        </GameContainer>
        <BottomBar left={<BackButton onClick={goBack} />} right={null} />
      </GameWrapper>
    </div>
  );
};
