import noop from "lodash/noop";
import React, { useEffect, useCallback } from "react";
import { activity } from "../../util/Analytics/events";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";
import A1 from "./A1";
import A2 from "./A2";
import A3 from "./A3";
import A4 from "./A4";
import P1 from "./P1";

export const VARIANT = {
  A1: A1,
  A2: A2,
  A3: A3,
  A4: A4,
  P1: P1,
};

export default ({
  onComplete = noop,
  variant,
  withIntro = true,
  findLetter = null,
}) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.ALPHABET_SYNC,
    variant,
  };

  // variants A{2,4,5} accept a "findLetter" prop.
  if (findLetter) {
    eventData.findLetter = findLetter;
  }

  const onCompleteOverride = useCallback(() => {
    completeEvent(eventData);
    onComplete();
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => startEvent(eventData), []);

  return React.createElement(VARIANT[variant], {
    onComplete: onCompleteOverride,
    findLetter,
    withIntro,
  });
};
