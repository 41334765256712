import sampleSize from "lodash/sampleSize";
import { activity } from "../../../../util/Analytics/events";

export default class ComprehensiveAssessmentManager {
  constructor(vocabLevels, stopAfterIncorrectNum = 8) {
    this.vocabLevels = vocabLevels;
    this.timesForEachGroup = 1;
    this.totalLevels = 1;
    this.numWrongAnswers = 0;
    this.currentLevel = 0;
    this.currentInstance = 0;
    this.totalInstances = 0;
    //a map of level => final score
    this.levelToScore = {};
    //a map of level => group => [array of selected target words]
    this.levelGroupExposureMap = {};

    vocabLevels.forEach((level, index) => {
      this.levelGroupExposureMap[index] = [];
      this.levelToScore[index] = 0;
      this.totalInstances += level.length;
      for (let i = 0; i < level.length; i++) {
        this.levelGroupExposureMap[index].push({
          groupIndex: i,
          selectedTargetWords: [],
        });
      }
    });
    this.stopAfterIncorrectAnswers = stopAfterIncorrectNum;
  }

  shouldExitEarly() {
    return this.numWrongAnswers >= this.stopAfterIncorrectAnswers;
  }

  getCurrentLevel() {
    //0-indexed
    return this.currentLevel + 1;
  }

  getNextGroup() {
    this.currentInstance++;

    //find the least targeted group
    let groups = this.levelGroupExposureMap[this.currentLevel].sort((a, b) => {
      return a.selectedTargetWords.length - b.selectedTargetWords.length;
    });
    let { groupIndex, selectedTargetWords } = groups[0];

    let groupWords = this.vocabLevels[this.currentLevel][groupIndex];
    let nextTargetWord = groupWords.filter(
      (w) => !selectedTargetWords.includes(w)
    )[0];
    let nextConfusionWords = sampleSize(
      groupWords.filter((w) => w !== nextTargetWord),
      3
    );
    //track the exposed word
    selectedTargetWords.push(nextTargetWord);

    return {
      questionType: activity.IMAGE_CLICK,
      props: {
        targetWord: nextTargetWord,
        confusionWords: nextConfusionWords,
        shouldShowReview: false,
      },
    };
  }

  markAnswerIncorrect() {
    this.numWrongAnswers++;
  }

  markAnswerCorrect() {
    this.levelToScore[this.currentLevel]++;
  }

  getCurrentLevelScore() {
    const numGroups = this.vocabLevels[this.currentLevel].length;
    let scorePercent =
      this.levelToScore[this.currentLevel] /
      (this.timesForEachGroup * numGroups);
    return (scorePercent * 100).toFixed(2);
  }

  getTotalScore() {
    //not working

    let totalCorrect = 0;
    let totalGroups = 0;

    for (let i = 0; i <= this.totalLevels; i++) {
      totalCorrect += this.levelToScore[i];
      totalGroups += this.timesForEachGroup * this.vocabLevels[i].length;
    }

    console.log("total correct", totalCorrect);
    console.log("total groups", totalGroups);

    return ((totalCorrect / totalGroups) * 100).toFixed(2);
  }

  incrementLevel() {
    this.currentLevel++;
  }

  isLevelComplete() {
    return this.levelGroupExposureMap[this.currentLevel].every((group) => {
      return group.selectedTargetWords.length >= this.timesForEachGroup;
    });
  }

  isLastLevel() {
    return this.currentLevel === this.totalLevels;
  }

  getCurrentProgress() {
    return this.currentInstance / this.totalInstances;
  }
}
