import React from "react";
import ReactCardFlip from "react-card-flip";
import GCard from "./../../../components/Card/Card";

export default ({ children, isFlipped = false, onClick }) => {
  return (
    <ReactCardFlip isFlipped={isFlipped}>
      <GCard
        bg="blue"
        image={process.env.PUBLIC_URL + "/images/cardfront.png"}
        onClick={onClick}
      />
      {children}
    </ReactCardFlip>
  );
};
