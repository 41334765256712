import React, { useState } from "react";
import {
  AuthedTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import classnames from "classnames";
import { useParams, useHistory } from "react-router-dom";

const getTitle = (app) => {
  return {
    glenBooks: "FAQ: GLEN Books",
    glenRhymes: "FAQ: GLEN Rhymes",
    glenLearn: "FAQ: GLEN Learn",
  }[app];
};
const getContent = (app) => {
  switch (app) {
    case "glenLearn":
      return [
        {
          title: "Who is this content meant for?",
          content:
            "We are targeting children 3+ years of age. We have designed our app’s user interface so as to not “talk down” to the user, so older children and adults will also be able to take advantage of our content.",
        },
        {
          title: "How is GLEN Learn’s learning content organized?",
          content:
            "GLEN Learn has a significant volume of content that can be accessed out of order, including exercises, stories and rhymes.  However, we believe that the ideal place to start is our set of guided lessons, which  form the core of GLEN Learn.  Here a child needs to complete one set of lessons (by clearing an assessment based on the content) before getting access to the next set.  The initial focus of GLEN Learn is vocabulary building: associating the sound of a word with its image.  The text is provided, but it is not necessary to make sense of it at the beginning.  In the later lessons, we start throwing in sight reading and phonics.  Stories and rhymes are available at any time if a child wants to take a break from the lessons.",
        },
        {
          title:
            "How do we know if GLEN Learn’s pedagogical approach is working?",
          content:
            "The GLEN Learn guided lessons have built-in assessments after every few lessons, and we provide analytics, through a user dashboard, on the history of the child’s engagement with the content and the results of the assessments.  We also have a separate overall assessment module that covers the entire set of lessons currently released.  This assessment, which focuses on evaluating vocabulary acquisition, can be initiated at any time by a facilitator or the child by clicking the assessment module icon on the GLEN Learn main screen.",
        },
        {
          title: "Does the app provide any learner progress information?",
          content:
            "The learner dashboard includes our learner’s “wall of fame”, that showcases all their learning achievements and prizes. The dashboard also shows a timeline of completed lessons and the number of words introduced. A picture dictionary on the dashboard shows all the words introduced so far along with pictures and audio for easy review. We continue to add information to the dashboard that will give a detailed and accurate picture of the child’s learning progress.",
        },
        {
          title: "How do you expect GLEN Learn to be used?",
          content:
            "GLEN Learn can be used to strengthen language skills for children at homes or in informal preschool environments, as well as a complement to in-class face-to-face instruction in K-1 school settings.",
        },
        {
          title: "How much grown-up supervision is needed?",
          content:
            "We have designed GLEN Learn to require minimal grown-up supervision.  In informal settings such as a home or a preschool, the main role of the facilitator is to be a cheerleader, providing encouragement and ensuring that the children persist even if the material is initially difficult. They should encourage children to continue working on the exercises, and to interact with their peers and work in groups when possible. They should also monitor and help with any device related issues  (audio, low battery level etc). \n" +
            "In more formal school settings, it is up to the teacher as to how closely to supervise a child’s use of our content.  We provide information in the user dashboard that enables the teacher to track the child’s progress.  The teacher may adapt classroom instruction based on the strengths or weaknesses that this information reveals.",
        },
        {
          title:
            "How do you know that GLEN Learn can help children with school-readiness?",
          content:
            "GLEN Learn’s learning content is closely aligned with two research-backed and widely used school-readiness assessments, namely, Kindergarten Student Entrance Profile (KSEP) and Desired Results Developmental Profile (DRDP). KSEP and DRDP assessments are used by preschools in California to gauge the readiness levels of children entering kindergarten. Given this alignment, parents and preschool teachers can better utilize GLEN Learn to supplement learning for young English Learners -- the content is designed from the ground up to prepare children for their academic journey as they enter kindergarten.",
        },
        {
          title: "I love what you are doing. How can I help?",
          content:
            "Join us in our mission to scale early English literacy across the globe by donating to GLEN World via our website!",
        },
      ];
    case "glenBooks":
      return [
        {
          title: "Who is GLEN Books meant for?",
          content: " Children 3+ years of age will enjoy GLEN Books’ stories.",
        },
        {
          title: "Tell me more about the stories featured in GLEN Books.",
          content:
            "GLEN Books includes a rich collection of children’s stories in both English and Spanish languages. These stories include adaptations of timeless children’s classics such as Aesop’s fables and Indian folk tales, as well as a collection of original stories written by GLEN World’s authors.",
        },
        {
          title: "How do you expect GLEN Books to be used?",
          content:
            "GLEN Books can be used to strengthen language skills for children at homes or in informal preschool environments, as well as a complement to in-class activities in K-1 school settings." +
            "The stories in GLEN Books are engagingly narrated by narrators on the GLEN World team. The auto-narration feature enables kids to enjoy the books on their own well before they have learnt to read. Parents and caregivers with limited literacy can use this feature to enjoy reading with their kids.  Children, parents, and caregivers with reading skills can turn auto-narration off whenever they wish to read the stories on their own. Skilled teachers can use a blend of these approaches in designing in-class reading and comprehension exercises.\n",
        },
        {
          title: "Is grown-up supervision needed for using GLEN Books?",
          content:
            "While kids can enjoy our stories without adult supervision, GLEN Books is also a medium for playful interaction between kids and grown-ups.  Adult engagement helps instill a love of reading in children, and our in-app narration enables even adults with limited literacy to experience story time with kids.",
        },
        {
          title: "I love what you are doing. How can I help?",
          content:
            "Join us in our mission to scale early English literacy across the globe by donating to GLEN World via our website (www.glenworld.org)!",
        },
        {
          title: "How do I contact you?",
          content: "Drop us a line at info@glenworld.org!",
        },
      ];
    case "glenRhymes":
      return [];
    default:
      return [];
  }
};

export default () => {
  const history = useHistory();
  const { app } = useParams();
  const goBack = () => history.goBack();

  const [visibleIndex, setVisibleIndex] = useState(null);

  return (
    <GameWrapper>
      <AuthedTopBar />
      <GameContainer>
        <h1
          style={{
            fontSize: "3vw",
            color: "#fff",
            textAlign: "center",
            paddingBottom: "5px",
          }}
        >
          {getTitle(app)}
        </h1>
        <div className="accordion" id="accordionExample">
          {getContent(app).map(({ title, content }, i) => {
            return (
              <div className="card" onClick={() => setVisibleIndex(i)}>
                <div className="p-1">
                  <button className="btn btn-link" type="button">
                    {title}
                  </button>
                </div>

                <div
                  className={classnames("collapse", {
                    show: visibleIndex === i,
                  })}
                >
                  <div className="card-body">{content}</div>
                </div>
              </div>
            );
          })}
        </div>
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} />
    </GameWrapper>
  );
};
