import React from "react";
import styles from "./ShelfImageItem.module.scss";

export default ({ image, onClick, booksPerShelf }) => {
  return (
    <div>
      <div>
        <img
          className={booksPerShelf ? styles.doublecover : styles.cover}
          src={image}
          alt=""
          onClick={onClick}
        />
      </div>
    </div>
  );
};
