import shuffle from "lodash/shuffle";

export default class LessonAManager {
  constructor(parameters) {
    this.exposureMap = {};
    this.successMap = {};
    this.targetSuccessCount = 0;

    this.words = shuffle(parameters.content);
    this.words.forEach((w) => {
      this.exposureMap[w] = 0;
      this.successMap[w] = 0;
    });
    this.assessmentCount = 0;
    this.targetSuccessCount = parameters.targetSuccessCount;
    this.targetAssessmentCount = this.targetSuccessCount * this.words.length;
  }

  getCurrentProgress() {
    return Math.min(
      0.3 * this.getExposureCriterion() + 0.7 * this.getSuccessCriterion(),
      1
    );
  }

  getExposureCriterion() {
    // {number of concepts with exposure_count  > 0}/{number of concepts}
    //find all the words in the exposure map, then add the exposure counts together.
    var wordsWithExposure = this.words
      .map((w) => this.exposureMap[w])
      .filter((v) => v !== 0).length;

    return wordsWithExposure / this.words.length;
  }

  getSuccessCriterion() {
    //success_criterion = {number of concepts with success_count  >= target_success_count}/{number of concepts}
    var wordsWithSuccess = this.words
      .map((w) => this.successMap[w])
      .filter((v) => v >= this.targetSuccessCount).length;

    return wordsWithSuccess / this.words.length;
  }

  getAssessmentCriterion() {
    return this.assessmentCount / this.targetAssessmentCount;
  }

  //exposure_count: how many times the concept has been encountered
  addExposureForWord(word) {
    this.exposureMap[word]++;
    console.log("exposure map ", this.exposureMap);
  }

  getExposedWords() {
    return this.words.filter((w) => this.exposureMap[w] > 0);
  }

  //lowest->highest
  getWordsOrderedByExposure() {
    return this.words.sort((a, b) => this.exposureMap[a] - this.exposureMap[b]);
  }

  //lowest->highest
  getWordsOrderedBySuccess() {
    return this.words.sort((a, b) => this.successMap[a] - this.successMap[b]);
  }

  //assessment_count (not exposed to user. Instead, we do a progress bar): Keep adding up points from successful Image Click exercises.
  incrementAssessmentCount() {
    this.assessmentCount++;
  }

  addSuccessForWord(word) {
    this.successMap[word]++;
  }
}
