import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useFirebase from "../../../../util/Firebase/useFirebase";
import {
  AuthedTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useCollection } from "react-firebase-hooks/firestore";
import { ClipLoader } from "react-spinners";
import { AvatarWithText } from "../../../../components/Avatar/Avatar";
import styles from "./UserList.module.scss";
import PinProtected from "../DashboardForUser/PinProtected";

const UserList = () => {
  const { deploymentId } = useParams();
  const history = useHistory();
  const firebase = useFirebase();

  // eslint-disable-next-line
  const [value, loading, error] = useCollection(
    firebase.deploymentAccounts(deploymentId)
  );

  const selectUsername = (id) =>
    history.push(`/deployment/${deploymentId}/adminDashboard/user/${id}`);

  return (
    <div className={styles.userListContainer}>
      <div>Click an icon to view learner progress</div>
      <div style={{ marginBottom: "2vh" }}>
        {loading && <ClipLoader size={75} />}
        {value && (
          <div className="mt-5 ml-5 mr-5">
            <div className="row justify-content-center">
              {value.docs
                .map((doc) => {
                  return { id: doc.id, ...doc.data() };
                })
                .filter((doc) => doc.deleted !== true)
                .map((doc) => {
                  return (
                    <div className="col-3 mb-3" key={doc.id}>
                      <AvatarWithText
                        text={doc.username}
                        avatarId={doc.avatarId}
                        onClick={() => selectUsername(doc.id)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default () => {
  const history = useHistory();
  const { skipAuth = false } = history.location.state || {};
  const { deploymentId } = useParams();
  const gotoAbout = () => history.push(`/about/glenLearn`);
  const goToGlenLearn = () =>
    history.push(`/deployment/${deploymentId}/glenLearn`);

  const onAddEditDelete = () =>
    history.push({
      state: { editEnabled: true },
      pathname: `/deployment/${deploymentId}/profiles`,
    });
    const onAdminAccountSettings = () =>
        history.push({
            pathname: `/deployment/${deploymentId}/adminAccountSettings`,
    });

  const [isAuthed, setIsAuthed] = useState(skipAuth);

  const Content = () => {
    useEffect(() => setIsAuthed(true), []);
    return (
      <>
          <div className="row">
              <div className="col-6 text-left">
                  <button className="btn btn-primary" onClick={onAddEditDelete}>
                      Add / Edit / Delete Learners
                  </button>
              </div>
              <div className="col-6 text-right">
                  <button className="btn btn-primary" onClick={onAdminAccountSettings}>
                      Admin account settings
                  </button>
              </div>
          </div>
        <UserList />
      </>
    );
  };

  //we skip auth when the user navigates to collected words via the admin.
  return (
    <GameWrapper>
      <AuthedTopBar />
      <GameContainer>
        <h1 className={styles.headerText}>Teacher / Parent Dashboard</h1>
        {skipAuth ? (
          <Content />
        ) : (
          <PinProtected password={"1234"}>
            <Content />
          </PinProtected>
        )}
      </GameContainer>
      <BottomBar
        center={
          isAuthed && (
            <button className="btn btn-secondary" onClick={gotoAbout}>
              About
            </button>
          )
        }
        left={<BackButton onClick={goToGlenLearn} />}
      />
    </GameWrapper>
  );
};
