import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./turn.js";
import styles from "./GlenBooks.module.scss";
import Turn from "./Turner.js";
import AudioSync from "../../util/SyncedAudio/audio/audio.js";
import axios from "axios";
import { MaterialButton } from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import { isIOS } from "../../util/deviceType";
import once from "lodash/once";

const useBook = (bookId, onStart) => {
  const [loaded, setIsLoaded] = useState(false);
  const [book, setBook] = useState({
    audio: "",
    cover: "",
    title: {},
    pages: [],
    credits: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const dir = `${process.env.PUBLIC_URL}/content/ebooks/books/${bookId}.json`;
      let res = await axios.get(dir);
      setBook(res.data);
      setIsLoaded(true);
    };
    fetchData();
    onStart();
  }, [bookId, onStart]);
  return [loaded, book];
};

const GlenBooks = ({
  bookId,
  onStart,
  onComplete,
  onAnalyticComplete,
  autoTurnEnabled,
  autoTurnCover,
  allowManualPlayback = true,
  isSingle,
  rhymes,
  textAppears,
  isHeartBeat,
}) => {
  const Audio = useRef(null);
  const [pageNum, setPageNum] = useState(-1);
  const [time, setTime] = useState(0);
  const [mute, setMute] = useState(false);
  const inputRef = useRef(null);
  const [loaded, book] = useBook(bookId, onStart);
  const pageImages = useMemo(() => book.pages.map((page) => page.image), [
    book,
  ]);

  const peelRight = useCallback(() => inputRef.current.peelBottomRight(), []);
  const turnRight = useCallback(() => inputRef.current.rightTurn(), []);
  const turnLeft = useCallback(() => inputRef.current.leftTurn(), []);

  let currentPageText = useMemo(
    () => book.pages.map((page) => page.text)[pageNum],
    [pageNum, book]
  );

  const totalPages = useMemo(
    () => (isSingle ? book.pages.length + 2 : book.pages.length * 2 + 2),
    [book, isSingle]
  );

  const isNotCoverOrCredits = useMemo(
    () => pageNum >= 0 && pageNum <= book.pages.length - 1,
    [book.pages.length, pageNum]
  );

  // initialize Cover
  useEffect(() => {
    if (loaded) {
      Audio.current = new AudioSync(book.audio);
      if (!autoTurnCover) {
        Audio.current.atTime(book.title.end, () => {
          Audio.current.pause();
          Audio.current.clearCues();
          setTimeout(turnRight, 1500);
        });
        // hack needed for web/android only.
        if (!isIOS()) {
          Audio.current.play().pause();
        }
        Audio.current.setCurrentAudioTime(book.title.start);
        Audio.current.play();
      } else {
        turnRight();
      }
      return () => {
        Audio.current.deconstruct();
      };
    }
  }, [book, Audio, loaded, autoTurnCover, turnRight]);

  const setPageNumber = useCallback(
    (view) => {
      let page = view[0] / 2 - 1;
      if (isSingle) {
        page = view - 2;
      }
      setPageNum(page);
    },
    [isSingle, setPageNum]
  );

  const onAnalyticCompleteDebounced = useMemo(() => once(onAnalyticComplete), [onAnalyticComplete]);

  const playAudioForPage = useCallback((isLastPageOfBook) => {
    // hack needed for web/android only.
    if (!isIOS()) {
      Audio.current.play().pause();
    }
    Audio.current.setCurrentAudioTime(currentPageText[0].start);

    currentPageText.forEach(function (t, index) {
      Audio.current.atTime(t.start, () => {
        setTime(t.start);
      });
      //if at the end.
      if (index === currentPageText.length - 1) {
        Audio.current.atTime(t.end, () => {
          //if we're still on the same page when audio playout began, pause the audio and peel
          Audio.current.pause();
          peelRight();
          // rhymes fires analytics event on last page.
          if (isLastPageOfBook && rhymes) {
            // atTime callbacks are at least once, which means we don't want to invoke the method more
            // than once
            onAnalyticCompleteDebounced();
          }
          if (autoTurnEnabled) {
            setTimeout(turnRight, 500);
          }
        });
      }
    });

    setTime(currentPageText[0].start);
    Audio.current.play();
  }, [Audio, currentPageText, peelRight, autoTurnEnabled, turnRight]);

  const playback = useCallback(
    (position) => {
      if (!allowManualPlayback) {
        return;
      }

      // segment playback disabled on Android
      if (window.Cordova && window.device.platform === "Android") {
        return;
      }

      Audio.current.pause().clearCues();
      if (!mute) {
        let content = currentPageText[position];
        Audio.current.atTime(content.end, function () {
          Audio.current.pause();
        });

        setTime(content.start);
        Audio.current.setCurrentAudioTime(content.start);
        Audio.current.play();
      }
    },
    [Audio, mute, currentPageText]
  );

  // What happens when page number changes
  useEffect(() => {
    // If not cover and not credits
    const isLastPageOfBook = pageNum === book.pages.length - 1;
    if (isNotCoverOrCredits && !mute) {
      playAudioForPage(isLastPageOfBook);
    }
  }, [pageNum, book, playAudioForPage, isNotCoverOrCredits, mute]);

  const refreshPage = useCallback(() => {
    if (pageNum > -1 && !mute) {
      playAudioForPage(false);
    }
  }, [pageNum, mute, playAudioForPage]);

  const toggleMute = useCallback(() => {
    setMute((mute) => {
      return !mute;
    });
    Audio.current.pause();
  }, [Audio]);

  // given start time, return the page text (with the phrase beginning at start time highlighted)
  const displayText = useCallback(
    (startTime) => {
      let text = [];

      if (loaded && isNotCoverOrCredits) {
        currentPageText.forEach(function (item, index, array) {
          text.push(
            <span
              key={index}
              onClick={() => {
                playback(index);
              }}
              className={
                item.start <= startTime && !mute
                  ? item.start === startTime
                    ? textAppears
                      ? `${styles.selectedWord} animated fadeIn`
                      : styles.selectedWord
                    : null
                  : textAppears
                  ? styles.transparentWord
                  : null
              }
            >
              {item.text}
              {item.lineBreak && !isSingle && <div className={styles.spacer} />}
            </span>
          );
        });
        return (
          <div className={isSingle ? styles.singlePageText : styles.pageText}>
            {text}
          </div>
        );
      }
    },
    [
      loaded,
      isNotCoverOrCredits,
      currentPageText,
      isSingle,
      mute,
      textAppears,
      playback,
    ]
  );

  const displayCover = useCallback(() => {
    return (
      <div key={book.cover} className={(styles.page, "hard")}>
        <div className={styles.bookCover}>
          <img src={`${process.env.PUBLIC_URL}${book.cover}`} alt="" />
        </div>
      </div>
    );
  }, [book]);

  const displayCredits = useCallback(() => {
    // list of credits
    const creditsList = book.credits.map((credit) => (
      <p key={credit}> {credit} </p>
    ));

    return (
      <div key={creditsList} className={(styles.page, "hard")}>
        <div className={(styles.bookCover, styles.credits)}>
          {creditsList.length > 0 && <h3>Credits</h3>}
          <h5>{creditsList}</h5>
        </div>
      </div>
    );
  }, [book]);

  const displayImages = useCallback(
    (pageImage, index, startTime) => {
      let pager = "";

      if (!rhymes) {
        return (
          <img
            src={`${process.env.PUBLIC_URL}${pageImage}`}
            alt=""
            className={
              isSingle
                ? isHeartBeat
                  ? `${styles.singlePageImage} animated heartBeat infinite`
                  : styles.singlePageImage
                : styles.pageImage
            }
          />
        );
      }
      if (currentPageText) {
        currentPageText.forEach(function (item, index, array) {
          if (array[index - 1] && item.start === startTime) {
            if (array[index - 1].image !== item.image) {
              pager = (
                <img
                  src={`${process.env.PUBLIC_URL}${item.image}`}
                  alt=""
                  className="animated fadeIn"
                />
              );
            } else {
              pager = (
                <img
                  src={`${process.env.PUBLIC_URL}${item.image}`}
                  alt=""
                  className={styles.pageImage}
                />
              );
            }
          } else if (item.start === startTime) {
            pager = (
              <img
                src={`${process.env.PUBLIC_URL}${item.image}`}
                alt=""
                className={styles.pageImage}
              />
            );
          }
        });
      }
      return pager;
    },
    [currentPageText, isSingle, rhymes, isHeartBeat]
  );

  const bookDivs = useCallback(
    (page, index, pageArray) => {
      // cover outline
      let pageDiv = "";
      const view = inputRef.current ? inputRef.current.getView()[0] - 1 : false;

      if (index === 0) {
        pageDiv = displayCover();
      }
      // credits outline
      else if (index === pageArray.length - 1) {
        pageDiv = displayCredits();
      } else if (isSingle) {
        pageDiv = (
          <div key={index} className={styles.pageInner}>
            <div className={styles.leftdepth}></div>
            {displayImages(page, index, time)}
            {displayText(time)}
            <div className={styles.depth}></div>
          </div>
        );
      }

      // picture page
      else if (index % 2 === 1) {
        pageDiv = (
          <div key={index} className={styles.pageInner}>
            <div className={view === index ? styles.leftdepth : ""}></div>
            {displayImages(page, index, time)}
          </div>
        );
      }
      // text page
      else if (index === pageNum * 2 + 2) {
        pageDiv = (
          <div key={index} className={styles.pageInner}>
            <div className={styles.depth}></div>
            {displayText(time)}
          </div>
        );
      }
      // blank pages
      else {
        pageDiv = (
          <div key={index} className={styles.pageInner}>
            <div className={styles.depth}></div>
          </div>
        );
      }

      return pageDiv;
    },
    [
      displayCover,
      displayCredits,
      displayText,
      displayImages,
      pageNum,
      time,
      isSingle,
      rhymes,
    ]
  );

  const options = useMemo(() => {
    return {
      gradients: true,
      acceleration: false,
      autoCenter: true,
      display: isSingle ? "single" : "double",
      when: {
        turning: function (e, page, view) {
          Audio.current.pause();
          Audio.current.clearCues();
          if (view[0] === 0 || view[0] === totalPages || view[0] === 1) {
            // for books without credits, the last page should complete immediately
            let timeout =
              view[0] === totalPages && book.credits.length === 0 ? 500 : 2500;
            window.setTimeout(() => {
              // books runs analytics event on the cover page
              if (!rhymes) {
                onAnalyticComplete();
              }
              onComplete(true);
            }, timeout);
          } else {
            setPageNumber(view);
          }
        },
      },
    };
  }, [isSingle, totalPages, book.credits.length, onComplete, setPageNumber]);

  const turnJSBook = useCallback(() => {
    const length = isSingle ? book.pages.length : book.pages.length * 2;

    // Array of pages to be filled after data is loaded
    let pages = new Array(length + 2).fill("");

    if (loaded) {
      if (isSingle) {
        pageImages.forEach(function (item, index, array) {
          pages[index + 1] = pageImages[index];
        });
      } else {
        pageImages.forEach(function (item, index, array) {
          pages[index * 2 + 1] = pageImages[index];
        });
      }
      return (
        <Turn ref={inputRef} options={options} className={styles.book}>
          {pages.map((page, index, pageArray) =>
            bookDivs(page, index, pageArray)
          )}
        </Turn>
      );
    }
  }, [book, bookDivs, options, loaded, pageImages, isSingle]);

  return (
    <div className={styles.bookContainer}>
      <div className={styles.bookHolder}>
        <div className={styles.book}>
          {turnJSBook()}
          {pageNum >= 0 && pageNum <= book.pages.length - 1 && (
            <div ignore="1" className={styles.uiPrevious} onClick={turnLeft}>
              <div className={`material-icons md-60`}>keyboard_arrow_left</div>
            </div>
          )}
          {pageNum <= book.pages.length - 1 && (
            <div ignore="1" className={styles.uiNext} onClick={turnRight}>
              <div className={`material-icons md-60`}>keyboard_arrow_right</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.controlContainer}>
        <div className={styles.controls}>
          <MaterialButton
            size={60}
            icon={mute ? "volume_off" : "volume_up"}
            onClick={toggleMute}
          />
          <MaterialButton size={60} icon="refresh" onClick={refreshPage} />
        </div>
      </div>
    </div>
  );
};

export default GlenBooks;
