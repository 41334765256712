import sample from "lodash/sample";
import sampleSize from "lodash/sampleSize";
import shuffle from "lodash/shuffle";

export default class VocabAssessManager {
  constructor(allConcepts, allGroups, vocabAssessRecordFunc, textIndicator) {
    this.textIndicator = textIndicator;
    this.vocabAssessRecordFunc = vocabAssessRecordFunc;
    this.concepts = this._chooseConcepts(allConcepts);
    this.groups = allGroups;
    this.currentIndex = 0;
    this.score = 0;
    this.outOf = 20;
  }

  getOutOf() {
    return this.outOf;
  }

  getCurrentScore() {
    return this.score;
  }

  addSuccess() {
    this.score += 2;
  }

  hasNext() {
    return this.currentIndex !== this.concepts.length;
  }

  getNext() {
    const targetWord = this.concepts[this.currentIndex++];
    return {
      targetWord,
      confusionWords: this.getConfusionWords(targetWord, 3),
    };
  }

  // chooses a random group that contains the word, then chooses N words from that group (excluding the seed word)
  getConfusionWords(seedWord, wordsToSelect) {
    const group = sample(this.groups.filter((g) => g.includes(seedWord)));
    return sampleSize(
      group.filter((word) => word !== seedWord),
      wordsToSelect
    );
  }

  _chooseConcepts(allPossibleConcepts) {
    let recentlyFailedConcepts = this.getRecentlyFailedConcepts(
      allPossibleConcepts
    );
    let numberRecentFails = Math.min(6, recentlyFailedConcepts.length);
    let numberLeastExposed = 10 - numberRecentFails;
    let remainingConcepts = allPossibleConcepts
      .filter((c) => !recentlyFailedConcepts.includes(c))
      .sort((a, b) => {
        return (
          this.vocabAssessRecordFunc(a, this.textIndicator).length -
          this.vocabAssessRecordFunc(b, this.textIndicator).length
        );
      });

    let conceptsToBeAssessed = [];

    //choose at random number_recent_fails concepts from recently_failed_concepts;
    conceptsToBeAssessed = conceptsToBeAssessed.concat(
      sampleSize(recentlyFailedConcepts, numberRecentFails)
    );

    //choose at random number_least_exposed from remaining_concepts, starting with the smallest assess_count bin
    conceptsToBeAssessed = conceptsToBeAssessed.concat(
      remainingConcepts.slice(0, numberLeastExposed)
    );

    console.log("Chose concepts", conceptsToBeAssessed);
    console.log("Complete set", allPossibleConcepts);

    return shuffle(conceptsToBeAssessed);
  }

  getRecentlyFailedConcepts(concepts) {
    // recently_failed_concepts = {concepts for which assess_count (concept,text_indic) > 0 and assess_success_record(concept,text_indic) = 0}
    // remaining_concepts = content – recently_failed_concepts
    // Bin remaining_concepts by assess_count
    return concepts.filter((concept) => {
      const records = this.vocabAssessRecordFunc(concept, this.textIndicator);
      return records.length > 0 && records[records.length - 1] === false;
    });
  }
}
