import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Tracer from "tracer/dist/bundle";
import AudioRepeat from "../../components/AudioRepeat/AudioRepeat";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";
import { useResourceFromRegistry } from "../../util/Resource/useResource";
import WordReview from "../../components/WordReview/WordReview";

const TracerGame = ({ word, onTraceComplete, onComplete }) => {
  const ref = useRef(null);
  useLayoutEffect(() => {
    let tracer;
    if (ref.current && word) {
      tracer = new Tracer({
        element: ref.current,
        width: document.getElementById("gameContainer").clientWidth,
        height: document.getElementById("gameContainer").clientHeight,
        initialX: 50,
        initialY: 50,
        wordText: word,
        onCompleteCallback: onComplete,
        onTraceCompleteCallback: onTraceComplete,
        firstLetterOnly: true,
      });
      tracer.draw();
    }
    return () => {
      tracer && tracer.destroy();
    }
  }, [word]);
  return <div ref={ref}></div>;
};

export default ({ consonant, word, onStart, onComplete }) => {
  const [showTracer, setShowTracer] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const audioService = useContext(AudioContext);
  const flashcardResource = useResourceFromRegistry("flashcards");
  const flashcard = useMemo(() => flashcardResource.getFlashcard(word), []);

  useEffect(onStart, []);

  const onTraceComplete = async () => {
    await playConsonant();
  };

  const onGameComplete = async () => {
    await playAudio();
    await wait(1500);
    setShowReview(true);
  };

  const playConsonant = async () =>
    audioService.composeAndPlaySingleAudio("consonants", consonant);

  const playAudio = async () => audioService.playAudio(flashcard.audio);

  useEffect(() => {
    const steps = async () => {
      await playConsonant();
      await wait(1000);
      setShowTracer(true);
    };
    steps();
  }, []);

  if (showReview) {
    return (
      <WordReview flashcard={flashcard} onComplete={onComplete} />
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "10%", margin: "0 auto" }}>
        <AudioRepeat onClick={playConsonant} />
      </div>
      {showTracer && (
        <div id="gameContainer" style={{ width: "100%", height: "55vh" }}>
          <TracerGame
            word={flashcard.meaning}
            onTraceComplete={onTraceComplete}
            onComplete={onGameComplete}
          />
        </div>
      )}
    </div>
  );
};
