const cloudFunction = (firebase, endpoint, data = null) => {
  const api = firebase.functions.httpsCallable(endpoint);
  return api(data)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default cloudFunction;
