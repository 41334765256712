import shuffle from "lodash/shuffle";
import sample from "lodash/sample";
import sampleSize from "lodash/sampleSize";

export default class VocabStrengthenManager {
  numConcepts = 6;
  targetSuccessCount = 1;
  constructor(allConcepts, recentlyFailedConcepts, allGroups) {
    this.concepts = this._chooseConcepts(allConcepts, recentlyFailedConcepts);
    this.groups = allGroups;
    this.successMap = {};
    this.concepts.forEach((concept) => {
      this.successMap[concept] = 0;
    });
  }

  addSuccessForWord(word) {
    console.log("ADD success for", word);
    this.successMap[word]++;
    console.log("CRITERION", this.getSuccessCriterion());
  }

  getNext() {
    let sortedConcepts = shuffle(this.concepts).sort(
      (a, b) => this.successMap[a] - this.successMap[b]
    );
    let targetWord = sortedConcepts[0];
    let confusionWords = this._getConfusionWords(targetWord, 3);
    return { targetWord, confusionWords };
  }

  hasNext() {
    return this.getSuccessCriterion() < 1;
  }

  // chooses a random group that contains the word, then chooses N words from that group (excluding the seed word)
  _getConfusionWords(seedWord, wordsToSelect = 3) {
    const group = sample(this.groups.filter((g) => g.includes(seedWord)));
    return sampleSize(
      group.filter((word) => word !== seedWord),
      wordsToSelect
    );
  }

  getSuccessCriterion() {
    //success_criterion = {number of concepts with success_count  >= target_success_count}/{number_of_concepts}
    return (
      this.concepts.filter((c) => this.successMap[c] >= this.targetSuccessCount)
        .length / this.numConcepts
    );
  }

  _chooseConcepts(allConcepts, recentlyFailedConcepts) {
    /*
        Choose 6 concepts from “content” as follows
        N1 = min(6, size(recently_failed_concepts))
        N2 = 6 – N1

        concepts_to_strengthen = choose at random N1 concepts from recently_failed_concepts;
         choose at random N2 concepts from from remaining_concepts
         */
    const allOtherConcepts = allConcepts.filter(
      (concept) => !recentlyFailedConcepts.includes(concept)
    );

    let n1 = Math.min(this.numConcepts, recentlyFailedConcepts.length);
    let n2 = this.numConcepts - n1;
    let concepts = [];
    concepts = concepts.concat(sampleSize(recentlyFailedConcepts, n1));
    concepts = concepts.concat(sampleSize(allOtherConcepts, n2));
    return concepts;
  }

  getCurrentProgress() {
    return this.getSuccessCriterion();
  }
}
