import React, { useContext, useMemo, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LessonGroupCard from "../GroupCard/GroupCard";
import Card from "../Card/Card";
import AudioContext from "../../util/Audio/context";

export default ({ groupsToCompletedConcepts }) => {
  const audioService = useContext(AudioContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalFlashcards, setModalFlashcards] = useState([]);

  const closeModal = () => {
    setModalOpen(false);
    setModalFlashcards([]);
  };

  const openModal = (cards) => {
    setModalFlashcards(cards);
    setModalOpen(true);
  };

  const closeBtn = (
    <i
      className="material-icons md-48"
      style={{ cursor: "pointer" }}
      onClick={closeModal}
    >
      close
    </i>
  );

  if (!groupsToCompletedConcepts) {
    return;
  }

  const items = useMemo(
    () => Object.values(groupsToCompletedConcepts).filter((g) => g.length > 0),
    [groupsToCompletedConcepts]
  );
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        scrollable
        style={{ maxWidth: "70%", height: "90%" }}
      >
        <ModalHeader toggle={closeModal} close={closeBtn}>
          &nbsp;
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {modalFlashcards.map((card) => {
              return (
                <div className="col-4 mb-4" key={card.key}>
                  <Card
                    bg="blue"
                    image={card.image}
                    text={card.meaning}
                    onClick={() => audioService.playAudio(card.audio)}
                  />
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>
      <div className={`row mt-4`}>
        {items.map((cards, idx) => {
          return (
            <div key={idx} className={`col-3 mb-4`}>
              <LessonGroupCard
                onClick={() => openModal(cards)}
                group={{ images: cards.map((c) => c.image).slice(0, 4) }}
                withRangeText={false}
                isActive={true}
                withSpiral={false}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
