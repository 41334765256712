import React, { useEffect, useMemo, useState } from "react";
import wait from "../../../../util/wait";
import { useComponentRender } from "../../util/hooks";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import LessonC2Manager from "./manager";
import TraceAndSoundWithAnalytics from "../../../../games/TraceAndSound/TraceAndSoundWithAnalytics";
import SoundClick1WithAnalytics from "../../../../games/SoundClick1/SoundClick1WithAnalytics";

const RepeatedTraceAndSoundGame = ({ manager, updateProgress, onComplete }) => {
  const [gameState, setGameState] = useState(null);
  const initNewGame = (trialNum) => {
    setGameState({
      trialNum,
      consonant: manager.targetSound,
      word: manager.nextTraceAndSoundWord,
    });
  };

  const onRoundComplete = () => {
    manager.onTraceAndSoundFinished();
    updateProgress();

    if (manager.traceAndSoundComplete) {
      onComplete();
    } else {
      initNewGame(gameState.trialNum + 1);
    }
  };

  useEffect(() => initNewGame(1), []);

  if (!gameState) {
    return null;
  }

  return (
    <TraceAndSoundWithAnalytics
      key={gameState.trialNum}
      consonant={gameState.consonant}
      word={gameState.word}
      onComplete={onRoundComplete}
    />
  );
};

const RepeatedSoundClick1Game = ({ manager, updateProgress, onComplete }) => {
  const [gameState, setGameState] = useState(null);

  const onRoundComplete = (wasCorrect) => {
    manager.onSoundClick1Complete(wasCorrect);
    updateProgress();

    if (manager.soundClick1Complete) {
      onComplete();
    } else {
      initNewGame(gameState.trialNum + 1);
    }
  };
  const initNewGame = (trialNum) => {
    const {
      targetSound,
      correctWord,
      confusionWords,
    } = manager.nextSoundClick1;
    setGameState({
      trialNum,
      targetSound,
      correctWord,
      confusionWords,
    });
  };

  useEffect(() => initNewGame(1), []);

  if (!gameState) {
    return null;
  }

  return (
    <SoundClick1WithAnalytics
      key={gameState.trialNum}
      targetSound={gameState.targetSound}
      correctWord={gameState.correctWord}
      confusionWords={gameState.confusionWords}
      onComplete={onRoundComplete}
    />
  );
};

const useGameContent = (parameters, onComplete) => {
  const manager = useMemo(() => new LessonC2Manager(parameters), []);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => {
    setCurrentProgress(manager.progressBarLength);
    return wait(200);
  };
  const incrementStep = () => setCurrentStep(currentStep + 1);
  const stepToComponent = {
    1: (
      <RepeatedTraceAndSoundGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={incrementStep}
      />
    ),
    2: (
      <RepeatedSoundClick1Game
        manager={manager}
        updateProgress={updateProgress}
        onComplete={onComplete}
      />
    ),
  };
  const toRender = useComponentRender(stepToComponent, currentStep);
  return [toRender, currentProgress];
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
