import { TYPES as types } from "../../components/Lessons/util/groups";
import { useContext, useState } from "react";
import AudioContext from "../Audio/context";

// Currently, the only type of timeline modal supported is certificates.
const useTimelineModal = (timelineEvents) => {
  const [selectedCertIndex, setSelectedCertIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const audioService = useContext(AudioContext);

  const closeModal = () => setModalOpen(!modalOpen);

  const openModal = (uuid) => {
    let i = timelineEvents.findIndex((event) => event.id === uuid);
    setSelectedCertIndex(i);
    setModalOpen(true);
    const { lessonType } = timelineEvents[i].meta;
    let audio;
    if (lessonType === types.GLEN_LEARN) {
      const { totalWords } = timelineEvents[i].meta;
      audio = `this_prize_is_for_learning_${totalWords}_words`;
    } else if (lessonType === types.GLEN_SHAPES) {
      // GLEN Shapes encompasses numbers and shapes modules.
      const { totalShapes, totalNumbers } = timelineEvents[i].meta;
      if (totalShapes) {
        audio = `this_prize_is_for_learning_${totalShapes}_shapes`;
      } else if (totalNumbers) {
        audio = `this_prize_is_for_learning_${totalNumbers}_numbers`;
      }
    } else if (lessonType === types.GLEN_PHONICS) {
      const { totalSounds } = timelineEvents[i].meta;
      audio = `this_prize_is_for_learning_${totalSounds}_sounds`;
    } else if (lessonType === types.GLEN_ABC) {
      audio = `this_prize_is_for_learning_letters_a_to_z`;
    }
    audioService.composeAndPlaySingleAudio("narrator", audio);
  };

  return {
    selectedCertIndex,
    modalOpen,
    openModal,
    closeModal,
  };
};

export default useTimelineModal;
