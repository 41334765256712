import React from "react";
import BasePrompt from "./BasePrompt";
import AudioRepeat from "../../../components/AudioRepeat/AudioRepeat";

const AudioPrompt = ({ audio, delays = null, onPromptFinished }) => {
  return (
    <BasePrompt
      audio={audio}
      delays={delays}
      onPromptFinished={onPromptFinished}
    >
      <AudioRepeat />
    </BasePrompt>
  );
};

export default AudioPrompt;
