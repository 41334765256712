/**
 * These samplers are helper functions used in the generators to randomly
 * sample sets of answers from category data, while ensuring that questions
 * remain unique.
 */
import { sampleSize } from "lodash";

const arrayRemove = (arr, val) => arr.splice(arr.indexOf(val), 1);

/**
 * Randomly samples sets of answer choices from an array of possible answers.
 *
 * Returns an array of length `numQuestions`, where each element
 * is an array of length `numAnswers`, representing a set of answer
 * choices. The first element of each set of answer choices is the
 * correct answer.
 */
export const sampleFromAnswers = (answers, numQuestions, numAnswers) => {
  let answerBank = [...answers];
  const questions = [];

  for (let i = 0; i < numQuestions; ++i) {
    // If all the choices have been used as correct answers, we repopulate the answerBank
    if (answerBank.length === 0) {
      answerBank = [...answers];
    }

    const correctAnswer = sampleSize(answerBank, 1);
    arrayRemove(answerBank, correctAnswer[0]);

    const possibleAnswers = answers.filter((ans) => ans !== correctAnswer[0]);
    const sampledAnswers = sampleSize(possibleAnswers, numAnswers - 1);

    questions.push(correctAnswer.concat(sampledAnswers));
  }

  return questions;
};

/**
 * Randomly samples sets of correct and incorrect answers from an object mapping
 * categories to arrays of possible answers.
 *
 * Returns an array of length `numQuestions`, with elements in the form
 * ```
 * [correctCategory, correctAnswers, incorrectAnswers]
 * ```
 * where `correctAnswers` is an array of length `numCorrectAnswers` and
 * `incorrectAnswers` is an array of length `numIncorrectAnswers`.
 *
 * All answers in `correctAnswers` are from `correctCategory`, and all answers
 * in `incorrectAnswers` are from unique categories, excluding `correctCategory`.
 *
 * All `correctCategory`'s are guaranteed to be unique.
 */
export const sampleFromCategories = (
  categories,
  numQuestions,
  numCorrectAnswers,
  numIncorrectAnswers
) => {
  let categoryBank = Object.keys(categories);
  const questions = [];

  for (let i = 0; i < numQuestions; ++i) {
    // If all the choices have been used as correct answers, we repopulate the categoryBank
    if (categoryBank.length === 0) {
      categoryBank = Object.keys(categories);
    }

    const correctCategory = sampleSize(categoryBank, 1)[0];
    const correctAnswers = sampleSize(
      categories[correctCategory],
      numCorrectAnswers
    );
    arrayRemove(categoryBank, correctCategory);

    const possibleCategories = Object.keys(categories).filter(
      (cat) => cat !== correctCategory
    );
    const incorrectCategories = sampleSize(
      possibleCategories,
      numIncorrectAnswers
    );
    const incorrectAnswers = incorrectCategories.map(
      (cat) => sampleSize(categories[cat], 1)[0]
    );

    questions.push([correctCategory, correctAnswers, incorrectAnswers]);
  }

  return questions;
};
