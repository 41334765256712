import React, { useCallback, useEffect, useMemo, useState } from "react";
import PhonicAssessManager from "./manager";
import wait from "../../../../util/wait";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ScoreText,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import SoundClick1WithAnalytics from "../../../../games/SoundClick1/SoundClick1WithAnalytics";
import AssessComplete from "../AssessComplete/AssessComplete";
import { activity } from "../../../../util/Analytics/events";
import SoundClick2WithAnalytics from "../../../../games/SoundClick2/SoundClick2WithAnalytics";

const RepeatedSoundClick1Game = ({
  manager,
  updatePhonicsAssessmentRecord,
  updateProgress,
  onComplete,
}) => {
  const [gameState, setGameState] = useState(null);

  const initNewGame = useCallback((trialNum) => {
    const nextGameState = manager.getNextGame(
      gameState ? gameState.targetSound : null
    );
    setGameState({ trialNum, ...nextGameState });

    // eslint-disable-next-line
  }, []);

  const onRoundComplete = useCallback(
    (wasCorrect) => {
      updatePhonicsAssessmentRecord(gameState.targetSound, wasCorrect);
      if (wasCorrect) {
        manager.addSuccess();
      }
      updateProgress().then(() => {
        if (manager.hasNext()) {
          initNewGame(gameState.trialNum + 1);
        } else {
          onComplete();
        }
      });
    },
    [gameState]
  );

  useEffect(() => initNewGame(1), []);
  if (!gameState) {
    return null;
  }
  if (gameState.game === activity.SOUND_CLICK_1) {
    let { targetSound, correctWord, confusionWords } = gameState.parameters;
    return (
      <SoundClick1WithAnalytics
        key={gameState.trialNum}
        targetSound={targetSound}
        correctWord={correctWord}
        confusionWords={confusionWords}
        onComplete={onRoundComplete}
      />
    );
  } else if (gameState.game === activity.SOUND_CLICK_2) {
    let { targetSound, correctLetter, confusionLetter } = gameState.parameters;
    return (
      <SoundClick2WithAnalytics
        key={gameState.trialNum}
        targetSound={targetSound}
        correctLetter={correctLetter}
        confusionLetter={confusionLetter}
        onComplete={onRoundComplete}
      />
    );
  }
};

export default ({
  consonantWordMap,
  level,
  updatePhonicsAssessmentRecord,
  getPhonicsAssessmentRecord,
  onStart,
  onComplete,
}) => {
  useEffect(onStart, []);
  const manager = useMemo(
    () =>
      new PhonicAssessManager(
        consonantWordMap,
        level,
        getPhonicsAssessmentRecord
      ),
    []
  );
  const [currentScore, setCurrentScore] = useState(0);
  const [isAssessComplete, setIsAssessComplete] = useState(false);
  const updateProgress = () => {
    setCurrentScore(manager.getCurrentScore());
    return wait(200);
  };
  const onAssessComplete = () => setIsAssessComplete(true);

  let GameComponent;
  if (isAssessComplete) {
    GameComponent = (
      <AssessComplete
        score={currentScore}
        outOf={manager.getOutOf()}
        onFinished={() => onComplete(currentScore)}
      />
    );
  } else {
    GameComponent = (
      <RepeatedSoundClick1Game
        manager={manager}
        updatePhonicsAssessmentRecord={updatePhonicsAssessmentRecord}
        updateProgress={updateProgress}
        onComplete={onAssessComplete}
      />
    );
  }

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar
        left={
          !isAssessComplete && (
            <div className="w-50">
              <ScoreText score={currentScore} outOf={manager.getOutOf()} />
            </div>
          )
        }
      />
    </GameWrapper>
  );
};
