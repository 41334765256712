export const doLoginAuthUser = (fsUser, userProfile) => {
  // choose the first deployment in the map.
  // right now each user will only ever have one deployment.
  const deploymentId = Object.keys(userProfile.deployments)[0];
  const authUser = {
    uid: fsUser.uid,
    email: fsUser.email,
    emailVerified: fsUser.emailVerified,
    providerData: fsUser.providerData,
    //choose the first deployment
    deploymentId,
    ...userProfile,
  };
  console.log("AUTH EVENT", authUser);
  return { type: "AUTH_USER_SET", authUser };
};

export const doLogoutAuthUser = () => {
  return { type: "AUTH_USER_SET", authUser: null };
};
