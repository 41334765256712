export const LINES = ["abcdefg", "hijk", "lmnop", "qrstuv", "wxyz"];

export const MODE = {
  lower: "lower",
  upper: "upper",
  upperAndLower: "upperAndLower",
};

export const TIMINGS = [
  {
    startTime: 0.04,
    endTime: 0.62,
    letter: "a",
  },
  {
    startTime: 0.7,
    endTime: 1.17,
    letter: "b",
  },
  {
    startTime: 1.17,
    endTime: 2.01,
    letter: "c",
  },
  {
    startTime: 2.02,
    endTime: 2.69,
    letter: "d",
  },
  {
    startTime: 2.7,
    endTime: 3.54,
    letter: "e",
  },
  {
    startTime: 3.55,
    endTime: 3.95,
    letter: "f",
  },
  {
    startTime: 4.04,
    endTime: 4.96,
    letter: "g",
  },
  {
    startTime: 5.51,
    endTime: 6.23,
    letter: "h",
  },
  {
    startTime: 6.24,
    endTime: 6.59,
    letter: "i",
  },
  {
    startTime: 6.6,
    endTime: 7.51,
    letter: "j",
  },
  {
    startTime: 7.52,
    endTime: 8.05,
    letter: "k",
  },
  {
    startTime: 8.24,
    endTime: 8.64,
    letter: "l",
  },
  {
    startTime: 8.64,
    endTime: 8.9,
    letter: "m",
  },
  {
    startTime: 8.9,
    endTime: 9.27,
    letter: "n",
  },
  {
    startTime: 9.28,
    endTime: 9.53,
    letter: "o",
  },
  {
    startTime: 9.53,
    endTime: 10.5,
    letter: "p",
  },
  {
    startTime: 10.92,
    endTime: 11.63,
    letter: "q",
  },
  {
    startTime: 11.63,
    endTime: 12.34,
    letter: "r",
  },
  {
    startTime: 12.35,
    endTime: 12.95,
    letter: "s",
  },
  {
    startTime: 13.6,
    endTime: 14.24,
    letter: "t",
  },
  {
    startTime: 14.24,
    endTime: 15.04,
    letter: "u",
  },
  {
    startTime: 15.05,
    endTime: 15.88,
    letter: "v",
  },
  {
    startTime: 16.34,
    endTime: 17.75,
    letter: "w",
  },
  {
    startTime: 17.76,
    endTime: 18.65,
    letter: "x",
  },
  {
    startTime: 19.04,
    endTime: 19.76,
    letter: "y",
  },
  {
    startTime: 20.43,
    endTime: 21.3,
    letter: "z",
  },
];
