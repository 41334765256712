import React, { useCallback, useEffect, useMemo, useState } from "react";
import ShapesAssessManager from "./manager";
import ImageClickWithAnalytics from "../../../../games/ImageClick/ImageClickWithAnalytics";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ScoreText,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import AssessComplete from "../AssessComplete/AssessComplete";
import { GenericRepeatedGame } from "../../../../util/repeatedGame";

const RepeatedImageClickGame = ({ manager, updateProgress, onComplete }) => {
  const onRoundComplete = useCallback(
    (wasCorrect) => {
      manager.onRoundComplete(manager.currentWord, wasCorrect);
      updateProgress();
    },
    [manager, updateProgress]
  );

  const getNextGameProps = useCallback(() => {
    return { ...manager.getNext(), shouldShowReview: false };
  }, [manager]);

  const hasNext = useCallback(() => manager.hasNext(), [manager]);

  return (
    <GenericRepeatedGame
      GameComponent={ImageClickWithAnalytics}
      getNextGameProps={getNextGameProps}
      onRoundComplete={onRoundComplete}
      onComplete={onComplete}
      hasNext={hasNext}
    />
  );
};

export default ({
  concepts,
  updateShapesAssessmentRecord,
  getShapesAssessmentRecord,
  onStart,
  onComplete,
}) => {
  useEffect(onStart, []);
  const manager = useMemo(
    () => new ShapesAssessManager(concepts, updateShapesAssessmentRecord),
    // eslint-disable-next-line
    [concepts]
  );
  const [currentScore, setCurrentScore] = useState(0);
  const [isAssessComplete, setIsAssessComplete] = useState(false);
  const updateProgress = useCallback(
    () => setCurrentScore(manager.getCurrentScore()),
    [manager]
  );
  const onAssessComplete = useCallback(() => setIsAssessComplete(true), []);

  let GameComponent;

  if (isAssessComplete) {
    GameComponent = (
      <AssessComplete
        score={currentScore}
        outOf={manager.getOutOf()}
        onFinished={() => onComplete(currentScore)}
      />
    );
  } else {
    GameComponent = (
      <RepeatedImageClickGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={onAssessComplete}
      />
    );
  }

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar
        left={
          !isAssessComplete && (
            <div className="w-50">
              <ScoreText score={currentScore} outOf={manager.getOutOf()} />
            </div>
          )
        }
      />
    </GameWrapper>
  );
};
