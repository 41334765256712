import range from "lodash/range";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import wait from "../../../util/wait";

export const TYPES = {
  GLEN_LEARN: "glenLearn",
  GLEN_VOCAB: "glenVocab",
  GLEN_PHONICS: "glenPhonics",
  GLEN_SHAPES: "glenShapes",
  GLEN_ABC: "glenABC",
};

export const getLessons = (lessonResource, lessonGroup) => {
  return range(lessonGroup.start, lessonGroup.end + 1).map((num, i) => {
    return {
      number: num,
      ...lessonResource.getLesson(num),
    };
  });
};

export const gotoLessonGroupHome = (
  history,
  deploymentId,
  group,
  type,
  shouldStartAssessStrengthen
) => {
  history.push({
    pathname: `/deployment/${deploymentId}/${type}/group/${group}`,
    state: shouldStartAssessStrengthen
      ? { isAssessStrengthenLoop: true, doAssess: true }
      : null,
  });
};

export const gotoAssess = (history, deploymentId, group, type) => {
  // redirect back to user dashboard when complete
  history.push({
    pathname: `/deployment/${deploymentId}/${type}/assess/${group}`,
    state: {
      onCompleteRedirect: `/deployment/${deploymentId}/${type}/group/${group}`,
    },
  });
};

export const gotoStrengthen = (history, deploymentId, group, type) => {
  // redirect back to user dashboard when complete
  history.push({
    pathname: `/deployment/${deploymentId}/${type}/strengthen/${group}`,
    state: {
      onCompleteRedirect: `/deployment/${deploymentId}/${type}/group/${group}`,
    },
  });
};

export const useAssessStrengthenLoop = (groupId, deploymentId, type) => {
  const history = useHistory();
  const {
    isAssessStrengthenLoop = false,
    doAssess = false,
    doStrengthen = false,
  } = history.location.state || {};
  useEffect(() => {
    if (isAssessStrengthenLoop) {
      const loop = async () => {
        // wait for blinking icon
        await wait(2500);
        history.push({
          pathname: `/deployment/${deploymentId}/${type}/${
            doAssess ? "assess" : "strengthen"
          }/${groupId}`,
          state: { isAssessStrengthenLoop: true },
        });
      };
      loop();
    }
  }, []);
  return [doAssess, doStrengthen];
};
