import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {compose} from "recompose";

import {withFirebase} from "../../util/Firebase";
import * as ROUTES from "../../constants/routes";

import styles from "./index.module.scss";
import {connect} from "react-redux";
import {doLoginAuthUser} from "../../actions/session";
import {selectProfileUser} from "../../actions/profile";

const SignInPage = () => (
    <div className="text-center">
        <SignInForm/>
        <div className="mt-2">
            <img
                alt="Logo"
                src={process.env.PUBLIC_URL + "/images/app_icons/GLENlearn.png"}
                className={styles.glenImage}
            />
            <div className="text-muted">&copy; 2020 GLEN World</div>
        </div>
    </div>
);

const INITIAL_STATE = {
    email: "",
    password: "",
    error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
    "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an e-mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = (event, asGuest = false) => {
        if (asGuest) {
            // if sign in as guest, register a brand new account.
            // this is the exact same flow as in SignUp (see SignUp/index.js)
            this.props.firebase.doSignInAsGuest().then(async (authUser) => {
                const deployment = await this.props.firebase.createDeployment(
                    `Anonymous`
                );
                const adminAccount = await this.props.firebase.createAdminAccount(
                    authUser.user.uid,
                    "Anonymous",
                    "Anonymous",
                    {
                        [deployment.id]: "admin",
                    }
                );
                this.props.dispatch(doLoginAuthUser(authUser.user, adminAccount));

                const deploymentAccount = await this.props.firebase.createDeploymentAccount(
                    deployment.id,
                    "Guest",
                    1,
                    5
                );
                // same as ProfileList. We expect an ID and account data
                this.props.dispatch(
                    selectProfileUser({
                        id: deploymentAccount.id,
                        ...deploymentAccount.data,
                    })
                );
                this.props.history.push(`/deployment/${deployment.id}/glenLearn`);
            });
        } else {
            const {email, password} = this.state;
            this.props.firebase
                .doSignInWithEmailAndPassword(email, password)
                .then((authUser) => {
                    this.setState({...INITIAL_STATE});
                    this.props.firebase
                        .adminAccount(authUser.user.uid)
                        .get()
                        .then((snapshot) => {
                            const dbUser = snapshot.data();
                            this.props.dispatch(doLoginAuthUser(authUser.user, dbUser));
                            this.props.history.push(ROUTES.LANDING);
                        });
                })
                .catch((error) => {
                    this.setState({error});
                });
        }

        event.preventDefault();
    };

    onChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {email, password, error} = this.state;
        const isInvalid = password === "" || email === "";

        const SignInForm = <>
            <div className={styles.formSignIn}>
                <form onSubmit={this.onSubmit}>
                    <input
                        name="email"
                        className="form-control"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Email Address"
                        required
                    />
                    <input
                        name="password"
                        className="form-control"
                        value={password}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Password"
                    />
                    <button
                        disabled={isInvalid}
                        className="btn btn-md btn-primary btn-block mt-2"
                        type="submit"
                    >
                        Sign in
                    </button>

                    <div className="h6 text-muted mt-3">
                        {error && <p>{error.message}</p>}
                    </div>
                </form>
            </div>
        </>;

        return <div className="d-flex justify-content-around">
            <div className="w-50">
                <h1 className={styles.headerText}>New User?</h1>
                <Link to={ROUTES.SIGN_UP} className="btn btn-md btn-primary btn-block mt-2">Sign Up</Link>
                <hr/>
                <button
                    onClick={(e) => this.onSubmit(e, true)}
                    className="btn btn-link"
                >
                    Access as guest (No progress data saved)
                </button>
            </div>
            <div className="w-50">
                <h1 className={styles.headerText}>Existing User?</h1>
                {SignInForm}
                <hr/>
                <div className="mt-3 text-muted">
                    <Link to={ROUTES.RESET_PASSWORD}> Reset your password</Link>
                </div>
            </div>
        </div>

    }
}

class SignInGoogleBase extends Component {
    constructor(props) {
        super(props);

        this.state = {error: null};
    }

    onSubmit = (event) => {
        this.props.firebase
            .doSignInWithGoogle()
            .then((socialAuthUser) => {
                // Create a user in your Firebase Realtime Database too
                return this.props.firebase.adminAccount(socialAuthUser.user.uid).set(
                    {
                        username: socialAuthUser.user.displayName,
                        email: socialAuthUser.user.email,
                    },
                    {merge: true}
                );
            })
            .then(() => {
                this.setState({error: null});
                this.props.history.push(ROUTES.ProfileList);
            })
            .catch((error) => {
                if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                    error.message = ERROR_MSG_ACCOUNT_EXISTS;
                }

                this.setState({error});
            });

        event.preventDefault();
    };

    render() {
        const {error} = this.state;

        return (
            <form onSubmit={this.onSubmit}>
                <button className="btn btn-secondary" type="submit">
                    Sign In with Google
                </button>
                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SignInForm = compose(withRouter, withFirebase, connect())(SignInFormBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

export default SignInPage;

export {SignInForm, SignInGoogle};
