import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import cloudFunction from "./cloudFunction";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Firebase APIs */
    this.auth = app.auth();

    this.db = app.firestore();

    //see: https://firebase.google.com/docs/firestore/manage-data/enable-offline
    this.db.enablePersistence().catch((e) => console.error(e));

    this.functions = app.functions();

    /* Social Sign In Method Provider */
    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  doSendPasswordResetEmail = (emailAddress) => {
    return this.auth.sendPasswordResetEmail(emailAddress);
  };

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = async (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInAsGuest = () => this.auth.signInAnonymously();

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  // Data access APIs
  deleteAdminAccount = async () => {
    if (this.auth.currentUser) {
      await this.adminAccount(this.auth.currentUser.uid).delete();
      await this.auth.currentUser.delete();
    }
    return Promise.resolve();
  };

  adminAccount = (uid) => this.db.doc(`admin_account/${uid}`);
  deploymentAccounts = (deploymentId) =>
    this.db
      .collection(`deployment_account`)
      .where("deploymentId", "==", deploymentId)
      .orderBy("time");
  deploymentAccount = (deploymentAccountId) =>
    this.db.doc(`deployment_account/${deploymentAccountId}`);
  deployment = (pid) => this.db.doc(`deployments/${pid}`);

  deleteDeploymentAccount = (deploymentUserId) => {
    this.deploymentAccount(deploymentUserId).set(
      { username: `${deploymentUserId}-deleted`, deleted: true },
      { merge: true }
    );
    return Promise.resolve();
  };

  // Data write APIs
  createDeployment = (name, description = "") => {
    return this.db.collection(`deployments`).add({
      name,
      description,
      counters: {
        activitiesCompleted: 0,
        rhymesReadAloud: 0,
        storiesNarrated: 0,
        totalUsers: 0,
        wordsLearned: 0,
      },
      time: new Date().getTime(),
    });
  };

  createAdminAccount = async (uid, username, email, deployments) => {
    //keep in sync with Dashboard registration: pages/SignUp/index.js:onSubmit
    const data = {
      username,
      email,
      deployments,
      readOnlyDeployments: {},
      time: new Date().getTime(),
    };
    await this.adminAccount(uid).set(data, { merge: true });
    return data;
  };

  createDeploymentAccount = async (deploymentId, username, avatarId, age) => {
    const data = {
      deploymentId,
      username,
      avatarId,
      age,
      time: new Date().getTime(),
      profile: {
        glenLearn: {},
      },
    };

    const newDeploymentAccount = this.db.collection(`deployment_account`).doc();

    // not awaiting to allow adding accounts in offline mode. see approach:
    // https://stackoverflow.com/questions/49829714/firebase-firestore-get-document-id-after-adding-data-offline
    newDeploymentAccount.set(data);

    return { id: newDeploymentAccount.id, data };
  };

  getAssessmentCategoryDistribution = (deploymentId, assessment) =>
    cloudFunction(
      this,
      `getAssessmentCategoryDistribution?deploymentId=${deploymentId}&assessment=${assessment}`
    );

  updateDeploymentAccount = async (deploymentUserId, data) => {
    this.deploymentAccount(deploymentUserId).set(data, { merge: true });
    return Promise.resolve();
  };

  updateDeploymentAccountProfile = async (deploymentUserId, data) => {
    // updates just the profile key of the user record
    return this.updateDeploymentAccount(
      deploymentUserId,
      { profile: data },
      { merge: true }
    );
  };

  createActivityEvent = (event) => {
    console.log("Event record", event);
    return this.db.collection("activity_event").add(event);
  };
}

export default Firebase;
