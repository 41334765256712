import React, { useEffect, useRef } from "react";
import styles from "./RoundedText.module.scss";
import classnames from "classnames";
import textFit from "textfit";
import noop from "lodash/noop";
import CircleAnimation from "../CircleAnimation/CircleAnimation";

export default ({
  size = "sm",
  color = "green",
  bg = "black",
  border = null,
  withCircle = false,
  withRedHighlightAnimation = false,
  withGreenHighlightAnimation = false,
  minFontSize = 6,
  maxFontSize = 400,
  onClick = noop,
  children,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const doFit = () => {
      textFit(ref.current, {
        widthOnly: false,
        detectMultiLine: false,
        alignVert: true,
        //alignVertWithFlexbox: true,
        alignHoriz: true,
        minFontSize,
        maxFontSize,
        reProcess: true,
      });
    };
    window.addEventListener("resize", doFit);
    doFit();
    return () => window.removeEventListener("resize", doFit);
  }, [children, maxFontSize, minFontSize]);

  return (
    <div
      key={children}
      className={classnames(styles.textContainer, {
        [styles.greenHighlightAnimation]: withGreenHighlightAnimation,
        [styles.redHighlightAnimation]: withRedHighlightAnimation,
        [styles.borderGreen]: border === "green",
        [styles.borderRed]: border === "red",
      })}
    >
      {withCircle && <CircleAnimation />}
      <div
        ref={ref}
        onClick={onClick}
        className={classnames(styles.text, {
          [styles.sizeStandard]: size === "sm",
          [styles.sizeMd]: size === "md",
          [styles.sizeXl]: size === "xl",
          [styles.sizeXxl]: size === "xxl",
          [styles.sizeXxxl]: size === "xxxl",
          [styles.colorBlack]: color === "black",
          [styles.colorGreen]: color === "green",
          [styles.bgWhite]: bg === "white",
        })}
      >
        {children}
      </div>
    </div>
  );
};
