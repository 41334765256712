import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import React, { useCallback, useState } from "react";
import styles from "./Timeline.module.scss";
import classnames from "classnames";
import { events } from "../../../../../util/Timeline/timeline";
import { ReactComponent as StarIcon } from "./../../../../../components/Svg/Star.svg";
import { TYPES as types } from "../../../../../components/Lessons/util/groups";
import { Modal, ModalBody } from "reactstrap";
import Certificate from "../../../UserDashboard/WallOfFame/Certificate";
import useTimelineModal from "../../../../../util/Timeline/useTimelineModal";
import { useHistory, useParams } from "react-router-dom";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

const TimeLineIcon = {
  STAR: "star",
  FLAG: "flag",
};

const TimelineItem = ({ icon, title, time, onClick = () => {} }) => {
  const [visible, toggleVisibility] = useState(true);
  return (
    <li className={styles["timeline-item"]} onClick={onClick}>
      <div
        className={classnames(styles["timeline-badge"], styles["primary"])}
        onClick={() => toggleVisibility(!visible)}
      >
        <i className="material-icons">
          {icon === TimeLineIcon.STAR ? "star" : "flag"}
        </i>
      </div>
      <div
        className={classnames(styles["timeline-panel"], {
          [styles["hidden"]]: visible,
        })}
      >
        <div className={styles["timeline-heading"]}>
          <h4 className={`text-center ${styles["timeline-title"]}`}>{title}</h4>
          {time && (
            <p>
              <small className="text-muted">{time}</small>
            </p>
          )}
        </div>
      </div>
    </li>
  );
};

const ItemForEvent = ({ event, onCertificateClick, isAdmin }) => {
  let time = isAdmin ? (
    <span>
      <i className="material-icons">access_time</i> completed{" "}
      {timeAgo.format(event.time, "twitter")}
    </span>
  ) : null;

  if (event.type === events.GROUP_COMPLETED) {
    // default value for backwards compatibility.
    const { lessonType = types.GLEN_LEARN } = event.meta;

    let text;
    if (lessonType === types.GLEN_LEARN) {
      text = "Lessons";
    } else if (lessonType === types.GLEN_PHONICS) {
      text = "Sounds Lessons";
    } else if (lessonType === types.GLEN_VOCAB) {
      text = "Words Lessons";
    }

    return (
      <TimelineItem
        key={event.time}
        icon={TimeLineIcon.FLAG}
        time={time}
        title={
          <div>
            <StarIcon className={styles.starImage} />
            <div>
              {text} {event.meta.lessonStart}-{event.meta.lessonEnd}
            </div>
          </div>
        }
      />
    );
  } else if (event.type === events.CERTIFICATE) {
    const { lessonType } = event.meta;

    let description;
    if (lessonType === types.GLEN_LEARN) {
      description = `Completed ${event.meta.totalWords} words`;
    } else if (lessonType === types.GLEN_SHAPES) {
      // glen shapes has shapes and numbers achievements
      if (event.meta.totalShapes) {
        description = `Completed ${event.meta.totalShapes} shapes`;
      } else if (event.meta.totalNumbers) {
        description = `Completed ${event.meta.totalNumbers} shapes`;
      }
    } else if (lessonType === types.GLEN_PHONICS) {
      description = `Completed ${event.meta.totalSounds} sounds`;
    } else if (lessonType === types.GLEN_ABC) {
      description = `Completed letters A-Z`;
    }
    // we currently do not have GLEN Vocab certificates.
    return (
      <TimelineItem
        key={event.time}
        icon={TimeLineIcon.FLAG}
        time={time}
        onClick={onCertificateClick}
        title={
          isAdmin ? (
            description
          ) : (
            <img
              className={styles.certificateImage}
              src={process.env.PUBLIC_URL + "/images/certificate.png"}
            />
          )
        }
      />
    );
  } else {
    return null;
  }
};

export default ({ timelineEvents, profile, isAdmin = true }) => {
  const {
    selectedCertIndex,
    modalOpen,
    openModal,
    closeModal,
  } = useTimelineModal(timelineEvents);

  const history = useHistory();
  const { deploymentId } = useParams();

  const gotoWallOfFame = useCallback(() => {
    history.push(
      `/deployment/${deploymentId}/glenLearn/userDashboard/wallOfFame`
    );
  }, [deploymentId, history]);

  const closeBtn = (
    <i
      className="material-icons md-48 text-right p-2 float-right"
      style={{ cursor: "pointer" }}
      onClick={closeModal}
    >
      close
    </i>
  );

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        style={{ maxHeight: "75vh", maxWidth: "60vw" }}
      >
        {closeBtn}
        <ModalBody>
          {selectedCertIndex !== null && (
            <Certificate
              learnerName={profile.username}
              dateString={new Date(
                timelineEvents[selectedCertIndex].time
              ).toLocaleDateString("en-US")}
              meta={timelineEvents[selectedCertIndex].meta}
            />
          )}
        </ModalBody>
      </Modal>
      <ul className={`${styles["timeline"]} ${styles["timeline-horizontal"]}`}>
        {timelineEvents.map((event, i) => (
          <ItemForEvent
            key={event.id || i}
            event={event}
            // legacy functionality, for when events did not have a unique identifier.
            onCertificateClick={() =>
              event.id ? openModal(event.id) : gotoWallOfFame()
            }
            isAdmin={isAdmin}
          />
        ))}
      </ul>
    </div>
  );
};
