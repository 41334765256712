import sampleSize from "lodash/sampleSize";
import sample from "lodash/sample";
import React, { useEffect, useMemo, useState } from "react";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import ConsonantSoundsWithAnalytics from "../../../../games/ConsonantSounds/ConsonantSoundsWithAnalytics";
import wait from "../../../../util/wait";
import LessonCManager from "./manager";

const RepeatedConsonantSoundsGame = ({
  manager,
  updateProgress,
  onComplete,
}) => {
  const [gameState, setGameState] = useState(null);
  const initNewGame = (trialNum) => {
    let consonant = manager.getNextConsonant();
    let incorrectConsonant = manager
      .getConsonants()
      .filter((c) => c !== consonant)[0];

    //choose three correct words from the chosen consonant, and one from the incorrect consonant
    let correctWords = sampleSize(
      manager.wordsForConsonantOrderedByExposure(consonant),
      3
    );
    let confusionWord = sample(
      manager.wordsForConsonantOrderedByExposure(incorrectConsonant)
    );

    //take one correct word and move it to the candidate words (this is the correct answer)
    let answer = correctWords.pop();
    setGameState({
      trialNum,
      consonant,
      answer,
      confusionWord,
      correctWords,
    });
  };
  const onRoundComplete = () => {
    manager.addExposureToWord(gameState.answer);
    manager.incrementRound();
    updateProgress().then(() => {
      if (manager.gameIsComplete()) {
        onComplete();
      } else {
        initNewGame(gameState.trialNum + 1);
      }
    });
  };
  useEffect(() => initNewGame(1), []);
  if (!gameState) {
    return null;
  }
  return (
    <ConsonantSoundsWithAnalytics
      key={gameState.trialNum}
      consonant={gameState.consonant}
      answer={gameState.answer}
      confusionWord={gameState.confusionWord}
      correctWords={gameState.correctWords}
      allowIncorrect={false}
      onComplete={onRoundComplete}
    />
  );
};

const useGameContent = (parameters, onComplete) => {
  const manager = useMemo(() => new LessonCManager(parameters), [parameters]);
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => {
    setCurrentProgress(manager.getCurrentProgress());
    return wait(200);
  };
  const GameComponent = (
    <RepeatedConsonantSoundsGame
      manager={manager}
      updateProgress={updateProgress}
      onComplete={onComplete}
    />
  );
  return [GameComponent, currentProgress];
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
