import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./WordClick.module.scss";
import RoundedTextWithCheck from "../../components/RoundedTextWithCheck/RoundedTextWithCheck";
import { useResourceFromRegistry } from "../../util/Resource/useResource";
import shuffle from "lodash/shuffle";
import AudioContext from "../../util/Audio/context";
import noop from "lodash/noop";
import wait from "../../util/wait";
import DisableOverlay from "../../components/DisableOverlay/DisableOverlay";
import sample from "lodash/sample";
import WordReview from "../../components/WordReview/WordReview";

const WordClickGame = ({
  flashcard,
  shouldShowReview,
  confusionWords,
  onComplete,
}) => {
  const audioService = useContext(AudioContext);
  const [options, setOptions] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);

  useEffect(() => {
    audioService.playAudio(flashcard.audio).then(() => {
      setOptions(shuffle([flashcard.meaning, ...confusionWords]));
    });
  }, []);

  const onImageClick = () => audioService.playAudio(flashcard.audio);

  const onClick = async (word) => {
    setSelectedWord(word);
    if (word === flashcard.key) {
      await audioService.composeAndPlaySingleAudio("soundfx", "correct");
      //await audioService.playFX(getRandomEncouragementFX());
      await wait(400);
      onComplete(true);
    } else {
      await audioService.composeAndPlaySingleAudio("soundfx", "incorrect");
      onComplete(false);
    }
  };
  return (
    <div className="row h-100">
      <div className="col-4">
        <div className={styles.optionsContainer}>
          {options.map((word) => {
            return (
              <div key={word} className={styles.option}>
                <DisableOverlay enable={selectedWord !== null}>
                  <RoundedTextWithCheck
                    onClick={() => onClick(word)}
                    withCircle={
                      shouldShowReview &&
                      selectedWord !== null &&
                      selectedWord !== word &&
                      word === flashcard.meaning
                    }
                    isCorrect={
                      selectedWord === word &&
                      selectedWord === flashcard.meaning
                    }
                    isIncorrect={
                      selectedWord === word &&
                      selectedWord !== flashcard.meaning
                    }
                  >
                    {word}
                  </RoundedTextWithCheck>
                </DisableOverlay>
              </div>
            );
          })}
        </div>
      </div>
      <div className="offset-1 col-6">
        <div className={styles.card} onClick={onImageClick}>
          <div className={styles.cardInner}>
            <img className={styles.img} src={flashcard.image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ({
  targetWord,
  targetOverride = null,
  confusionWords,
  shouldShowReview = true,
  onStart = noop,
  onComplete = noop,
}) => {
  const audioService = useContext(AudioContext);
  const flashcardResource = useResourceFromRegistry("flashcards");

  const flashcard = useMemo(
    () => ({
      ...flashcardResource.getFlashcard(targetWord),
      ...targetOverride,
      /**
       * The targetOverride prop allows certain attributes like audio, image, and meaning to be
       * overriden in the flashcard corresponding to targetWord. Example:
       * targetOverride={ meaning: "b" }
       * will use "b" instead of "boy" for the "meaning" property (what is actually displayed onscreen)
       * of the "boy" flashcard.
       */
    }),
    []
  );

  // the review flashcard should not be the same image as the original flashcard.
  const reviewFlashcard = useMemo(() => {
    return {
      ...flashcard,
      // not all flashcards hve more than one image. in those cases, use the original flashcard.
      image:
        sample(flashcard.imageOptions.filter((i) => i !== flashcard.image)) ||
        flashcard.image,
    };
  }, [flashcard]);

  const [showReview, setShowReview] = useState(false);
  const [wasCorrectAnswer, setWasCorrectAnswer] = useState(null);
  const onWordClickComplete = async (wasCorrect) => {
    setWasCorrectAnswer(wasCorrect);
    if (shouldShowReview && !wasCorrect) {
      await audioService.playAudio(flashcard.audio);
      await wait(1500);
    }
    if (shouldShowReview) {
      setShowReview(true);
    } else {
      onComplete(wasCorrect);
    }
  };
  useEffect(() => {
    onStart();
  }, []);
  return (
    <div className={styles.container}>
      {!showReview && (
        <WordClickGame
          shouldShowReview={shouldShowReview}
          flashcard={flashcard}
          confusionWords={confusionWords}
          onComplete={onWordClickComplete}
        />
      )}
      {showReview && (
        <WordReview
          flashcard={reviewFlashcard}
          onComplete={() => onComplete(wasCorrectAnswer)}
        />
      )}
    </div>
  );
};
