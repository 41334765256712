import useFirebase from "../Firebase/useFirebase";
import useProfile from "./useProfile";
import { useMemo } from "react";

class ProfileManager {
  constructor(firebase, profileUser, key) {
    this.firebase = firebase;
    this.profileUser = profileUser;
    this.key = key;
  }

  _getProfile() {
    return this.profileUser?.profile?.[this.key] || {};
  }

  _updateProfile(data) {
    return this.firebase.updateDeploymentAccountProfile(this.profileUser.id, {
      [this.key]: data,
    });
  }
}

export class StatisticsProfileManager extends ProfileManager {
  constructor(firebase, profileUser) {
    super(firebase, profileUser, "statistics");
  }
  incrementCompletedExercises() {
    const current = this.getCompletedExercisesCount();
    return this._updateProfile({ completedExercisesCount: current + 1 });
  }
  getCompletedExercisesCount() {
    return this._getProfile().completedExercisesCount || 0;
  }
}

// base required fields for a "Lesson" profile. Used by GLEN Learn, GLEN Vocab, GLEN Phonics, etc.
class LessonsProfileManager extends ProfileManager {
  getCompletedAssessmentGroupLessonIds() {
    return this._getProfile().completedAssessmentLessonGroupIds || [];
  }

  markLessonGroupIdAsAssessed(groupId) {
    const existing = this.getCompletedAssessmentGroupLessonIds();
    const merged = new Set([groupId, ...existing]);
    return this._updateProfile({
      completedAssessmentLessonGroupIds: [...merged],
    });
  }

  getHasPlayedWelcomeAnimation() {
    return this._getProfile().hasPlayedWelcomeAnimation || false;
  }

  setHasPlayedWelcomeAnimation(value) {
    return this._updateProfile({ hasPlayedWelcomeAnimation: true });
  }

  getLastMasteredLesson() {
    return this._getProfile().lastMasteredLesson || 0;
  }

  setLastMasteredLesson(newLastMastered) {
    return this._updateProfile({ lastMasteredLesson: newLastMastered });
  }

  getLessonGroupsWithPlayedIntro() {
    return this._getProfile().lessonGroupsWithPlayedIntro || [];
  }

  markPlayedIntroForLessonGroupId(groupId) {
    const existing = this.getLessonGroupsWithPlayedIntro();
    const merged = new Set([groupId, ...existing]);
    return this._updateProfile({
      lessonGroupsWithPlayedIntro: [...merged],
    });
  }

  addTimelineEvent = (timelineEvent) => {
    const json = timelineEvent.toJson();
    let timelineEvents = this.getTimelineEvents();
    timelineEvents.push(json);
    this._updateProfile({ timelineEvents });
  };
  getTimelineEvents = () => {
    return this._getProfile().timelineEvents || [];
  };
}

export class GlenLearnProfileManager extends LessonsProfileManager {
  constructor(firebase, profileUser) {
    super(firebase, profileUser, "glenLearn");
  }

  getVocabAssessmentRecord = (word, withTextIndicator) => {
    const vocabAssessmentRecord = this._getProfile().vocabAssessmentRecord;
    //this looks like:
    // { word: { withText: [true, true, false], withoutText: [true, true, false] } }
    const wordRecord = vocabAssessmentRecord && vocabAssessmentRecord[word];
    if (!wordRecord) {
      return [];
    }
    const withIndicator =
      wordRecord[withTextIndicator ? "withText" : "withoutText"];
    return withIndicator || [];
  };
  updateVocabAssessmentRecord = (word, withTextIndicator, wasSuccess) => {
    let vocabAssessmentRecord = this._getProfile().vocabAssessmentRecord;
    if (!vocabAssessmentRecord) {
      vocabAssessmentRecord = {};
    }
    const wordRecord = vocabAssessmentRecord[word];
    if (!wordRecord) {
      vocabAssessmentRecord[word] = { withText: [], withoutText: [] };
    }
    vocabAssessmentRecord[word][
      withTextIndicator ? "withText" : "withoutText"
    ].push(wasSuccess);
    this._updateProfile({ vocabAssessmentRecord });
  };

  getPhonicsAssessmentRecord = (word) => {
    const phonicsAssessmentRecord = this._getProfile().phonicsAssessmentRecord;
    //this looks like:
    // { word: { withText: [true, true, false], withoutText: [true, true, false] } }
    const wordRecord = phonicsAssessmentRecord && phonicsAssessmentRecord[word];
    return wordRecord ? wordRecord : [];
  };
  updatePhonicsAssessmentRecord = (word, wasSuccess) => {
    let phonicsAssessmentRecord = this._getProfile().phonicsAssessmentRecord;
    if (!phonicsAssessmentRecord) {
      phonicsAssessmentRecord = {};
    }
    const wordRecord = phonicsAssessmentRecord[word];
    if (!wordRecord) {
      phonicsAssessmentRecord[word] = [];
    }
    phonicsAssessmentRecord[word].push(wasSuccess);
    this._updateProfile({ phonicsAssessmentRecord });
  };
}

export class GlenVocabProfileManager extends LessonsProfileManager {
  constructor(firebase, profileUser) {
    super(firebase, profileUser, "glenVocab");
  }

  getVocabAssessmentRecord = (word, withTextIndicator) => {
    const vocabAssessmentRecord = this._getProfile().vocabAssessmentRecord;
    //this looks like:
    // { word: { withText: [true, true, false], withoutText: [true, true, false] } }
    const wordRecord = vocabAssessmentRecord && vocabAssessmentRecord[word];
    if (!wordRecord) {
      return [];
    }
    const withIndicator =
      wordRecord[withTextIndicator ? "withText" : "withoutText"];
    return withIndicator || [];
  };

  updateVocabAssessmentRecord = (word, withTextIndicator, wasSuccess) => {
    let vocabAssessmentRecord = this._getProfile().vocabAssessmentRecord;
    if (!vocabAssessmentRecord) {
      vocabAssessmentRecord = {};
    }
    const wordRecord = vocabAssessmentRecord[word];
    if (!wordRecord) {
      vocabAssessmentRecord[word] = { withText: [], withoutText: [] };
    }
    vocabAssessmentRecord[word][
      withTextIndicator ? "withText" : "withoutText"
    ].push(wasSuccess);
    this._updateProfile({ vocabAssessmentRecord });
  };
}
export class GlenPhonicsProfileManager extends LessonsProfileManager {
  constructor(firebase, profileUser) {
    super(firebase, profileUser, "glenPhonics");
  }
  getPhonicsAssessmentRecord = (word) => {
    const phonicsAssessmentRecord = this._getProfile().phonicsAssessmentRecord;
    //this looks like:
    // { word: { withText: [true, true, false], withoutText: [true, true, false] } }
    const wordRecord = phonicsAssessmentRecord && phonicsAssessmentRecord[word];
    return wordRecord ? wordRecord : [];
  };

  updatePhonicsAssessmentRecord = (word, wasSuccess) => {
    let phonicsAssessmentRecord = this._getProfile().phonicsAssessmentRecord;
    if (!phonicsAssessmentRecord) {
      phonicsAssessmentRecord = {};
    }
    const wordRecord = phonicsAssessmentRecord[word];
    if (!wordRecord) {
      phonicsAssessmentRecord[word] = [];
    }
    phonicsAssessmentRecord[word].push(wasSuccess);
    this._updateProfile({ phonicsAssessmentRecord });
  };
}
export class GlenShapesProfileManager extends LessonsProfileManager {
  constructor(firebase, profileUser) {
    super(firebase, profileUser, "glenShapes");
  }
  getShapesAssessmentRecord = (word) => {
    const shapesAssessmentRecord = this._getProfile().shapesAssessmentRecord;
    const wordRecord = shapesAssessmentRecord && shapesAssessmentRecord[word];
    return wordRecord ? wordRecord : [];
  };
  updateShapesAssessmentRecord = (word, wasSuccess) => {
    let shapesAssessmentRecord = this._getProfile().shapesAssessmentRecord;
    if (!shapesAssessmentRecord) {
      shapesAssessmentRecord = {};
    }
    const wordRecord = shapesAssessmentRecord[word];
    if (!wordRecord) {
      shapesAssessmentRecord[word] = [];
    }
    shapesAssessmentRecord[word].push(wasSuccess);
    this._updateProfile({ shapesAssessmentRecord });
  };
}
export class GlenABCProfileManager extends LessonsProfileManager {
  constructor(firebase, profileUser) {
    super(firebase, profileUser, "glenABC");
  }
}

// profile classes for the currently logged in profile user, or a specific profile (for admin dashboard)
export const useGlenABCProfile = (profileOverride) => {
  const firebase = useFirebase();
  const profile = useProfile();
  return useMemo(
    () => new GlenABCProfileManager(firebase, profileOverride || profile),
    [firebase, profileOverride, profile]
  );
};
export const useGlenShapesProfile = (profileOverride) => {
  const firebase = useFirebase();
  const profile = useProfile();
  return useMemo(
    () => new GlenShapesProfileManager(firebase, profileOverride || profile),
    [firebase, profileOverride, profile]
  );
};
export const useGlenPhonicsProfile = (profileOverride) => {
  const firebase = useFirebase();
  const profile = useProfile();
  return useMemo(
    () => new GlenPhonicsProfileManager(firebase, profileOverride || profile),
    [firebase, profileOverride, profile]
  );
};
export const useGlenVocabProfile = (profileOverride) => {
  const firebase = useFirebase();
  const profile = useProfile();
  return useMemo(
    () => new GlenVocabProfileManager(firebase, profileOverride || profile),
    [firebase, profileOverride, profile]
  );
};
export const useGlenLearnProfile = (profileOverride) => {
  const firebase = useFirebase();
  const profile = useProfile();
  return useMemo(
    () => new GlenLearnProfileManager(firebase, profileOverride || profile),
    [firebase, profileOverride, profile]
  );
};
export const useStatisticsProfile = (profileOverride) => {
  const firebase = useFirebase();
  const profile = useProfile();
  return useMemo(
    () => new StatisticsProfileManager(firebase, profileOverride || profile),
    [firebase, profileOverride, profile]
  );
};
