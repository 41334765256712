import React from "react";
import confetti from "canvas-confetti";
import { useEffect } from "react";

export const ConfettiAnimation = {
  BASIC_CANNON: "basic_cannon",
  FIREWORKS: "fireworks",
};

const playBasicCannon = () => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 },
  });
};

const playFireworks = () => {
  let duration = 4 * 1000;
  let animationEnd = Date.now() + duration;
  let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  let interval = setInterval(function () {
    let timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    let particleCount = 50 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      })
    );
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      })
    );
  }, 250);
};

export default ({ animation }) => {
  useEffect(() => {
    if (animation === ConfettiAnimation.BASIC_CANNON) {
      playBasicCannon();
    } else if (animation === ConfettiAnimation.FIREWORKS) {
      playFireworks();
    }
  }, [animation]);
  return <div></div>;
};
