import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  BottomButton,
  GameContainer,
  GameWrapper,
  PublicSpiralTopBar,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import GlenBooksWithAnalytics from "../../games/GlenBooks/GlenBooksWithAnalytics";
import { activity } from "../../util/Analytics/events";
import { WithAnonymousLogin } from "../../util/anonymousLogin";

export const GlenBooksBookWithAuth = () => <Book withAuth={true} />;
export const GlenBooksBookWithoutAuth = () => (
  <WithAnonymousLogin
    deploymentId={process.env.REACT_APP_GLEN_BOOKS_ANONYMOUS_DEPLOYMENT_ID}
  >
    <Book withAuth={false} />
  </WithAnonymousLogin>
);

const Book = ({ withAuth }) => {
  const { book } = useParams();
  const history = useHistory();
  const onComplete = () => history.goBack();
  const onClickSpiral = () => history.push("/glenBooks/categories");
  const onClickInfo = () => history.push("/about/glenBooks");

  return (
    <GameWrapper>
      {withAuth ? (
        <AuthedAndProfiledTopBar />
      ) : (
        <PublicSpiralTopBar
          onClickSpiral={onClickSpiral}
          onClickInfo={onClickInfo}
        />
      )}
      <GameContainer>
        <GlenBooksWithAnalytics
          activity={activity.BOOK}
          bookId={book}
          onComplete={onComplete}
          autoTurnCover={false}
          autoTurnEnabled={false}
          isSingle={false}
          rhymes={false}
          textAppears={false}
          isHeartBeat={false}
        />
      </GameContainer>
      <BottomBar
        left={
          <BottomButton
            src={process.env.PUBLIC_URL + "/images/back.png"}
            onClick={onComplete}
          />
        }
      />
    </GameWrapper>
  );
};
