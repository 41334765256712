import noop from "lodash/noop";
import React, { useEffect } from "react";
import { activity } from "../../util/Analytics/events";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";
import NumbersBook from "./NumbersBook";

const eventData = {
  activity: activity.NUMBERS_BOOK,
};

export default ({ onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();

  const onCompleteOverride = () => {
    completeEvent({ ...eventData });
    onComplete();
  };

  useEffect(() => {
    startEvent(eventData);
  }, [startEvent]);
  return <NumbersBook {...props} onComplete={onCompleteOverride} />;
};
