import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenVocabProfile } from "../../../util/Profile/ProfileUtil";
import Assess from "../../../components/Lessons/Assess/Assess";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const profile = useGlenVocabProfile();
  const lessonResource = useResourceFromRegistry("vocabLessons");
  const lessonGroupResource = useResourceFromRegistry("vocabLessonGroups");

  return (
    <Assess
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      profile={profile}
      type={types.GLEN_VOCAB}
    />
  );
};
