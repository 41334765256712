import React from "react";
import PhonicStrengthen1WithAnalytics from "./PhonicStrengthen1/PhonicStrengthen1WithAnalytics";
import PhonicStrengthen0WithAnalytics from "./PhonicStrengthen0/PhonicStrengthen0WithAnalytics";
import PhonicStrengthen2WithAnalytics from "./PhonicStrengthen2/PhonicStrengthen2WithAnalytics";

export default ({
  groupId,
  consonantWordMap,
  recentlyFailedSounds,
  selectedVariant,
  onComplete,
  wasManual,
}) => {
  if (selectedVariant === 0) {
    return (
      <PhonicStrengthen0WithAnalytics
        consonantWordMap={consonantWordMap}
        recentlyFailedSounds={recentlyFailedSounds}
        wasManual={wasManual}
        lessonGroupId={groupId}
        onComplete={onComplete}
      />
    );
  } else if (selectedVariant === 1) {
    return (
      <PhonicStrengthen1WithAnalytics
        consonantWordMap={consonantWordMap}
        recentlyFailedSounds={recentlyFailedSounds}
        wasManual={wasManual}
        lessonGroupId={groupId}
        onComplete={onComplete}
      />
    );
  } else if (selectedVariant === 2) {
    return (
      <PhonicStrengthen2WithAnalytics
        consonantWordMap={consonantWordMap}
        recentlyFailedSounds={recentlyFailedSounds}
        wasManual={wasManual}
        lessonGroupId={groupId}
        onComplete={onComplete}
      />
    );
  }
};
