/* AnswerStatus is an object that acts as an enum for the different
statuses that an answer component can have */
const AnswerStatus = {
  CORRECT: "correct",
  INCORRECT: "incorrect",
  REVIEW_CORRECT: "review correct",
  NONE: "none",
};

Object.freeze(AnswerStatus);

export default AnswerStatus;
