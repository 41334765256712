import styles from "./CategoryIcon.module.scss";
import React from "react";

export default ({ imageSrc, imageText, onClick }) => {
  return (
    <div onClick={onClick}>
      <div>
        <img className={styles.iconImage} src={imageSrc} />
      </div>
      <div className={styles.iconText}>{imageText}</div>
    </div>
  );
};
