import React, { useEffect, useMemo } from "react";
import PhonicStrengthen1Manager from "./manager";
import LessonC from "../../../LessonTypes/LessonC/LessonC";

export default ({
  consonantWordMap,
  recentlyFailedSounds,
  onStart,
  onComplete,
}) => {
  const manager = useMemo(
    () => new PhonicStrengthen1Manager(consonantWordMap, recentlyFailedSounds),
    []
  );
  useEffect(onStart, []);
  return (
    <LessonC parameters={manager.lessonCParameterMap} onComplete={onComplete} />
  );
};
