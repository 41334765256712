import HTML5Audio from "./implementations/HTML5Audio.js";
import Cordova from "./implementations/cordova.js";
import { getAudioPath } from "../../Audio/Audio";

class SyncedAudio {
  constructor(audioPath) {
    const resolvedPath = getAudioPath(audioPath);
    this.audio = window.Cordova
      ? new Cordova(resolvedPath)
      : new HTML5Audio(resolvedPath);
  }

  deconstruct() {
    this.pause();
    this.clearCues();
    this.audio.deconstruct();
    return this;
  }

  atTime(time, whenFinishedCallback) {
    this.audio.atTime(time, whenFinishedCallback);
    return this;
  }

  clearCues() {
    this.audio.clearCues();
    return this;
  }

  play() {
    this.audio.play();
    return this;
  }

  pause() {
    this.audio.pause();
    return this;
  }

  setCurrentAudioTime(position) {
    this.audio.seekTo(position);
    return this;
  }
}
export default SyncedAudio;
