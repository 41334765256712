export const activity = {
  LESSON: "lesson",

  // assess & strengthen
  COMPREHENSIVE_ASSESSMENT: "comprehensiveAssessment",
  KSEP_ASSESSMENT: "ksepAssessment",
  VOCAB_ASSESS: "vocabAssess",
  VOCAB_STRENGTHEN: "vocabStrengthen",
  PHONIC_ASSESS: "phonicAssess",
  PHONIC_STRENGTHEN: "phonicStrengthen",
  SHAPES_ASSESS: "shapesAssess",
  SHAPES_STRENGTHEN: "shapesStrengthen",

  // lesson exercises
  ENTERTAINMENT: "entertainment",
  BOOK: "book",
  RHYME: "rhyme",
  NUMBERS_BOOK: "numbersBook",
  SOUND_CLICK: "soundClick",
  SOUND_CLICK_1: "soundClick1",
  SOUND_CLICK_2: "soundClick2",
  IMAGE_CLICK: "imageClick",
  WORD_CLICK: "wordClick",
  GLEN_MATCH: "glenMatch",
  GLEN_WRITE: "glenWrite",
  DESCRIBE_IT: "describeIt",
  CONSONANT_SOUNDS: "consonantSounds",
  TRACE_AND_SOUND: "traceAndSound",
  SELECT_ANSWER: "selectAnswer",
  MULTIPLE_CHOICE: "multipleChoice",
  ALPHABET_SYNC: "alphabetSync",
};

// exercises that should increment "total activities"
export const LESSON_EXERCISES = [
  activity.ENTERTAINMENT,
  activity.BOOK,
  activity.RHYME,
  activity.NUMBERS_BOOK,
  activity.SOUND_CLICK,
  activity.SOUND_CLICK_1,
  activity.SOUND_CLICK_2,
  activity.IMAGE_CLICK,
  activity.WORD_CLICK,
  activity.GLEN_MATCH,
  activity.GLEN_WRITE,
  activity.DESCRIBE_IT,
  activity.CONSONANT_SOUNDS,
  activity.TRACE_AND_SOUND,
  activity.SELECT_ANSWER,
  activity.MULTIPLE_CHOICE,
  activity.ALPHABET_SYNC,
];

export const events = {
  START_ACTIVITY: "startActivity",
  COMPLETE_ACTIVITY: "completeActivity",
};
