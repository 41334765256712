import React, { useContext, useEffect, useState } from "react";
import NumberAnswer from "../../Answers/NumberAnswer/NumberAnswer";
import styles from "./NumberReview.module.scss";
import wait from "../../../../util/wait";
import numberToWord from "../../../../constants/numberToWord";
import AudioContext from "../../../../util/Audio/context";
import classnames from "classnames";

export default ({ number, onComplete }) => {
  const [showNumber, setShowNumber] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const audioService = useContext(AudioContext);

  useEffect(() => {
    const fx = async () => {
      await wait(200);
      setShowNumber(true);
      await audioService.composeAndPlaySingleAudio(
        "numbers",
        numberToWord[number]
      );
      await wait(100);
      setShowCard(true);
      // await audioService.composeAndPlayAudio([
      //     {
      //         "numbers",
      //         // e.g. "circles"
      //     }
      // ])
      await wait(500);
      onComplete();
    };
    fx();
  }, [audioService, number, onComplete]);

  return (
    <div className="row h-100">
      <div className="col-3 d-flex justify-content-center align-items-center">
        <div
          className={classnames(styles.number, {
            invisible: !showNumber,
            "animated fadeIn": showNumber,
          })}
        >
          {number}
        </div>
      </div>
      <div className="col-1">&nbsp;</div>
      <div className="col-5 d-flex justify-content-center align-items-center">
        <div
          className={classnames({
            invisible: !showCard,
            "animated fadeIn": showCard,
          })}
        >
          <NumberAnswer number={number} />
        </div>
      </div>
    </div>
  );
};
