import { useHistory, useParams } from "react-router-dom";
import useFirebase from "../../../util/Firebase/useFirebase";
import {
  AuthedTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import ProfileForm from "../ProfileForm/ProfileForm";
import { useProfilesForDeployment } from "../../../util/Profile/useProfile";
import { ClipLoader } from "react-spinners";
import { profileAlreadyExists } from "../util";

export default () => {
  const { deploymentId, deploymentAccountId } = useParams();
  const firebase = useFirebase();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  const [username, setUsername] = useState("");
  const [age, setAge] = useState(null);
  const [avatarId, setAvatarId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [existingProfiles, profilesLoading] = useProfilesForDeployment(
    deploymentId
  );
  const isFilledOut = username && age && avatarId;

  //load the deployment profile into our local state
  useEffect(() => {
    if (!profilesLoading) {
      const deploymentProfile = existingProfiles.docs
        .find((account) => account.id === deploymentAccountId)
        .data();
      setUsername(deploymentProfile.username);
      setAge(deploymentProfile.age);
      setAvatarId(deploymentProfile.avatarId);
    }
  }, [deploymentAccountId, existingProfiles, profilesLoading]);

  const onBack = () =>
    history.push({
      state: { editEnabled: true },
      pathname: `/deployment/${deploymentId}/profiles`,
    });

  const showError = (message) => {
    setErrorMessage(message);
    setIsDisabled(false);
  };

  const updateUsername = async () => {
    if (isDisabled) {
      return false;
    }
    setIsDisabled(true);
    if (!isFilledOut) {
      showError("Please fill out all fields");
    } else if (
      profileAlreadyExists(
        username,
        existingProfiles.docs.filter((doc) => doc.id !== deploymentAccountId)
      )
    ) {
      //if a profile exists with the name we are adding and isnt the profile that we are editing.
      showError("Username taken, please try a different one");
    } else {
      await firebase.updateDeploymentAccount(deploymentAccountId, {
        username,
        avatarId,
        age,
      });
      onBack();
    }
  };

  const RightIcon = () => (
    <div className="text-right">
      <button
        disabled={isDisabled || !isFilledOut}
        className={classnames("btn btn-lg btn-primary", {
          "animated pulse infinite": isFilledOut,
        })}
        type="button"
        onClick={updateUsername}
      >
        Done
      </button>
    </div>
  );

  return (
    <GameWrapper>
      <AuthedTopBar />
      <GameContainer>
        {profilesLoading ? (
          <ClipLoader size={75} />
        ) : (
          <ProfileForm
            state={{ username, age, avatarId }}
            onSetAge={setAge}
            onSetUsername={setUsername}
            onSetAvatarId={setAvatarId}
            error={errorMessage}
          />
        )}
      </GameContainer>
      <BottomBar left={<BackButton onClick={onBack} />} right={<RightIcon />} />
    </GameWrapper>
  );
};
