import React, { useEffect, useMemo, useState, useCallback } from "react";
import AudioSync from "../../util/SyncedAudio/audio/audio";
import styles from "./P1.module.scss";
import classnames from "classnames";
import { ReactComponent as MusicIcon } from "./../../components/Svg/MusicIcon.svg";
import CircleAnimation from "../../components/CircleAnimation/CircleAnimation";
import { TIMINGS, LINES } from "./util";
import wait from "../../util/wait";

const endOfLineLetters = LINES.map((line) => line.slice(-1));

// this is the original A2 exercise.
// TODO: adapt to new Practice version
export default ({ onComplete = () => {} }) => {
  const [visibleRowsUpTo, setVisibleRowsUpTo] = useState(-1);
  const [activeRowIdx, setActiveRowIdx] = useState(0);
  const [currentLetter, setCurrentLetter] = useState(null);
  const [endOfLineLetter, setEndOfLineLetter] = useState(null);

  useEffect(() => {
    const fx = async () => {
      if (endOfLineLetter) {
        Audio.pause();
        const nextRow = activeRowIdx + 1;
        if (nextRow === LINES.length) {
          await wait(500);
          onComplete();
        } else {
          setActiveRowIdx(nextRow);
        }
      }
    };
    fx();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endOfLineLetter]);

  const Audio = useMemo(() => {
    const audio = new AudioSync("/content/audio/alphabet/ABCsong.mp3");
    // active letter timings
    TIMINGS.forEach((t, i) => {
      audio.atTime(t.startTime, () => {
        setCurrentLetter(t.letter);
      });
      if (endOfLineLetters.includes(t.letter)) {
        audio.atTime(t.endTime, () => {
          setEndOfLineLetter(t.letter);
        });
      }
    });
    return audio;
  }, []);

  const playIcon = useCallback(() => {
    Audio.play();
    setVisibleRowsUpTo(visibleRowsUpTo + 1);
  }, [Audio, visibleRowsUpTo]);

  // when active row index changes, pause audio
  useEffect(() => {
    Audio.pause();
    return () => Audio.pause();
  }, [Audio, activeRowIdx]);

  return (
    <div className={styles.alphabetContainer}>
      {LINES.map((line, lineIndex) => (
        <div key={line} className="d-flex flex-row">
          <div className={styles.musicCircleContainer} onClick={playIcon}>
            <div className="position-relative">
              {lineIndex === activeRowIdx && lineIndex > visibleRowsUpTo && (
                <CircleAnimation
                  key={lineIndex}
                  delay={500}
                  offsets={{ top: 0, bottom: 0, left: 0, right: 0 }}
                />
              )}
              <div
                className={classnames("mx-auto", styles.musicIconContainer, {
                  "animated flash slow infinite":
                    lineIndex === visibleRowsUpTo + 1,
                })}
              >
                {lineIndex === activeRowIdx && <MusicIcon />}
              </div>
            </div>
          </div>
          <div
            key={line}
            className={classnames(styles.rowContainer, {
              [`animated fastest fadeInUp`]: lineIndex <= visibleRowsUpTo,
              [styles.activeRow]: lineIndex <= visibleRowsUpTo,
            })}
          >
            {line.split("").map((letter) => {
              return (
                <div
                  key={letter}
                  className={classnames(styles.letter, {
                    [`animated fastest fadeInUp`]: letter <= currentLetter,
                    [styles.activeLetter]: letter === currentLetter,
                    [styles.activeLetter]:
                      letter === currentLetter && lineIndex === activeRowIdx,
                  })}
                >
                  {letter}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
