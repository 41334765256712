import React from "react";
import styles from "./AudioRepeat.module.scss";
import hearAgainImg from "./hear_again.png";

export default ({ onClick }) => {
  return (
    <div className={`${styles.repeatHolder} animated fadeIn`} onClick={onClick}>
      <img
        className={styles.repeatAudio}
        src={hearAgainImg}
        alt="Replay audio"
      />
    </div>
  );
};
