import React from "react";
import { activity } from "../../../../util/Analytics/events";
import noop from "lodash/noop";
import { useTimedAnalytics } from "../../util/hooks";
import ShapesStrengthen from "./ShapesStrengthen";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.SHAPES_STRENGTHEN,
    wasManual: props.wasManual,
    lessonGroupId: props.lessonGroupId,
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  const onCompleteOverride = () => {
    completeEvent({ ...eventData });
    onComplete();
  };
  return (
    <ShapesStrengthen
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
      {...props}
    />
  );
};
