import React, { useContext, useLayoutEffect, useEffect, useMemo, useRef, useState } from "react";
import { useResourceFromRegistry } from "../../util/Resource/useResource";
import Tracer from "tracer/dist/bundle";
import AudioContext from "../../util/Audio/context";
import wait from "../../util/wait";
import LargeCard from "../../components/LargeCard/LargeCard";
import noop from "lodash/noop";
import styles from "./GlenWrite.module.scss";

const TracerGame = ({
  word,
  highlightMap,
  onHighlight = noop,
  onTraceComplete = noop,
  onLetterSuccess = noop,
  onComplete = noop,
}) => {
  const ref = useRef(null);
  useLayoutEffect(() => {
    let tracer;
    if (ref.current && word) {
      tracer = new Tracer({
        element: ref.current,
        width: document.getElementById("gameContainer").clientWidth,
        height: document.getElementById("gameContainer").clientHeight,
        initialX: 50,
        initialY: 75,
        wordText: word,
        highlightMap: highlightMap,
        onHighlightCallback: onHighlight,
        onTraceCompleteCallback: onTraceComplete,
        onLetterSuccessCallback: onLetterSuccess,
        onCompleteCallback: onComplete,
      });
      tracer.draw();
    }
    return () => {
      tracer && tracer.destroy();
    }
  }, [word]);
  return <div ref={ref}></div>;
};

const TracerReview = ({ flashcard, onComplete }) => {
  const audioService = useContext(AudioContext);

  useEffect(() => {
    const reviewStart = async () => {
      await audioService.playAudio(flashcard.audio);
      onComplete();
    };
    reviewStart();
  }, []);

  return (
    <div className={styles.reviewContainer}>
      <LargeCard image={flashcard.image} text={flashcard.meaning} />
    </div>
  );
};

export default ({ word, onStart, onComplete }) => {
  const flashcardResource = useResourceFromRegistry("flashcards");
  const flashcard = useMemo(() => flashcardResource.getFlashcard(word), [
    flashcardResource,
    word,
  ]);

  const [showReview, setShowReview] = useState(false);
  const audioService = useContext(AudioContext);
  const wordsToSoundsResource = useResourceFromRegistry(
    "wordsToSoundsResource"
  );
  const { sounds, highlightMap } = wordsToSoundsResource.getWord(word);
  const highlightSounds = sounds.filter((s) => s !== null);

  const onCompleteCallback = async () => {
    await wait(700);
    await audioService.playAudio(flashcard.audio);
    await wait(1500);
    setShowReview(true);
  };

  const playAudio = (audioPath) => {
    const [type, audio] = audioPath.split("/");
    if (type === "vowels" || type === "consonants") {
      audioService.composeAndPlayAudio([
        {
          directory: type,
          files: [audio],
        },
      ]);
    }
  };
  const onLetterSuccess = ({ letter, position }) => {
    const sound = sounds[position];
    // some segments have a "null" audio associated with them.
    if (sound) {
      playAudio(sound);
    }
  };

  const onTraceComplete = () => {
    audioService.composeAndPlaySingleAudio("soundfx", "correct");
  };

  const onHighlight = (i) => {
    console.log("HIGHLIGHT", i);

    //const index = highlightMap[i];
    playAudio(highlightSounds[i]);
  };

  useEffect(onStart, []);

  if (showReview) {
    return <TracerReview flashcard={flashcard} onComplete={onComplete} />;
  }

  return (
    <div id="gameContainer" style={{ width: "100%", height: "100%" }}>
      <TracerGame
        word={flashcard.meaning}
        highlightMap={highlightMap}
        onTraceComplete={onTraceComplete}
        onLetterSuccess={onLetterSuccess}
        onHighlight={onHighlight}
        onComplete={onCompleteCallback}
      />
    </div>
  );
};
