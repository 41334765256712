import GlenBooksWithAnalytics from "../../../games/GlenBooks/GlenBooksWithAnalytics";
import { activity } from "../../../util/Analytics/events";
import {
  AuthedAndProfiledTopBar,
  GameContainer,
  GameWrapper,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import React from "react";

export default ({ parameters, onComplete }) => {
  const { type, id } = parameters;
  let typeToGame = {
    book: (
      <GlenBooksWithAnalytics
        activity={activity.BOOK}
        bookId={id}
        onComplete={onComplete}
      />
    ),
    rhyme: (
      <GlenBooksWithAnalytics
        activity={activity.RHYME}
        autoTurnCover={true}
        bookId={id}
        rhymes={true}
        onComplete={onComplete}
      />
    ),
  };
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>{typeToGame[type]}</GameContainer>
    </GameWrapper>
  );
};
