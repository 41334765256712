import { combineReducers } from "redux";
import sessionReducer from "./session";
import profileReducer from "./profile";

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  profileState: profileReducer,
});

export default rootReducer;
