import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenLearnProfile } from "../../../util/Profile/ProfileUtil";
import Assess from "../../../components/Lessons/Assess/Assess";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const lessonResource = useResourceFromRegistry("lessons");
  const lessonGroupResource = useResourceFromRegistry("lessonGroups");
  const glenLearnProfile = useGlenLearnProfile();
  return (
    <Assess
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      profile={glenLearnProfile}
      type={types.GLEN_LEARN}
    />
  );
};
