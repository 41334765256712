import { LESSON_TYPE } from "../../components/Lessons/LessonTypes/constants";

export const mapGroupsToCompletedConcepts = (
  lastMastered,
  lessonResource,
  collectedWordsResource,
  flashcardResource
) => {
  let completedConcepts = [];
  if (lastMastered > 0) {
    completedConcepts = getConceptsBetweenLessons(
      lessonResource,
      1,
      lastMastered
    );
  }

  let groupsToCompletedConcepts = [];
  for (const [groupKey, groupConcepts] of Object.entries(
    collectedWordsResource.content
  )) {
    groupsToCompletedConcepts[groupKey] = groupConcepts
      .filter((concept) => completedConcepts.includes(concept))
      .map((concept) => flashcardResource.getFlashcard(concept));
  }
  return groupsToCompletedConcepts;
};

export const getConceptsBetweenLessons = (
  lessonResource,
  lessonStart,
  lessonEnd
) => {
  //map each lesson from (lessonStart, lessonEnd) to the set of concepts within.
  //vocab_list (L1,L2) = union of “content” classes from lessons of type A, and “LessonB.Class2”
  //classes for lessons of type B (the latter may be redundant, but that may depend on the set of lessons selected)
  let concepts = new Set();
  for (let i = lessonStart; i <= lessonEnd; i++) {
    let lesson = lessonResource.getLesson(i);
    if (
      lesson.type === LESSON_TYPE.A ||
      lesson.type === LESSON_TYPE.A1 ||
      lesson.type === LESSON_TYPE.A2 ||
      lesson.type === LESSON_TYPE.A3
    ) {
      lesson.parameters.content.forEach(concepts.add, concepts);
    } else if (lesson.type === LESSON_TYPE.B) {
      lesson.parameters.classes[1].forEach(concepts.add, concepts);
    }
  }

  return Array.from(concepts);
};

export const getPhonicsBetweenLessons = (
  lessonResource,
  lessonStart,
  lessonEnd
) => {
  let consonantWordMap = {};

  //make a sound => set of words map
  for (let i = lessonStart; i <= lessonEnd; i++) {
    let lesson = lessonResource.getLesson(i);
    if (lesson.type === LESSON_TYPE.C) {
      for (const [consonant, value] of Object.entries(
        lesson.parameters.content
      )) {
        const words = consonantWordMap[consonant] || new Set();
        value.words.forEach((c) => words.add(c));
        consonantWordMap[consonant] = words;
      }
    } else if (
      lesson.type === LESSON_TYPE.C2 ||
      lesson.type === LESSON_TYPE.C3
    ) {
      for (const [consonant, value] of Object.entries(
        lesson.parameters.content
      )) {
        const words = consonantWordMap[consonant] || new Set();
        value.forEach((c) => words.add(c));
        consonantWordMap[consonant] = words;
      }
    }
  }
  console.log("MAP IS", consonantWordMap);

  //convert the sets back to arrays.
  Object.keys(consonantWordMap).forEach((sound) => {
    consonantWordMap[sound] = Array.from(consonantWordMap[sound]);
  });

  return consonantWordMap;
};

export const getGroupsBetween = function (
  lessonResource,
  lessonStart,
  lessonEnd
) {
  //returns all the groups between two lessons
  let groups = [];
  for (let i = lessonStart; i <= lessonEnd; i++) {
    let lesson = lessonResource.getLesson(i);
    if (
      lesson.type === "A" ||
      lesson.type === "A1" ||
      lesson.type === "A2" ||
      lesson.type === "A3"
    ) {
      groups.push(lesson.parameters.content);
    } else if (lesson.type === "B") {
      groups.push(lesson.parameters.classes[1]);
    }
  }
  return groups;
};

export const getNumWordsLearned = (lastMastered, lessonResource) => {
  let completedConcepts = [];
  if (lastMastered > 0) {
    completedConcepts = getConceptsBetweenLessons(
      lessonResource,
      1,
      lastMastered
    );
  }
  return completedConcepts.length;
};

export const getNumPhonicsInLesson = (lessonId, lessonResource) => {
  let completedPhonics = [];
  if (lessonId > 0) {
    completedPhonics = Object.keys(getPhonicsBetweenLessons(
        lessonResource,
        lessonId,
        lessonId
    ))
  }
  return completedPhonics.length;
};

export const getNumWordsInLesson = (lessonId, lessonResource) => {
  let completedConcepts = [];
  if (lessonId > 0) {
    completedConcepts = getConceptsBetweenLessons(
      lessonResource,
      lessonId,
      lessonId
    );
  }
  return completedConcepts.length;
};
