import React, { useState, useMemo } from "react";
import styles from "./LessonCard.module.scss";
import overlay from "./gwlessons_overlay.png";
import sampleSize from "lodash/sampleSize";
import classnames from "classnames";
import noop from "lodash/noop";
import { ClipLoader } from "react-spinners";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { LESSON_A_GROUP, LESSON_TYPE } from "../LessonTypes/constants";
import { ReactComponent as CheckIcon } from "./../../Svg/Check.svg";
import LottieGuide from "../../Guide/LottieGuide";
import { ReactComponent as PlayIcon } from "../../Svg/Play.svg";

const useGroupImages = (lesson) => {
  const flashcardResource = useResourceFromRegistry("flashcards");
  const describeItResource = useResourceFromRegistry("describeItResource");
  return useMemo(() => {
    if (LESSON_A_GROUP.includes(lesson.type)) {
      let words = sampleSize(lesson.parameters.content, 4);
      return words.map((w) => flashcardResource.getFlashcard(w).image);
    } else if (lesson.type === LESSON_TYPE.B) {
      let words = sampleSize(lesson.parameters.classes[2], 4);
      return words.map((w) => describeItResource.getSentence(w).image);
    } else if (lesson.type === LESSON_TYPE.C) {
      let words = [];
      Object.keys(lesson.parameters.content).forEach((k) => {
        words = words.concat(lesson.parameters.content[k].words);
      });
      return sampleSize(words, 4).map(
        (w) => flashcardResource.getFlashcard(w).image
      );
    } else if (
      lesson.type === LESSON_TYPE.C2 ||
      lesson.type === LESSON_TYPE.C3
    ) {
      let words = [];
      Object.values(lesson.parameters.content).forEach((value) => {
        words = words.concat([...value]);
      });
      return sampleSize(words, 4).map(
        (w) => flashcardResource.getFlashcard(w).image
      );
    } else if (lesson.type === LESSON_TYPE.S) {
      let allImages = new Set();
      lesson.parameters.forEach((instance) =>
        allImages.add(instance.context.image)
      );
      return sampleSize([...allImages], 4);
    } else if (lesson.type === LESSON_TYPE.N0) {
      return sampleSize(["one", "two", "three", "four", "five"], 4).map(
        (w) => flashcardResource.getFlashcard(w).image
      );
    } else if (lesson.type === LESSON_TYPE.DYNAMIC) {
      return lesson.parameters.images;
    }
  }, [describeItResource, flashcardResource, lesson.parameters, lesson.type]);
};

export default ({
  lesson,
  isEnabled,
  isMastered,
  isActive = false,
  onClick,
  onGuideStart = noop,
  onGuideStop = noop,
}) => {
  const images = useGroupImages(lesson);
  const [lottieGuideActive, setLottieGuideActive] = useState(false);

  const clickCard = () => {
    if (!isEnabled) {
      return;
    }
    if (lesson.introAudio) {
      onGuideStart();
      setLottieGuideActive(true);
    } else {
      onClick();
    }
  };

  const onGuideFinished = () => {
    onGuideStop();
    onClick();
  };

  return (
    <div
      className={classnames(styles.cardContainer, {
        "animated pulse": lottieGuideActive,
      })}
      onClick={clickCard}
    >
      {lottieGuideActive && (
        <LottieGuide
          audio={[
            {
              directory: "narrator",
              files: lesson.introAudio,
            },
          ]}
          onComplete={onGuideFinished}
        />
      )}
      {!isEnabled && <div className={styles.disabledOverlay} />}
      {isMastered && <CheckIcon className={styles.completed} />}
      {isActive && <PlayIcon className={styles.play} />}
      <div className={styles.lessonImageWrapper}>
        <img alt="lesson" className={styles.lessonImage} src={overlay} />
        <div className={styles.textLesson}>{lesson.number}</div>
      </div>
      <div className={styles.imagesContainer}>
        {images.length === 0 && <ClipLoader size={75} />}
        {images.length > 0 &&
          images.map((image) => (
            <div
              className={classnames(styles.imageItem, {
                [styles.singleItem]: images.length === 1,
              })}
              key={image}
            >
              <img alt="lesson" src={image} />
            </div>
          ))}
      </div>
    </div>
  );
};
