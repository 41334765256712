import PinProtected from "../GlenLearn/AdminDashboard/DashboardForUser/PinProtected";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { signOutProfileUser } from "../../actions/profile";
import {
  AuthedAndProfiledTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useHistory, useParams } from "react-router-dom";

const PinProtectChild = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // this dispatch will cause the user to be redirected (see AuthAndProfileRoute)
    dispatch(signOutProfileUser());
  }, [dispatch]);
  return null;
};

const PinSignOut = () => {
  const history = useHistory();
  const { deploymentId } = useParams();
  const goBack = () => history.push(`/deployment/${deploymentId}/glenLearn`);

  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>
        <PinProtected password={"1234"}>
          <PinProtectChild />
        </PinProtected>{" "}
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} />
    </GameWrapper>
  );
};

export default PinSignOut;
