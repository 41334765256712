import React, { useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import PhonicStrengthen from "../../../components/Lessons/Strengthen/PhonicStrengthen/PhonicStrengthen";
import VocabStrengthenWithAnalytics from "../../../components/Lessons/Strengthen/VocabStrengthen/VocabStrengthenWithAnalytics";
import { getPhonicStrengthenInfo } from "./PhonicStrengthen/util";
import { getVocabStrengthenInfo } from "./VocabStrengthen/util";
import ShapesStrengthenWithAnalytics from "./ShapesStrengthen/ShapesStrengthenWithAnalytics";

export default ({ profile, lessonResource, lessonGroupResource, type }) => {
  const { groupId, deploymentId } = useParams();
  const history = useHistory();

  const lessonGroup = useMemo(() => lessonGroupResource.getGroup(groupId), [
    lessonGroupResource,
    groupId,
  ]);

  const {
    onCompleteRedirect = `/deployment/${deploymentId}/${type}`, //for when not specified, e.g. direct url access
    isAssessStrengthenLoop = false,
  } = history.location.state || {};
  const onComplete = useCallback(() => {
    if (isAssessStrengthenLoop) {
      history.push({
        pathname: `/deployment/${deploymentId}/${type}/group/${groupId}`,
        state: { isAssessStrengthenLoop: true, doAssess: true },
      });
    } else {
      history.push(onCompleteRedirect);
    }
  }, [
    deploymentId,
    groupId,
    history,
    isAssessStrengthenLoop,
    onCompleteRedirect,
    type,
  ]);
  const wasManual = !isAssessStrengthenLoop;

  const groupIdParsed = parseInt(groupId, 10);

  if (lessonGroup.strengthening.type === "vocab") {
    const { concepts, recentlyFailedConcepts, groups, textIndicator } = useMemo(
      () =>
        getVocabStrengthenInfo(
          lessonResource,
          lessonGroup,
          profile.getVocabAssessmentRecord
        ),
      // profile should not be part of the dependency array.
      // when the profile mutates, we should not refresh phonic strengthen
      //eslint-disable-next-line
      [lessonGroup, lessonResource]
    );
    return (
      <VocabStrengthenWithAnalytics
        concepts={concepts}
        recentlyFailedConcepts={recentlyFailedConcepts}
        groups={groups}
        textIndicator={textIndicator}
        wasManual={wasManual}
        lessonGroupId={groupId}
        onComplete={onComplete}
      />
    );
  } else if (lessonGroup.strengthening.type === "phonic") {
    const { consonantWordMap, recentlyFailedSounds, selectedVariant } = useMemo(
      () =>
        getPhonicStrengthenInfo(
          lessonResource,
          lessonGroup,
          profile.getPhonicsAssessmentRecord
        ),
      // profile should not be part of the dependency array.
      // when the profile mutates, we should not refresh phonic strengthen
      //eslint-disable-next-line
      [lessonGroup, lessonResource]
    );
    return (
      <PhonicStrengthen
        groupId={groupIdParsed}
        consonantWordMap={consonantWordMap}
        recentlyFailedSounds={recentlyFailedSounds}
        selectedVariant={selectedVariant}
        onComplete={onComplete}
        wasManual={wasManual}
      />
    );
  } else if (lessonGroup.strengthening.type === "shapes") {
    let concepts = useMemo(
      () => ["circle", "star", "triangle", "square", "rectangle"],
      []
    );
    return (
      <ShapesStrengthenWithAnalytics
        concepts={concepts}
        wasManual={wasManual}
        lessonGroupId={groupId}
        onComplete={onComplete}
      />
    );
  }
};
