import React, { useEffect, useState } from "react";
import SoundClickWithAnalytics from "../../../../games/SoundClick/SoundClickWithAnalytics";

export default ({ words, onRoundComplete, onComplete }) => {
  const [gameState, setGameState] = useState(null);
  const [trialNum, setTrialNum] = useState(0);
  const onRoundFinished = (wasCorrect) => {
    onRoundComplete({ wasCorrect, targetWord: gameState.targetWord });
    if (!wasCorrect) {
      onComplete();
    } else {
      setTrialNum(trialNum + 1);
    }
  };
  useEffect(() => {
    if (trialNum >= words.length) {
      onComplete();
    } else {
      const state = {
        targetWord: words[trialNum],
        confusionWords: words.filter((w, i) => i !== trialNum),
      };
      setGameState(state);
    }
  }, [onComplete, trialNum, words]);

  if (!gameState) {
    return null;
  }

  return (
    <SoundClickWithAnalytics
      key={gameState.targetWord}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      onComplete={onRoundFinished}
    />
  );
};
