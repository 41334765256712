import React from "react";
import { useGlenPhonicsProfile } from "../../../util/Profile/ProfileUtil";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import LessonGroups from "../../../components/Lessons/LessonGroups/LessonGroups";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const resource = useResourceFromRegistry("phonicsLessonGroups");
  const glenPhonicsProfile = useGlenPhonicsProfile();
  return (
    <LessonGroups
      profile={glenPhonicsProfile}
      lessonGroupsResource={resource}
      type={types.GLEN_PHONICS}
    />
  );
};
