import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Landing from "../Landing";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import ResetPasswordPage from "../ResetPassword/ResetPassword";
import ProfileListPage from "../Profiles/ProfileList/ProfileList";
import ProfileAddPage from "../Profiles/AddProfile/AddProfile";
import ProfileEditPage from "../Profiles/EditProfile/EditProfile";
import AboutPage from "../About/About";
import FaqPage from "../Faq/Faq";
import AdminAccountSettingsPage from "../AdminAccount/AdminAccountSettings";
import GlenLearnLessonGroupsPage from "../GlenLearn/LessonGroups/LessonGroups";
import GlenLearnLessonGroupPage from "../GlenLearn/LessonGroup/LessonGroup";
import GlenLearnLessonPage from "../GlenLearn/Lesson/Lesson";
import GlenVocabLessonGroupsPage from "../GlenVocab/LessonGroups/LessonGroups";
import GlenVocabLessonGroupPage from "../GlenVocab/LessonGroup/LessonGroup";
import GlenVocabLessonPage from "../GlenVocab/Lesson/Lesson";
import GLENVocabStrengthenPage from "./../GlenVocab/Strengthen/Strengthen";
import GLENVocabAssessPage from "./../GlenVocab/Assess/Assess";

import GlenShapesLessonGroupsPage from "../GlenShapes/LessonGroups/LessonGroups";
import GlenShapesLessonGroupPage from "../GlenShapes/LessonGroup/LessonGroup";
import GlenShapesLessonPage from "../GlenShapes/Lesson/Lesson";
import GLENShapesStrengthenPage from "./../GlenShapes/Strengthen/Strengthen";
import GLENShapesAssessPage from "./../GlenShapes/Assess/Assess";

import GlenABCLessonGroupsPage from "../GlenABC/LessonGroups/LessonGroups";
import GlenABCLessonGroupPage from "../GlenABC/LessonGroup/LessonGroup";
import GlenABCLessonPage from "../GlenABC/Lesson/Lesson";

import GlenPhonicsLessonGroupsPage from "../GlenPhonics/LessonGroups/LessonGroups";
import GlenPhonicsLessonGroupPage from "../GlenPhonics/LessonGroup/LessonGroup";
import GlenPhonicsLessonPage from "../GlenPhonics/Lesson/Lesson";
import GLENPhonicsStrengthenPage from "./../GlenPhonics/Strengthen/Strengthen";
import GLENPhonicsAssessPage from "./../GlenPhonics/Assess/Assess";

import * as ROUTES from "../../constants/routes";
import "./index.scss";
import AdminLayout from "./../../layouts/AdminLayout/AdminLayout";
import useAuth from "../../util/Session/useAuth";
import useProfile from "../../util/Profile/useProfile";
import {
  GlenBooksCategoriesWithAuth,
  GlenBooksCategoriesWithoutAuth,
} from "../GlenBooks/Categories";
import {
  GlenBooksCategoryWithAuth,
  GlenBooksCategoryWithoutAuth,
} from "../GlenBooks/Category";
import {
  GlenBooksBookWithAuth,
  GlenBooksBookWithoutAuth,
} from "../GlenBooks/Book";
import {
  GlenRhymesCategoryWithAuth,
  GlenRhymesCategoryWithoutAuth,
} from "../GlenRhymes/Category";
import {
  GlenRhymesBookWithAuth,
  GlenRhymesBookWithoutAuth,
} from "../GlenRhymes/Book";
import GLENLearnUserDashboardPage from "./../GlenLearn/UserDashboard/UserDashboard";
import GLENLearnUserDashboardWallOfFamePage from "./../GlenLearn/UserDashboard/WallOfFame/WallOfFame";
import GLENLearnUserDashboardTimelinePage from "./../GlenLearn/UserDashboard/Timeline/Timeline";
import GLENLearnDashboardForUserPage from "./../GlenLearn/AdminDashboard/DashboardForUser/DashboardForUser";
import GLENLearnAdminDashboardUserListPage from "../GlenLearn/AdminDashboard/UserList/UserList";
import GLENLearnAdminCollectedWordsPage from "../GlenLearn/AdminDashboard/CollectedWordsForUser/CollectedWordsForUser";
import GLENLearnCollectedWordsPage from "./../GlenLearn/CollectedWords/CollectedWords";
import GLENLearnStrengthenPage from "./../GlenLearn/Strengthen/Strengthen";
import GLENLearnAssessPage from "./../GlenLearn/Assess/Assess";
import GLENLearnSelectAssessmentPage from "./../GlenLearn/SelectAssessmentType/SelectAssessment";
import GLENLearnAssessmentPage from "../GlenLearn/Assessment/AssessmentPage";
import AudioController from "../../util/Audio/AudioController";
import ProfileSync from "../../util/Profile/ProfileSync";
import { activity } from "../../util/Analytics/events";
import PinSignOut from "../SignOut/PinSignOut";

//requires a master user auth
const AuthRoute = (props) => {
  const authUser = useAuth();
  return !authUser ? <Redirect to={ROUTES.SIGN_IN} /> : <Route {...props} />;
};

// requires a master user auth and a profile
const AuthAndProfileRoute = (props) => {
  const profileUser = useProfile();
  return !profileUser ? (
    <Redirect
      to={`/deployment/${props.computedMatch.params.deploymentId}/profiles`}
    />
  ) : (
    <ProfileSync>
      <Route {...props} />
    </ProfileSync>
  );
};

function AdminLayoutWrapper({ ...props }) {
  return (
    <AdminLayout>
      <Route {...props} />
    </AdminLayout>
  );
}

const App = () => (
  <Router>
    <AudioController>
      <Switch>
        {/*login and sign up*/}
        <AdminLayoutWrapper exact path={ROUTES.LANDING} component={Landing} />
        <AdminLayoutWrapper
          exact
          path={ROUTES.SIGN_IN}
          component={SignInPage}
        />
        <AdminLayoutWrapper
          exact
          path={ROUTES.SIGN_UP}
          component={SignUpPage}
        />
        <AdminLayoutWrapper
          exact
          path={ROUTES.RESET_PASSWORD}
          component={ResetPasswordPage}
        />

        {/* GLEN Books anonymous */}
        <Route
          exact
          path={ROUTES.GLENBooksCategoriesNoAuth}
          component={GlenBooksCategoriesWithoutAuth}
        />
        <Route
          exact
          path={ROUTES.GLENBooksCategoryNoAuth}
          component={GlenBooksCategoryWithoutAuth}
        />
        <Route
          exact
          path={ROUTES.GLENBooksItemNoAuth}
          component={GlenBooksBookWithoutAuth}
        />

        {/* GLEN Rhymes anonymous */}
        <Route
          exact
          path={ROUTES.GLENRhymesItemNoAuth}
          component={GlenRhymesBookWithoutAuth}
        />
        <Route
          exact
          path={ROUTES.GLENRhymesCategoryNoAuth}
          component={GlenRhymesCategoryWithoutAuth}
        />

        <Route exact path={ROUTES.ABOUT} component={AboutPage} />
        <Route exact path={ROUTES.FAQ} component={FaqPage} />

        {/* profile management */}
        <AuthRoute
          exact
          path={ROUTES.ProfileList}
          component={ProfileListPage}
        />
        <AuthRoute exact path={ROUTES.ProfileAdd} component={ProfileAddPage} />
        <AuthRoute
          exact
          path={ROUTES.ProfileEdit}
          component={ProfileEditPage}
        />
        <AuthRoute
          exact
          path={ROUTES.GLENLearnAdminDashboardUserList}
          component={GLENLearnAdminDashboardUserListPage}
        />
        <AuthRoute
          exact
          path={ROUTES.GLENLearnAdminDashboard}
          component={GLENLearnDashboardForUserPage}
        />
        <AuthRoute
          exact
          path={ROUTES.GLENLearnAdminDashboardCollectedWords}
          component={GLENLearnAdminCollectedWordsPage}
        />
        <AuthRoute
            exact
            path={ROUTES.AdminAccountSettings}
            component={AdminAccountSettingsPage}
        />

        {/* authed and profiled */}
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnProfilePinLogout}
          component={PinSignOut}
        />

        {/* GLEN Books */}
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENBooksItem}
          component={GlenBooksBookWithAuth}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENBooksCategories}
          component={GlenBooksCategoriesWithAuth}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENBooksCategory}
          component={GlenBooksCategoryWithAuth}
        />

        {/* GLEN Rhymes */}
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENRhymesItem}
          component={GlenRhymesBookWithAuth}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENRhymesCategory}
          component={GlenRhymesCategoryWithAuth}
        />

        {/* GLEN Learn  */}

        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnComprehensiveAssessment}
          component={() =>
            GLENLearnAssessmentPage({
              activity: activity.COMPREHENSIVE_ASSESSMENT,
            })
          }
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnKSEPAssessment}
          component={() =>
            GLENLearnAssessmentPage({ activity: activity.KSEP_ASSESSMENT })
          }
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnSelectAssessment}
          component={GLENLearnSelectAssessmentPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnAssess}
          component={GLENLearnAssessPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnStrengthen}
          component={GLENLearnStrengthenPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnCollectedWords}
          component={GLENLearnCollectedWordsPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnUserDashboard}
          component={GLENLearnUserDashboardPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnUserDashboardWallOfFamePage}
          component={GLENLearnUserDashboardWallOfFamePage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnUserDashboardTimelinePage}
          component={GLENLearnUserDashboardTimelinePage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearn}
          component={GlenLearnLessonGroupsPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnGroup}
          component={GlenLearnLessonGroupPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENLearnLesson}
          component={GlenLearnLessonPage}
        />

        {/* GLEN Vocab */}
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENVocab}
          component={GlenVocabLessonGroupsPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENVocabGroup}
          component={GlenVocabLessonGroupPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENVocabLesson}
          component={GlenVocabLessonPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENVocabAssess}
          component={GLENVocabAssessPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENVocabStrengthen}
          component={GLENVocabStrengthenPage}
        />

        {/* GLEN Shapes */}
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENShapes}
          component={GlenShapesLessonGroupsPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENShapesGroup}
          component={GlenShapesLessonGroupPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENShapesLesson}
          component={GlenShapesLessonPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENShapesAssess}
          component={GLENShapesAssessPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENShapesStrengthen}
          component={GLENShapesStrengthenPage}
        />

        {/* GLEN Phonics */}
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENPhonics}
          component={GlenPhonicsLessonGroupsPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENPhonicsGroup}
          component={GlenPhonicsLessonGroupPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENPhonicsLesson}
          component={GlenPhonicsLessonPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENPhonicsAssess}
          component={GLENPhonicsAssessPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENPhonicsStrengthen}
          component={GLENPhonicsStrengthenPage}
        />

        {/* GLEN ABC */}
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENABC}
          component={GlenABCLessonGroupsPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENABCGroup}
          component={GlenABCLessonGroupPage}
        />
        <AuthAndProfileRoute
          exact
          path={ROUTES.GLENABCLesson}
          component={GlenABCLessonPage}
        />

        <Route render={() => <Redirect to={ROUTES.LANDING} />} />
      </Switch>
    </AudioController>
  </Router>
);

export default App;
