import React, { useEffect, useState } from "react";
import useFirebase from "./Firebase/useFirebase";
import { useDispatch } from "react-redux";
import { doLoginAuthUser } from "../actions/session";
import { selectProfileUser } from "../actions/profile";
import { ClipLoader } from "react-spinners";
import useAuth from "./Session/useAuth";
import { getDeviceType } from "./deviceType";

// 1. sign in anonymously
// 2. create a deployment account underneath the provided deployment user
// 3. log in as that deployment account
// 4. Render the child
export const WithAnonymousLogin = ({ deploymentId, children }) => {
  const [loading, setIsLoading] = useState(true);
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const existingAuthUser = useAuth();

  useEffect(() => {
    const signIn = async () => {
      if (!existingAuthUser) {
        console.log("NO CURRENT USER");
        let authUser = await firebase.doSignInAsGuest();
        const adminAccount = {
          username: "Anonymous",
          email: "Anonymous",
          deployments: {
            [deploymentId]: "admin",
          },
          readOnlyDeployments: {},
          time: new Date().getTime(),
        };
        dispatch(doLoginAuthUser(authUser.user, adminAccount));
        const deploymentAccount = await firebase.createDeploymentAccount(
          deploymentId,
          `Guest - ${getDeviceType()}`,
          1,
          5
        );
        dispatch(
          selectProfileUser({
            id: deploymentAccount.id,
            ...deploymentAccount.data,
          })
        );
      }
      setIsLoading(false);
    };
    signIn();
    //eslint-disable-next-line
  }, [firebase]);

  return loading ? <ClipLoader size={75} /> : children;
};
