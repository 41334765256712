import React from "react";
import styles from "./styles.module.scss";
import RoundedText from "../../components/RoundedText/RoundedText";
import { useHistory } from "react-router-dom";
import useAuth from "../../util/Session/useAuth";
import useProfile from "../../util/Profile/useProfile";
import classnames from "classnames";
import Avatar from "../../components/Avatar/Avatar";
import infoIcon from "./info_icon_200.png";

export const TopBar = ({ left = null, center = null, right = null }) => {
  return (
    <div style={{ height: "5vh", marginTop: "5vh", marginBottom: "5vh" }}>
      <div className="row text-center align-items-center">
        <div className="col-4">
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            {left}
          </div>
        </div>
        <div className="col-4">{center}</div>
        <div className="col-4">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: "70%" }}>{right}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PublicSpiralTopBar = ({
  onClickSpiral = null,
  onClickInfo = null,
  withInfoButton = true,
}) => {
  const router = useHistory();
  const goHome = () => router.push(process.env.REACT_APP_LANDING_PAGE);
  const centerItem = (
    <img
      onClick={onClickSpiral || goHome}
      className={styles.logo}
      src={process.env.PUBLIC_URL + "/images/logo_with_text.png"}
    />
  );
  const leftItem = (
    <img src={infoIcon} className={styles.infoIcon} onClick={onClickInfo} />
  );
  return (
    <TopBar
      left={withInfoButton ? leftItem : null}
      right={null}
      center={centerItem}
    />
  );
};

export const AuthedTopBar = ({ left = null, right = null }) => {
  const router = useHistory();
  const goHome = () => router.push(process.env.REACT_APP_LANDING_PAGE);
  const centerItem = (
    <img
      onClick={goHome}
      className={styles.logo}
      src={process.env.PUBLIC_URL + "/images/logo_with_text.png"}
    />
  );
  return <TopBar left={left} right={right} center={centerItem} />;
};

export const AuthedAndProfiledTopBar = React.forwardRef((props, ref) => {
  // top bar that includes the logged in username
  const router = useHistory();
  const authUser = useAuth();
  const profileUser = useProfile();

  const ProfileIconText = ({ onClick }) => {
    return (
      <div onClick={onClick} className={styles.profileIcon}>
        {profileUser.username}{" "}
        <Avatar size="sm" avatarId={profileUser.avatarId} />
      </div>
    );
  };

  const gotoUserDashboard = () =>
    router.push(`/deployment/${authUser.deploymentId}/glenLearn/userDashboard`);
  const gotoAdmin = () =>
    router.push(`/deployment/${authUser.deploymentId}/adminDashboard/list`);
  const goHome = () =>
    router.push(`/deployment/${authUser.deploymentId}/glenLearn`);
  const centerItem = (
    <img
      onClick={goHome}
      className={styles.logo}
      src={process.env.PUBLIC_URL + "/images/logo_with_text.png"}
    />
  );
  const rightItem = (
    <div ref={ref}>
      <ProfileIconText onClick={gotoUserDashboard} />
    </div>
  );
  const leftItem = props.withAdminDashboardIcon && (
    <MaterialButton icon={"assessment"} onClick={gotoAdmin} />
  );
  return <TopBar left={leftItem} center={centerItem} right={rightItem} />;
});

export const GameContainer = ({ children }) => {
  return (
    <div style={{ height: "70vh" }}>
      <div className={styles.gameContainer}>{children}</div>
    </div>
  );
};

export const BottomMultiple = ({ children, justifyContentBetween=true }) => {
  return (
    <div
      className={classnames("d-flex", {
        "justify-content-between": justifyContentBetween,
      })}
    >
      {children.map((c, i) => {
        return <div key={i}>{c}</div>;
      })}
    </div>
  );
};

export const BackButton = ({ onClick }) => {
  const router = useHistory();
  const onClickResolved = onClick || (() => router.goBack());
  return (
    <BottomButton
      src={process.env.PUBLIC_URL + "/images/back.png"}
      onClick={onClickResolved}
    />
  );
};

export const MaterialButtonWithText = ({ text, onClick, ...props }) => {
  return (
    <div onClick={onClick} className={styles.materialButtonTextContainer}>
      <MaterialButton {...props} style={{ verticalAlign: "middle" }} />
      <div className={styles.materialButtonText}>{text}</div>
    </div>
  );
};
export const MaterialButton = ({
  icon,
  onClick,
  size = 75,
  blink = false,
  style = {},
}) => {
  return (
    <i
      className={classnames(
        `material-icons md-${size}`,
        styles.materialButton,
        { "animated flash slow infinite": blink }
      )}
      style={style}
      onClick={onClick}
    >
      {icon}
    </i>
  );
};

export const BottomButton = ({ src, onClick, blink = false }) => {
  return (
    <img
      className={classnames("mr-4", styles.bottomButton, {
        "animated flash slow infinite": blink,
      })}
      src={src}
      onClick={onClick}
    />
  );
};

export const BottomBar = ({ left = null, center = null, right = null }) => {
  return (
    <div style={{ height: "10vh", marginTop: "2.5vh", marginBottom: "2.5vh" }}>
      <div className="d-flex justify-content-between">
        <div className="w-100">
          <div className="">{left}</div>
        </div>
        {center && <div className="w-100 text-center">{center}</div>}
        <div className="w-100">{right}</div>
      </div>
    </div>
  );
};

export const GameWrapper = ({ children }) => {
  return <div className={`container-fluid pl-5 pr-5`}>{children}</div>;
};

export const ScoreText = ({ score, outOf }) => {
  return (
    <RoundedText>
      {score} / {outOf}
    </RoundedText>
  );
};

export const ProgressBar = ({ value = 0 }) => {
  const rounded = Math.floor(value / 0.05) * 0.05;
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${rounded * 100}%` }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};
