import React, { useEffect, useState } from "react";
import {
  AuthedTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useHistory, useParams } from "react-router-dom";
import { useGlenLearnProfile } from "../../../../util/Profile/ProfileUtil";
import { useResourceFromRegistry } from "../../../../util/Resource/useResource";
import PictureDictionary from "../../../../components/PictureDictionary/PictureDictionary";
import { mapGroupsToCompletedConcepts } from "../../../../util/Lesson/util";
import { useProfileForUserId } from "../../../../util/Profile/useProfile";

const useCompletedConcepts = (userId) => {
  const flashcardResource = useResourceFromRegistry("flashcards");
  const lessonResource = useResourceFromRegistry("lessons");
  const collectedWordsResource = useResourceFromRegistry("collectedWords");

  // eslint-disable-next-line
  const [profile, loading, error] = useProfileForUserId(userId);
  const glenLearnProfile = useGlenLearnProfile(profile);

  const [concepts, setCompletedConcepts] = useState([]);

  useEffect(() => {
    if (!loading) {
      const lastMastered = glenLearnProfile.getLastMasteredLesson();
      const groupsToCompletedConcepts = mapGroupsToCompletedConcepts(
        lastMastered,
        lessonResource,
        collectedWordsResource,
        flashcardResource
      );
      setCompletedConcepts(groupsToCompletedConcepts);
    }
  }, [loading]);
  return concepts;
};

export default () => {
  const history = useHistory();
  const { userId = null } = useParams();
  const groupsToCompletedConcepts = useCompletedConcepts(userId);
  const goBack = () => history.goBack();
  return (
    <GameWrapper>
      <AuthedTopBar />
      <GameContainer>
        <PictureDictionary
          groupsToCompletedConcepts={groupsToCompletedConcepts}
        />
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} right={null} />
    </GameWrapper>
  );
};
