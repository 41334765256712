import React, { useEffect } from "react";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";
import { activity } from "../../util/Analytics/events";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";

/**
 * If "analytics" is true, send all props as event data when question starts and completes.
 * Additionally, on completion, send wasCorrect as true or false.
 */
const MultipleChoiceQuestionWithAnalytics = ({ onComplete, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.MULTIPLE_CHOICE,
    ...props,
  };

  useEffect(() => {
    startEvent(eventData);
  }, []);

  const onQuestionCompleted = (wasCorrect) => {
    completeEvent({ ...eventData, wasCorrect });
    onComplete(wasCorrect);
  };

  return <MultipleChoiceQuestion onComplete={onQuestionCompleted} {...props} />;
};

export default MultipleChoiceQuestionWithAnalytics;
