import sampleSize from "lodash/sampleSize";

export default class PhonicStrengthen1Manager {
  constructor(consonantWordMap, recentlyFailedSounds) {
    this.consonantWordMap = consonantWordMap;
    const sounds = this._chooseConcepts(
      Object.keys(consonantWordMap),
      recentlyFailedSounds
    );
    this.lessonCParameterMap = this._buildLessonCParameterMap(sounds);
  }

  _buildLessonCParameterMap(sounds) {
    const parameters = {};
    for (const sound of sounds) {
      parameters[sound] = {
        count: 2,
        words: sampleSize(this.consonantWordMap[sound], 4),
      };
    }
    return { content: parameters };
  }

  _chooseConcepts(allSounds, recentlyFailedSounds) {
    // Choose N0 = min(4,length(sounds) sounds from “sounds” as follows
    // N1 = min(N0, size(recently_failed_sounds))
    // N2 = N0 – N1

    const allOtherSounds = allSounds.filter(
      (concept) => !recentlyFailedSounds.includes(concept)
    );

    let n0 = Math.min(4, allSounds.length);
    let n1 = Math.min(n0, recentlyFailedSounds.length);
    let n2 = n0 - n1;
    let sounds = [];
    sounds = sounds.concat(sampleSize(recentlyFailedSounds, n1));
    sounds = sounds.concat(sampleSize(allOtherSounds, n2));
    return sounds;
  }
}
