import React, { useContext, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import sample from "lodash/sample";
import range from "lodash/range";

import WorkBook, { PageProps } from "../../components/WorkBook/WorkBook";
import wait from "../../util/wait";
import AudioContext from "../../util/Audio/context";
import numberToWord from "../../constants/numberToWord";
import styles from "./NumbersBook.module.scss";

const numbers = range(1, 6);

const Page1 = ({ onComplete }: PageProps) => {
  const [currentNum, setCurrentNum] = useState(0);
  const audioService = useContext(AudioContext);

  useEffect(() => {
    let isCancelled = false;
    const action = async () => {
      await wait(100);
      for (let i = numbers[0]; i <= numbers[numbers.length - 1]; i++) {
        if (isCancelled) {
          break;
        }
        setCurrentNum(i);
        // @ts-ignore
        await audioService.composeAndPlaySingleAudio(
          "numbers",
          numberToWord[i]
        );
        await wait(1000);
      }
      onComplete();
    };
    action();

    return () => {
      isCancelled = true;
    };
  }, [audioService, onComplete]);

  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      {numbers.map((number) => (
        <div
          key={number}
          className={classnames("h2 w-75 display-1", {
            "d-none": number > currentNum,
            "animated fadeIn": currentNum >= number,
          })}
        >
          {number}
        </div>
      ))}
    </div>
  );
};

const ShapePage = (number: number, shapeSelection: string | undefined) => ({
  onComplete,
}: PageProps) => {
  return (
    <div className="h-100 w-100 d-flex align-items-center pl-5 pr-5 flex-column">
      <h1 className="pt-5 pb-5 h-25 display-1">{number}</h1>
      <div className="pt-5 h-75 w-100">
        <ShapePageInner
          onComplete={onComplete}
          number={number}
          shapeSelection={shapeSelection}
        />
      </div>
    </div>
  );
};

const ShapePageInner = ({
  shapeSelection,
  number,
  onComplete,
}: {
  shapeSelection: string | undefined;
  number: number;
  onComplete: () => void;
}) => {
  const [currentNum, setCurrentNum] = useState(0);
  const audioService = useContext(AudioContext);

  useEffect(() => {
    let isCancelled = false;
    const action = async () => {
      // @ts-ignore
      await audioService.composeAndPlaySingleAudio(
        "numbers",
        numberToWord[number]
      );
      await wait(500);

      // special case for 1, to not repeat it
      if (number === 1) {
        setCurrentNum(1);
      } else {
        for (let i = 1; i <= number; i++) {
          if (isCancelled) {
            break;
          }
          setCurrentNum(i);
          // @ts-ignore
          await audioService.composeAndPlaySingleAudio(
            "numbers",
            numberToWord[i]
          );
        }
      }

      await wait(700);

      // @ts-ignore
      await audioService.composeAndPlaySingleAudio(
        "numbers",
        numberToWord[number]
      );
      // @ts-ignore
      await audioService.composeAndPlaySingleAudio(
        "shapes",
        number === 1 ? shapeSelection : `${shapeSelection}s`
      );

      onComplete();
    };
    action();
    return () => {
      isCancelled = true;
    };
  }, [audioService, number, onComplete, shapeSelection]);

  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      {numbers.map((n) => {
        if (n > number) {
          return null;
        }
        return (
          <div
            key={n}
            className={classnames("w-100 h-100", {
              "animated fadeIn": currentNum === n,
              invisible: n > currentNum,
            })}
          >
            <img
              className={styles.numberImage}
              src={`${process.env.PUBLIC_URL}/content/images/shapes/black_${shapeSelection}_medium.png`}
              alt=""
            />
          </div>
        );
      })}
    </div>
  );
};

export default ({ onComplete }: { onComplete: () => void }) => {
  const shapeSelection = useMemo(() => sample(["circle", "triangle"]), []);

  const cover = (
    <img
      src={`${process.env.PUBLIC_URL}/content/images/numbers/numbers_book.png`}
      alt=""
    />
  );
  const pages = useMemo(() => {
    let allPages = [Page1];
    for (let i = numbers[0]; i <= numbers[numbers.length - 1]; i++) {
      allPages.push(ShapePage(i, shapeSelection));
    }
    return allPages;
  }, [shapeSelection]);

  return <WorkBook cover={cover} pages={pages} onComplete={onComplete} />;
};
