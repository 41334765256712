import React from "react";
import styles from "./Card.module.scss";
import classnames from "classnames";
import { ReactComponent as CheckIcon } from "./../Svg/Check.svg";
import { ReactComponent as CrossIcon } from "./../Svg/Cross.svg";
import CircleAnimation from "../CircleAnimation/CircleAnimation";

const getCardClasses = ({ size, bg, outline, animationClass = null }) => {
  return classnames(styles.card, {
    [styles.cardSmall]: size === "sm",
    [styles.cardLarge]: size === "lg",
    [styles.cardXLarge]: size === "xl",
    [styles.blue]: bg === "blue",
    [styles.white]: bg === "white",
    [styles.outlineRed]: outline === "red",
    [styles.outlineGreen]: outline === "green",
    [`animated ${animationClass}`]: animationClass !== null,
  });
};

const getFontClasses = (textSize) => {
  return classnames({
    [styles.textLarge]: textSize === "large",
    [styles.textNormal]: textSize === "normal",
    [styles.textSmall]: textSize === "small",
  });
};

export const CardImageOnly = ({ image, ...props }) => {
  return (
    <CardComponentOnly
      component={
        <img
          src={image}
          className={classnames(styles.image, styles.imageWithoutText)}
        />
      }
      {...props}
    />
  );
};

const CardComponentOnly = ({
  component,
  bg = "white",
  size = "md",
  outline = null,
  withCheck = false,
  withCross = false,
  withCircle = false,
  animationClass = null,
  onClick,
}) => {
  return (
    <div
      className={getCardClasses({ size, bg, outline, animationClass })}
      onClick={onClick}
    >
      {withCheck && <CheckIcon className={styles.checkIcon} />}
      {withCross && <CrossIcon className={styles.checkIcon} />}
      {withCircle && <CircleAnimation />}
      {component}
    </div>
  );
};

export const CardTextOnly = ({
  text,
  bg = "white",
  size = "md",
  textSize = "large",
  outline = null,
  animationClass = null,
  onClick,
}) => {
  return (
    <div
      className={getCardClasses({ size, bg, outline, animationClass })}
      onClick={onClick}
    >
      <div className={`${styles.textOnly} ${getFontClasses(textSize)}`}>
        {text}
      </div>
    </div>
  );
};

export const CardImageAndText = ({
  image,
  bg = "white",
  size = "md",
  text,
  withCheck = false,
  withCross = false,
  withCircle = false,
  textSize = "normal",
  textColor = "black",
  animationClass = null,
  textBold = false,
  outline = null,
  onClick,
}) => {
  return (
    <div
      className={getCardClasses({ size, bg, outline, animationClass })}
      onClick={onClick}
    >
      {withCheck && <CheckIcon className={styles.checkIcon} />}
      {withCross && <CrossIcon className={styles.checkIcon} />}
      {withCircle && <CircleAnimation />}
      <div style={{ height: "17vh" }}>
        <img
          src={image}
          className={classnames(styles.image, styles.imageWithText)}
        />
      </div>
      <div style={{ height: "7vh" }}>
        <div
          className={classnames({
            [styles.textLarge]: textSize === "large",
            [styles.textNormal]: textSize === "normal",
            [styles.textSmall]: textSize === "small",
            [styles.textColorGreen]: textColor === "green",
            [styles.textBold]: textBold,
          })}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default (props) => {
  const textOnly = props.text && !props.image && !props.component;
  const imageOnly = !props.text && props.image && !props.component;
  const componentOnly = props.component && !props.image && !props.text;
  const imageAndText = props.text && props.image && !props.component;
  return (
    <>
      {textOnly && <CardTextOnly {...props} />}
      {imageOnly && <CardImageOnly {...props} />}
      {componentOnly && <CardComponentOnly {...props} />}
      {imageAndText && <CardImageAndText {...props} />}
    </>
  );
};
