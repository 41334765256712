import React, { useCallback, useMemo, useState } from "react";
import wait from "../../../../util/wait";
import { useComponentRender } from "../../util/hooks";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import LessonN0Manager, { Inputs } from "./manager";
import { useResourceFromRegistry } from "../../../../util/Resource/useResource";
import {
  GenericRepeatedGame,
  repeatTimes,
} from "../../../../util/repeatedGame";
import GlenMatchWithAnalytics from "../../../../games/GlenMatch/GlenMatchWithAnalytics";
import MultipleChoiceQuestionWithAnalytics from "../../../../games/MultipleChoice/MultipleChoiceQuestionWithAnalytics";

type GameProps = {
  manager: LessonN0Manager;
  updateProgress: () => void;
  onComplete: () => void;
};

const RepeatedGlenMatchGame = ({
  manager,
  updateProgress,
  onComplete,
}: GameProps) => {
  const timesFx = useMemo<() => boolean>(
    () => repeatTimes(manager.inputs.glenMatchCount),
    [manager]
  );
  const getNextGameProps = useCallback(() => manager.getGlenMatchProps(), [
    manager,
  ]);
  const hasNext = useCallback(() => {
    // immediately skip glen match
    if (manager.inputs.glenMatchCount === 0) {
      return false;
    }
    // stop if game complete
    if (manager.isComplete) {
      return false;
    }
    // otherwise continue glenMatchCount times
    return timesFx();
  }, [manager.inputs.glenMatchCount, manager.isComplete, timesFx]);

  return (
    <GenericRepeatedGame
      GameComponent={GlenMatchWithAnalytics}
      getNextGameProps={getNextGameProps}
      onRoundComplete={updateProgress}
      onComplete={onComplete}
      hasNext={hasNext}
    />
  );
};

const RepeatedImageClickGame = ({
  manager,
  updateProgress,
  onComplete,
}: GameProps) => {
  const timesFx = useMemo(() => repeatTimes(4), []);
  const getNextGameProps = useCallback(
    (trialNum: number) => manager.getImageClickProps(trialNum),
    [manager]
  );
  const hasNext = useCallback(() => {
    // stop if game complete
    if (manager.isComplete) {
      return false;
    }
    // otherwise continue glenMatchCount times
    return timesFx();
  }, [manager.isComplete, timesFx]);

  const onRoundComplete = useCallback(
    (wasSuccess: boolean) => {
      manager.markImageClickFeedback(wasSuccess);
      updateProgress();
    },
    [manager, updateProgress]
  );

  return (
    <GenericRepeatedGame
      GameComponent={MultipleChoiceQuestionWithAnalytics}
      getNextGameProps={getNextGameProps}
      onRoundComplete={onRoundComplete}
      onComplete={onComplete}
      hasNext={hasNext}
    />
  );
};

const useGameContent = (
  parameters: Inputs,
  onComplete: () => void
): [React.Component | null, number] => {
  const shapesResource = useResourceFromRegistry("shapes");
  const manager = useMemo(
    () => new LessonN0Manager(parameters, shapesResource),
    [parameters, shapesResource]
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [currentProgress, setCurrentProgress] = useState<number>(
    manager.progressBarLength
  );
  const updateProgress = useCallback(() => {
    setCurrentProgress(manager.progressBarLength);
    return wait(200);
  }, [manager]);

  const onStepFinished = useCallback(() => {
    if (manager.isComplete) {
      onComplete();
    } else {
      setCurrentStep(currentStep === 1 ? 2 : 1);
    }
  }, [currentStep, manager.isComplete, onComplete]);

  const stepToComponent = {
    1: (
      <RepeatedGlenMatchGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={onStepFinished}
      />
    ),
    2: (
      <RepeatedImageClickGame
        manager={manager}
        updateProgress={updateProgress}
        onComplete={onStepFinished}
      />
    ),
  };
  const toRender: React.Component | null = useComponentRender(
    stepToComponent,
    currentStep
  );
  return [toRender, currentProgress];
};

export default ({
  parameters,
  onComplete,
  profileBoxRef,
}: {
  parameters: Inputs;
  onComplete: () => void;
  profileBoxRef: any;
}) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
