import React, { useContext, useEffect, useMemo, useState } from "react";
import sample from "lodash/sample";
import noop from "lodash/noop";
import styles from "./Guide.module.scss";
import AudioContext from "./../../util/Audio/context";
import wait from "../../util/wait";

export default ({ character, audio = [], onComplete = noop }) => {
  const animationOptions = [
    ["bounceInLeft", "bounceOut"],
    ["zoomIn", "zoomOut"],
    ["fadeInRight", "fadeOutLeft"],
  ];
  const selectedCharacter =
    character || useMemo(() => sample(["girl1", "girl2"]), []);
  const animation = useMemo(() => sample(animationOptions), [animationOptions]);

  const [animationClass, setAnimationClass] = useState(animation[0]);

  const audioService = useContext(AudioContext);

  useEffect(() => {
    const playAudio = async () => {
      await wait(300);
      const delays = new Array(audio.length).fill(300);
      await audioService.composeAndPlayAudio([
        {
          directory: "narrator",
          files: audio,
        },
        delays, // Array of length audio with values 300
      ]);

      await wait(1800);
      setAnimationClass(animation[1]);
      await wait(300);
      onComplete();
    };

    if (audio.length > 0) {
      playAudio();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${styles.glenGuide} ${styles[selectedCharacter]} animated ${animationClass}`}
    ></div>
  );
};
