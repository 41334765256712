import React from "react";
import { useGlenABCProfile } from "../../../util/Profile/ProfileUtil";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import LessonGroups from "../../../components/Lessons/LessonGroups/LessonGroups";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const resource = useResourceFromRegistry("ABCLessonGroups");
  const profile = useGlenABCProfile();
  return (
    <LessonGroups
      profile={profile}
      lessonGroupsResource={resource}
      type={types.GLEN_ABC}
    />
  );
};
