import React, { useMemo } from "react";
import {
  AuthedAndProfiledTopBar,
  BackButton,
  BottomBar,
  GameContainer,
  GameWrapper,
} from "../../../layouts/GLENLearnLayout/GLENLearnLayout";
import { useHistory } from "react-router-dom";
import { useGlenLearnProfile } from "../../../util/Profile/ProfileUtil";
import { mapGroupsToCompletedConcepts } from "../../../util/Lesson/util";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import PictureDictionary from "../../../components/PictureDictionary/PictureDictionary";

const useCompletedConcepts = () => {
  const flashcardResource = useResourceFromRegistry("flashcards");
  const lessonResource = useResourceFromRegistry("lessons");
  const collectedWordsResource = useResourceFromRegistry("collectedWords");
  const glenLearnProfile = useGlenLearnProfile();
  return useMemo(() => {
    const lastMastered = glenLearnProfile.getLastMasteredLesson();
    return mapGroupsToCompletedConcepts(
      lastMastered,
      lessonResource,
      collectedWordsResource,
      flashcardResource
    );
  }, []);
};

export default () => {
  const history = useHistory();
  const groupsToCompletedConcepts = useCompletedConcepts();
  const goBack = () => history.goBack();
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar />
      <GameContainer>
        <PictureDictionary
          groupsToCompletedConcepts={groupsToCompletedConcepts}
        />
      </GameContainer>
      <BottomBar left={<BackButton onClick={goBack} />} right={null} />
    </GameWrapper>
  );
};
