export const selectProfileUser = (profileUser) => {
  return { type: "PROFILE_USER_SET", profileUser };
};

export const signOutProfileUser = () => {
  return { type: "PROFILE_USER_SET", profileUser: null };
};

export const updateProfile = (profile) => {
  return { type: "PROFILE_USER_UPDATE", profile };
};
