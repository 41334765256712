import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenLearnProfile } from "../../../util/Profile/ProfileUtil";
import { TYPES as types } from "../../../components/Lessons/util/groups";
import Lesson from "../../../components/Lessons/Lesson/Lesson";

export default () => {
  const glenLearnProfile = useGlenLearnProfile();
  const lessonResource = useResourceFromRegistry("lessons");
  const lessonGroupResource = useResourceFromRegistry("lessonGroups");
  return (
    <Lesson
      profile={glenLearnProfile}
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      type={types.GLEN_LEARN}
    />
  );
};
