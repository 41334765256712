import React from "react";
import SignInPage from "../SignIn";
import { Redirect } from "react-router-dom";
import useAuth from "../../util/Session/useAuth";
import useProfile from "../../util/Profile/useProfile";

const StandardAuthLanding = () => {
  const authUser = useAuth();
  const profileUser = useProfile();

  if (profileUser && profileUser.id) {
    return (
      <Redirect
        to={`/deployment/${Object.keys(authUser.deployments)[0]}/glenLearn`}
      />
    );
  }
  if (authUser) {
    return (
      <Redirect
        to={`/deployment/${Object.keys(authUser.deployments)[0]}/profiles`}
      />
    );
  }

  return <SignInPage />;
};

export default () => {
  if (process.env.REACT_APP_LANDING_PAGE) {
    return <Redirect to={process.env.REACT_APP_LANDING_PAGE} />;
  }
  return <StandardAuthLanding />;
};
