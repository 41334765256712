import noop from "lodash/noop";
import DescribeIt from "./DescribeIt";
import React from "react";
import { activity } from "../../util/Analytics/events";
import { useTimedAnalytics } from "../../components/Lessons/util/hooks";

export default ({ onStart = noop, onComplete = noop, ...props }) => {
  const { startEvent, completeEvent } = useTimedAnalytics();
  const eventData = {
    activity: activity.DESCRIBE_IT,
    sentence: props.sentence,
  };
  const onCompleteOverride = () => {
    completeEvent(eventData);
    onComplete();
  };
  const onStartOverride = () => {
    startEvent(eventData);
    onStart();
  };
  return (
    <DescribeIt
      {...props}
      onStart={onStartOverride}
      onComplete={onCompleteOverride}
    />
  );
};
