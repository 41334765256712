import React, { useCallback, useContext, useEffect, useState } from "react";
import AudioContext from "../../../util/Audio/context";
import DisableOverlay from "../../../components/DisableOverlay/DisableOverlay";

const BasePrompt = ({
  children = null,
  audio = null,
  onPromptFinished,
  delays = null,
}) => {
  const audioService = useContext(AudioContext);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  // Play audio when prompt loads

  const playAudio = useCallback(async () => {
    if (isAudioPlaying) {
      return false;
    }
    setIsAudioPlaying(true);
    await audioService.playAudio(audio, delays);
    setIsAudioPlaying(false);
  }, [audio, audioService, delays, isAudioPlaying]);

  useEffect(() => {
    const fx = async () => {
      await playAudio();
      if (onPromptFinished) {
        onPromptFinished();
      }
    };
    fx();
    // eslint-disable-next-line
  }, []);

  return (
    <DisableOverlay enable={isAudioPlaying}>
      <div onClick={playAudio} style={{ width: "100%" }}>
        {children}
      </div>
    </DisableOverlay>
  );
};

export default BasePrompt;
