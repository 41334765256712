import { useGlenVocabProfile } from "../../../util/Profile/ProfileUtil";
import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { TYPES as types } from "../../../components/Lessons/util/groups";
import LessonGroup from "../../../components/Lessons/LessonGroup/LessonGroup";

export default () => {
  const profile = useGlenVocabProfile();
  const lessonResource = useResourceFromRegistry("vocabLessons");
  const lessonGroupResource = useResourceFromRegistry("vocabLessonGroups");

  return (
    <LessonGroup
      profile={profile}
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      type={types.GLEN_VOCAB}
    />
  );
};
