import styles from "./ImageAnswer.module.scss";
import classnames from "classnames";
import React, { useMemo } from "react";
import Card from "../../../../components/Card/Card";
import { useResourceFromRegistry } from "../../../../util/Resource/useResource";
import AnswerStatus from "../../../../constants/answerStatus";
import noop from "lodash/noop";

/**
 * @component for displaying a card with an image
 * @param {AnswerStatus} props.answerStatus is the status of the card
 * @param {string} props.word               is the word used to lookup the resource flashcard
 * @param {string} props.path               is a direct path for the image
 * @param {function} props.onSelect         is the callback for clicking on the answer card
 * @return {JSX.Element}                    is a div containing the image card
 */
export default ({
  answerStatus = AnswerStatus.NONE,
  word = null,
  path = null,
  onSelect = noop,
  ...props
}) => {
  if ((!word && !path) || (word && path)) {
    throw new TypeError(
      "must specify exactly one of word (for flashcard) or path (to image directly)"
    );
  }

  const flashcardResource = useResourceFromRegistry("flashcards");
  const flashcardResponse = flashcardResource.getFlashcard(word);

  const imagePath = useMemo(() => (word ? flashcardResponse.image : path), [
    word,
    flashcardResponse.image,
    path,
  ]);

  const clickedCorrect = answerStatus === AnswerStatus.CORRECT;
  const clickedIncorrect = answerStatus === AnswerStatus.INCORRECT;
  const showReviewCircle = answerStatus === AnswerStatus.REVIEW_CORRECT;

  return (
    <div key={imagePath} className={styles.imageClickOption}>
      <Card
        outline={classnames({
          green: clickedCorrect,
          red: clickedIncorrect,
        })}
        animationClass={classnames({
          bounce: clickedCorrect,
          shake: clickedIncorrect,
        })}
        withCheck={clickedCorrect}
        withCross={clickedIncorrect}
        withCircle={showReviewCircle}
        image={imagePath}
        onClick={onSelect}
        {...props}
      />
    </div>
  );
};
