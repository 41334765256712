import useFirebase from "../Firebase/useFirebase";
import useProfile from "./useProfile";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateProfile } from "../../actions/profile";

const ProfileSync = ({ children }) => {
  // when a firebase change occurs to a deployment account profile, we sync it back to the Redux store.
  // this callback happens immediately on profile change, regardless of whether the user is online/offline
  const firebase = useFirebase();
  const profile = useProfile();
  const dispatch = useDispatch();
  useEffect(() => {
    const listener = firebase
      .deploymentAccount(profile.id)
      .onSnapshot((data) => {
        const snapshot = data.data();
        if (snapshot) {
          dispatch(updateProfile(snapshot.profile));
        }
      });
    return () => listener();
  }, []);
  return children;
};
export default ProfileSync;
