import React from "react";
import { useResourceFromRegistry } from "../../../util/Resource/useResource";
import { useGlenABCProfile } from "../../../util/Profile/ProfileUtil";
import Lesson from "../../../components/Lessons/Lesson/Lesson";
import { TYPES as types } from "../../../components/Lessons/util/groups";

export default () => {
  const profile = useGlenABCProfile();
  const lessonResource = useResourceFromRegistry("ABCLessons");
  const lessonGroupResource = useResourceFromRegistry("ABCLessonGroups");

  return (
    <Lesson
      profile={profile}
      lessonResource={lessonResource}
      lessonGroupResource={lessonGroupResource}
      type={types.GLEN_ABC}
      allowAssessStrengthen={false}
    />
  );
};
