import React, { useCallback, useContext, useEffect, useState } from "react";
import Card from "./../../components/Card/Card";
import wait from "./../../util/wait";
import AudioContext from "../../util/Audio/context";
import styles from "./SelectAnswer.module.scss";
import RoundedText from "../../components/RoundedText/RoundedText";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import Backend, { Preview } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/cjs/HTML5toTouch";
import noop from "lodash/noop";
import classnames from "classnames";
import DisableOverlay from "../../components/DisableOverlay/DisableOverlay";

export const MODES = {
  LEARNING: "learning",
  CORRECTION: "correction",
  EVALUATION: "evaluation",
};

//e.g.
// question = { text: "What shape is this?", audio: "/audio/random.mp3" }
// answers = [ { text: "triangle", audio: "abc.mp3"}, { text: "square", audio: "abc.mp3"} ]
const DragBox = ({ children, onClick, onDrag, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: "word" },
    begin: onDrag,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  return (
    <div ref={drag} style={{ opacity }} onClick={onClick}>
      <RoundedText color="black" size="sm" bg="white">
        {children}
      </RoundedText>
    </div>
  );
};

const DropBox = ({
  text = "?",
  showRedHighlight = false,
  showGreenHighlight = false,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "word",
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <div ref={drop}>
      <RoundedText
        withRedHighlightAnimation={showRedHighlight}
        withGreenHighlightAnimation={showGreenHighlight}
        color="black"
      >
        {text}
      </RoundedText>
    </div>
  );
};

const GeneratePreview = () => {
  const { style, item } = useContext(Preview.Context);
  return (
    <div style={{ ...style, width: "25vw", zIndex: 999 }}>
      <RoundedText size="sm" color="black">
        {item.name}
      </RoundedText>
    </div>
  );
};
let SelectAnswer = ({
  contextComponent,
  question,
  answers,
  correctIndex,
  mode,
  teachingAudio,
  onStart = noop,
  onComplete = noop,
}) => {
  const [greenHighlightDrop, setGreenHighlightDrop] = useState(false);
  const [redHighlightDrop, setRedHighlightDrop] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [showDropBox, setShowDropBox] = useState(false);

  const [isGameOverView, setIsGameOverView] = useState(false);
  const [clicksEnabled, setClicksEnabled] = useState(false);

  const audioService = useContext(AudioContext);

  const playQuestionAudio = useCallback(() => {
    const fx = async () => {
      setClicksEnabled(false);
      await audioService.playAudio(question.audio);
      setClicksEnabled(true);
    };
    fx();
  }, [audioService, question]);

  const onDrop = async (index) => {
    if (index === correctIndex) {
      audioService.composeAndPlaySingleAudio("soundfx", "correct");
      setIsGameOverView(true);
      setGreenHighlightDrop(true);
      await wait(1000);
      setGreenHighlightDrop(false);
      await wait(1000);
      if (mode === MODES.LEARNING && teachingAudio) {
        let formattedAudio = Array.isArray(teachingAudio)
          ? teachingAudio
          : [teachingAudio];
        for (const audio of formattedAudio) {
          await audioService.playAudio(audio);
        }
      }
      await wait(500);
      onComplete(true);
    } else {
      audioService.composeAndPlaySingleAudio("soundfx", "incorrect");
      setRedHighlightDrop(true);
      await wait(1000);
      setRedHighlightDrop(false);
      await wait(500);
      if (mode === MODES.EVALUATION) {
        onComplete(false);
      }
    }
  };

  useEffect(() => {
    onStart();
    let animation = async () => {
      await wait(200);
      setShowContext(true);
      await wait(1000);
      setShowQuestion(true);
      await playQuestionAudio();
      await wait(1000);
      setShowAnswers(true);
      await wait(1000);
      setShowDropBox(true);
      setClicksEnabled(true);
    };
    animation();
  }, [audioService, onStart, playQuestionAudio, question]);

  return (
    <DisableOverlay enable={!clicksEnabled}>
      <DndProvider backend={Backend} options={HTML5toTouch}>
        <Preview>
          <GeneratePreview />
        </Preview>
        <div className={styles.container}>
          <div className={styles.context}>
            {showContext && (
              <div className="animated fadeIn">
                {React.createElement(contextComponent)}
              </div>
            )}
          </div>
          <div className={styles.question}>
            {showQuestion && (
              <div className="animated fadeIn">
                <RoundedText onClick={playQuestionAudio} color="black">
                  {question.text}
                </RoundedText>
              </div>
            )}
          </div>
          <div className={styles.answers}>
            {showAnswers &&
              answers.map((a, index) => {
                return (
                  <div
                    className={classnames("w-100 ml-1 mr-1 animated fadeIn", {
                      [styles.hidden]: isGameOverView,
                    })}
                    key={a.text}
                  >
                    <DragBox
                      onClick={() => audioService.playAudio(a.audio)}
                      onDrop={() => onDrop(index)}
                    >
                      {a.text}
                    </DragBox>
                  </div>
                );
              })}
          </div>

          <div className={styles.dropBoxContainer}>
            {showDropBox && (
              <div className="animated fadeIn">
                <DropBox
                  showRedHighlight={redHighlightDrop}
                  showGreenHighlight={greenHighlightDrop}
                  text={isGameOverView ? answers[correctIndex].text : "?"}
                />
              </div>
            )}
          </div>
        </div>
      </DndProvider>
    </DisableOverlay>
  );
};

let SelectAnswerWithSingleImage = ({ context, ...props }) => {
  let ContextComponent = () => <Card image={context.image} />;
  return <SelectAnswer contextComponent={ContextComponent} {...props} />;
};

export { SelectAnswerWithSingleImage };
