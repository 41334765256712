import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AuthedAndProfiledTopBar,
  BottomBar,
  GameContainer,
  GameWrapper,
  ProgressBar,
} from "../../../../layouts/GLENLearnLayout/GLENLearnLayout";
import LessonA3Manager from "./manager";
import ImageClickWithAnalytics from "../../../../games/ImageClick/ImageClickWithAnalytics";
import wait from "../../../../util/wait";

const RepeatedImageClickGame = ({ manager, updateProgress, onComplete }) => {
  const blacklist = useRef([]);
  const [gameState, setGameState] = useState(null);

  const onRoundComplete = (wasCorrect) => {
    if (wasCorrect) {
      manager.markWordSuccess(gameState.targetWord);
    } else {
      manager.markWordFailure(gameState.targetWord);
    }
    updateProgress().then(() => {
      if (manager.getCurrentProgress() >= 1) {
        onComplete();
      } else {
        initNewGame(gameState.trialNum + 1);
      }
    });
  };

  const initNewGame = (trialNum) => {
    // after four trials, we reset the blacklist
    if (trialNum === 4) {
      blacklist.current = [];
    }
    const { targetWord, confusionWords } = manager.getNextGame(
      blacklist.current
    );
    setGameState({ trialNum, targetWord, confusionWords });
    blacklist.current.push(targetWord);
  };

  useEffect(() => initNewGame(1), []);
  if (!gameState) {
    return null;
  }

  return (
    <ImageClickWithAnalytics
      key={gameState.trialNum}
      targetWord={gameState.targetWord}
      confusionWords={gameState.confusionWords}
      onComplete={onRoundComplete}
    />
  );
};

const useGameContent = (parameters, onComplete) => {
  const manager = useMemo(() => new LessonA3Manager(parameters), [parameters]);
  const [currentProgress, setCurrentProgress] = useState(0);
  const updateProgress = () => {
    setCurrentProgress(manager.getCurrentProgress());
    return wait(200);
  };
  const component = (
    <RepeatedImageClickGame
      manager={manager}
      updateProgress={updateProgress}
      onComplete={onComplete}
    />
  );
  return [component, currentProgress];
};

export default ({ parameters, onComplete, profileBoxRef }) => {
  const [GameComponent, currentProgress] = useGameContent(
    parameters,
    onComplete
  );
  return (
    <GameWrapper>
      <AuthedAndProfiledTopBar ref={profileBoxRef} />
      <GameContainer>{GameComponent}</GameContainer>
      <BottomBar center={<ProgressBar value={currentProgress} />} />
    </GameWrapper>
  );
};
